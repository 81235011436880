import { OrganizationTraits, ProjectPrivacy } from '@neptune/shared/core-organizations-domain';

export function hasPublicProjectsDisabledByAdmin(traits: OrganizationTraits): boolean {
  return !!traits.projectVisibilityRestricted?.disabledByAdmin.includes(ProjectPrivacy.public);
}

export function pickDefaultNonPublicVisibilityValue(traits: OrganizationTraits) {
  const limitedTo = traits.projectVisibilityRestricted?.limitedTo;

  if (!limitedTo || limitedTo.includes(ProjectPrivacy.workspace)) {
    return ProjectPrivacy.workspace;
  }

  return ProjectPrivacy.private;
}

export function getPrivacySettingsDisabledByAdmin(
  traits: OrganizationTraits,
): ProjectPrivacy[] | undefined {
  return traits.projectVisibilityRestricted?.disabledByAdmin;
}

export function hasPrivatePrivacySupport(traits: OrganizationTraits): boolean {
  return (
    !traits.projectVisibilityRestricted ||
    traits.projectVisibilityRestricted.limitedToByPlan.includes(ProjectPrivacy.private)
  );
}
