import React from 'react';
import { ProjectAdminStorageErrorBanner } from '@neptune/project-ui';
import { ChangeProjectStorageQuotaModalContainer } from './ChangeProjectStorageQuotaModalContainer';
import { useLocalModal } from '@neptune/shared/common-util';
import { useGetProjectStorage } from '@neptune/project-business-logic';

type ProjectAdminStorageErrorBannerContainerProps = {
  projectIdentifier: string;
  refresh: () => Promise<void>;
};

export const ProjectAdminStorageErrorBannerContainer: React.FC<
  ProjectAdminStorageErrorBannerContainerProps
> = ({ projectIdentifier, refresh }) => {
  const { isOpen, open, close } = useLocalModal();
  const {
    loading: isLoading,
    value: projectStorage,
    refresh: refreshStorageLimit,
  } = useGetProjectStorage(projectIdentifier);

  const handleRefresh = async () => {
    await Promise.all([refreshStorageLimit(), refresh()]);
  };

  return (
    <>
      <ProjectAdminStorageErrorBanner onChangeLimit={open} />
      {!isLoading && (
        <ChangeProjectStorageQuotaModalContainer
          projectIdentifier={projectIdentifier}
          isOpen={isOpen}
          onClose={close}
          storageLimit={projectStorage?.storageLimit}
          refresh={handleRefresh}
        />
      )}
    </>
  );
};
