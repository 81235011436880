import {
  OrganizationTraits,
  ProjectPrivacy,
  PrivacyAvailability,
} from '@neptune/shared/core-organizations-domain';

export function hasLessPrivacy(from: ProjectPrivacy, to: ProjectPrivacy): boolean {
  return (
    (from === ProjectPrivacy.private && to === ProjectPrivacy.public) ||
    (from === ProjectPrivacy.private && to === ProjectPrivacy.workspace) ||
    (from === ProjectPrivacy.workspace && to === ProjectPrivacy.public)
  );
}

export function mayContributorsLoseAccess(from: ProjectPrivacy, to: ProjectPrivacy): boolean {
  return from === ProjectPrivacy.workspace && to === ProjectPrivacy.private;
}

export function isProjectPrivacyAvailable(
  privacy: ProjectPrivacy,
  organizationTraits: OrganizationTraits,
): boolean {
  if (!organizationTraits.projectVisibilityRestricted) {
    return true;
  }

  return organizationTraits.projectVisibilityRestricted.limitedTo.includes(privacy);
}

export function getPrivacyAvailability(
  privacy: ProjectPrivacy,
  organizationTraits: OrganizationTraits,
): PrivacyAvailability {
  const restrictions = organizationTraits.projectVisibilityRestricted;

  if (!restrictions) {
    return {
      privacy,
      available: true,
      availableInPlan: true,
      disabledByAdmin: false,
    };
  }

  return {
    privacy,
    available: restrictions.limitedTo.includes(privacy),
    availableInPlan: restrictions.limitedToByPlan.includes(privacy),
    disabledByAdmin: restrictions.disabledByAdmin.includes(privacy),
  };
}

export function calculateProjectPrivacyAvailability(
  organizationTraits: OrganizationTraits,
): PrivacyAvailability[] {
  const availablePrivacySettings = [
    ProjectPrivacy.private,
    ProjectPrivacy.workspace,
    ProjectPrivacy.public,
  ];
  return availablePrivacySettings.map((privacy) =>
    getPrivacyAvailability(privacy, organizationTraits),
  );
}
