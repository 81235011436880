import React from 'react';
import { isEmpty } from 'lodash';
import type { FilterResultItem } from '@neptune/shared/search-domain';
import {
  ActiveListElementProvider,
  ScrollIntoView,
  bemBlock,
  Dropdown,
  ActiveListElementChildrenProps,
  Text,
} from '@neptune/shared/venus-ui';

import { FilterSearchItem, FilterSearchItemProps } from './FilterSearchItem';
import './EnhancedFilterSearchResults.less';

interface FilterSearchResultsWithAdditionalSearchByNameProps<T> {
  query: string;
  results: FilterResultItem<T>[];
  onSelect?: (value: T) => void;
  RenderItem?: React.ComponentType<FilterSearchItemProps<T>>;
  className?: string;
  loading?: boolean;
  error?: boolean;
  onSearchByNameSelected: (query: string) => void;
}

const block = bemBlock('filter-search-results');

type SearchByNameItem = { type: string };
const SEARCH_BY_NAME_TYPE = 'SEARCH_BY_NAME_TYPE';

function isFilterResultItem(
  value: FilterResultItem<unknown> | SearchByNameItem,
): value is FilterResultItem<unknown> {
  return 'original' in value;
}

/** @deprecated Use Query builder and NameSearch.tsx instead */
export function FilterSearchResultsWithAdditionalSearchByName<T>({
  query,
  results,
  onSelect,
  RenderItem = FilterSearchItem,
  className,
  loading,
  error,
  onSearchByNameSelected,
}: FilterSearchResultsWithAdditionalSearchByNameProps<T>) {
  type Entry = FilterResultItem<T> | SearchByNameItem;

  const handleSelectItem = React.useCallback(
    (event: KeyboardEvent, entry: Entry) => {
      if (isFilterResultItem(entry)) {
        return onSelect?.(entry.original);
      }

      return onSearchByNameSelected(query);
    },
    [onSearchByNameSelected, onSelect, query],
  );

  const entries = React.useMemo(
    () => [{ type: SEARCH_BY_NAME_TYPE, name: query }, ...results],
    [query, results],
  );

  return (
    <div
      className={block({ extra: className })}
      data-query={query}
      data-loaded={!loading && !error}
    >
      <ActiveListElementProvider<Entry>
        entries={entries}
        onActivate={handleSelectItem}
        onKeyDown={(event: Event) => event.preventDefault()}
      >
        {({
          entry,
          select,
          isSelected: isActive,
          index,
        }: ActiveListElementChildrenProps<Entry>) => (
          <ScrollIntoView key={index} enabled={isActive}>
            {isFilterResultItem(entry) ? (
              <RenderItem
                onClick={(event) => {
                  event.preventDefault();
                  onSelect?.(entry.original);
                  select(event);
                }}
                value={entry.original}
                match={entry.string}
                active={isActive}
              />
            ) : (
              <>
                <Dropdown.Item
                  label={
                    query ? (
                      <Text fontWeight="normal">
                        Search <Text fontWeight="semibold">{query}</Text> in name
                      </Text>
                    ) : (
                      <Text fontWeight="normal">Search by name</Text>
                    )
                  }
                  data-role="search-by-name-item"
                  onClick={(event) => {
                    onSearchByNameSelected(query);
                    select(event);
                  }}
                  active={isActive}
                />
                {!isEmpty(results) && <Dropdown.Separator className={block('separator')} />}
              </>
            )}
          </ScrollIntoView>
        )}
      </ActiveListElementProvider>
    </div>
  );
}
