import React from 'react';
import { ProjectUsageSettings } from '@neptune/project-ui';
import { useGetProjectStorage } from '@neptune/project-business-logic';
import { useLocalModal } from '@neptune/shared/common-util';
import { ChangeProjectStorageQuotaModalContainer } from './ChangeProjectStorageQuotaModalContainer';

type ProjectUsageSettingsContainerProps = {
  projectIdentifier: string;
};

export const ProjectUsageSettingsContainer: React.FC<ProjectUsageSettingsContainerProps> = ({
  projectIdentifier,
}) => {
  const { isOpen, open, close } = useLocalModal();
  const {
    loading: isLoading,
    value: projectStorage,
    refresh,
  } = useGetProjectStorage(projectIdentifier);

  return (
    <>
      <ProjectUsageSettings
        isLoading={isLoading}
        storageLimit={projectStorage?.storageLimit}
        onOpenStorageModal={open}
      />
      <ChangeProjectStorageQuotaModalContainer
        projectIdentifier={projectIdentifier}
        isOpen={isOpen}
        onClose={close}
        storageLimit={projectStorage?.storageLimit}
        refresh={refresh}
      />
    </>
  );
};
