import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSideBarVisibility } from 'state/selectors';
import { collapseSideBar, expandSideBar } from 'state/ui/side-bars/actions';

type UseCollapsibleSidebarValue = {
  sidebarExpanded: boolean;
  toggleSidebar: () => void;
  expandSidebar: () => void;
  collapseSidebar: () => void;
};

export function useCollapsibleSidebar(sidebarId: string): UseCollapsibleSidebarValue {
  const sidebarExpanded = useSelector((state) => getSideBarVisibility(state, sidebarId));

  const dispatch = useDispatch();
  const expandSidebar = React.useCallback(() => {
    dispatch(expandSideBar(sidebarId));
  }, [dispatch, sidebarId]);

  const collapseSidebar = React.useCallback(() => {
    dispatch(collapseSideBar(sidebarId));
  }, [dispatch, sidebarId]);

  const toggleSidebar = sidebarExpanded ? collapseSidebar : expandSidebar;

  return {
    sidebarExpanded,
    collapseSidebar,
    expandSidebar,
    toggleSidebar,
  };
}
