import { LayoutType } from '@neptune/shared/common-domain';
import { NThunkAction } from 'state/types';
import { AnyAction } from 'redux';

import { getExpandableLayoutState } from './selectors';
import { storeState } from '../storage-utils';

export enum ExpandableLayoutActionTypes {
  Set = 'SetExpandableLayout',
}

export const setExpandableLayout = (
  id: string,
  layout: LayoutType,
  projectIdentifier: string,
): NThunkAction<void, AnyAction> => {
  return (dispatch, getState) => {
    dispatch({
      type: ExpandableLayoutActionTypes.Set,
      payload: {
        projectIdentifier,
        id,
        layout,
      },
    });

    const expandableLayoutState = getExpandableLayoutState(getState());
    storeState(expandableLayoutState);
  };
};

export type ExpandableLayoutActions = {
  type: ExpandableLayoutActionTypes.Set;
  payload: {
    projectIdentifier: string;
    id: string;
    layout: LayoutType;
  };
};
