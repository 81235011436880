import {
  selectDaysToEndOfTrial,
  selectDaysWithoutPayment,
  selectMonitoringHoursAvailable,
  selectPercentOfStorageQuotaUsage,
} from './selectors';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

export function useBannerDetailsData(organizationName: string) {
  const daysToEndOfTrial = useSelector((state: AppState) =>
    selectDaysToEndOfTrial(state, organizationName),
  );
  const daysWithoutPayment = useSelector((state: AppState) =>
    selectDaysWithoutPayment(state, organizationName),
  );
  const monitoringHoursAvailable = useSelector((state: AppState) =>
    selectMonitoringHoursAvailable(state, organizationName),
  );
  const percentOfStorageQuotaUsage = useSelector((state: AppState) =>
    selectPercentOfStorageQuotaUsage(state, organizationName),
  );

  return {
    daysToEndOfTrial,
    daysWithoutPayment,
    monitoringHoursAvailable,
    percentOfStorageQuotaUsage,
  };
}
