import React from 'react';
import { isEqual, noop } from 'lodash';

import {
  SearchCriterion,
  SearchQuery,
  SearchQueryModelConverter,
} from '@neptune/search-query-domain';

export type SearchQueryContextValue = [string | undefined, (searchQuery: string) => void];

export const SearchQueryContext = React.createContext<SearchQueryContextValue>([undefined, noop]);

export const useSearchQuery = () => {
  const [rawQuery, setRawQuery] = React.useContext(SearchQueryContext);
  const metaQuery = React.useMemo(
    () => SearchQueryModelConverter.convertNqlToSearchQuery(rawQuery),
    [rawQuery],
  );

  const setQuery = React.useCallback(
    (query: SearchQuery) => setRawQuery(SearchQueryModelConverter.convertSearchQueryToNql(query)),
    [setRawQuery],
  );

  const appendCriterion = React.useCallback(
    (criterion) => {
      if (!isNewCriterion(criterion, metaQuery.criteria)) {
        return;
      }

      const newQuery = {
        criteria: [...metaQuery.criteria, criterion],
        operator: metaQuery.operator,
      };

      setRawQuery(SearchQueryModelConverter.convertSearchQueryToNql(newQuery));
    },
    [setRawQuery, metaQuery],
  );

  return {
    metaQuery,
    rawQuery,
    setQuery,
    appendCriterion,
  };
};

function isNewCriterion(criterion: SearchCriterion, criteria: (SearchCriterion | SearchQuery)[]) {
  return criteria.every((item) => {
    return !isEqual(item, criterion);
  });
}
