import React from 'react';
import config from 'config';

import { LazyModelRegistryModelVersionView } from '@neptune/model-registry-model-version-feature';
import { lazyWrap } from '@neptune/shared/common-ui';
import { useSelector } from 'react-redux';
import { getShortIdFromRouteParams, getTypeFromRouteParams } from 'state/selectors-global';

const LazyModelRegistryNotAvailableFeature = lazyWrap(
  async () =>
    (
      await import(
        /* webpackChunkName: "model-registry-not-available" */ '@neptune/model-registry-common-ui'
      )
    ).ModelRegistryNotAvailableFeature,
);

const LazyModelRegistryModelsView = lazyWrap(
  async () =>
    (
      await import(
        /* webpackChunkName: "model-registry" */ '@neptune/model-registry-models-feature'
      )
    ).ModelRegistryModelsView,
);

const LazyModelRegistryModelView = lazyWrap(
  async () =>
    (await import(/* webpackChunkName: "model-registry" */ '@neptune/model-registry-model-feature'))
      .ModelView,
);

export const ModelRegistryEntryPoint: React.FC = () => {
  const shortId = useSelector(getShortIdFromRouteParams);
  const type = useSelector(getTypeFromRouteParams);

  if (config.withNewEmptyState) {
    return <LazyModelRegistryNotAvailableFeature />;
  }

  if (!shortId) {
    return <LazyModelRegistryModelsView />;
  }

  switch (type) {
    case 'model': {
      return <LazyModelRegistryModelView />;
    }

    case 'modelVersion': {
      return <LazyModelRegistryModelVersionView />;
    }

    default:
      return null;
  }
};
