import { lazyWrap } from '@neptune/shared/common-ui';
import { VersionTab } from '@neptune/model-registry-model-version-domain';

const LazyModelVersionMetadataView = lazyWrap(
  async () =>
    (
      await import(
        /* webpackChunkName: "model-registry-model-version" */ './ModelVersionMetadataView'
      )
    ).ModelVersionMetadataView,
);

export const versionTabs: VersionTab[] = [
  {
    id: 'metadata',
    component: LazyModelVersionMetadataView,
    dataRole: 'model-version-metadata',
    glyph: 'folder-open',
    label: 'All metadata',
  },
];
