import React from 'react';
import { StageChangeConfirmationContext } from '@neptune/model-registry-common-domain';

// context approach is used instead of useGenericModal because callback is required at data, which can't be kept at redux
export const useStageChangeConfirmationModal = () => {
  const context = React.useContext(StageChangeConfirmationContext);

  if (!context) {
    throw new Error(
      'useStageChangeConfirmationModal should be used under StageChangeConfirmationContext',
    );
  }

  return context;
};
