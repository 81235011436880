import React from 'react';
import { ProjectPrivacy } from '@neptune/shared/core-organizations-domain';
import { VisibilityChangeConfirmationModalProps } from './interface';
import { PrivatePrivacyChangeConfirmationModal } from './PrivatePrivacyChangeConfirmationModal';
import { PublicPrivacyChangeConfirmationModal } from './PublicPrivacyChangeConfirmationModal';
import { WorkspacePrivacyChangeConfirmationModal } from './WorkspacePrivacyChangeConfirmationModal';

const modals: Partial<
  Record<ProjectPrivacy, React.ComponentType<VisibilityChangeConfirmationModalProps> | undefined>
> = {
  [ProjectPrivacy.private]: PrivatePrivacyChangeConfirmationModal,
  [ProjectPrivacy.public]: PublicPrivacyChangeConfirmationModal,
  [ProjectPrivacy.workspace]: WorkspacePrivacyChangeConfirmationModal,
};

export const PrivacyChangeConfirmationModal: React.FC<VisibilityChangeConfirmationModalProps> = ({
  isOpen,
  target,
  onClose,
  onConfirm,
}) => {
  const ConcreteConfirmationModal =
    modals[target ?? ProjectPrivacy.notSupported] || PublicPrivacyChangeConfirmationModal;
  return (
    <ConcreteConfirmationModal
      isOpen={isOpen}
      target={target}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};
