import React from 'react';
import { chunk } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { KnownAttributes } from 'domain/experiment/attribute';
import { searchLeaderboard } from '@neptune/shared/leaderboard-domain';
import { Entity } from '@neptune/shared/entity-domain';

const MAX_IDS_PER_REQUEST = 100;
const EMPTY_SELECTION: Entity[] = [];

export function useEntityIdentificationEntries(
  projectIdentifier: string,
  experimentNames: string[],
  shortIds: string[],
) {
  const queryClient = useQueryClient();

  const result = useQuery({
    cacheTime: 10_000,
    keepPreviousData: true,
    enabled: !!experimentNames.length || !!shortIds.length,
    queryFn: () => queryFieldsFromAllPages(projectIdentifier, experimentNames, shortIds),
    queryKey: ['entity-identification-map', projectIdentifier, { experimentNames, shortIds }],
  });

  const invalidateQuery = React.useCallback(() => {
    queryClient.invalidateQueries(['entity-identification-map', projectIdentifier]);
  }, [projectIdentifier, queryClient]);

  return { result, invalidateQuery };
}

async function queryFieldsFromAllPages(
  projectIdentifier: string,
  names: string[],
  runIds: string[],
) {
  if (!names.length && !runIds.length) {
    return Promise.resolve(EMPTY_SELECTION);
  }

  const namesQuery = names.map((name) => `(${KnownAttributes.Name}:string = "${name}")`);
  const runsQuery = runIds.map((id) => `(${KnownAttributes.Id}:string = "${id}")`);

  return (
    await Promise.all(
      chunk([...namesQuery, ...runsQuery], MAX_IDS_PER_REQUEST).map(async (chunkIds) => {
        const leaderboard = await searchLeaderboard({
          attributesToFetch: [KnownAttributes.Id, KnownAttributes.Name],
          pagination: { limit: MAX_IDS_PER_REQUEST, offset: 0 },
          query: chunkIds.join(' OR '),
          experimentsOnly: true,
          projectIdentifier,
          type: ['run'],
          sorting: {
            dir: 'ascending',
            sortBy: {
              type: 'datetime',
              aggregationMode: 'auto',
              name: KnownAttributes.CreationTime,
            },
          },
        });

        return leaderboard.entries;
      }),
    )
  ).flat();
}
