// Libs
import * as Sentry from '@sentry/browser';

// App
import { createNormalizedRouteTest } from 'router/helpers';

// Module
import { InitParams, KnownSignals } from '../types';
import { CustomTracingBus } from '../CustomTracingBus';

const TRANSACTION_NAME = 'projects_loaded';

export function ProjectsLoadTransaction({ route, isMobile }: InitParams) {
  if (!route) {
    return;
  }

  const testRoute = createNormalizedRouteTest(route.name);

  if (!testRoute('organization.projects')) {
    return;
  }

  const transaction = Sentry.startTransaction({ name: TRANSACTION_NAME });
  transaction.setTag('is_mobile', isMobile);

  const finishToken = CustomTracingBus.subscribe(
    KnownSignals.PROJECTS_RENDERED,
    (_, { items, renderedItems, isError }) => {
      transaction.setTag('fetched_elements', items);
      transaction.setTag('rendered_elements', renderedItems);
      transaction.setTag('is_error', isError);
      transaction.finish();
      removeListeners();
    },
  );

  const changeToken = CustomTracingBus.subscribe(KnownSignals.ROUTE_CHANGED, (_, { route }) => {
    if (!route) {
      return;
    }

    const testRoute = createNormalizedRouteTest(route.name);

    if (!testRoute('organization.projects')) {
      removeListeners();
    }
  });

  function removeListeners() {
    CustomTracingBus.unsubscribe(finishToken);
    CustomTracingBus.unsubscribe(changeToken);
  }
}
