import React from 'react';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import { SearchInput } from '@neptune/shared/common-ui';
import { Layout, bemBlock, LayoutRow, Dropdown, Text } from '@neptune/shared/venus-ui';
import { Version } from '@neptune/shared/dashboard-domain';
import { RouterSimpleState } from '@neptune/shared/routing-domain';

import { VersionsList } from './VersionsList';

import './VersionsDropdownContent.less';

const block = bemBlock('versions-dropdown-content');

type VersionsDropdownContentProps = {
  searchValue?: string;
  versions?: Version[];
  loading?: boolean;
  onChange: (value: string) => void;
  paginationElement?: React.ReactNode;
  makeVersionRoute: (id: string) => RouterSimpleState | undefined;
  branchViewRoute?: RouterSimpleState;
  onShareLink?: (id: string) => void;
  onRestore?: (id: string) => void;
  onEditName?: (id: string) => void;
  onEditDescription?: (id: string) => void;
  latestVersionId?: string;
  currentVersionId?: string;
  noResults?: boolean;
};

export const VersionsDropdownContent: React.FC<VersionsDropdownContentProps> = ({
  searchValue,
  versions,
  loading,
  onChange,
  paginationElement,
  makeVersionRoute,
  branchViewRoute,
  onShareLink,
  onRestore,
  onEditName,
  onEditDescription,
  latestVersionId,
  currentVersionId,
  noResults,
}) => {
  return (
    <Dropdown.Menu
      className={block()}
      withPadding="md"
      justifyContent="start"
      width={400}
      data-role="versions-dropdown-content"
    >
      <Layout.Column withPadding="xs" overflow="hidden" spacedChildren="sm">
        <SearchInput
          value={searchValue || ''}
          className={block('search')}
          glyph="search"
          onChange={onChange}
          placeholder="Search..."
          withIcon
        />
        {noResults ? (
          <Layout.Column withPadding={['md', 'none', 'none', 'none']}>
            <Text size="xs" color="text-secondary">
              No matching results
            </Text>
          </Layout.Column>
        ) : (
          <>
            <VersionsList
              currentVersionId={currentVersionId}
              latestVersionId={latestVersionId}
              versions={versions}
              makeVersionRoute={makeVersionRoute}
              branchViewRoute={branchViewRoute}
              onShareLink={onShareLink}
              onRestore={onRestore}
              onEditName={onEditName}
              onEditDescription={onEditDescription}
            />
            <LayoutRow span="shrink">{paginationElement}</LayoutRow>
          </>
        )}
        {loading && <LoadingIndicator size="medium" isVisible centered />}
      </Layout.Column>
    </Dropdown.Menu>
  );
};
