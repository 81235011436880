import { OrganizationTraits } from '@neptune/shared/core-organizations-domain';

export const hasDashboardsLimits = (traits: OrganizationTraits): boolean =>
  !!traits.dashboardsLimits;
export const getDashboardsPerProjectLimit = (traits: OrganizationTraits): number | undefined =>
  traits.dashboardsLimits?.maxDashboardsPerProject;
export const getDashboardsPerProjectEffectiveLimit = (traits: OrganizationTraits): number =>
  traits.dashboardsLimits?.maxDashboardsPerProject !== undefined
    ? traits.dashboardsLimits?.maxDashboardsPerProject
    : Number.POSITIVE_INFINITY;
