import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNumber } from 'lodash';
import { InvitationType } from '@neptune/invitations-domain';
import { sendEventToGTM } from '@neptune/analytics-api';
import { ErrorPlaceholderContainer } from '@neptune/shared/common-feature';
import { availableOrganizationRoles } from '@neptune/shared/core-organizations-business-logic';
import {
  OrganizationRole,
  organizationRoleLabels,
} from '@neptune/shared/core-organizations-domain';
import { hasMembersManagePermission } from '@neptune/shared/core-permissions-business-logic';
import { RoleDropdown } from '@neptune/shared/user-management-ui';
import { Section } from '@neptune/shared/venus-ui';
import {
  canCreatePrivateProjects,
  createOrganizationInvitation,
  deleteOrganizationMember,
  fetchOrganizationMemberList,
  getOrganizationMembersState,
  resendExistingOrganizationInvitation,
  revokeOrganizationInvitation,
  shouldShowProjectAccessTooltip,
  sortOrganizationMemberList,
  updateOrganizationMember,
} from '@neptune/user-management-business-logic';
import {
  ALREADY_AN_ADMIN_MODAL,
  ALREADY_INVITED_MODAL_TYPE,
  DELETE_MODAL_TYPE,
  MEMBERS_LIMIT_REACHED_MODAL,
  ONLY_ADMIN_MODAL,
  OrganizationMember,
} from '@neptune/user-management-domain';
import {
  AlreadyAnAdminModal,
  AlreadyInvitedModal,
  InvitationsLimitReachedNotice,
  MemberList,
  MemberListContext,
  MemberListView,
  MembersLimitReachedModal,
  OnlyAdminModal,
} from '@neptune/user-management-ui';
import { useConfirmationModal, useGenericModal } from '@neptune/shared/common-business-logic';
import PricePerUserNoticeContainer from './PricePerUserNoticeContainer';
import { isFetchStatusFailed } from 'state/fetch-status';
import { getMembersLimit } from 'state/organization-limits/selectors';

// App
import { getOrganizationNameFromRouteParams, getWorkspaceByName } from 'state/selectors-global';
import { AppState } from 'state/types';
import { openConfirmationModal } from 'state/ui/global/confirmation-modal/actions';
import { getCurrentUser } from 'state/users/current-user/selectors';
import { AddMember, AddMemberContext } from './AddMember';
import { DeleteModal } from './DeleteModal';

export const OrganizationMemberListContainer: React.FC = () => {
  const dispatch = useDispatch();
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const currentUser = useSelector(getCurrentUser);
  const { entries, fetchStatus, lastRefreshTime, sortOptions } = useSelector(
    getOrganizationMembersState,
  );

  const currentOrganization = useSelector((state: AppState) =>
    getWorkspaceByName(state, organizationName),
  );

  const canEdit = hasMembersManagePermission(currentOrganization);

  const membersLimit = useSelector(getMembersLimit);

  const user = useSelector(getCurrentUser);

  const { isOpen: isAlreadyInvitedModalOpen, close: closeAlreadyInvitedModal } =
    useConfirmationModal(ALREADY_INVITED_MODAL_TYPE);
  const { isOpen: isMembersLimitReachedModalOpen, close: closeMembersLimitReachedModal } =
    useGenericModal(MEMBERS_LIMIT_REACHED_MODAL);
  const { isOpen: isAlreadyAnAdminModalOpen, close: closeAlreadyAnAdminModal } =
    useGenericModal(ALREADY_AN_ADMIN_MODAL);
  const { isOpen: isOnlyAdminModalOpen, close: closeOnlyAdminModal } =
    useGenericModal(ONLY_ADMIN_MODAL);

  React.useEffect(() => {
    dispatch(fetchOrganizationMemberList({ organizationIdentifier: organizationName }));
  }, [dispatch, organizationName, lastRefreshTime]);

  const handleUpdateRole = React.useCallback(
    ({ userId, role }: { userId?: string; role: OrganizationRole }) => {
      if (!userId) {
        return;
      }

      dispatch(
        updateOrganizationMember({
          organizationIdentifier: organizationName,
          userId,
          member: { role },
        }),
      );
    },
    [dispatch, organizationName],
  );

  const handleDeleteMember = React.useCallback(
    ({
      userId,
      invitationId,
      invitee,
    }: {
      userId?: string;
      invitationId?: string;
      invitee?: string;
    }) => {
      if (invitationId) {
        dispatch(openConfirmationModal(DELETE_MODAL_TYPE, { invitationId, invitee }));
      } else if (userId) {
        dispatch(openConfirmationModal(DELETE_MODAL_TYPE, { userId, organizationName }));
      }
    },
    [dispatch, organizationName],
  );

  const handleConfirmDeleteMember = React.useCallback(
    ({ invitationId, userId }: { userId?: string; invitationId?: string }) => {
      if (invitationId) {
        dispatch(
          revokeOrganizationInvitation({
            invitationId,
          }),
        );
      } else if (userId) {
        dispatch(
          deleteOrganizationMember({
            organizationIdentifier: organizationName,
            userId,
          }),
        );
      }
    },
    [dispatch, organizationName],
  );

  const handleInvite = React.useCallback(
    ({
      invitee,
      roleGrant,
      invitationType,
      addToAllProjects,
    }: {
      invitee: string;
      roleGrant: OrganizationRole;
      invitationType: InvitationType;
      addToAllProjects: boolean;
    }) => {
      dispatch(
        createOrganizationInvitation({
          organizationIdentifier: organizationName,
          invitationsEntries: [
            {
              invitee,
              invitationType,
              roleGrant,
              addToAllProjects,
            },
          ],
        }),
      );

      if (origin && currentUser?.username) {
        sendEventToGTM({
          eventName: 'invite_sent',
          senderUsername: currentUser.username,
          attributes: {
            origin: 'user-management',
          },
        });
      }
    },
    [currentUser?.username, dispatch, organizationName],
  );

  const invitesLeft = isNumber(membersLimit)
    ? Math.max(membersLimit - entries.length, 0)
    : undefined;
  const invitationsLimitReached = invitesLeft === 0;

  const handleResendInvite = React.useCallback(
    (invitationId: string) => {
      dispatch(resendExistingOrganizationInvitation({ invitationId }));
    },
    [dispatch],
  );

  const showProjectAccessTooltip = !!(
    currentOrganization &&
    shouldShowProjectAccessTooltip(currentOrganization.pricingPlan, currentOrganization.traits)
  );
  const projectAccessTooltipText = showProjectAccessTooltip
    ? 'Setting granular project access is available only in the Organization plan.'
    : undefined;
  const showAddToAllProjects = !(
    currentOrganization && !canCreatePrivateProjects(currentOrganization.traits)
  );

  if (isFetchStatusFailed(fetchStatus)) {
    return <ErrorPlaceholderContainer context="organization_member_list" withBorder />;
  }

  return (
    <MemberListView>
      {canEdit && (
        <React.Fragment>
          <Section.Title>Invite people to your workspace</Section.Title>
          <AddMember<OrganizationRole>
            context={AddMemberContext.workspace}
            invitesLeft={invitesLeft}
            isAddingBlocked={invitationsLimitReached}
            defaultRole={OrganizationRole.member}
            renderRoleDropdown={(params) => (
              <RoleDropdown
                data-role="invited-user-role"
                roleLabels={organizationRoleLabels}
                availableRoles={availableOrganizationRoles}
                {...params}
              />
            )}
            onSubmit={handleInvite}
            showAddToAllProjects={showAddToAllProjects}
          />
          {invitationsLimitReached ? (
            <InvitationsLimitReachedNotice workspaceName={organizationName} />
          ) : (
            <PricePerUserNoticeContainer membersLimit={membersLimit} />
          )}
        </React.Fragment>
      )}
      <MemberList<OrganizationMember>
        data-role="organization-member-list"
        user={user}
        entries={entries}
        fetchStatus={fetchStatus}
        sortOptions={sortOptions}
        renderRoleDropdown={({ userId, ...params }) => (
          <RoleDropdown
            {...params}
            roleLabels={organizationRoleLabels}
            availableRoles={availableOrganizationRoles}
            onSelect={(role: OrganizationRole) => handleUpdateRole({ userId, role })}
          />
        )}
        onMemberDelete={handleDeleteMember}
        onSort={(sortOptions: { sortBy: string; sortOrder: string }) =>
          dispatch(sortOrganizationMemberList(sortOptions))
        }
        context={MemberListContext.workspace}
        projectAccessTooltipText={projectAccessTooltipText}
        onInviteResend={handleResendInvite}
        canEdit={canEdit}
      />
      <AlreadyInvitedModal isOpen={isAlreadyInvitedModalOpen} close={closeAlreadyInvitedModal} />
      <MembersLimitReachedModal
        isOpen={isMembersLimitReachedModalOpen}
        close={closeMembersLimitReachedModal}
        workspaceName={organizationName}
      />
      <DeleteModal organizationName={organizationName} onConfirm={handleConfirmDeleteMember} />
      <AlreadyAnAdminModal
        isOpen={isAlreadyAnAdminModalOpen}
        close={closeAlreadyAnAdminModal}
        workspaceName={organizationName}
      />
      <OnlyAdminModal isOpen={isOnlyAdminModalOpen} close={closeOnlyAdminModal} />
    </MemberListView>
  );
};
