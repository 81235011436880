// Libs
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import classNames from 'classnames';
import { isFunction } from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { Icon } from 'neptune-core-ui/components';
import { KEY_ESCAPE } from 'common/keymap';


// Module
import './EditableInput.less';
import InputWithConfirmReset from './input-with-confirm-reset/InputWithConfirmReset';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  isEditable: PropTypes.bool,
  isInputEnabled: PropTypes.bool,
  forbidEmpty: PropTypes.bool,
  textarea: PropTypes.bool,
  children: PropTypes.node,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
};

const defaultProps = {
  value: '',
  isEditable: true,
  isInputEnabled: false,
  textarea: false,
};

class EditableInput extends React.Component {
  constructor(props) {
    super(props);

    const {
      value,
      isInputEnabled,
    } = this.props;

    this.state = {
      isInputEnabled,
      value,
      currentValue: value,
      saveEnabled: true,
    };

    this.onChange = this.onChange.bind(this);
    this.disableInputMode = this.disableInputMode.bind(this);
    this.enableInputMode = this.enableInputMode.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.saveNewValue = this.saveNewValue.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value,
        currentValue: this.props.value,
      });
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, true);
  }

  handleDocumentClick(event) {
    if (
      this.state.isInputEnabled
      && !ReactDOM.findDOMNode(this).contains(event.target)
    ) {
      this.setState({ isInputEnabled: false });
    }
  }

  updateValue() {
    this.setState({ value: this.state.currentValue });
  }

  onChange(event) {
    const newState = {};
    if (this.props.forbidEmpty) {
      newState.saveEnabled = event.target.value.length > 0;
    }
    newState.currentValue = event.target.value;

    this.setState(newState);
  }

  enableInputMode() {
    this.setState({
      isInputEnabled: true,
      saveEnabled: !this.props.forbidEmpty || this.state.value.length > 0,
    });
  }

  disableInputMode() {
    this.setState({ isInputEnabled: false });
  }

  saveNewValue(event) {
    if (event) {
      event.preventDefault();
    }

    if (!this.state.saveEnabled) {
      return;
    }

    const oldValue = this.state.value;
    const newValue = this.state.currentValue;

    this.disableInputMode();
    this.updateValue();

    if (isFunction(this.props.onChange) && oldValue !== newValue) {
      this.props.onChange({
        [this.props.name]: newValue,
      });
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === KEY_ESCAPE) {
      this.disableInputMode();
    }
  }

  renderText() {
    const {
      isEditable,
      name,
      placeholder,
      textarea,
      value,
    } = this.props;

    let noValueText = '';
    if (isEditable) {
      if (placeholder === undefined) {
        noValueText = `Add ${name}`;
      } else {
        noValueText = placeholder;
      }
    }

    const displayedText = (value).trim() ? value : noValueText;

    const displayedTextClasses = classNames(
      'editable-input__text', {
        'editable-input__text--one-line': !textarea,
      });

    return (
      <span
        className="editable-input__text-container"
        data-role="edit-action"
        onClick={this.enableInputMode}
      >
          <span className={displayedTextClasses} title={value}>{displayedText}</span>
          <Icon
            className="editable-input__edit-icon"
            glyph="pencil"
            title="Edit"
          />
        </span>
    );
  }

  renderInputEnabled() {
    const {
      value,
      maxLength,
      textarea,
    } = this.props;

    const { saveEnabled } = this.state;

    return (
      <InputWithConfirmReset
        className="editable-input__input"
        value={value}
        textarea={textarea}
        saveEnabled={saveEnabled}
        maxLength={maxLength}
        onChange={this.onChange}
        onKeyDown={this.handleKeyDown}
        onSave={this.saveNewValue}
        onReset={this.disableInputMode}
      />
    );
  }

  render() {
    const innerComponent = (this.state.isInputEnabled) ?
      this.renderInputEnabled() : this.renderText();

    const cssClass = classNames(
      this.props.className,
      'editable-input', {
        'editable-input--disabled': !this.props.isEditable,
        'editable-input--placeholder': !this.props.value,
      });

    return (
      <div
        className={cssClass}
        data-role="editable-input"
      >
        {innerComponent}
      </div>
    );
  }
}

EditableInput.propTypes = propTypes;
EditableInput.defaultProps = defaultProps;

export default EditableInput;
