import { persistObjectOptimistic, loadObject } from '@neptune/content-addressable-store-domain';

export const COMPARE_STORED_DATA_VERSION = '1';

export type CompareContent = {
  version: string;
  workspaceName: string;
  projectIdentifier: string;
  compare: {
    runIds: string[];
    experimentNames: string[];
  };
};

const isValidContentObject = (content: any): content is CompareContent => {
  return (
    typeof content === 'object' &&
    typeof content.version === 'string' &&
    typeof content.workspaceName === 'string' &&
    typeof content.projectIdentifier === 'string' &&
    typeof content.compare === 'object' &&
    Array.isArray(content.compare.runIds) &&
    Array.isArray(content.compare.experimentNames)
  );
};

export async function persistCompareStateToStore(content: CompareContent) {
  return persistObjectOptimistic({
    ...content,
    compare: {
      runIds: content.compare.runIds.sort(),
      experimentNames: content.compare.experimentNames.sort(),
    },
  });
}

export async function loadCompareStateFromStore(key: string): Promise<CompareContent | null> {
  const content = await loadObject(key, isValidContentObject);
  return content ?? null;
}
