import React from 'react';
import { LicenseStatusModal } from '@neptune/license-ui';
import { uploadLicense } from '@neptune/license-domain';

export const LicenseStatusModalContainer: React.FC<{
  isOpen: boolean;
  close: () => void;
  expirationDate: Date;
}> = ({ isOpen, close, expirationDate }) => {
  const [statusText, setStatusText] = React.useState('');

  const submitNewLicense = React.useCallback(async (newLicenseNumber: string) => {
    try {
      setStatusText('Submitting...');

      await uploadLicense(newLicenseNumber);

      setStatusText('License uploaded. This page will reload soon');

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      // The license upload endpoint always returns 200, regardless of license validity.
      setStatusText('Error occured.');
    }
  }, []);

  return (
    <LicenseStatusModal
      isOpen={isOpen}
      close={close}
      expirationDate={expirationDate}
      onSubmitNewLicense={submitNewLicense}
      submitStatusText={statusText}
    />
  );
};
