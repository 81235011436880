import {
  GRID_LAYOUT_COLUMNS,
  LayoutMinDimensions,
  LayoutRect,
  compareRectsByYX,
} from '@neptune/shared/grid-layout-domain';

type LayoutForCompaction = LayoutRect & Partial<LayoutMinDimensions>;

// Apply no overlaps and vertical compaction. Assumes that all widgets fit inside grid.
//
// This simulates compaction from react-grid-layout:
// https://github.com/react-grid-layout/react-grid-layout/blob/1.1.1/lib/utils.js#L179
//
// but we only do it vertically and don't handle static layouts. We also know column number
// is small, so we can optimize for that.
//
// It is not crucial to mimic react-grid-layout's behavior exactly - we're mostly concerned
// with making sure that the layouts we provide to the library are correcly compacted and
// exactly as they will be displayed.
export function compactGridLayouts<T extends LayoutForCompaction>(layouts: T[]): void {
  layouts.sort(compareRectsByYX);
  const bottomLine = new Array(GRID_LAYOUT_COLUMNS).fill(0);

  for (const layout of layouts) {
    const xEnd = layout.x + layout.w;
    layout.y = Math.max(...bottomLine.slice(layout.x, xEnd));

    const yEnd = layout.y + layout.h;

    for (let i = layout.x; i < xEnd; ++i) {
      bottomLine[i] = yEnd;
    }
  }

  // The order might have been broken, make sure it's restored.
  layouts.sort(compareRectsByYX);
}
