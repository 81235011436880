import {
  ColumnIdentifier,
  FloatColumn,
  LeaderboardColumn,
} from '@neptune/shared/leaderboard-domain';
import { ColumnsRootKey } from './ColumnsRootKey';

export enum OrderedColumnsActionTypes {
  Set = 'ORDERED_COLUMNS_SET',
  SetDefaultColumns = 'ORDERED_COLUMNS_SET_DEFAULT_COLUMNS',
  Update = 'ORDERED_COLUMN_UPDATE',
  Move = 'ORDERED_COLUMN_MOVE',
  Show = 'ORDERED_COLUMN_SHOW',
  ShowPinned = 'ORDERED_COLUMN_SHOW_PINNED',
  Hide = 'ORDERED_COLUMN_HIDE',
}

export type OrderedColumnsAction =
  | {
      type: OrderedColumnsActionTypes.Set;
      payload: {
        columns: LeaderboardColumn[];
        columnsSetId: string;
        columnsRootKey: ColumnsRootKey;
        persist: boolean;
      };
    }
  | {
      type: OrderedColumnsActionTypes.Update;
      payload: {
        change: ColumnChange;
        column: ColumnIdentifier;
        columnsRootKey: ColumnsRootKey;
        columnsSetId: string;
        persist: boolean;
      };
    }
  | {
      type: OrderedColumnsActionTypes.Move;
      payload: {
        order: ColumnOrder;
        source: ColumnIdentifier;
        target: ColumnIdentifier;
        columnsRootKey: ColumnsRootKey;
        columnsSetId: string;
        persist: boolean;
      };
    }
  | {
      type: OrderedColumnsActionTypes.Show;
      payload: {
        column: ColumnIdentifier;
        columnsRootKey: ColumnsRootKey;
        columnsSetId: string;
        persist: boolean;
      };
    }
  | {
      type: OrderedColumnsActionTypes.ShowPinned;
      payload: {
        column: ColumnIdentifier;
        columnsRootKey: ColumnsRootKey;
        columnsSetId: string;
        persist: boolean;
        pinned: boolean;
      };
    }
  | {
      type: OrderedColumnsActionTypes.Hide;
      payload: {
        column: ColumnIdentifier;
        columnsRootKey: ColumnsRootKey;
        columnsSetId: string;
        persist: boolean;
      };
    }
  | {
      type: OrderedColumnsActionTypes.SetDefaultColumns;
      payload: {
        defaultColumnsIds: string[];
        columnsRootKey: ColumnsRootKey;
      };
    };

export interface OrderedColumnsSingleState {
  defaultColumnsIds: string[];
  columns: Record<string, LeaderboardColumn[]>;
}

export interface OrderedColumnsState {
  [key: string]: OrderedColumnsSingleState;
}

export type ColumnChange = Partial<
  Pick<LeaderboardColumn, 'width' | 'pinned' | 'displayName' | 'color'> &
    Pick<FloatColumn, 'displayMode'>
>;

export type ColumnOrder = 'before' | 'after';
