import React from 'react';
import { LeaderboardSortingParams } from '@neptune/shared/leaderboard-domain';
import { KnownAttributes } from 'domain/experiment/attribute';

const DEFAULT_SORT_OPTIONS: LeaderboardSortingParams = {
  dir: 'descending',
  sortBy: {
    name: KnownAttributes.CreationTime,
    type: 'datetime',
    aggregationMode: 'auto',
  },
};

export type SortOptionsContextValue = [
  LeaderboardSortingParams | undefined,
  (options: LeaderboardSortingParams) => void,
];

export const SortOptionsContext = React.createContext<SortOptionsContextValue>([
  DEFAULT_SORT_OPTIONS,
  () => {},
]);

export const useSortOptions = (): [
  LeaderboardSortingParams,
  (options: LeaderboardSortingParams) => void,
] => {
  const [sortOptions, setSortOptions] = React.useContext(SortOptionsContext);

  return [sortOptions || DEFAULT_SORT_OPTIONS, setSortOptions];
};
