import { fetchStatus } from 'state/fetch-status';
import type { Entity } from '@neptune/shared/entity-domain';
import { EntityActions, EntityActionTypes } from '@neptune/shared/entity-business-logic';
import { ExperimentAttributeOperation, OperationActions } from 'state/attributes/actions';

import { ModelVersionActions, ModelVersionActionTypes } from './model-version-actions';

export type ModelVersionState = {
  modelVersion?: Entity;
  fetchStatus: fetchStatus;
  error?: any;
};

const initialState: ModelVersionState = {
  fetchStatus: fetchStatus.NONE,
};

export function modelVersionReducer(
  state: ModelVersionState = initialState,
  action: ModelVersionActions | OperationActions | EntityActions,
): ModelVersionState {
  switch (action.type) {
    case ModelVersionActionTypes.success:
      return {
        ...state,
        modelVersion: action.payload,
        fetchStatus: fetchStatus.SUCCESS,
      };

    case ModelVersionActionTypes.fail:
      return {
        ...state,
        error: action.error,
        fetchStatus: fetchStatus.FAILED,
      };

    case ModelVersionActionTypes.request:
      return {
        ...state,
        fetchStatus: fetchStatus.PENDING,
      };

    case ExperimentAttributeOperation.success: {
      const { experiment: modelVersion, params } = action.payload;
      const isTheSameEntity = params.parentId === state.modelVersion?.id;

      if (!isTheSameEntity) {
        return state;
      }

      const modelVersionWithUpdatedAttributes: Entity = {
        ...modelVersion,
        attributes:
          state.modelVersion?.attributes.map((attribute) => {
            const updatedAttribute = modelVersion.attributes.find(
              (modelVersionAttr) =>
                modelVersionAttr.attributeName === attribute.attributeName &&
                modelVersionAttr.attributeType === attribute.attributeType,
            );

            return updatedAttribute ?? attribute;
          }) ?? [],
      };

      return {
        ...state,
        modelVersion: modelVersionWithUpdatedAttributes,
        fetchStatus: fetchStatus.SUCCESS,
      };
    }

    case EntityActionTypes.FullyUpdatedNotification: {
      const { entity } = action.payload;

      if (state.modelVersion?.id === entity.id) {
        return {
          ...state,
          modelVersion: entity,
        };
      }

      return state;
    }

    default:
      return state;
  }
}
