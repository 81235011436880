// Libs
import React from 'react';
import { bemBlock, Dialog, Layout, Text } from '@neptune/shared/venus-ui';

// App
import { Input } from '@neptune/shared/common-ui';

// Module
import './CreateNewLbViewModal.less';

const block = bemBlock('create-new-lb-view-modal');

type CreateNewLbViewModalProps = {
  isOpen: boolean;
  name: string;
  onNameChange(newName: string): void;
  onClose(): void;
  onSubmit(): void;
};

export const CreateNewLbViewModal: React.FC<CreateNewLbViewModalProps> = ({
  isOpen,
  name,
  onNameChange,
  onClose,
  onSubmit,
}) => {
  const handleNameChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => onNameChange(evt.target.value),
    [onNameChange],
  );

  const handleSubmitOnEnter = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onSubmit();
      }
    },
    [onSubmit],
  );

  return (
    <Dialog
      className={block()}
      isOpen={isOpen}
      title="Create new custom view"
      primaryButtonLabel="Save"
      secondaryButtonLabel="Cancel"
      primaryButtonRole="confirm-button"
      primaryButtonDisabled={name.length === 0}
      onClose={onClose}
      onPrimaryButtonClick={onSubmit}
      onSecondaryButtonClick={onClose}
      role="create-new-lb-view-modal"
    >
      <Layout.Column>
        <Text>Name</Text>
        <Input
          data-role="create-lb-view-input"
          autoFocus
          selectOnFocus
          type="string"
          value={name}
          onChange={handleNameChange}
          onKeyDown={handleSubmitOnEnter}
        />
      </Layout.Column>
    </Dialog>
  );
};
