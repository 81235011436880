import {
  backendClient,
  OrganizationOwnerDTO,
  OrganizationOwnerListDTO,
} from '@neptune/shared/core-apis-backend-domain';

export type OrganizationOwner = {
  email: string;
};

export async function fetchOwners(organizationIdentifier: string) {
  const result = await backendClient.listOwners({ organizationIdentifier });
  return listOwnersFromApiToDomain(result);
}

function listOwnersFromApiToDomain(source: OrganizationOwnerListDTO): OrganizationOwner[] {
  return source.admins.map(ownerFromApiToDomain);
}

function ownerFromApiToDomain(source: OrganizationOwnerDTO): OrganizationOwner {
  // note: there is more data in source
  return {
    email: source.email,
  };
}
