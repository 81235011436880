// Libs
import * as Sentry from '@sentry/react';

// App
import config from 'config';

// Module
import { breadcrumbsIntegration } from './breadcrumbs';
import { tracingOptions, tracingIntegration } from './browser-tracing';
import { initTransactions } from './custom-tracing/init';

export const isMonitoringEnabled = config.sentryDsn && window.location.hostname !== 'localhost';

export function initMonitoring() {
  if (isMonitoringEnabled) {
    Sentry.init({
      beforeSend: (event) => {
        // We want to filter out Unknown series error. It's hard to mute in Sentry because each error has different value.
        // We don't want to filter out errors from error boundary, because those are visible to the user.
        if (!isErrorFromBoundary(event) && isErrorFromUnknownSeries(event)) {
          return null;
        }

        return event;
      },
      dsn: config.sentryDsn,
      // for some reason sentry does not accept "/" in release string
      release: config.version.replace(/\//g, '-'),
      ...tracingOptions,
      integrations: [breadcrumbsIntegration, tracingIntegration],
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP 'optimizing' proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Browser extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /safari-extension:/i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /\/auryc.lib.js$/i,
      ],
      ...(config.sentryEnvironment ? { environment: config.sentryEnvironment } : {}),
    });

    setTag('component', 'NEPTUNE_FRONTEND');

    initTransactions();
  }
}

export function setTag(key: string, value: any) {
  Sentry.configureScope((scope) => {
    scope.setTag(key, value);
  });
}

function isErrorFromUnknownSeries(event: Sentry.Event) {
  return event.exception?.values?.find((exception) => {
    return exception?.value?.startsWith('Unknown series:');
  });
}

function isErrorFromBoundary(event: Sentry.Event) {
  return event.tags?.fromErrorBoundary === true;
}
