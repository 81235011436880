import React from 'react';
import { EntityType, fetchEntityChunk } from '@neptune/shared/entity-domain';
import { useAsyncValue } from '@neptune/shared/common-util';

type UseEntityChunk = {
  entityId: string | undefined | null;
  entityFieldsToFetch: string[];
  type: EntityType;
};

export const useEntityChunk = ({ entityId, entityFieldsToFetch, type }: UseEntityChunk) => {
  const fetchModel = React.useCallback(
    async () =>
      entityId
        ? fetchEntityChunk({
            id: entityId,
            fieldsToFetch: entityFieldsToFetch,
            type,
          })
        : undefined,
    [entityId, entityFieldsToFetch, type],
  );

  const {
    value: entity,
    loading,
    refresh,
  } = useAsyncValue({
    resolver: fetchModel,
  });

  return React.useMemo(
    () => ({
      entity,
      isLoading: loading,
      refresh,
    }),
    [entity, loading, refresh],
  );
};
