import { WidgetDefinition } from '../core/domain/widget-definition';
import { Widget } from '../core/widget';

// This is only a stub. Options type is defined in @neptune/gallery-domain.
export type GalleryWidget = Widget & {
  type: 'gallery';
};

export function isGalleryWidget(widget: Widget): widget is GalleryWidget {
  return widget.type === 'gallery';
}

export function galleryWidgetOptionsFromApiToDomain(options?: Record<string, any>) {
  return {
    mode: options?.mode,
  };
}

export const galleryWidgetName = 'Image gallery';

export const galleryWidgetDefinition: WidgetDefinition = {
  type: 'gallery',
  name: galleryWidgetName,
  fromApiToDomain: galleryWidgetOptionsFromApiToDomain,
};
