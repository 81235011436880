import React from 'react';
import { bemBlock } from '@neptune/shared/venus-ui';
import type { StringAttribute } from 'domain/experiment/attribute';
import { PropertyRow } from 'components/layouts/rows';
import { EditStringAttribute } from './inputs/EditStringAttribute';
import { EntityChanges } from '@neptune/shared/entity-domain';
import './NameRenderer.less';

const block = bemBlock('entity-properties-name');

type NameRendererProps = {
  attribute?: StringAttribute;
  canModify: boolean;
  labelWidth?: string;
  label?: string;
  'data-name'?: string;
  onEntityChange: (entityUpdates: Partial<EntityChanges>) => Promise<void>;
};

const emptyAttribute: StringAttribute = {
  attributeName: 'sys/name',
  attributeType: 'string',
  value: '',
};

export const NameRenderer: React.FC<NameRendererProps> = ({
  attribute = emptyAttribute,
  canModify,
  labelWidth = '100px',
  label,
  'data-name': dataName,
  onEntityChange,
}) => {
  const name = attribute.value || 'Untitled';

  const handleNameChange = React.useCallback(
    (value: string) => {
      onEntityChange({ name: value });
    },
    [onEntityChange],
  );

  return (
    <PropertyRow
      value={
        <EditStringAttribute
          className={block()}
          attribute={attribute}
          isEditable={canModify}
          onChange={handleNameChange}
        />
      }
      title={name}
      labelWidth={labelWidth}
      label={label}
      data-name={dataName}
    />
  );
};
