import type Stripe from 'stripe';
import { fetchStatus } from '@neptune/shared/legacy-domain';
import { UpcomingInvoiceActions, UpcomingInvoiceActionTypes } from './upcoming-invoice-actions';

export type UpcomingInvoiceState = {
  fetchStatus: fetchStatus;
  invoice?: Stripe.invoices.IInvoice;
};

const initialState: UpcomingInvoiceState = {
  fetchStatus: fetchStatus.NONE,
  invoice: undefined,
};

export function upcomingInvoiceReducer(
  state: UpcomingInvoiceState = initialState,
  action: UpcomingInvoiceActions,
): UpcomingInvoiceState {
  switch (action.type) {
    case UpcomingInvoiceActionTypes.request:
      return {
        ...state,
        fetchStatus: fetchStatus.PENDING,
      };

    case UpcomingInvoiceActionTypes.success:
      return {
        ...state,
        invoice: action.payload,
        fetchStatus: fetchStatus.SUCCESS,
      };

    case UpcomingInvoiceActionTypes.fail:
      return {
        ...state,
        invoice: undefined,
        fetchStatus: fetchStatus.FAILED,
      };

    default:
      return state;
  }
}
