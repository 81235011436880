import React from 'react';
import { LegacyEmblem, LegacyEmblemProps, Text, bemBlock } from '@neptune/shared/venus-ui';

import { ServiceAccountAvatar } from '../service-account-avatar/ServiceAccountAvatar';
import './ServiceAccountEmblem.less';

type ServiceAccountEmblemProps = LegacyEmblemProps & {
  name: string;
};

const block = bemBlock('service-account-emblem');

export const ServiceAccountEmblem: React.FC<ServiceAccountEmblemProps> = ({
  name,
  className,
  ...restProps
}) => {
  return (
    <LegacyEmblem className={block({ extra: className })} {...restProps}>
      <ServiceAccountAvatar size="xs" />
      <Text>{name}</Text>
    </LegacyEmblem>
  );
};
