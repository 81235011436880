import { AppState } from '@neptune/shared/legacy-domain';
import { UpcomingInvoiceState } from './upcoming-invoice-reducer';

const getPricing = (state: any) => state.pricing;

export function getUpcomingInvoiceState(state: AppState): UpcomingInvoiceState {
  return getPricing(state).upcomingInvoice;
}

export function getUpcomingInvoicePrice(state: AppState): number | undefined {
  const { invoice } = getUpcomingInvoiceState(state);
  return invoice ? invoice.amount_due / 100 : undefined;
}
