// Libs
import React, { useEffect } from 'react';
import { Layout, Link, Modal } from '@neptune/shared/venus-ui';

// App
import { fetchStatus } from 'state/fetch-status';
import { useApiToken } from 'common/hooks/useApiToken';
import { useGenericModal } from '@neptune/shared/common-business-logic';
import {
  UserProfileLink,
  UserProfileLinkProps,
} from 'components/user-panel/user-profile-link/UserProfileLink';

// Module
import { SetupTutorial } from './SetupTutorial';
export const MODAL_NAME = 'API_TOKEN_MODAL';

export const ApiTokenModal = () => {
  const { token, status, error, refetchToken } = useApiToken();
  const { isOpen, close } = useGenericModal(MODAL_NAME);

  let apiToken: string | undefined;

  useEffect(() => {
    if (isOpen === true) {
      refetchToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  switch (status) {
    case fetchStatus.FAILED: {
      apiToken = `<<< FETCHING API TOKEN FAILED (${error}) >>>`;
      break;
    }

    case fetchStatus.PENDING: {
      apiToken = '<<< FETCHING API TOKEN... >>>';
      break;
    }

    case fetchStatus.SUCCESS: {
      apiToken = token;
      break;
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={close} data={{ role: 'api-token-modal' }}>
      <Modal.Header title="Your API Token" onClose={close} />

      <SetupTutorial apiToken={apiToken}>
        <Layout.Column>
          <Link<UserProfileLinkProps>
            component={UserProfileLink}
            editMode
            data-role="revoke-token-link"
            onClick={close}
            children="I want to generate a new API token"
          />
        </Layout.Column>
      </SetupTutorial>

      <Modal.Footer>
        <Modal.Button variant="primary" onClick={close} children="Close" />
      </Modal.Footer>
    </Modal>
  );
};
