import {
  AttributeDefinition,
  getAttributeSubpropertiesDefinitions,
} from 'domain/experiment/attribute';

export enum UnwindSubpropertyStrategy {
  none = 'none',
  expand = 'expand',
  attach = 'attach',
}

export const unwindSubpropertyStrategies: Record<
  UnwindSubpropertyStrategy,
  (attrs: AttributeDefinition[]) => AttributeDefinition[]
> = {
  [UnwindSubpropertyStrategy.attach]: addAttributesSubproperties,
  [UnwindSubpropertyStrategy.expand]: replaceAttributeWithSubproperties,
  [UnwindSubpropertyStrategy.none]: (attrs: AttributeDefinition[]): AttributeDefinition[] => attrs,
};

export function replaceAttributeWithSubproperties(
  attributes: AttributeDefinition[],
): AttributeDefinition[] {
  return attributes.reduce((result: AttributeDefinition[], attribute) => {
    const subproperties = getAttributeSubpropertiesDefinitions(attribute);

    if (subproperties.length) {
      result.push(...subproperties);
    } else {
      result.push(attribute);
    }

    return result;
  }, []);
}

export function addAttributesSubproperties(
  attributes: AttributeDefinition[],
): AttributeDefinition[] {
  return attributes.reduce((result: AttributeDefinition[], attribute) => {
    const subproperties = getAttributeSubpropertiesDefinitions(attribute);

    result.push(...subproperties);
    result.push(attribute);

    return result;
  }, []);
}
