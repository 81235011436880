import React from 'react';

import { Text, bemBlock } from '@neptune/shared/venus-ui';

import './ProjectHeaderSectionName.less';

const block = bemBlock('project-header-section-name');

type ProjectHeaderSectionNameProps = {
  name: string;
};

export const ProjectHeaderSectionName: React.FC<ProjectHeaderSectionNameProps> = ({ name }) => {
  return (
    <Text
      className={block()}
      size="sm"
      fontWeight="bold"
      ellipsis
      title={name}
      data-role="project-section-name"
    >
      {name}
    </Text>
  );
};
