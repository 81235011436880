import { isEqual } from 'lodash';
import type { GlyphName } from '@neptune/shared/core-glyphs-domain';
import {
  ColorsConfigDTO,
  DashboardConfigDTO,
  DashboardDTO,
  DashboardLayoutsDTO,
  ListDashboardsDashboardTypeEnum,
  NewDashboardDTO,
  NewDashboardDTOTypeEnum,
  NewVersionDashboardDTO,
  NewVersionDashboardDTOTypeEnum,
  UpdateDashboardDTO,
  UpdateDashboardDTOTypeEnum,
  WidgetDTO,
  WidgetLayoutDTO,
} from '@neptune/shared/core-apis-leaderboard-domain';
import {
  Dashboard,
  WidgetLayouts,
  DashboardType,
  NewDashboard,
  NewVersionDashboard,
  DashboardConfig,
} from 'domain/dashboard/dashboard-model';
import { dashboardIcons } from 'domain/dashboard/dashboard-icons';
import { DEFAULT_CHART_GLOBAL_CONFIG, Widget, WidgetModelConverter } from 'domain/widget';
import { TableViewParamsConverter } from '@neptune/shared/leaderboard-domain';
import { AttributeDefinitionConverter } from 'domain/experiment/attribute';
import { AxisScale, XAxisMode } from '@neptune/charts-domain';

// Magic values to support migrating grid from 100 px rows to 46 px rows (i.e. exactly
// twice as granular - it's 46 px instead of 50 px because of 8 px gap between rows).
//
// As the migration is initially happening only in frontend, we need to handle both
// new and old formats, and we differentiate them by putting a magic marker in
// unused xsLayouts field.
//
// TODO(ENG-230): Remove the magic markers and lazy migration when all data is converted.
const MIGRATION_MARKER_BREAKPOINT = 'xs' as const;
const MIGRATION_MARKER_ID = '__WIDGET_GRID_VERSION_MARKER__' as const;

const MIGRATION_MARKER_RECORD_12COL_46PX: WidgetLayoutDTO = {
  id: MIGRATION_MARKER_ID,
  x: 2,
  y: 0,
  w: 0,
  h: 0,
  isStatic: undefined,
};

const newDashboardTypeMapping: Record<DashboardType, NewDashboardDTOTypeEnum> = {
  compare: NewDashboardDTOTypeEnum.Compare,
  experiment: NewDashboardDTOTypeEnum.Experiment,
  report: NewDashboardDTOTypeEnum.Report,
};

const updateDashboardTypeMapping: Record<DashboardType, UpdateDashboardDTOTypeEnum> = {
  compare: UpdateDashboardDTOTypeEnum.Compare,
  experiment: UpdateDashboardDTOTypeEnum.Experiment,
  report: UpdateDashboardDTOTypeEnum.Report,
};

const newVersionTypeMapping: Record<DashboardType, NewVersionDashboardDTOTypeEnum> = {
  compare: NewVersionDashboardDTOTypeEnum.Compare,
  experiment: NewVersionDashboardDTOTypeEnum.Experiment,
  report: NewVersionDashboardDTOTypeEnum.Report,
};

const listDashboardTypeMapping: Record<DashboardType, ListDashboardsDashboardTypeEnum> = {
  compare: ListDashboardsDashboardTypeEnum.Compare,
  experiment: ListDashboardsDashboardTypeEnum.Experiment,
  report: ListDashboardsDashboardTypeEnum.Report,
};

export abstract class DashboardModelConverter {
  static dashboardFromApiToDomain(dashboard: DashboardDTO): Dashboard {
    return {
      ...dashboard,
      config: DashboardModelConverter.dashboardConfigFromApiToDomain(dashboard.config),
      gridLayouts: DashboardModelConverter.gridLayoutsFromApiToDomain(dashboard.layouts),
      versionBranchId: dashboard.versionBranch,
      icon: DashboardModelConverter.dashboardIconFromApiToDomain(dashboard.icon),
      widgets: dashboard.widgets.map(WidgetModelConverter.widgetFromApiToDomain),
      viewParams: dashboard.viewParams
        ? TableViewParamsConverter.fromApiToDomain(dashboard.viewParams)
        : undefined,
      colorsConfig: DashboardModelConverter.colorsFromApiToDomain(dashboard.colorsConfig),
    };
  }

  static dashboardConfigFromApiToDomain(
    config: DashboardConfigDTO | undefined,
  ): DashboardConfig | undefined {
    if (!config) {
      return undefined;
    }

    const {
      metricsStepsRange,
      xaxisScale: xAxisScale,
      yaxisScale: yAxisScale,
      xaxisMode: xAxisMode,
      xaxisMetric: xAxisMetric,
    } = config;

    const chartGlobalConfig = {
      xAxisScale: (xAxisScale as AxisScale | undefined) ?? DEFAULT_CHART_GLOBAL_CONFIG.xAxisScale,
      yAxisScale: (yAxisScale as AxisScale | undefined) ?? DEFAULT_CHART_GLOBAL_CONFIG.yAxisScale,
      xAxisMode: (xAxisMode as XAxisMode | undefined) ?? DEFAULT_CHART_GLOBAL_CONFIG.xAxisMode,
      xAxisMetric:
        xAxisMetric && AttributeDefinitionConverter.attributeDefinitionFromApiToDomain(xAxisMetric),
    };

    return {
      metricsStepsRange,
      chartGlobalConfig,
    };
  }

  static dashboardIconFromApiToDomain(icon: string | undefined): GlyphName | undefined {
    if (!dashboardIcons.includes(icon as GlyphName)) {
      return undefined;
    }

    return icon as GlyphName;
  }

  static gridLayoutsFromApiToDomain(layouts: DashboardLayoutsDTO): WidgetLayouts {
    // TODO(ENG-230): Remove the magic markers and lazy migration when all data is converted.
    const migrationMarkerRecord = layouts[MIGRATION_MARKER_BREAKPOINT]?.find(
      ({ id }) => id === MIGRATION_MARKER_ID,
    );

    if (!isEqual(migrationMarkerRecord, MIGRATION_MARKER_RECORD_12COL_46PX)) {
      // Migration marker is missing, which means that dashboard data is in "old"
      // coordinates (for 100 px grid). Convert it to current format (46 px).
      return layouts.md.map(({ isStatic, y, h, ...rest }) => ({
        y: 2 * y,
        h: 2 * h,
        ...rest,
      }));
    }

    // The layouts are already in new coordinates, return them without changes.
    return layouts.md.map(({ isStatic, ...rest }) => rest);
  }

  static gridLayoutsFromDomainToApi(layouts: WidgetLayouts): DashboardLayoutsDTO {
    return {
      // All WidgetLayouts in application are based on new grid dimensions.
      // TODO(ENG-230): Remove the magic markers and lazy migration when all data is converted.
      xs: [MIGRATION_MARKER_RECORD_12COL_46PX], // migration marker
      md: layouts,
      lg: [], // required for legacy reasons
      sm: [], // required for legacy reasons
      xl: [], // required for legacy reasons
    };
  }

  static dashboardWidgetsFromDomainToApi(widgets: Widget[]): WidgetDTO[] {
    return widgets
      .map(WidgetModelConverter.widgetFromDomainToApi)
      .filter((widget): widget is WidgetDTO => widget != null);
  }

  static newDashboardFromDomainToApi(dashboard: NewDashboard): NewDashboardDTO {
    const { experimentsOnly, runsLineage, ...rest } = dashboard;
    return {
      ...rest,
      config: DashboardModelConverter.dashboardConfigFromDomainToApi(dashboard.config),
      layouts: DashboardModelConverter.gridLayoutsFromDomainToApi(dashboard.gridLayouts),
      type: DashboardModelConverter.newDashboardTypeFromDomainToApi(dashboard.type),
      widgets: DashboardModelConverter.dashboardWidgetsFromDomainToApi(dashboard.widgets),
      viewParams: dashboard.viewParams
        ? TableViewParamsConverter.fromDomainToApi(dashboard.viewParams)
        : undefined,
      colorsConfig: DashboardModelConverter.colorsFromDomainToApi(dashboard.colorsConfig),
    };
  }

  static dashboardConfigFromDomainToApi(
    config: DashboardConfig | undefined,
  ): DashboardConfigDTO | undefined {
    if (!config) {
      return undefined;
    }

    const { chartGlobalConfig, metricsStepsRange } = config;

    const { xAxisScale, yAxisScale, xAxisMode, xAxisMetric } = chartGlobalConfig ?? {};

    return {
      metricsStepsRange,
      xaxisScale: xAxisScale,
      yaxisScale: yAxisScale,
      xaxisMode: xAxisMode,
      xaxisMetric:
        xAxisMetric && AttributeDefinitionConverter.attributeDefinitionFromDomainToApi(xAxisMetric),
    };
  }

  static newDashboardTypeFromDomainToApi(type: DashboardType): NewDashboardDTOTypeEnum {
    return newDashboardTypeMapping[type];
  }

  static updateDashboardTypeFromDomainToApi(type: DashboardType): UpdateDashboardDTOTypeEnum {
    return updateDashboardTypeMapping[type];
  }

  static updateDashboardFromDomainToApi(dashboard: Dashboard): UpdateDashboardDTO {
    return {
      ...dashboard,
      config: DashboardModelConverter.dashboardConfigFromDomainToApi(dashboard.config),
      layouts: DashboardModelConverter.gridLayoutsFromDomainToApi(dashboard.gridLayouts),
      type: DashboardModelConverter.updateDashboardTypeFromDomainToApi(dashboard.type),
      widgets: DashboardModelConverter.dashboardWidgetsFromDomainToApi(dashboard.widgets),
      viewParams: dashboard.viewParams
        ? TableViewParamsConverter.fromDomainToApi(dashboard.viewParams)
        : undefined,
      colorsConfig: DashboardModelConverter.colorsFromDomainToApi(dashboard.colorsConfig),
    };
  }

  static newVersionDashboardFromDomainToApi(
    dashboard: NewVersionDashboard,
  ): NewVersionDashboardDTO {
    return {
      ...dashboard,
      config: DashboardModelConverter.dashboardConfigFromDomainToApi(dashboard.config),
      layouts: DashboardModelConverter.gridLayoutsFromDomainToApi(dashboard.gridLayouts),
      versionBranch: dashboard.versionBranchId,
      type: DashboardModelConverter.newVersionTypeFromDomainToApi(dashboard.type),
      widgets: DashboardModelConverter.dashboardWidgetsFromDomainToApi(dashboard.widgets),
      viewParams: dashboard.viewParams
        ? TableViewParamsConverter.fromDomainToApi(dashboard.viewParams)
        : undefined,
      colorsConfig: DashboardModelConverter.colorsFromDomainToApi(dashboard.colorsConfig),
    };
  }
  static newVersionTypeFromDomainToApi(type: DashboardType): NewVersionDashboardDTOTypeEnum {
    return newVersionTypeMapping[type];
  }

  static listDashboardTypeFromDomainToApi(types: DashboardType[]): ListDashboardsDashboardTypeEnum {
    // casting is a workaround for Enum[] generated as Enum in our TS client (bug in swagger generation)
    return types.map(
      (type) => listDashboardTypeMapping[type],
    ) as any as ListDashboardsDashboardTypeEnum;
  }

  static colorsFromApiToDomain(colorsConfig?: ColorsConfigDTO): Record<string, string> | undefined {
    return colorsConfig?.colors?.reduce<Record<string, string>>(
      (res, { owner, color }) => ((res[owner] = color), res),
      {},
    );
  }

  static colorsFromDomainToApi(colors?: Record<string, string>): ColorsConfigDTO | undefined {
    if (!colors) {
      return undefined;
    }

    return {
      colors: Object.entries(colors).map(([owner, color]) => ({ owner, color })),
    };
  }
}
