// Libs
import React from 'react';
import { InfoIcon, Layout, LayoutRowProps, Text } from '@neptune/shared/venus-ui';

// App

// Module
type MonitoringLabelProps = LayoutRowProps<{}>;

export const MonitoringLabel: React.FC<MonitoringLabelProps> = (props) => {
  return (
    <Layout.Row span="auto" spacedChildren="xs" {...props}>
      <Text fontWeight="semibold">Monitoring</Text>
      <InfoIcon placement="bottom-center">
        Monitoring hours refer to the time you use Neptune to monitor your runs like model training
        or retraining.
      </InfoIcon>
    </Layout.Row>
  );
};
