import { memoize } from 'lodash';
import { hashString } from '@neptune/content-addressable-store-domain';
import { ShortIdCompress } from 'common/short-id-compress';
import { CompareContent } from '@neptune/compare-domain';

export const extractCachedCompareState = memoize(
  (compressed: string, projectKey: string): string[] => {
    return ShortIdCompress.parse(compressed, projectKey);
  },
  (...args) => args.join('#'),
);

export const hashCompareData = async (content: CompareContent) => {
  return hashString(
    JSON.stringify({
      ...content,
      compare: {
        runIds: content.compare.runIds.sort(),
        experimentNames: content.compare.experimentNames.sort(),
      },
    }),
  );
};
