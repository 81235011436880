import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { bemBlock, Layout } from '@neptune/shared/venus-ui';

import { VersionSidebar } from '@neptune/model-registry-model-version-ui';
import { useCollapsibleSidebar } from '@neptune/shared/common-business-logic';
import { useNotFound, ErrorPlaceholderContainer } from '@neptune/shared/common-feature';
import {
  useModelVersion,
  useModelVersionIdentifier,
} from '@neptune/model-registry-model-version-business-logic';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import { useLegacyEntityUpdates } from '@neptune/shared/entity-business-logic';
import {
  useBasicEntityDetailsWithFetching,
  useBasicEntityUpdates,
  useEntityAttributeDefinitionsWithFetching,
  useEntityAttributeDefinitionsUpdates,
} from '@neptune/current-entity-business-logic';
import {
  getOrganizationNameFromRouteParams,
  getProjectNameFromRouteParams,
  getShortIdFromRouteParams,
} from 'state/selectors-global';

import { VersionMenuContainer } from './VersionMenuContainer';
import { VersionBasicInfoContainer } from './VersionBasicInfoContainer';
import { ViewContents } from './ViewContents';

import './ModelRegistryModelVersionView.less';
import { VersionDetailsModal } from './version-details-modal/VersionDetailsModal';
import {
  StageChangeConfirmationContainer,
  StageChangeConfirmationProvider,
} from '@neptune/model-registry-common-feature';

const block = bemBlock('model-registry-model-version-view');

type ModelRegistryModelVersionViewProps = {
  trashBackLink?: React.ReactElement;
};

export const ModelRegistryModelVersionView: React.FC<ModelRegistryModelVersionViewProps> = ({
  trashBackLink,
}) => {
  const shortId = useSelector(getShortIdFromRouteParams);
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const projectName = useSelector(getProjectNameFromRouteParams);

  const { sidebarExpanded, toggleSidebar } = useCollapsibleSidebar('model-version');

  // TODO: To remove
  const modelVersionIdentifier = useModelVersionIdentifier();
  const { fetchStatus: legacyEntityFetchStatus, error: legacyEntityError } =
    useModelVersion(modelVersionIdentifier);

  const {
    entity: basicEntity,
    fetchStatus: basicEntityFetchStatus,
    error: basicEntityError,
  } = useBasicEntityDetailsWithFetching(organizationName, projectName, shortId);

  useEntityAttributeDefinitionsWithFetching(organizationName, projectName, shortId);

  const { id: entityId, projectId } = basicEntity || {};

  useBasicEntityUpdates({
    projectId,
    entityId,
    entityType: 'modelVersion',
  });

  useEntityAttributeDefinitionsUpdates({
    projectId,
    entityId,
    entityType: 'modelVersion',
  });

  // TODO: To remove
  useLegacyEntityUpdates({
    projectId,
    entityId,
    entityType: 'modelVersion',
  });

  const ErrorPage = useNotFound({
    fetchStatus: basicEntityFetchStatus,
    error: basicEntityError,
  });

  // TODO: To remove
  const LegacyEntityErrorPage = useNotFound({
    fetchStatus: legacyEntityFetchStatus,
    error: legacyEntityError,
  });

  if (ErrorPage) {
    return <ErrorPage />;
  }

  // TODO: To remove
  if (LegacyEntityErrorPage) {
    return <LegacyEntityErrorPage />;
  }

  // TODO: To remove legacyEntityError
  if (basicEntityError || legacyEntityError) {
    return <ErrorPlaceholderContainer context="model-registry-model-version-view" />;
  }

  return (
    <StageChangeConfirmationProvider>
      <StageChangeConfirmationContainer />
      <VersionDetailsModal />
      <Helmet defer={false}>
        <title>{shortId}</title>
      </Helmet>
      <Layout.Row className={block()} overflow="hidden" data-role="model-version-view">
        {basicEntity && (
          <>
            <VersionSidebar
              versionBasicInfoElement={
                <VersionBasicInfoContainer
                  expanded={sidebarExpanded}
                  modelVersion={basicEntity}
                  trashBackLink={trashBackLink}
                />
              }
              menuElement={<VersionMenuContainer expanded={sidebarExpanded} />}
              expanded={sidebarExpanded}
              onToggle={toggleSidebar}
            />
            <ViewContents />
          </>
        )}
        <LoadingIndicator fetchStatus={basicEntityFetchStatus} centered />
      </Layout.Row>
    </StageChangeConfirmationProvider>
  );
};
