// Libs
import React from 'react';

// Module
import { AppSidebarToggle } from './app-sidebar-toggle/AppSidebarToggle';
import { bemBlock, Sidebar } from '@neptune/shared/venus-ui';
import './AppSidebar.less';

type AppSidebarProps = React.ComponentProps<typeof Sidebar> & {
  alignment?: 'left' | 'right';
  onToggle: () => void;
};

const block = bemBlock('app-sidebar');

export const AppSidebar: React.FC<AppSidebarProps> = ({
  className,
  children,
  expanded = true,
  alignment = 'left',
  onToggle,
  ...restProps
}) => {
  const cssClass = block({
    modifiers: {
      'align-right': alignment === 'right',
    },
    extra: [className],
  });

  return (
    <Sidebar className={cssClass} expanded={expanded} span="auto" {...restProps}>
      <AppSidebarToggle
        className={block('toggle')}
        expanded={expanded}
        alignment={alignment}
        onToggle={onToggle}
      />

      {children}
    </Sidebar>
  );
};
