import {
  RGLLayout,
  SectionLayoutDetails,
  SectionsLayoutMap,
} from '@neptune/shared/grid-layout-domain';

export function getGridLayoutsWithFoldedSections(
  unfoldedGridLayouts: RGLLayout[],
  sectionsMap: SectionsLayoutMap,
): Array<RGLLayout & { unfoldedHeight?: number }> {
  const foldedLayouts: Array<RGLLayout & { unfoldedHeight?: number }> = [];
  let currentFoldedSection:
    | (SectionLayoutDetails & {
        foldedSectionBottomY: number;
      })
    | undefined = undefined;
  let yOffset = 0;

  for (let idx = 0; idx < unfoldedGridLayouts.length; ++idx) {
    const layout = unfoldedGridLayouts[idx];

    const sectionDetails = sectionsMap[layout.i];

    if (sectionDetails) {
      if (currentFoldedSection) {
        // Start positioning upcoming widgets just after folded section.
        yOffset = layout.y - currentFoldedSection.foldedSectionBottomY;
      }

      currentFoldedSection = sectionDetails?.folded
        ? {
            ...sectionDetails,
            foldedSectionBottomY: layout.y + layout.h - yOffset, // For positioning dummy widget layouts.
          }
        : undefined;

      foldedLayouts.push({
        ...layout,
        y: layout.y - yOffset,
        isResizable: false,
      });
    } else if (currentFoldedSection) {
      // It is crucial that we provide a dummy layouts for the folded widgets.
      // We still want to render them in order to keep them alive (virtualized).
      // If we did't provide any layouts, RGL would create some with actual size
      // (and mess up the layout). Providing 0-height layouts prevents that behavior.
      //
      // The y coord needs to match the section so that RGLs virtualization will render
      // the elements for folded widgets together with the section, and not when showing
      // some other part of the dashboard. This should improve responsiveness when folding
      // and unfolding sections, and also avoid rendering all folded wigets at once.
      //
      // Keeping x and w values prevents weird animation when unfolding sections.
      const y = currentFoldedSection.foldedSectionBottomY;
      foldedLayouts.push({
        ...layout,
        y,
        h: 0,
        unfoldedHeight: layout.h,
        minH: 0,
        isResizable: false,
      });
    } else {
      const y = layout.y - yOffset;

      foldedLayouts.push({
        ...layout,
        y,
      });
    }
  }

  return foldedLayouts;
}
