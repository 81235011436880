import { Button, DropdownToggleProps, Icon } from '@neptune/shared/venus-ui';
import React from 'react';

export const StepsRangeToggle: React.FC<
  DropdownToggleProps & { toggleVariant?: 'big' | 'small'; label?: string }
> = ({ label, onCollapse, onExpand, onToggle, ...restProps }) => {
  return (
    <Button
      data-role="open-step-range-modal"
      size="md"
      variant="basic"
      square
      onClick={onToggle}
      {...restProps}
    >
      <Icon size="lg" glyph="steps-range" />
    </Button>
  );
};
