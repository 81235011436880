import React from 'react';
import { useSelector } from 'react-redux';
import { View } from '@neptune/shared/venus-ui';
import { getDetailsTabFromRouteParams } from 'state/selectors-global';
import { versionTabs } from './version-tabs';

type ViewContentsProps = {};

export const ViewContents: React.FC<ViewContentsProps> = () => {
  const currentTab = useSelector(getDetailsTabFromRouteParams) || 'metadata';

  const matchingTab = versionTabs.find((tab) => tab.id === currentTab);
  const ViewToRender = matchingTab?.component;

  if (!ViewToRender) {
    return null;
  }

  return (
    <View overflow="hidden">
      <ViewToRender />
    </View>
  );
};
