// Libs
import React from 'react';

// App
import { Dialog } from '@neptune/shared/venus-ui';

type UnsavedChangesDialogProps = {
  isOpen: boolean;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  title?: string;
  role?: string;
  onConfirm(): void;
  onCancel(): void;
};

export const UnsavedChangesDialog: React.FC<UnsavedChangesDialogProps> = ({
  children,
  isOpen,
  primaryButtonLabel = 'Yes, discard changes',
  secondaryButtonLabel = 'No, continue editing',
  title = 'Unsaved changes',
  role,
  onConfirm,
  onCancel,
  ...props
}) => {
  const defaultText = (
    <>
      If you leave this page, your changes will be discarded.
      <br />
      Is that OK?
    </>
  );

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      primaryButtonLabel={primaryButtonLabel}
      primaryButtonRole="discard-button"
      secondaryButtonLabel={secondaryButtonLabel}
      secondaryButtonRole="continue-button"
      onPrimaryButtonClick={onConfirm}
      onSecondaryButtonClick={onCancel}
      onClose={onCancel}
      role={role}
      {...props}
    >
      {children || defaultText}
    </Dialog>
  );
};
