import React from 'react';

import { useAsyncValue } from '@neptune/shared/common-util';
import { createErrorDescriptor } from 'common/error-handler';
import { fetchMonitoringTimePackageList } from '@neptune/pricing-commons-domain';

type FetchInput = Parameters<typeof fetchMonitoringTimePackageList>[0];

type UseMonitoringPackagesHistoryParams = FetchInput;

export function useMonitoringPackagesHistory({
  organizationIdentifier,
  limit,
  offset,
  dateMin,
  dateMax,
}: UseMonitoringPackagesHistoryParams) {
  const resolver = React.useCallback(
    () =>
      fetchMonitoringTimePackageList({
        organizationIdentifier,
        limit,
        offset,
        dateMin,
        dateMax,
      }),

    [organizationIdentifier, limit, offset, dateMin, dateMax],
  );

  return useAsyncValue({
    resolver,
    errorFormatter: createErrorDescriptor,
  });
}
