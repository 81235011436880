import React from 'react';
import { Icon } from '@neptune/shared/venus-ui';
import { InfoTooltip } from '@neptune/shared/common-ui';

type LimitAttributesNotificationProps = {
  'data-role': string;
};

export const LimitAttributesInfo = ({
  'data-role': dataRole,
}: LimitAttributesNotificationProps) => {
  return (
    <InfoTooltip
      text="The dashboard includes over 1k widgets. For a comprehensive view, consider applying a more specific query filter."
      data-role={dataRole}
    >
      <Icon glyph="info-circle" color="info" fillRule="evenodd" />
    </InfoTooltip>
  );
};
