// Libs
import React from 'react';
import PropTypes from 'prop-types';
import {
  isEqual,
  identity,
} from 'lodash';

const propTypes = {
  resolverFn: PropTypes.func.isRequired,
  requestPayloadSelector: PropTypes.func,
  comparePayloadSelector: PropTypes.func,
  children: PropTypes.func,
};

const defaultProps = {
  requestPayloadSelector: identity,
  comparePayloadSelector: identity,
};

const filterProps = ({
  resolverFn,
  requestPayloadSelector,
  comparePayloadSelector,
  children,
  ...props
}) => props;

class RefetchProvider extends React.Component {

  componentDidMount() {
    this.runFetch();
  }

  componentDidUpdate(prevProps) {
    const {
      comparePayloadSelector,
    } = this.props;

    const prevParams = comparePayloadSelector(filterProps(prevProps));
    const nextParams = comparePayloadSelector(filterProps(this.props));

    if (!isEqual(prevParams, nextParams)) {
      this.runFetch();
    }
  }

  runFetch() {
    const {
      resolverFn,
      requestPayloadSelector,
    } = this.props;

    resolverFn(requestPayloadSelector(filterProps(this.props)));
  }

  render() {
    const {
      children,
    } = this.props;

    return children();
  }
}

RefetchProvider.propTypes = propTypes;
RefetchProvider.defaultProps = defaultProps;

export default RefetchProvider;

