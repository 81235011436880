import React from 'react';
import { Toolbar, ToggleSwitch } from '@neptune/shared/venus-ui';
import { DashboardLayout } from '@neptune/shared/dashboard-domain';

export const DashboardToolbarLayoutItem: React.FC<{
  isLayoutExpanded?: boolean;
  onLayoutChange?: (isExpanded: boolean) => void;
}> = ({ isLayoutExpanded, onLayoutChange }) => {
  const toggleLayout = () => {
    if (!onLayoutChange) {
      return;
    }

    onLayoutChange(!Boolean(isLayoutExpanded));
  };

  return (
    <Toolbar.Item>
      <ToggleSwitch
        selected={isLayoutExpanded ? DashboardLayout.ROW : DashboardLayout.MOSAIC}
        onChange={toggleLayout}
      >
        <ToggleSwitch.Button
          data-role="dashboard-display-mosaic-mode"
          value={DashboardLayout.MOSAIC}
        >
          Mosaic
        </ToggleSwitch.Button>
        <ToggleSwitch.Button data-role="dashboard-display-row-mode" value={DashboardLayout.ROW}>
          One in row
        </ToggleSwitch.Button>
      </ToggleSwitch>
    </Toolbar.Item>
  );
};
