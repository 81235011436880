import React from 'react';
import { NormalizedLink, NormalizedLinkProps } from '@neptune/shared/routing-ui';
import { Version } from '@neptune/shared/dashboard-domain';
import { UserAvatar } from '@neptune/shared/avatars-ui';
import { formatDate } from '@neptune/shared/common-util';
import { bemBlock, Layout, Text } from '@neptune/shared/venus-ui';
import { RouterSimpleState } from '@neptune/shared/routing-domain';

import { VersionActionsDropdown } from '../VersionActionsDropdown';

import './VersionItem.less';

const block = bemBlock('dashboard-version-item');

type VersionItemProps = {
  version: Version;
  selected?: boolean;
  route: RouterSimpleState;
  onClick?: (id: string) => void;
  onShareLink?: (id: string) => void;
  onRestore?: (id: string) => void;
  onEditName?: (id: string) => void;
  onEditDescription?: (id: string) => void;
};

export const VersionItem: React.FC<VersionItemProps> = ({
  version,
  selected = false,
  route,
  onShareLink,
  onRestore,
  onEditName,
  onEditDescription,
}) => {
  const showToggle = !!onEditDescription || !!onEditName || !!onRestore || !!onShareLink;

  const timestampText = version.timestamp ? formatDate(version.timestamp) : '';

  return (
    <Layout.Row<NormalizedLinkProps>
      component={NormalizedLink}
      routeName={route.name}
      routeParams={route.params}
      className={block({ modifiers: { selected } })}
      justifyContent="center"
      spacedChildren="sm"
      span="auto"
      data-role="version-item"
      data-version-id={version.id}
      data-selected={selected}
    >
      <Layout.Column withPadding="sm" overflow="hidden">
        <Layout.Row>
          <Text size="xs" ellipsis title={`${timestampText} by ${version.owner}`}>
            <Text color="text-secondary">{timestampText}</Text>
            {'  '}by <UserAvatar className={block('avatar')} size="xxs" username={version.owner} />{' '}
            {version.owner}
          </Text>
        </Layout.Row>
        <Layout.Row wrap="wrap" spacedChildren="xs">
          {version.versionName && (
            <Text
              fontWeight="semibold"
              data-role="version-name"
              size="xs"
              ellipsis
              data-version-name={version.versionName}
              title={version.versionName}
            >
              {version.versionName}
            </Text>
          )}
          {version.versionDescription && (
            <Text
              data-role="version-description"
              size="xs"
              ellipsis
              data-version-description={version.versionDescription}
              italic
              title={version.versionDescription}
            >
              {version.versionDescription}
            </Text>
          )}
        </Layout.Row>
      </Layout.Column>
      {showToggle && (
        <Layout.Column
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          span="shrink"
          withPadding="xs"
          width={32}
        >
          <VersionActionsDropdown
            versionId={version.id}
            variant="basic"
            className={block('actions')}
            onShareLink={onShareLink}
            onEditDescription={onEditDescription}
            onEditName={onEditName}
            onRestore={onRestore}
          />
        </Layout.Column>
      )}
    </Layout.Row>
  );
};
