// Libs
import React from 'react';
import { bemBlock, Layout } from '@neptune/shared/venus-ui';

// App
import type { ServiceAccount, TokenState } from '@neptune/service-accounts-domain';

// Module
import { ServiceAccountTile } from './service-account-tile/ServiceAccountTile';
import { UserWithAvatar } from './service-account-tile/UserWithAvatar';
import { ApiTokenSection } from './service-account-tile/ApiTokenSection';
import './WorkspaceServiceAccountsList.less';

type WorkspaceServiceAccountsListProps = {
  entries?: ServiceAccount[];
  createNewElement: React.ReactElement;
  onResetApiToken?(id: string): void;
  emptyState: React.ReactElement;
  loading: boolean;
  tokenStatus: Record<string, TokenState | undefined>;
  onDeactivateAccount(account: ServiceAccount): void;
  onReactivateAccount(account: ServiceAccount): void;
  onToggleApiToken(id: string): void;
  getApiToken(id: string): Promise<string | undefined>;
};

const block = bemBlock('workspace-service-accounts-list');

export const WorkspaceServiceAccountsList: React.FC<WorkspaceServiceAccountsListProps> = ({
  entries,
  createNewElement,
  emptyState: emptyStateRaw,
  loading,
  tokenStatus,
  onDeactivateAccount,
  onReactivateAccount,
  onToggleApiToken,
  getApiToken,
  onResetApiToken,
}) => {
  const emptyState = loading !== true ? emptyStateRaw : null;

  return entries && entries.length > 0 ? (
    <Layout.Row
      className={block()}
      spacedChildren="md"
      alignItems="start"
      wrap="wrap"
      span="shrink"
    >
      {createNewElement}
      {entries.map((serviceAccount) => (
        <ServiceAccountTile
          key={serviceAccount.id}
          id={serviceAccount.id}
          className={block('element')}
          name={serviceAccount.displayName}
          activated={serviceAccount.activated}
          createdBy={serviceAccount.createdBy}
          createdDate={serviceAccount.createdDate}
          keyGeneratedBy={serviceAccount.keyGeneratedBy}
          keyGeneratedDate={serviceAccount.keyGeneratedDate}
          deactivatedBy={serviceAccount.deactivatedBy}
          deactivatedDate={serviceAccount.deactivatedDate}
          userComponent={UserWithAvatar}
          apiTokenSectionElement={
            <ApiTokenSection
              apiToken={tokenStatus[serviceAccount.id]?.token}
              shownApiToken={tokenStatus[serviceAccount.id]?.isShown}
              showApiTokenFetchStatus={tokenStatus[serviceAccount.id]?.fetchStatus}
              getApiToken={() => getApiToken(serviceAccount.id)}
              onToggleApiToken={() => onToggleApiToken(serviceAccount.id)}
            />
          }
          onDeactivateToken={() => onDeactivateAccount(serviceAccount)}
          onReactivateToken={() => onReactivateAccount(serviceAccount)}
          onResetToken={onResetApiToken ? () => onResetApiToken(serviceAccount.id) : undefined}
        />
      ))}
    </Layout.Row>
  ) : (
    emptyState
  );
};
