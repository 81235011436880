import React from 'react';
import { Layout } from '@neptune/shared/venus-ui';

import { LayoutComponent } from '@neptune/entity-common-domain';
import {
  DateRenderer,
  DescriptionRenderer,
  GroupTagsRenderer,
  MonitoringTimeRenderer,
  SizeRenderer,
  StateRenderer,
  StringRenderer,
  TagsRenderer,
} from '@neptune/entity-common-ui';
import { getEntityAttribute } from '@neptune/shared/entity-domain';
import { KnownAttributes } from 'domain/experiment/attribute';

import { StageRenderer } from './renderers/StageRenderer';

export const VersionPropertiesLayout: LayoutComponent = ({
  colorGetter,
  entity,
  groupTagsDataSource,
  onEntityChange,
  tagsDataSource,
}) => {
  if (!entity) {
    return null;
  }

  const canModify = entity.canModify;

  return (
    <Layout.Column spacedChildren="sm" data-role="entity-properties">
      <StringRenderer
        attribute={getEntityAttribute(entity, KnownAttributes.ModelId, 'string')}
        label="Model ID"
        data-name="entity-parent-id"
        copyable
      />
      <StringRenderer
        attribute={getEntityAttribute(entity, KnownAttributes.Id, 'string')}
        label="Version"
        data-name="entity-id"
        copyable
      />
      <StageRenderer
        attribute={getEntityAttribute(entity, KnownAttributes.Stage, 'string')}
        modelVersion={entity}
        canModify={canModify}
        label="Stage"
        data-name="entity-stage"
        onEntityChange={onEntityChange}
      />

      <StringRenderer
        attribute={getEntityAttribute(entity, KnownAttributes.Owner, 'string')}
        label="Owned by"
        data-name="entity-owned-by"
      />
      <DateRenderer
        attribute={getEntityAttribute(entity, KnownAttributes.CreationTime, 'datetime')}
        label="Created"
        data-name="entity-created"
      />
      <DateRenderer
        attribute={getEntityAttribute(entity, KnownAttributes.ModificationTime, 'datetime')}
        label="Modified"
        data-name="entity-completed"
      />
      <SizeRenderer
        attribute={getEntityAttribute(entity, KnownAttributes.Size, 'int')}
        label="Size"
        data-name="entity-size"
      />
      <MonitoringTimeRenderer
        attribute={getEntityAttribute(entity, KnownAttributes.MonitoringTime, 'int')}
        label="Monitoring time"
        data-name="entity-monitoring-time"
      />
      <StateRenderer
        attribute={getEntityAttribute(entity, KnownAttributes.State, 'experimentState')}
        label="State"
        data-name="entity-state"
      />
      <DescriptionRenderer
        attribute={getEntityAttribute(entity, KnownAttributes.Description, 'string')}
        canModify={canModify}
        placeholder="Add model version description"
        label="Description"
        data-name="entity-description"
        onEntityChange={onEntityChange}
      />
      <TagsRenderer
        canModify={canModify}
        colorGetter={colorGetter}
        onEntityChange={onEntityChange}
        tagsDataSource={tagsDataSource}
        attribute={getEntityAttribute(entity, KnownAttributes.Tags, 'stringSet')}
      />
      <GroupTagsRenderer
        canModify={canModify}
        colorGetter={colorGetter}
        onEntityChange={onEntityChange}
        tagsDataSource={groupTagsDataSource}
        attribute={getEntityAttribute(entity, KnownAttributes.GroupTags, 'stringSet')}
      />
    </Layout.Column>
  );
};
