import React from 'react';
import { useAsyncValue } from '@neptune/shared/common-util';
import { FilterResultItem, QueryFilterSearch } from '@neptune/shared/search-domain';
import { AddWithAutocompletion } from './AddWithAutocompletion';
import { useDebounce } from 'common/hooks/useDebounce';

type AddTagWithAutocompletionProps = {
  dataSource: QueryFilterSearch<string>;
  placeholder?: string;
  onTagAdd: (tag: string) => void;
};

export const AddTagWithAutocompletion: React.FC<AddTagWithAutocompletionProps> = ({
  dataSource,
  placeholder = 'Enter value',
  onTagAdd,
}) => {
  const [searchText, setSearchText] = React.useState('');

  const searchTextDebounced = useDebounce(searchText, 200);
  const resolver = React.useCallback(
    () => dataSource(searchTextDebounced),
    [dataSource, searchTextDebounced],
  );
  const { value } = useAsyncValue({ resolver });

  const handleOnNewTag = React.useCallback(
    (tag) => {
      if (tag) {
        onTagAdd(tag);
      }
    },
    [onTagAdd],
  );

  const autocompletionValues = React.useMemo(() => {
    const filterResult = value?.entries ?? [];

    return filterResult.map((entry: FilterResultItem<string>) => entry.original);
  }, [value?.entries]);

  return (
    <AddWithAutocompletion
      placeholder={placeholder}
      autocompletionValues={autocompletionValues}
      onSubmit={handleOnNewTag}
      onChange={setSearchText}
      value={searchText}
    />
  );
};
