import { WorkspaceStatus } from '@neptune/pricing-workspace-status-domain';
import { combineReducers } from 'redux';

import { fetchStatus as FetchStatus } from 'state/fetch-status';

import { WorkspaceStatusActions, WorkspaceStatusActionTypes } from './actions';

export type SingleWorkspaceStatusState = {
  fetchStatus: FetchStatus;
  date?: string;
} & Partial<WorkspaceStatus>;

type ByOrganizationState = Record<string, SingleWorkspaceStatusState | undefined>;

export type WorkspaceStatusState = {
  byOrganization: ByOrganizationState;
};

const byOrganizationSupportedActionTypes = Object.values(WorkspaceStatusActionTypes);

export const workspaceStatusReducer = combineReducers({
  byOrganization: byOrganizationReducer,
});

function byOrganizationReducer(
  state: ByOrganizationState = {},
  action: WorkspaceStatusActions,
): ByOrganizationState {
  if (!byOrganizationSupportedActionTypes.includes(action.type)) {
    return state;
  }

  const organizationIdentifier = action.payload.organizationIdentifier;
  const currentState = state[organizationIdentifier];
  const newState = singleWorkspaceStateReducer(currentState, action);

  return currentState === newState
    ? state
    : {
        ...state,
        [organizationIdentifier]: newState,
      };
}

const singleWorkspaceStateInitialState: SingleWorkspaceStatusState = {
  fetchStatus: FetchStatus.NONE,
};

function singleWorkspaceStateReducer(
  state: SingleWorkspaceStatusState = singleWorkspaceStateInitialState,
  action: WorkspaceStatusActions,
): SingleWorkspaceStatusState {
  switch (action.type) {
    case WorkspaceStatusActionTypes.request: {
      return state.fetchStatus === FetchStatus.PENDING
        ? state
        : {
            ...state,
            fetchStatus: FetchStatus.PENDING,
          };
    }

    case WorkspaceStatusActionTypes.success: {
      return {
        ...action.payload.status,
        fetchStatus: FetchStatus.SUCCESS,
        date: action.payload.date,
      };
    }

    case WorkspaceStatusActionTypes.fail: {
      return state.fetchStatus === FetchStatus.FAILED
        ? state
        : {
            ...state,
            fetchStatus: FetchStatus.FAILED,
          };
    }

    default: {
      return state;
    }
  }
}
