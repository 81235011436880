import React from 'react';

import { isFetchStatusFailed } from 'state/fetch-status';
import { ErrorPlaceholderContainer } from '@neptune/shared/common-feature';

import { ProjectUsageHistory } from './ProjectUsageHistory';
import { useProjectUsageHistory } from './useProjectUsageHistory';

type ProjectUsageHistoryContainerProps = {
  projectIdentifier: string;
};

export const ProjectUsageHistoryContainer: React.FC<ProjectUsageHistoryContainerProps> = ({
  projectIdentifier,
}) => {
  const { data, fetchStatus } = useProjectUsageHistory({
    projectIdentifier,
  });

  if (isFetchStatusFailed(fetchStatus)) {
    return <ErrorPlaceholderContainer context="project-usage-history" withBorder />;
  }

  return <ProjectUsageHistory data={data} fetchStatus={fetchStatus} />;
};
