import { storage } from 'common/storage';
import { makeEnterpriseRoute } from '@neptune/shared/enterprise-context-business-logic';
import { rejectActivation } from '@neptune/shared/routing-business-logic';
import { AppRoute } from '@neptune/shared/routing-domain';

const modelRegistryRoute: AppRoute = {
  name: 'project.model-registry',
  path: '/models',
  canActivate: () => (toState) => {
    const { name, params } = toState;

    if (params.type === 'model') {
      if (!['versions', 'activity-log', 'metadata'].includes(params.detailsTab)) {
        return rejectActivation({
          name,
          params: { ...params, detailsTab: 'versions' },
        });
      }
    }

    if (params.type === 'modelVersion') {
      if (!['activity-log', 'metadata'].includes(params.detailsTab)) {
        return rejectActivation({
          name,
          params: { ...params, detailsTab: 'metadata' },
        });
      }
    }

    return true;
  },
  onActivate: () => () => storage.local.setItem('lastProjectTab', 'models'),
};

export const modelRegistryRoutes = [modelRegistryRoute, makeEnterpriseRoute(modelRegistryRoute)];
