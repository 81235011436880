import React from 'react';
import { bemBlock, Text } from '@neptune/shared/venus-ui';
import { KnownAttributes, StringAttribute } from 'domain/experiment/attribute';
import { PropertyRow } from 'components/layouts/rows';
import { EditStringAttribute } from './inputs/EditStringAttribute';
import { EntityChanges } from '@neptune/shared/entity-domain';
import './DescriptionRenderer.less';

const block = bemBlock('entity-properties-description-renderer');

type DescriptionRendererProps = {
  attribute?: StringAttribute;
  canModify: boolean;
  placeholder?: string;
  labelWidth?: string;
  label?: string;
  'data-name'?: string;
  onEntityChange: (entityChanges: Partial<EntityChanges>) => Promise<void>;
};

const emptyAttribute: StringAttribute = {
  attributeName: KnownAttributes.Description,
  attributeType: 'string',
  value: '',
};

export const DescriptionRenderer: React.FC<DescriptionRendererProps> = ({
  attribute = emptyAttribute,
  canModify,
  placeholder,
  labelWidth = '100px',
  label,
  'data-name': dataName,
  onEntityChange,
}) => {
  const description = attribute.value;

  const handleDescriptionChange = React.useCallback(
    (value: string) => {
      onEntityChange({ description: value });
    },
    [onEntityChange],
  );

  return (
    <PropertyRow className={block()} labelWidth={labelWidth} label={label} data-name={dataName}>
      {description.length > 0 || canModify ? (
        <EditStringAttribute
          className={block('input')}
          attribute={attribute}
          placeholder={placeholder}
          textArea
          isEditable={canModify}
          onChange={handleDescriptionChange}
        />
      ) : (
        <Text color="text-alt" size="sm">
          No description
        </Text>
      )}
    </PropertyRow>
  );
};
