import { neptuneUrls } from '@neptune/shared/common-domain';
import type { Integration } from './types';

export const python: Integration = {
  id: 'python',
  title: 'Any framework (Python)',
  installCommand: 'pip install neptune',
  scriptName: 'train.py',
  runCommand: 'python train.py',
  link: neptuneUrls.integrationPythonDoc,
  script: `import neptune

run = neptune.init_run(
    project="<%= projectIdentifier %>",
    api_token="<%= apiToken %>",
)  # your credentials

params = {"learning_rate": 0.001, "optimizer": "Adam"}
run["parameters"] = params

for epoch in range(10):
    run["train/loss"].append(0.9 ** epoch)

run["eval/f1_score"] = 0.66

run.stop()`,
};
