import { SurveyDescriptor, SurveyFlow } from 'common/survey-flow';

import { TEAM_TYPE_OTHER_OPTION_NAME } from './team-type/TeamTypeForm';

export type RegistrationSurveyData = {
  organizationType?: string;
  role?: string;
  otherRoleDescription?: string;
  teamTrial?: string;

  // sub questions in organization types
  partOfEduOrNonProfit?: string;
  requested1On1Session?: string;

  // additional integrations near framework
  suggestedIntegrations?: string;

  // internal state
  teamTrialRegistration?: boolean;
  teamInvitationRegistration?: boolean;

  signupDevice?: string;

  typeOfData?: string[];
  otherTypeOfData?: boolean;
  otherTypeOfDataValue?: string;
  selfAttributedChannel?: string;
  teamType?: string;
  otherTeamTypeDescription?: string;
};

type TargetRegistrationSurveyData = {
  organizationType?: string;
  role?: string;
  teamTrial?: string;
  partOfEduOrNonProfit?: string;
  requested1On1Session?: string;
  suggestedIntegrations?: string;
  signedupFromInvite: boolean;
  signupDevice?: string;
  createdTeamWorkspaceOnSignup: string;
  typeOfData: string;
  selfAttributedChannel?: string;
  teamType?: string;
};

export function formatSurvey(data: RegistrationSurveyData) {
  const target: TargetRegistrationSurveyData = {
    typeOfData: [
      ...(data.typeOfData || []),
      ...(data.otherTypeOfData ? [`Other: ${data.otherTypeOfDataValue}`] : []),
    ].join(', '),
    organizationType: formatOrganizationType(data),
    role: data.role === 'Other' ? `Other: ${data.otherRoleDescription}` : data.role,
    partOfEduOrNonProfit: data.organizationType === 'team' ? data.partOfEduOrNonProfit : undefined,
    requested1On1Session:
      data.organizationType === 'team' && data.partOfEduOrNonProfit === 'no'
        ? data.requested1On1Session
        : undefined,
    suggestedIntegrations: data.suggestedIntegrations,
    teamTrial: data.teamTrial,
    signedupFromInvite: data.teamInvitationRegistration === true,
    signupDevice: data.signupDevice,
    createdTeamWorkspaceOnSignup: 'false',
    selfAttributedChannel: data.selfAttributedChannel,
    // if user leaves input empty, we treat it as 'Not in team' predefined value. If user fills input, we treat it as custom answer ('Other').
    teamType:
      data.teamType === TEAM_TYPE_OTHER_OPTION_NAME &&
      (data.otherTeamTypeDescription || '').length > 0
        ? `Other: ${data.otherTeamTypeDescription}`
        : data.teamType,
  };
  return JSON.stringify(target);
}

function formatOrganizationType(data: RegistrationSurveyData) {
  if (data.teamInvitationRegistration) {
    return 'team';
  }

  if (data.teamTrialRegistration) {
    return 'team-trial';
  }

  return data.organizationType;
}

export enum RegistrationSurveyStep {
  selfAttribution = 'selfAttribution',
  teamType = 'teamType',
  role = 'role',
  problem = 'problem',
  organizationType = 'organizationType',
  teamTrial = 'teamTrial',
}

type RegistrationSurveyDescriptor = SurveyDescriptor<
  RegistrationSurveyStep,
  RegistrationSurveyData
>;

const registrationSurveyDescriptor: RegistrationSurveyDescriptor = {
  [RegistrationSurveyStep.selfAttribution]: {
    validate: (data) => (data.selfAttributedChannel || '').length > 1,
    getNext: () => RegistrationSurveyStep.teamType,
  },
  [RegistrationSurveyStep.teamType]: {
    validate: (data) => !!data.teamType,
    getNext: () => RegistrationSurveyStep.role,
    getPrev: () => RegistrationSurveyStep.selfAttribution,
  },
  [RegistrationSurveyStep.role]: {
    validate: (data) =>
      data.role === 'Other'
        ? !!data.role && (data.otherRoleDescription || '').length > 0
        : !!data.role,
    getNext: () => RegistrationSurveyStep.problem,
    getPrev: (data) =>
      data.teamInvitationRegistration ? undefined : RegistrationSurveyStep.teamType,
  },
  [RegistrationSurveyStep.problem]: {
    isFinal: (data) =>
      data.teamTrialRegistration === true || data.teamInvitationRegistration === true,
    validate: (data) =>
      (data.typeOfData || []).length + (data.otherTypeOfData ? 1 : 0) > 0 &&
      !(data.otherTypeOfData && !data.otherTypeOfDataValue),
    getNext: ({ teamTrialRegistration, teamInvitationRegistration }) =>
      teamTrialRegistration || teamInvitationRegistration
        ? undefined
        : RegistrationSurveyStep.organizationType,
    getPrev: () => RegistrationSurveyStep.role,
  },
  [RegistrationSurveyStep.organizationType]: {
    isFinal: (data) => data.organizationType !== 'team',
    validate: (data) => !!data.organizationType,
    getNext: (data) =>
      data.organizationType === 'team' ? RegistrationSurveyStep.teamTrial : undefined,
    getPrev: () => RegistrationSurveyStep.problem,
  },
  [RegistrationSurveyStep.teamTrial]: {
    isFinal: () => true,
    validate: (data) => !!data.teamTrial,
    getPrev: () => RegistrationSurveyStep.organizationType,
  },
};

export type RegistrationSurveyFlow = SurveyFlow<RegistrationSurveyStep, RegistrationSurveyData>;

export const createRegistrationSurveyFlow = (
  submit: (data: RegistrationSurveyData) => Promise<void>,
): RegistrationSurveyFlow =>
  new SurveyFlow<RegistrationSurveyStep, RegistrationSurveyData>(
    registrationSurveyDescriptor,
    submit,
  );
