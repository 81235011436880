import React from 'react';
import { Dialog, Layout, Text } from '@neptune/shared/venus-ui';

import { ServiceAccount } from '@neptune/service-accounts-domain';

type RemoveProjectServiceAccountModalProps = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(): void;
  projectIdentifier: string;
  serviceAccount: ServiceAccount;
};

export const RemoveProjectServiceAccountModal: React.FC<RemoveProjectServiceAccountModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  projectIdentifier,
  serviceAccount,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onPrimaryButtonClick={onSubmit}
      onSecondaryButtonClick={onClose}
      primaryButtonLabel="Remove"
      primaryButtonRole="remove-service-account-submit"
      role="remove-service-account-modal"
      title="Remove service account"
    >
      <Layout.Column spacedChildren="md">
        <Text>
          You are about to remove <Text fontWeight="semibold">{serviceAccount.displayName}</Text>{' '}
          from project <Text fontWeight="semibold">{projectIdentifier}</Text>. Are you sure?
        </Text>
      </Layout.Column>
    </Dialog>
  );
};
