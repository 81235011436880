import React from 'react';
import { useSelector } from 'react-redux';

import { LinkRenderer, VersionMenu } from '@neptune/model-registry-model-version-ui';
import { getCurrentRouteName, getCurrentRouteParams } from '@neptune/shared/routing-business-logic';
import { NormalizedLink } from '@neptune/shared/routing-ui';
import { getDetailsTabFromRouteParams } from 'state/selectors-global';
import { versionTabs } from './version-tabs';

type VersionMenuContainerProps = {
  expanded: boolean;
};

export const VersionMenuContainer: React.FC<VersionMenuContainerProps> = ({ expanded }) => {
  const routeName = useSelector(getCurrentRouteName);
  const routeParams = useSelector(getCurrentRouteParams);
  const detailsTab = useSelector(getDetailsTabFromRouteParams) || 'metadata';

  const renderLink: LinkRenderer = (item, children) => (
    <NormalizedLink
      key={item.id}
      children={children}
      routeName={routeName}
      routeParams={{
        ...routeParams,
        detailsTab: item.id,
      }}
    />
  );

  return (
    <VersionMenu
      isActive={(itemId) => itemId === detailsTab}
      items={versionTabs}
      renderLink={renderLink}
      expanded={expanded}
    />
  );
};
