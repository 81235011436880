import { State } from 'router5';

export enum KnownSignals {
  LEADERBOARD_RENDERED = 'leaderboard.rendered',
  PROJECTS_RENDERED = 'projects.rendered',
  SURVEY_SUBMIT = 'survey.submit',
  SURVEY_SUBMIT_FAILED = 'survey.failed',
  ROUTE_CHANGED = 'route.changed',
  INVITATIONS_RENDERED = 'invitations.rendered',
}

export type InitParams = {
  route: State | null;
  isMobile: boolean;
};

export type RouteChangedParams = {
  route: State | null;
};

type LeaderboardLoadParams = {
  items: number;
  renderedItems: number;
  isError: boolean;
};

type ProjectsLoadParams = {
  items: number;
  renderedItems: number;
  isError: boolean;
};

type SurveySubmitParams = {
  withInvites: boolean;
};

export type ParamsMap = {
  [KnownSignals.LEADERBOARD_RENDERED]: LeaderboardLoadParams;
  [KnownSignals.PROJECTS_RENDERED]: ProjectsLoadParams;
  [KnownSignals.SURVEY_SUBMIT]: SurveySubmitParams;
  [KnownSignals.ROUTE_CHANGED]: RouteChangedParams;
  [KnownSignals.INVITATIONS_RENDERED]: null;
  [KnownSignals.SURVEY_SUBMIT_FAILED]: null;
};

export type SubscriptionMap<T extends KnownSignals> = (msg: T, data: ParamsMap[T]) => void;
