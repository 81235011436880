// Libs
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rx';

// App
import { verifyProjectKey } from '@neptune/shared/core-project-domain';

const PROJECT_KEY_REGEX = /^[A-Z0-9]{1,10}$/;

const withProjectKeyChecked = Component => class extends React.Component {
  componentDidMount() {
    this.projectKeyUpdatesStream = new Rx.Subject();

    this.projectKeyUpdatesSubscription = (
      this
        .projectKeyUpdatesStream
        .debounce(300)
        .filter(() => this.props.canValidateProjectKey)
        .map(this.validateFormat)
        .flatMapLatest(this.validateUniqueness)
        .retryWhen(errors => errors)
        .subscribe(
          ({ error }) => this.props.onValidationChange({ error }),
        )
    );
  }

  componentDidUpdate(prevProps) {
    const {
      organizationId,
      projectKey,
    } = this.props;

    if (prevProps.projectKey !== projectKey || organizationId !== prevProps.organizationId) {
      this.projectKeyUpdatesStream.onNext({organizationId, projectKey});
    }
  }

  componentWillUnmount() {
    this.projectKeyUpdatesSubscription.dispose();
    this.projectKeyUpdatesStream = null;
  }

  validateFormat(props) {
    const { projectKey = '' } = props;

    const containsDigitsOnly = /^\d+$/.test(projectKey);
    if (containsDigitsOnly) {
      return {
        error: 'Project key must contain at least one letter',
        ...props,
      };
    }

    const res = PROJECT_KEY_REGEX.test(projectKey);

    return {
      error: res ? '' : 'Project key must contain 1 to 10 alphanumeric capital chars',
      ...props,
    };
  }

  validateUniqueness(props) {
    // we pass it further if error occurred before
    if (props.error) {
      return Promise.resolve(props);
    }
    const {organizationId, projectKey} = props;

    return verifyProjectKey({organizationIdentifier: organizationId, projectKey})
      .catch(() => ( {projectExists: false} ))
      .then(({projectExists}) => {
        const error = projectExists ? 'Project key already exists' : '';
        return {error};
      });
  }

  render() {
    return (
      <Component
        {...this.props}
      />
    );
  }
};

export default withProjectKeyChecked;
