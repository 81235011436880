import { Input } from '@neptune/shared/common-ui';
import { Button, Dropdown, Layout, Text, bemBlock } from '@neptune/shared/venus-ui';
import React from 'react';

import './StepsRangeDropdown.less';

const block = bemBlock('steps-range-dropdown');

type StepsRangeDropdownProps = {
  onReset: () => void;
  onChange: (range: { from?: number; to?: number }) => void;
  range: { from?: number | string; to?: number | string };
};

const BASE_TAB_INDEX = 1;

export const StepsRangeDropdown: React.FC<StepsRangeDropdownProps> = ({
  range,
  onReset,
  onChange,
}) => {
  const handleChangeFactory = (key: 'from' | 'to') => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      onChange({ [key]: undefined });
      return;
    }

    const number = Number(e.target.value);

    if (!isNaN(number)) {
      onChange({ [key]: Number(e.target.value) });
    }
  };

  const handleChangeFrom = handleChangeFactory('from');
  const handleChangeTo = handleChangeFactory('to');

  return (
    <Dropdown.Menu
      className={block()}
      withPadding="sm"
      justifyContent="start"
      width={400}
      data-role="step-range-dropdown-container"
    >
      <Layout.Row withPadding="xs" spacedChildren="md">
        <Layout.Column spacedChildren="sm">
          <Text size="xs" color="text-alt" fontWeight="semibold" className={block('label')}>
            Step range
          </Text>
          <div className={block('left-col')}>
            <Input
              name="name"
              data-role="step-range-from-input"
              placeholder="min (auto)"
              value={range.from ?? ''}
              onChange={handleChangeFrom}
              className={block('input')}
              autoFocus
              tabIndex={BASE_TAB_INDEX}
            />
          </div>
        </Layout.Column>
        <Layout.Column alignItems="end" spacedChildren="sm">
          <Button
            tabIndex={BASE_TAB_INDEX + 2}
            onClick={onReset}
            data-role="step-range-reset-button"
            size="sm"
            variant="transparent"
            color="text-alt"
          >
            Reset
          </Button>
          <Input
            name="name"
            tabIndex={BASE_TAB_INDEX + 1}
            data-role="step-range-to-input"
            placeholder="max (auto)"
            value={range.to ?? ''}
            onChange={handleChangeTo}
            className={block('input')}
          />
        </Layout.Column>
      </Layout.Row>
    </Dropdown.Menu>
  );
};
