import { Icon, SimpleTooltip } from '@neptune/shared/venus-ui';
import React from 'react';

type RegexTooltipProps = {
  onClick: () => void;
  className?: string;
};

export const RegExpTooltip: React.FC<RegexTooltipProps> = ({ onClick, className }) => {
  const content = (
    <>
      The search bar supports regular expressions.
      <br />
      Click for instructions.
    </>
  );

  return (
    <SimpleTooltip content={content}>
      <div className={className} onClick={onClick} data-role="regexp-tooltip">
        <Icon glyph="circle-question-mark" />
      </div>
    </SimpleTooltip>
  );
};
