// App
import getParentState from '../selectors';
import { AppState } from 'state/types';

// Module
export function getLeaderboardState(state: AppState) {
  return getParentState(state).projectLeaderboard;
}

export default getLeaderboardState;
