import React from 'react';

import { Entity, EntityChanges } from '@neptune/shared/entity-domain';
import { PropertyRow } from 'components/layouts/rows';
import type { StringAttribute } from 'domain/experiment/attribute';
import { stringAttributeToStage } from '@neptune/model-registry-common-business-logic';
import { StageDropdownContainer } from '@neptune/model-registry-common-feature';

type StringRendererProps = {
  attribute?: StringAttribute;
  labelWidth?: string;
  label?: string;
  'data-name'?: string;
  modelVersion: Entity;
  canModify: boolean;
  onEntityChange: (entityChanges: Partial<EntityChanges>) => Promise<void>;
};

export const StageRenderer: React.FC<StringRendererProps> = ({
  attribute,
  labelWidth = '100px',
  label,
  'data-name': dataName,
  modelVersion,
  canModify,
  onEntityChange,
}) => {
  if (!attribute) {
    return null;
  }

  const stage = stringAttributeToStage(attribute);

  if (!stage) {
    // oops, maybe wrong attribute passed?
    return null;
  }

  const value = (
    <StageDropdownContainer
      modelVersion={modelVersion}
      disabled={!canModify}
      onEntityChange={onEntityChange}
    />
  );

  return (
    <PropertyRow
      value={value}
      labelWidth={labelWidth}
      label={label}
      data-name={dataName}
      copyable={false}
    />
  );
};
