// App
import { ProjectUsageDTO } from '@neptune/shared/core-apis-backend-domain';

// Module
import { UsageProjectModel } from './usage-project-model';

export abstract class UsageProjectModelConverter {
  static usageProjectFromApiToDomain({
    projectId,
    projectName,
    last30DaysSeconds,
    storageUsed,
    deleted,
  }: ProjectUsageDTO): UsageProjectModel {
    return {
      projectId,
      projectName,
      last30DaysMinutes: last30DaysSeconds / 60,
      storageUsed,
      deleted,
    };
  }
}
