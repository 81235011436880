// Lib
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {isFunction} from 'lodash';

// App
import { Dialog } from '@neptune/shared/venus-ui';
import {closeConfirmationModal} from 'state/ui/global/confirmation-modal/actions';
import {
  getConfirmationModalStateByType,
  getConfirmationModalDataByType,
} from 'state/selectors';

const propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  className: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};
const defaultProps = {
  confirmButtonText: 'OK',
  cancelButtonText: 'Cancel',
};

class ConfirmationModal extends React.Component {
  constructor(...args) {
    super(...args);

    this.close = this.close.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  close() {
    if (isFunction(this.props.onCancel)) {
      this.props.onCancel();
    }
    this.props.handleClose(this.props.type);
  }

  confirm() {
    this.props.onConfirm(this.props.modalData);
    this.props.handleClose(this.props.type);
  }

  render() {
    return (
      <Dialog
        className={this.props.className}
        role={this.props.role}
        isOpen={this.props.modalOpened}
        title={this.props.title}
        onClose={this.close}
        primaryButtonLabel={this.props.confirmButtonText}
        primaryButtonRole="confirm-button"
        onPrimaryButtonClick={this.confirm}
        secondaryButtonLabel={this.props.cancelButtonText}
        secondaryButtonRole="cancel-button"
        onSecondaryButtonClick={this.close}
      >
        {this.props.children}
      </Dialog>
    );
  }
}

ConfirmationModal.propTypes = propTypes;
ConfirmationModal.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
  return {
    modalOpened: getConfirmationModalStateByType(state, ownProps.type),
    modalData: getConfirmationModalDataByType(state, ownProps.type),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleClose: (type) => {
      dispatch(closeConfirmationModal(type));
    },
  };
};

ConfirmationModal = connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);

export default ConfirmationModal;
