type Pricing = {
  currentValue: number;
  priceTiers: Tier[];
};

type Tier = {
  lowerAmount: number;
  upperAmount: number;
  unitPriceInCents: number;
};

export function calculateFreeAmount(pricing: Pricing): number {
  const amountFromFreeTiers = getFreeTiers(pricing)
    .map(({ lowerAmount, upperAmount }) => upperAmount - lowerAmount)
    .reduce((a, b) => a + b, 0);
  const minimalAmount = pricing.priceTiers
    .map(({ lowerAmount }) => lowerAmount)
    .reduce((a, b) => Math.min(a, b), Number.POSITIVE_INFINITY);
  return amountFromFreeTiers + (Number.isFinite(minimalAmount) ? minimalAmount : 0);
}

export function getUnitPriceForFirstPaidTier(pricing: Pricing): number {
  const paidTiers = getPaidTiers(pricing);
  return paidTiers.length > 0 ? paidTiers[0].unitPriceInCents : 0;
}

function getFreeTiers(pricing: Pricing): Tier[] {
  return pricing.priceTiers.filter(({ unitPriceInCents }) => unitPriceInCents === 0);
}

function getPaidTiers(pricing: Pricing): Tier[] {
  return pricing.priceTiers.filter(({ unitPriceInCents }) => unitPriceInCents > 0);
}

export function calculateCostBasedOnPricing(amount: number, pricing: Pricing): number {
  const [min, max] = pricing.priceTiers.reduce(
    ([min, max], tier) => {
      return [Math.min(min, tier.lowerAmount), Math.max(max, tier.upperAmount)];
    },
    [Infinity, 0],
  );

  let result = 0;

  if (amount < min) {
    throw new Error('Minimum amount not met');
  }

  if (amount > max) {
    throw new Error('Maximum amount exceeded');
  }

  pricing.priceTiers.forEach((tier) => {
    if (amount >= tier.upperAmount) {
      result += (tier.upperAmount - tier.lowerAmount) * tier.unitPriceInCents;
    } else if (amount > tier.lowerAmount) {
      result += (amount - tier.lowerAmount) * tier.unitPriceInCents;
    }
  });

  return result;
}

export function calculateCostBasedOnPricingWhileTakingIntoAccountPreviousPurchases(
  amount: number,
  pricing: Pricing,
): number {
  let result = calculateCostBasedOnPricing(amount + pricing.currentValue, pricing);
  result -= calculateCostBasedOnPricing(pricing.currentValue, pricing);

  return result;
}
