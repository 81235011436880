export function makeExperimentIdentifier(
  organizationName: string,
  projectName: string,
  shortId: string,
): string {
  return `${organizationName}/${projectName}/${shortId}`;
}

export function makeExperimentIdentifierWithProjectIdentifier(
  projectIdentifier: string,
  shortId: string,
) {
  return `${projectIdentifier}/${shortId}`;
}

export function getShortIdFromQualifiedName(qualifiedName: string): string | undefined {
  return qualifiedName.split('/').pop();
}
