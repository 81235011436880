import { backendClient } from '@neptune/shared/core-apis-backend-domain';

export const getProjectStorage = async (
  projectIdentifier: string,
): Promise<{ storageLimit: number | undefined; storageUsage: number }> => {
  const { traits, storageUsage } = await backendClient.getProjectMetadata({ projectIdentifier });

  return {
    storageUsage,
    storageLimit: traits.storageLimit?.maxStorageSize,
  };
};

export const updateProjectStorageLimit = async (
  projectIdentifier: string,
  storageLimit: number,
) => {
  try {
    await backendClient.setStorageLimit({
      projectIdentifier,
      storageLimit: { maxStorageSize: storageLimit },
    });
  } catch (error: unknown) {
    if (error instanceof Response) {
      throw await error.json();
    }

    throw error;
  }
};

export const deleteProjectStorageLimit = async (projectIdentifier: string) => {
  try {
    await backendClient.deleteStorageLimit({ projectIdentifier });
  } catch (error: unknown) {
    if (error instanceof Response) {
      throw await error.json();
    }

    throw error;
  }
};
