import React from 'react';
import { noop } from 'lodash';

import { RunsLineage } from '@neptune/shared/leaderboard-domain';

const DEFAULT_VALUE = RunsLineage.FULL;

export type RunsLineageOptionsContextValue = [RunsLineage, (value: RunsLineage) => void];

export const RunsLineageOptionsContext = React.createContext<RunsLineageOptionsContextValue>([
  DEFAULT_VALUE,
  noop,
]);

export const useRunsLineageOptions = (): RunsLineageOptionsContextValue => {
  const [value, setValue] = React.useContext(RunsLineageOptionsContext);

  return [value, setValue];
};
