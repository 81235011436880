import React from 'react';
import {
  getInvitationEntityName,
  getInvitationTarget,
  Invitation,
} from '@neptune/invitations-domain';
import { bemBlock, Dialog, Text } from '@neptune/shared/venus-ui';

import './AcceptInvitation.less';

type AcceptUserInvitationProps = {
  invitation: Invitation;
  username: string;
  onClose: () => void;
  onConfirm: () => void;
};
const block = bemBlock('accept-invitation');

export const AcceptUserInvitation: React.FC<AcceptUserInvitationProps> = ({
  invitation,
  username,
  onClose,
  onConfirm,
}) => {
  const target = getInvitationTarget(invitation);
  const entityString = getInvitationEntityName(invitation);

  return (
    <div className={block()}>
      <Dialog
        isOpen
        title={`Join ${target}`}
        role="accept-invitation-dialog"
        closeOnBackgroundClick={false}
        onClose={onClose}
        primaryButtonRole="confirm-button"
        onPrimaryButtonClick={onConfirm}
        secondaryButtonRole="cancel-button"
        onSecondaryButtonClick={onClose}
      >
        <Text fontWeight="semibold">{username}</Text> you are about to join{' '}
        <Text fontWeight="semibold">{entityString}</Text>. Do you want to proceed?
      </Dialog>
    </div>
  );
};
