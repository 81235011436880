import React from 'react';
import { Button, GenericLink } from '@neptune/shared/venus-ui';

export const NotifyAdmin: React.FC<{ recipients: string[] }> = ({ recipients }) => {
  if (recipients.length === 0) {
    return null;
  }

  return (
    <Button<React.ComponentProps<typeof GenericLink>>
      data-role="notify-admin"
      component={GenericLink}
      children="Notify Admin"
      href={`mailto:${recipients.join()}`}
    />
  );
};
