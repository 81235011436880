import { GroupOptions, isGroupByParams } from '@neptune/shared/leaderboard-domain';
import { AttributeDefinition, getReadableAttributeName } from 'domain/experiment/attribute';

export function getAttributesDefinitionsFromGroupOptions(
  groupOptions?: GroupOptions,
): AttributeDefinition[] {
  if (!isGroupByParams(groupOptions)) {
    return [];
  }

  return groupOptions.groupBy.map(
    (name, index): AttributeDefinition => ({
      name,
      type: groupOptions.groupByFieldType[index],
      displayName: getReadableAttributeName(name),
      subproperty: groupOptions.groupByFieldAggregationMode[index],
    }),
  );
}
