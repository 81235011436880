import { UsageFlattenedPricing, UsagePricing } from '@neptune/pricing-commons-domain';
import {
  calculateCostBasedOnPricing,
  calculateFreeAmount,
  getUnitPriceForFirstPaidTier,
} from '@neptune/pricing-commons-util';

export function flattenUsagePricing(pricing: UsagePricing): UsageFlattenedPricing {
  return {
    unitsInFreeTier: calculateFreeAmount(pricing),
    unitPriceInPaidTier: getUnitPriceForFirstPaidTier(pricing) / 100,
    currentUnits: pricing.currentValue,
    paidUnits: Math.max(0, pricing.currentValue - calculateFreeAmount(pricing)),
    totalPriceForCurrentUnits: calculateCostBasedOnPricing(pricing.currentValue, pricing) / 100,
    unitSymbol: pricing.unitSymbol,
    stepSize: pricing.stepSize,
  };
}
