import { Observable, filter } from 'rxjs';

import { EntityLeaderboardNotification } from '@neptune/shared/entity-leaderboard-domain';
import {
  ProjectNotification,
  getProjectNotificationStream,
} from '@neptune/shared/core-project-business-logic';

type GetEntityLeaderboardNotificationStreamParams = {
  projectId: string;
  entityTypes: string[];
};

export function getEntityLeaderboardNotificationStream({
  projectId,
  entityTypes,
}: GetEntityLeaderboardNotificationStreamParams): Observable<EntityLeaderboardNotification> {
  const projectNotifications$ = getProjectNotificationStream({ projectId });

  return projectNotifications$.pipe(
    filter(isProjectLeaderboardChangedNotification),
    filter((notification) => matchEntityTypes(entityTypes, notification)),
  );
}

function isProjectLeaderboardChangedNotification(
  notification: ProjectNotification,
): notification is EntityLeaderboardNotification {
  return notification.messageType === 'ProjectLeaderboardChanged';
}

function matchEntityTypes(
  entityTypes: string[],
  notification: EntityLeaderboardNotification,
): boolean {
  const notificationEntityTypes = notification.messageBody.experimentTypes;
  return entityTypes.some((entityType) => notificationEntityTypes.includes(entityType));
}
