import { useRecentSearches } from '@neptune/recent-searches-business-logic';
import { SearchQuery } from '@neptune/search-query-domain';

export const useRecentSearchesQueries = ({ namespace }: { namespace: string }) => {
  return useRecentSearches<SearchQuery>({
    namespace,
    key: 'recentSearchQueries',
    getInitialState: (lsState, projectIdentifier) =>
      lsState?.recentSearchQueries?.[namespace]?.[projectIdentifier],
  });
};
