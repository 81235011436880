import React from 'react';
import { useAsyncValue } from '@neptune/shared/common-util';
import { fetchServiceAccounts } from '@neptune/service-accounts-domain';

export const useServiceAccounts = (organizationName: string | undefined, filter?: string) => {
  const resolver = React.useCallback(async () => {
    if (!organizationName) {
      return undefined;
    }

    return await fetchServiceAccounts(organizationName);
  }, [organizationName]);

  const {
    error,
    loading,
    refresh,
    value: allEntries,
  } = useAsyncValue({
    resolver,
  });

  const value = React.useMemo(() => {
    if (!filter) {
      return allEntries;
    }

    return allEntries?.filter(({ name }) => name.toLowerCase().includes(filter.toLowerCase()));
  }, [allEntries, filter]);

  return {
    error,
    loading,
    refresh,
    value,
  };
};
