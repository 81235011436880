// Libs
import React from 'react';
import PropTypes from 'prop-types';
import {
  bemBlock,
  Layout,
} from '@neptune/shared/venus-ui';

// App
import { CopyToClipboard } from '@neptune/shared/common-ui';

// Module
import './PropertyRow.less';

const propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  labelWidth: PropTypes.string,
  labelChildren: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  copyable: PropTypes.bool,
  centered: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  textValue: PropTypes.string,
};

const defaultProps = {
  copyable: false,
  labelChildren: null,
  labelWidth: '150px',
};

const block = bemBlock('property-row');

class PropertyRow extends React.PureComponent {

  renderLabel = () => {
    const {
      label,
      labelChildren,
      labelWidth,
    } = this.props;
    const isAuto = labelWidth === 'auto';
    const labelProps = {
      width: isAuto ? null : labelWidth,
      span: isAuto ? 'auto' : undefined,
    };

    return (
      <Layout.Row
        className={block('label')}
        data-role="property-row-label"
        {...labelProps}
      >
        {label} {labelChildren}
      </Layout.Row>
    );
  };


  renderClipboardButton() {
    const {
      copyable,
      value,
      textValue,
    } = this.props;

    if (copyable) {
      return (
        <CopyToClipboard
          text={textValue ? textValue : value}
          className={block('copy-to-clipboard')}
        />
      );
    }
  }


  render() {
    const {
      centered,
      children,
      className,
      title,
      textValue,
      value,
      label,
      labelWidth,
      labelChildren,
      copyable,
      ...otherProps
    } = this.props;

    if ((value == null || isEmptyString(value)) && !children) {
      return null;
    }

    const ownProps = {};

    if (centered) {
      ownProps.alignItems = 'center';
    }

    return (
      <Layout.Row
        className={block({ extra: className })}
        span="auto"
        spacedChildren="md"
        data-role="property-row"
        {...ownProps}
        {...otherProps}
      >
        { this.renderLabel() }
        <Layout.Row
          className={block('value')}
          span="greedy"
          title={title || textValue || value}
          data-role="property-row-value"
        >
          {children || value}
          { this.renderClipboardButton() }
        </Layout.Row>
      </Layout.Row>
    );
  }
}

PropertyRow.propTypes = propTypes;
PropertyRow.defaultProps = defaultProps;


export default PropertyRow;


function isEmptyString(value) {
  return typeof value === 'string' && value.length === 0;
}
