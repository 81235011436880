import { toRunsTabName } from '@neptune/project-runs-domain';
import {
  createCurrentRouteParamSelector,
  getCurrentRouteParams,
} from '@neptune/shared/routing-business-logic';
import { useSelector } from 'react-redux';
import { Params } from 'router5/types/types/base';

type DashboardMode = 'single' | 'compare';
const runIdentificationKeySelector = createCurrentRouteParamSelector('runIdentificationKey');

export const useRunsViewMode = (): { dashboardMode: DashboardMode } => {
  const runIdentificationKey: string = useSelector(runIdentificationKeySelector);
  const params = useSelector(getCurrentRouteParams);

  const dashboardMode = determineDashboardMode(params, runIdentificationKey);

  return { dashboardMode };
};

const determineDashboardMode = (params: Params, runIdentificationKey: string): DashboardMode => {
  const currentTab = toRunsTabName(params.tab, 'table');
  return (currentTab === 'table' && !!runIdentificationKey) || currentTab === 'details'
    ? 'single'
    : 'compare';
};
