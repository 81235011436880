import { isArray } from 'lodash';

export function serializeToString(value?: string): string | undefined {
  return value;
}

export function serializeToStringArray(value?: string[]): string | undefined {
  if (!isArray(value) || value.length === 0) {
    return undefined;
  }

  return JSON.stringify(value);
}

export function serializeToBoolean(value?: boolean): string | undefined {
  return value != null ? value.toString() : undefined;
}
