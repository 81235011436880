// App
import { hideGenericModal, showGenericModal } from 'state/ui/modals/generic/actions';

// Module
export const SERVER_ERROR_MODAL_NAME = 'serverError';

export function hideServerErrorModal() {
  return hideGenericModal(SERVER_ERROR_MODAL_NAME);
}

export function showServerErrorModal() {
  return showGenericModal(SERVER_ERROR_MODAL_NAME);
}
