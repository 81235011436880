import { LoginActionDTO } from '@neptune/shared/core-apis-backend-domain';

export function setLoginActions(actions: LoginActionDTO[]) {
  return {
    type: 'LOGIN_ACTIONS_SET',
    payload: actions,
  } as const;
}

export function completeLoginAction(action: LoginActionDTO) {
  return {
    type: 'LOGIN_ACTIONS_COMPLETE',
    payload: action,
  } as const;
}

export function setLoginActionFlag(name: 'registration' | 'teamTrial') {
  return {
    type: 'LOGIN_ACTIONS_SET_FLAG',
    payload: name,
  } as const;
}

export type RegisterActions = ReturnType<
  typeof setLoginActions | typeof completeLoginAction | typeof setLoginActionFlag
>;
