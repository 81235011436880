import React from 'react';
import { Entity, getEntityShortId } from '@neptune/shared/entity-domain';
import { useAsyncValue } from '@neptune/shared/common-util';
import { fetchEntitiesChunksInCluster } from './clustering-entity-attributes-fetcher';
import { fetchEntitiesChunks } from './fetch-entities-chunks';

export type UseEntitiesWithAttributesFetchingParams<E> = {
  entities: Entity[] | undefined;
  requestedAttributes: string[] | undefined;
  // Request clustering delays the request slightly, allowing for other requests for
  // same entities to be merged together.
  // When clustered, resulting data will contain all attributes from merged requests.
  allowRequestClustering?: boolean;
  // We might need to use the fetch only conditionally.
  skipFetch?: boolean;
  errorFormatter?: (error: unknown) => E;
};

export function useEntitiesWithAttributesFetching<E = unknown>({
  entities: inputEntities,
  requestedAttributes: fieldsToFetch,
  allowRequestClustering = false,
  skipFetch,
  errorFormatter,
}: UseEntitiesWithAttributesFetchingParams<E>) {
  const fetchAttributes = React.useCallback(async (): Promise<
    (Entity | undefined)[] | undefined
  > => {
    if (skipFetch || !inputEntities || inputEntities.length === 0 || !fieldsToFetch) {
      return inputEntities?.map(() => undefined);
    }

    const fetchFunction = allowRequestClustering
      ? fetchEntitiesChunksInCluster
      : fetchEntitiesChunks;

    return fetchFunction({
      entities: inputEntities,
      fieldsToFetch,
    });
  }, [inputEntities, fieldsToFetch, allowRequestClustering, skipFetch]);

  const {
    value: lastFetchResult,
    loading,
    error,
    refresh,
  } = useAsyncValue({
    resolver: fetchAttributes,
    errorFormatter,
  });

  const resultEntities = React.useMemo(() => {
    return lastFetchResult && isCompatibleResult(lastFetchResult, inputEntities)
      ? lastFetchResult
      : undefined;
  }, [lastFetchResult, inputEntities]);

  return {
    entities: resultEntities,
    // For empty input useAsyncValue will still get into `loading` value,
    // but it doesn't make sense to return this value, as we know there
    // is no actual loading and the result won't change.
    loading: Boolean(inputEntities && inputEntities.length !== 0 && loading),
    error,
    refresh,
  };
}

function isCompatibleResult(result?: (Entity | undefined)[], requestedEntities?: Entity[]) {
  if (!result || !requestedEntities || result.length !== requestedEntities.length) {
    return false;
  }

  return result.every(
    (resultEntity, idx) =>
      !resultEntity || getEntityShortId(resultEntity) === getEntityShortId(requestedEntities[idx]),
  );
}
