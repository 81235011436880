// Libs
import React from 'react';
import { Layout, AvatarSize, Link, bemBlock, uiState } from '@neptune/shared/venus-ui';

// App
import { UserEntry } from '@neptune/shared/core-users-domain';
import { UserAvatarContainer } from '@neptune/shared/avatars-feature';

// Module
import './AvatarsList.less';

const block = bemBlock('avatars-list');

type MembersListProps = {
  avatarsSize?: AvatarSize;
  className?: string;
  members: UserEntry[];
  visibleAvatars: number;
  onClick?(): void;
};

export class AvatarsList extends React.PureComponent<MembersListProps> {
  public static defaultProps = {
    members: [],
    visibleAvatars: 5,
  };

  renderAvatars = () => {
    const { avatarsSize, members, visibleAvatars } = this.props;

    return (
      <Layout.Row
        data-role="members-list"
        className={block('avatars')}
        alignItems="center"
        reversed
      >
        {members.slice(0, visibleAvatars).reduceRight((avatars, member, key) => {
          avatars.push(
            <Layout.Element key={key} className={block('member')}>
              <UserAvatarContainer
                className={block('avatar')}
                username={member.username}
                size={avatarsSize}
              />
            </Layout.Element>,
          );

          return avatars;
        }, [] as JSX.Element[])}
      </Layout.Row>
    );
  };

  render() {
    const { members, visibleAvatars, onClick } = this.props;

    return (
      <Layout.Row
        className={block({ extra: [uiState.clickableClassName(!!onClick)] })}
        span="auto"
        alignItems="center"
        spacedChildren="sm"
        onClick={onClick}
      >
        {this.renderAvatars()}
        {members.length > visibleAvatars && (
          <Link
            fontWeight="normal"
            size="xs"
            children={`+${members.length - visibleAvatars} more`}
          />
        )}
      </Layout.Row>
    );
  }
}
