import React from 'react';
import { useMobileView } from 'common/hooks/useMobileView';
import { bemBlock, Layout } from '@neptune/shared/venus-ui';
import neptuneLogo from 'assets/logo/neptune-logo.svg';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import './SplashScreen.less';

const block = bemBlock('splash-screen');

const SplashScreen: React.FC = () => {
  useMobileView();
  return (
    <Layout.Column alignItems="center" justifyContent="center" className={block()}>
      <img className={block('logo')} src={neptuneLogo} alt="neptune logo" />
      <LoadingIndicator isVisible />
    </Layout.Column>
  );
};

export default SplashScreen;
