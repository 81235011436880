// App
import { AsyncActionsReturnType, createAsyncActions } from 'state/async-actions';
import { fetchUpcomingInvoice } from '@neptune/pricing-commons-domain';

// Module
export enum UpcomingInvoiceActionTypes {
  request = 'CURRENT_ORGANIZATION_UPCOMING_INVOICE_FETCH_REQUEST',
  success = 'CURRENT_ORGANIZATION_UPCOMING_INVOICE_FETCH_SUCCESS',
  fail = 'CURRENT_ORGANIZATION_UPCOMING_INVOICE_FETCH_FAIL',
}

const actions = createAsyncActions({
  types: UpcomingInvoiceActionTypes,
  async resolver(organizationIdentifier: string) {
    return fetchUpcomingInvoice(organizationIdentifier);
  },
});

export const { execute: fetchUpcomingInvoiceAction } = actions;
export type UpcomingInvoiceActions = AsyncActionsReturnType<typeof actions>;
