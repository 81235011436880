import { fetchStatus } from 'state/fetch-status';

import { OrganizationPricing } from '@neptune/pricing-commons-domain';
import { OrganizationPricingActions } from './organization-pricing-actions';

export interface OrganizationPricingState {
  fetchStatus: fetchStatus;
  pricing?: OrganizationPricing;
}

const initialState: OrganizationPricingState = {
  fetchStatus: fetchStatus.NONE,
  pricing: undefined,
};

export const organizationPricingReducer = (
  state: OrganizationPricingState = initialState,
  action: OrganizationPricingActions,
): OrganizationPricingState => {
  switch (action.type) {
    case 'ORGANIZATION_PRICING_FETCH_REQUEST':
      return {
        ...state,
        fetchStatus: fetchStatus.PENDING,
      };

    case 'ORGANIZATION_PRICING_FETCH_SUCCESS':
      return {
        ...state,
        pricing: action.payload,
        fetchStatus: fetchStatus.SUCCESS,
      };

    case 'ORGANIZATION_PRICING_FETCH_FAIL':
      return {
        ...state,
        pricing: undefined,
        fetchStatus: fetchStatus.FAILED,
      };

    default:
      return state;
  }
};
