import { unionWith, isPlainObject, isEmpty, zip, isEqual } from 'lodash';

import {
  availableColumnTypes,
  ColumnIdentifier,
  columnIdentityComparator,
  ColumnType,
  getBuiltInColumnSettingsById,
  LeaderboardColumn,
  leaderboardColumnFactory,
  NEPTUNE_COLUMN_IDS,
  primaryColumnId,
} from '@neptune/shared/leaderboard-domain';
import { loadState } from 'common/localStorage';
import { ExcludesUndefined } from 'common/utility-types';
import { stripUndefinedProps } from '@neptune/shared/common-util';

import { OrderedColumnsState } from '@neptune/shared/columns-domain';

export function orderedColumnsSorter(a: LeaderboardColumn, b: LeaderboardColumn): number {
  /**
   * First GroupBy, then Selection, then Compare, then pinned, then rest.
   */
  if (a.id === NEPTUNE_COLUMN_IDS.GROUP_BY) {
    return -1;
  }

  if (b.id === NEPTUNE_COLUMN_IDS.GROUP_BY) {
    return 1;
  }

  if (a.id === NEPTUNE_COLUMN_IDS.SELECTION) {
    return -1;
  }

  if (b.id === NEPTUNE_COLUMN_IDS.SELECTION) {
    return 1;
  }

  if (a.id === NEPTUNE_COLUMN_IDS.COMPARE) {
    return -1;
  }

  if (b.id === NEPTUNE_COLUMN_IDS.COMPARE) {
    return 1;
  }

  if (a.id === primaryColumnId) {
    return -1;
  }

  if (b.id === primaryColumnId) {
    return 1;
  }

  if (a.id === NEPTUNE_COLUMN_IDS.TYPE) {
    return -1;
  }

  if (b.id === NEPTUNE_COLUMN_IDS.TYPE) {
    return 1;
  }

  return Number(b.pinned ?? 0) - Number(a.pinned ?? 0);
}

export function filterGroupByColumns<T extends ColumnIdentifier>(
  columns: T[],
  groupingEnabled: boolean,
): T[] {
  return columns.filter((column) => groupingEnabled || column.id !== NEPTUNE_COLUMN_IDS.GROUP_BY);
}

export function ensureDefaultColumns(
  columns: LeaderboardColumn[],
  defaultColumnIds: string[],
): LeaderboardColumn[] {
  // if for some reason there are no columns just take defaults
  if (isEmpty(columns)) {
    return defaultColumnIds
      .map(getBuiltInColumnSettingsById)
      .filter(Boolean as any as ExcludesUndefined)
      .map(leaderboardColumnFactory);
  }

  const defaultVisibleColumnsForState = defaultColumnIds
    .map(getBuiltInColumnSettingsById)
    .filter(Boolean as any as ExcludesUndefined)
    .filter(({ hideable }) => hideable === false)
    .map(leaderboardColumnFactory);

  // make sure that columns contain non-removable columns (user can modify his own local storage...)
  return unionWith(columns, defaultVisibleColumnsForState, columnIdentityComparator);
}

export function getInitialState(): OrderedColumnsState {
  const storedState = loadState() as Record<string, any>;

  if (!storedState) {
    return {};
  }

  const storedColumns = storedState['orderedColumns'];

  if (!isOrderedColumnsState(storedColumns)) {
    return {};
  }

  return Object.keys(storedColumns).reduce((acc: OrderedColumnsState, columnsRootKey: string) => {
    if (!acc[columnsRootKey]) {
      acc[columnsRootKey] = {
        defaultColumnsIds: [],
        columns: {},
      };
    }

    acc[columnsRootKey].columns = validateColumnSets(storedColumns[columnsRootKey]);
    return acc;
  }, {});
}

function validateColumnSets(columnSets: Record<string, LeaderboardColumn[]>) {
  const validKeys = Object.keys(columnSets).filter((key) =>
    columnSets[key].every((column) => availableColumnTypes.includes(column.columnType)),
  );

  return validKeys.reduce((acc: Record<string, LeaderboardColumn[]>, columnsSetId) => {
    if (columnSets[columnsSetId].length > 0) {
      const storedColumns: LeaderboardColumn[] = columnSets[columnsSetId];

      acc[columnsSetId] = storedColumns.map(leaderboardColumnFactory).sort(orderedColumnsSorter);
    }

    return acc;
  }, {});
}

export function getColumnsSet(
  state: OrderedColumnsState,
  columnsSetId: string,
  columnsRootKey: string,
): LeaderboardColumn[] {
  return state[columnsRootKey]?.columns[columnsSetId] || [];
}

export function setColumnsToColumnsSet(
  state: OrderedColumnsState,
  columnsSetId: string,
  columnsRootKey: string,
  columns: LeaderboardColumn[],
) {
  return {
    ...state,
    [columnsRootKey]: {
      ...state[columnsRootKey],
      columns: {
        ...state[columnsRootKey]?.columns,
        [columnsSetId]: columns,
      },
    },
  };
}

export function isOrderedColumnsState(
  state: any,
): state is Record<string, Record<string, LeaderboardColumn[]>> {
  return isPlainObject(state);
}

export function getColumnsById<CT extends string = string>(
  columnIds: string[] | undefined,
  columnTypes: CT[] | undefined,
  columns: LeaderboardColumn[],
): (LeaderboardColumn | undefined)[] {
  return zip(columnIds, columnTypes).map(([id, columnType]) => {
    if (id && columnType) {
      return columns.find((column) =>
        columnIdentityComparator(column, { id, columnType: columnType as ColumnType }),
      );
    }

    return;
  });
}

export function areColumnSetsEqual(
  first: LeaderboardColumn[],
  second: LeaderboardColumn[],
): boolean {
  const firstFiltered = filterColumns(first).sort(orderedColumnsSorter);
  const secondFiltered = filterColumns(second).sort(orderedColumnsSorter);

  return isEqual(firstFiltered, secondFiltered);
}

function filterColumns(columns: LeaderboardColumn[]): LeaderboardColumn[] {
  return columns
    .filter((column): column is LeaderboardColumn => column != null)
    .map(stripUndefinedProps);
}
