import React from 'react';
import { ProjectPrivacy } from '@neptune/shared/core-organizations-domain';
import {
  hasLessPrivacy,
  mayContributorsLoseAccess,
} from '@neptune/shared/core-project-business-logic';
import FormSubmissionLayout from 'components/form-controls/form-submission-layout/FormSubmissionLayout';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import { PrivacyChangeConfirmationModal } from 'components/projects/privacy-change-confirmation/PrivacyChangeConfirmationModal';
import { ProjectFormContainer } from 'components/projects/project-form/ProjectFormContainer';
import { UnsavedChangesPrompt } from 'components/unsaved-changes-prompt/UnsavedChangesPrompt';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { bemBlock, Button, Layout } from '@neptune/shared/venus-ui';
import { getPropertiesView } from 'state/selectors';
import { ProjectBeingEdited } from 'state/ui/modals/project/actions';
import {
  initProjectEditThunk,
  submitProjectPropertiesThunk,
  editProjectProperty,
} from 'state/views/project-view/properties/actions';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';
import { makeProjectIdentifier } from '@neptune/shared/core-project-util';

import './Properties.less';

const block = bemBlock('properties-view');

type PropertiesProps = {
  project: ProjectWithRole;
};

export const Properties = ({ project: currentProject }: PropertiesProps) => {
  const [isFormValid, setIsFormValid] = React.useState(true);
  const [confirmationOpened, setConfirmationOpened] = React.useState(false);
  const dispatch = useDispatch();

  const projectIdentifier = makeProjectIdentifier(
    currentProject.organizationName,
    currentProject.name,
  );

  const { initialProject, project, updateFetchStatus } = useSelector(getPropertiesView);

  const onValidationChange = (isFormValid: boolean) => {
    setIsFormValid(isFormValid);
  };

  const openConfirmation = () => {
    setConfirmationOpened(true);
  };

  const closeConfirmation = () => {
    setConfirmationOpened(false);
  };

  const onApply = () => {
    closeConfirmation();
    dispatch(submitProjectPropertiesThunk(projectIdentifier, project));
  };

  const initialPrivacy = initialProject?.visibility ?? ProjectPrivacy.private;
  const projectPrivacy = project?.visibility ?? ProjectPrivacy.private;
  const shouldBeConfirmed =
    hasLessPrivacy(initialPrivacy, projectPrivacy) ||
    mayContributorsLoseAccess(initialPrivacy, projectPrivacy);
  const handleSave = shouldBeConfirmed ? openConfirmation : onApply;
  const isFormChanged = !isEqual(project, initialProject);

  const onReset = () => {
    dispatch(editProjectProperty(initialProject));
  };

  const onUpdateProject = (project: ProjectBeingEdited) => {
    dispatch(editProjectProperty(project));
  };

  React.useEffect(() => {
    dispatch(initProjectEditThunk({ projectIdentifier }));
  }, [dispatch, projectIdentifier]);

  return (
    <Layout.Column className={block()} spacedChildren="md">
      <Layout.Element>
        <ProjectFormContainer
          mode="edit"
          project={project || {}}
          onProjectUpdate={onUpdateProject}
          onValidationChange={onValidationChange}
          initialProjectName={initialProject?.name}
        />
        <PrivacyChangeConfirmationModal
          target={project?.visibility}
          isOpen={confirmationOpened}
          onClose={closeConfirmation}
          onConfirm={onApply}
        />
      </Layout.Element>
      <FormSubmissionLayout
        primaryButton={
          <Button
            disabled={!isFormChanged || !isFormValid}
            onClick={handleSave}
            size="lg"
            data-role="confirm-button"
          >
            Save changes
          </Button>
        }
        secondaryButton={
          <Button disabled={!isFormChanged} onClick={onReset} variant="secondary" size="lg">
            Reset
          </Button>
        }
        submissionMessage={<LoadingIndicator fetchStatus={updateFetchStatus} size="small" />}
      />
      <Layout.Fill />
      {isFormChanged && <UnsavedChangesPrompt />}
    </Layout.Column>
  );
};
