import { LeaderboardColumn } from './column/column-model';
import { GroupOptions } from './group-options/group-options-model';
import { SortOptions } from './sort-options/sort-options-model';

export enum RunsLineage {
  FULL = 'FULL',
  NONE = 'NONE',
}

export type TableViewParams = {
  columnList: LeaderboardColumn[];
  groupOptions?: GroupOptions;
  sortOptions: SortOptions;
  experimentsOnly?: boolean;
  runsLineage?: RunsLineage;
  query?: string;
};
