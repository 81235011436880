import React from 'react';
import EditableInput from 'components/editable-input/EditableInput';
import { StringAttribute } from 'domain/experiment/attribute';

type EditStringAttributeProps = {
  className?: string;
  attribute: StringAttribute;
  placeholder?: string;
  name?: string;
  isEditable?: boolean;
  textArea?: boolean;
  onChange: (value: string) => void;
};

export const EditStringAttribute: React.FC<EditStringAttributeProps> = ({
  className,
  attribute,
  placeholder,
  name,
  isEditable,
  textArea = false,
  onChange,
}) => {
  const value = attribute.value;
  const inputName = name || attribute.attributeName;

  return (
    <EditableInput
      name={inputName}
      className={className}
      value={value}
      onChange={(params: any) => onChange(params[inputName])}
      textarea={textArea}
      placeholder={placeholder}
      isEditable={isEditable}
    />
  );
};
