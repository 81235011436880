export interface StepDescriptor<StepEnum extends keyof any, Data> {
  isFinal?: (currentFormData: Data) => boolean;
  isCancellable?: (currentFormData: Data) => boolean;
  validate?: (currentFormData: Data) => boolean;
  getNext?: (currentFormData: Data) => StepEnum | undefined;
  getPrev?: (currentFormData: Data) => StepEnum | undefined;
  onNext?: (currentFormData: Data) => Promise<void>;
  onPrev?: (currentFormData: Data) => Promise<void>;
  labelNext?: string;
  labelPrev?: string;
  labelCancel?: string;
}

export type SurveyDescriptor<StepEnum extends keyof any, Data> = Record<
  StepEnum,
  StepDescriptor<StepEnum, Data>
>;

export class SurveyFlow<StepEnum extends keyof any, Data> {
  constructor(
    private flow: SurveyDescriptor<StepEnum, Data>,
    private onSubmit: (data: Data) => Promise<void>,
  ) {}

  isPrevAvailable(current: StepEnum, data: Data) {
    return !!this.current(current).getPrev?.(data);
  }

  isNextAvailable(current: StepEnum, data: Data) {
    return !!this.current(current).getNext?.(data) || !!this.current(current).isFinal;
  }

  isCancellable(current: StepEnum, data: Data) {
    return !!this.current(current).isCancellable?.(data);
  }

  canGoPrev(current: StepEnum, data: Data) {
    return this.isPrevAvailable(current, data);
  }

  canGoNext(current: StepEnum, data: Data) {
    const validate = this.current(current).validate;
    return this.isNextAvailable(current, data) && (validate ? validate(data) : true);
  }

  async goPrev(current: StepEnum, data: Data): Promise<StepEnum> {
    const { getPrev, onPrev } = this.current(current);

    if (this.canGoPrev(current, data)) {
      await onPrev?.(data);

      return getPrev?.(data) || current;
    }

    return current;
  }

  async goNext(current: StepEnum, data: Data): Promise<StepEnum> {
    const { getNext, onNext } = this.current(current);

    if (this.shouldSubmit(current, data)) {
      await this.onSubmit(data);
    }

    if (this.canGoNext(current, data)) {
      await onNext?.(data);

      return getNext?.(data) || current;
    }

    return current;
  }

  getLabelPrev(current: StepEnum) {
    return this.current(current).labelPrev;
  }

  getLabelNext(current: StepEnum) {
    return this.current(current).labelNext;
  }

  getLabelCancel(current: StepEnum) {
    return this.current(current).labelCancel;
  }

  private shouldSubmit(current: StepEnum, data: Data) {
    const validate = this.current(current).validate;
    const isFinal = this.current(current).isFinal;
    return isFinal && isFinal(data) && (validate ? validate(data) : true);
  }

  private current(current: StepEnum) {
    return this.flow[current];
  }
}
