// Libs
import React from 'react';
import Autosuggest from 'react-autosuggest';
import { bemBlock, Icon } from '@neptune/shared/venus-ui';

// Module
import './AddWithAutocompletion.less';

const block = bemBlock('add-with-autocompletion');

// Explanation for unobvious code here.
//
// Requirements:
// Submit should trigger on:
// 1) user click suggestion
// 2) user scrolls suggestion list with arrows and presses 'Enter'
// 3) user ignores suggestions, types string and then presses 'Enter'
//
// 1 and 2 are handled by `onSuggestionSelected` callback of Autosuggest.
// 3 can be currently tackled by wrapping Autosuggest in a `form` and using onSubmit handler.
//
// BUT when user scrolls suggestion list and presses 'Enter'
// both `onSubmit` and `onSuggestionSelected` are fired.
// We could work around it by checking `method` in `onSuggestionSelected` to see if it was 'enter'.
//
// Long story short:
// external code sees 'onSubmit' which is product of regular `onSubmit` and `onSuggestionSelected`
// and abstracts weirdness.

export const AddWithAutocompletion: React.FC<{
  autocompletionValues: string[];
  className?: string;
  onSubmit: (value: string) => void;
  placeholder?: string;
  onChange: (query: string) => void;
  value: string;
}> = ({ autocompletionValues, value, onChange, className, onSubmit, placeholder = '' }) => {
  const handleOnSubmit = React.useCallback(
    (event: React.SyntheticEvent, value: string) => {
      event.preventDefault();
      onChange('');
      onSubmit(value);
    },
    [onChange, onSubmit],
  );

  const onSuggestionSelected = React.useCallback(
    (
      event: React.FormEvent<any>,
      { suggestionValue, method }: Autosuggest.SuggestionSelectedEventData<string>,
    ) => {
      // 'enter pressed' handled by onSubmit. Handle only 'suggestion clicked' here.
      if (method !== 'enter') {
        handleOnSubmit(event, suggestionValue);
      }
    },
    [handleOnSubmit],
  );

  const inputProps: Autosuggest.InputProps<string> = {
    className: block('input'),
    placeholder,
    value,
    onChange: (event, { newValue }) => onChange(newValue),
  };

  return (
    <div className={block({ extra: className })}>
      <form onSubmit={(event) => handleOnSubmit(event, value)}>
        <Autosuggest
          suggestions={autocompletionValues}
          onSuggestionsFetchRequested={({ value }) => onChange(value)}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={inputProps}
        />
      </form>
      <Icon
        glyph="plus"
        fixedWidth
        className={block('plus-icon')}
        data-role="add-action"
        onClick={(event: React.SyntheticEvent) => handleOnSubmit(event, value)}
      />
    </div>
  );
};

function getSuggestionValue(suggestion: string) {
  return suggestion;
}

function renderSuggestion(suggestion: string) {
  return <span>{suggestion}</span>;
}
