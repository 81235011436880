import React from 'react';
import { Dialog } from '@neptune/shared/venus-ui';
import { VisibilityChangeConfirmationModalProps } from './interface';

export const PrivatePrivacyChangeConfirmationModal: React.FC<
  VisibilityChangeConfirmationModalProps
> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Dialog
      isOpen={isOpen}
      title="Set project privacy to private"
      primaryButtonLabel="Change"
      primaryButtonRole="visibility-confirm"
      secondaryButtonLabel="Cancel"
      onPrimaryButtonClick={onConfirm}
      onSecondaryButtonClick={onClose}
      role="private-privacy-change-confirmation"
    >
      You are about to set this project privacy to private. This will make it unavailable to all the
      people that had previously access to it. You will have to add project contributors manually.
      <br />
      Do you want to continue?
    </Dialog>
  );
};
