// Libs
import React from 'react';
import { Icon, IconProps, bemBlock } from '@neptune/shared/venus-ui';

// App
import { ExperimentState, isStateRunning } from 'domain/experiment/experiment-state';

// Module
import './UnifiedExperimentStateIcon.less';
import { InfoTooltip } from '@neptune/shared/common-ui';
import { FailedIconTooltipText } from './FailedIconTooltipText';

type UnifiedExperimentStateIconProps = {
  className?: string;
  state?: ExperimentState;
  title?: string;
  failed?: boolean;
} & Pick<IconProps, 'size'>;

const block = bemBlock('unified-experiment-state-icon');

export const UnifiedExperimentStateIcon: React.FC<UnifiedExperimentStateIconProps> = ({
  className,
  size,
  failed = false,
  state,
  title,
}) => {
  if (!state) {
    return null;
  }

  const isRunning = isStateRunning(state);

  const icon = (
    <Icon
      fixedWidth
      glyph={isRunning ? 'circle-notch' : 'circle-alt'}
      spin={isRunning}
      className={block({ modifiers: state })}
      size={size}
      title={title}
      data-role="state-icon"
      data-activity={state}
      data-failed={failed}
    />
  );

  if (failed === true) {
    return (
      <InfoTooltip text={FailedIconTooltipText} placement="top-start">
        <span className={block({ element: 'wrapper', extra: className })}>
          {icon}
          <Icon
            className={block('failed-icon')}
            glyph="exclamation-circle"
            color="error"
            size="xs"
          />
        </span>
      </InfoTooltip>
    );
  }

  return icon;
};
