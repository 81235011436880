import React from 'react';

import { useGroupBy } from '@neptune/shared/leaderboard-business-logic';
import { GroupByModelConverter } from '@neptune/shared/leaderboard-domain';
import { useEntityLeaderboardPolling } from './use-entity-leaderboard-polling';

export function useEntityLeaderboardPollingWithGrouping({
  compareIds,
}: { compareIds?: string[] } = {}) {
  const { isEnabled: groupByMode, options: groupByOptions, openedGroups } = useGroupBy();

  const grouping = React.useMemo(() => {
    if (!groupByMode || !groupByOptions) {
      return undefined;
    }

    return {
      groupBy: GroupByModelConverter.fromGroupOptionsToDomain(groupByOptions),
      openedGroups,
      compareIds,
    };
  }, [groupByMode, groupByOptions, openedGroups, compareIds]);

  return useEntityLeaderboardPolling({
    grouping,
  });
}
