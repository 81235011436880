import { Entity, getEntityTrashed } from '@neptune/shared/entity-domain';
import { ProjectPermissions, WithPermissions } from '@neptune/shared/project-domain';

export function applyTrashedEntityPermission(
  entry: Entity & Partial<ProjectPermissions>,
): WithPermissions<Entity> {
  if (!entry) {
    return entry;
  }

  const isTrashed = getEntityTrashed(entry) || false;

  return {
    ...entry,
    canModify: isTrashed ? false : !!entry.canModify,
    canDelete: isTrashed ? false : !!entry.canDelete,
    canAbort: isTrashed ? false : !!entry.canAbort,
  };
}
