import React from 'react';
import { shuffle } from 'lodash';
import { Checkbox, Layout, Text, bemBlock } from '@neptune/shared/venus-ui';

import { KeycloakLabel } from 'components/keycloak';
import { Input } from '@neptune/shared/common-ui';

const typesOfData: string[] = [
  'Tabular (numeric and categorical)',
  'Text',
  'Images',
  'Audio',
  'Video',
  'Time series',
  'Graphs',
  'Prompts (LLMs)',
];
const shuffledTypesOfData: string[] = shuffle(typesOfData);

import './ProblemForm.less';

const block = bemBlock('problem-form');

type ProblemFormProps = {
  data: {
    typeOfData?: string[];
    otherTypeOfData?: boolean;
    otherTypeOfDataValue?: string;
  };
  onChange: (form: {
    typeOfData?: string[];
    otherTypeOfData?: boolean;
    otherTypeOfDataValue?: string;
  }) => void;
};

const xor = (a: boolean, b: boolean) => (a ? !b : b);

export const ProblemForm: React.FC<ProblemFormProps> = ({ onChange, data }) => {
  const { typeOfData, otherTypeOfData, otherTypeOfDataValue } = data;

  const toggleType = React.useCallback(
    (toggledTypeOfData: string) =>
      onChange({
        typeOfData: typesOfData
          .map((option) =>
            xor((typeOfData || []).includes(option), option === toggledTypeOfData) ? option : '',
          )
          .filter(Boolean),
      }),
    [typeOfData, onChange],
  );

  const otherOnChange = React.useCallback(
    () => onChange({ otherTypeOfData: !otherTypeOfData }),
    [onChange, otherTypeOfData],
  );

  const otherValueOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange && onChange({ otherTypeOfDataValue: event.target.value });

  return (
    <Layout.Column component="form" spacedChildren="md" data-role="problem-form">
      <Layout.Column span="auto">
        <KeycloakLabel>What type of data are you working with most often?</KeycloakLabel>
      </Layout.Column>
      <Layout.Column spacedChildren="sm" span="auto">
        {shuffledTypesOfData.map((option) => (
          <Checkbox
            key={option}
            checked={typeOfData?.some((name) => option === name)}
            data-role={option}
            value={option}
            children={<Text color="text">{option}</Text>}
            name="survey-types-of-data"
            onChange={() => toggleType(option)}
          />
        ))}

        <Checkbox
          className={block('other')}
          value="other"
          name="survey-types-of-data"
          checked={otherTypeOfData}
          onChange={otherOnChange}
        >
          <Text color="text">Other</Text>
          {otherTypeOfData && (
            <Input
              className={block('input')}
              value={otherTypeOfDataValue}
              onChange={otherValueOnChange}
              variant="small"
              placeholder="Please describe"
            />
          )}
        </Checkbox>
      </Layout.Column>
    </Layout.Column>
  );
};
