import { Entity, EntityModelConverter } from '@neptune/shared/entity-domain';
import {
  LeaderboardEntriesSearchResultDTO,
  LeaderboardEntryGroupDTO,
  LeaderboardFieldDTOAggregationEnum,
  LeaderboardFieldWithValueDTO,
} from '@neptune/shared/core-apis-leaderboard-domain';
import {
  AttributeDefinition,
  AttributeDefinitionConverter,
  AttributeType,
} from 'domain/experiment/attribute';
import { AggregationMode } from '../column/column-model';

export type Leaderboard = {
  entries: Entity[];
  groups?: LeaderboardEntryGroup[];
  matchingItemCount: number;
  suggestions?: AttributeDefinition[];
  totalGroupCount?: number;
};

type LeaderboardEntryGroup = {
  childrenIds: string[];
  id: string;
  itemCount: number;
  key: LeaderboardFieldWithValue[];
  beforeToken?: string;
  continuationToken?: string;
};
type LeaderboardFieldWithValue = {
  field: {
    aggregation: AggregationMode;
    name: string;
    type: AttributeType;
  };
  value?: object;
};

function convertAggregationModeFromApiToDomain(
  input: LeaderboardFieldDTOAggregationEnum,
): AggregationMode {
  switch (input) {
    case LeaderboardFieldDTOAggregationEnum.Last:
      return 'last';
    case LeaderboardFieldDTOAggregationEnum.Min:
      return 'min';
    case LeaderboardFieldDTOAggregationEnum.Max:
      return 'max';
    case LeaderboardFieldDTOAggregationEnum.Average:
      return 'average';
    case LeaderboardFieldDTOAggregationEnum.Variance:
      return 'variance';
    case LeaderboardFieldDTOAggregationEnum.Auto:
      return 'auto';
  }
}

function convertLeaderboardFieldWithValueFromApiToDomain(
  input: LeaderboardFieldWithValueDTO,
): LeaderboardFieldWithValue {
  return {
    field: {
      aggregation: convertAggregationModeFromApiToDomain(input.field.aggregation),
      name: input.field.name,
      type: AttributeDefinitionConverter.attributeTypeFromApiToDomain(input.field.type),
    },
    value: input.value,
  };
}

function convertLeaderboardEntryGroupFromApiToDomain(
  input: LeaderboardEntryGroupDTO,
): LeaderboardEntryGroup {
  return {
    childrenIds: [...input.childrenIds],
    id: input.id,
    itemCount: input.itemCount,
    key: input.key.map(convertLeaderboardFieldWithValueFromApiToDomain),
    continuationToken: input.continuationToken,
    beforeToken: input.beforeToken,
  };
}

export function leaderboardFromApiToDomain(
  input: LeaderboardEntriesSearchResultDTO,
  suggestionsRequestParam?: { enabled: boolean },
): Leaderboard {
  const suggestions = input.suggestions?.map(
    AttributeDefinitionConverter.attributeDefinitionFromApiToDomain,
  );
  const hasSomeSuggestions = !!suggestions?.length;
  const suggestionsRequested = !!suggestionsRequestParam?.enabled;

  return {
    entries: input.entries.map(EntityModelConverter.entityFromApiToDomain),
    groups: input.groups?.map(convertLeaderboardEntryGroupFromApiToDomain),
    matchingItemCount: input.matchingItemCount,
    totalGroupCount: input.totalGroupCount,
    suggestions: suggestionsRequested || hasSomeSuggestions ? suggestions : undefined, // we need to disambiguate no suggestions when requested from no suggestions when not requested
  };
}
