// App
import { IntegerCriterion, PartialSearchCriterion } from '../search-query-model';
import { criterionHasAttributeAndOperator } from '../search-query-model-core-utils';

// Module
import { CriterionHelpers } from './types';

export function isIntegerCriterion(
  criterion: PartialSearchCriterion,
): criterion is IntegerCriterion {
  return criterionHasAttributeAndOperator(criterion) && criterion.type === 'int';
}

export const integerCriterionHelpers: CriterionHelpers<IntegerCriterion> = {
  getReadableValue(criterion) {
    return String(criterion.value);
  },
  getShortReadableValue(criterion) {
    return String(criterion.value);
  },
  isComplete(criterion): boolean {
    return criterion.value != null;
  },
};
