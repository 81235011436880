export async function hashString(stringToHash: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(stringToHash);

  const hashBuffer = await crypto.subtle.digest('SHA-256', data);

  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const binaryString = hashArray.map((byte) => String.fromCharCode(byte)).join('');

  // Encode the binary string as a base64 string
  const base64String = btoa(binaryString);

  // Replace characters to make the base64 string URL-safe
  const base64urlString = base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

  // The `u` prefix is used to indicate that the string is URL-safe base64 encoded
  return `u${base64urlString}`;
}
