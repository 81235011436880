import {
  PartialSearchCriterion,
  PartialSearchCriterionWithAttributeAndType,
  PartialSearchCriterionWithOperator,
} from './search-query-model';

export function criterionHasAttribute(
  criterion: PartialSearchCriterion,
): criterion is PartialSearchCriterionWithAttributeAndType {
  return !!criterion.attribute && !!criterion.type;
}

export function criterionHasAttributeAndOperator(
  criterion: PartialSearchCriterion,
): criterion is PartialSearchCriterionWithOperator {
  return criterionHasAttribute(criterion) && !!criterion.operator;
}

export function getReadableArrayValue(value: string[] | number[]): string {
  return `(${value.join(', ')})`;
}
