const containsUUIDRegexp = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;


export function isTheSamePage(uriIdA, uriIdB) {
  const normalizedAId = getPageId(uriIdA);
  const normalizedBId = getPageId(uriIdB);

  return normalizedAId === normalizedBId;
}

export function getPageId(uriId = '') {
  const matched = uriId.match(containsUUIDRegexp);
  return matched && matched[0];
}

export function createPageIdUrlParam({ id = '', title = '' }) {
  if (title) {
    const escapedTitle = title.replace(/[^a-zA-Z0-9]+/g, '-');
    return `${escapedTitle}-${id}`;
  }

  return id;
}
