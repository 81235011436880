import { OrganizationWithRole } from '@neptune/shared/core-organizations-domain';

import {
  NeptuneInternalsActions,
  UPDATE_NEPTUNE_ROLE,
  SUPER_READER_ORGANIZATION_FETCH_SUCCESS,
} from './actions';

export type NeptuneInternalsState = {
  neptuneRole?: string;
  neptuneInstanceRole?: string;
  superReaderOrganization?: OrganizationWithRole;
};

const initialState: NeptuneInternalsState = {};

export function neptuneInternalsReducer(
  state = initialState,
  action: NeptuneInternalsActions,
): NeptuneInternalsState {
  switch (action.type) {
    case UPDATE_NEPTUNE_ROLE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case SUPER_READER_ORGANIZATION_FETCH_SUCCESS: {
      const { superReaderOrganization } = action.payload;
      return {
        ...state,
        superReaderOrganization,
      };
    }

    default: {
      return state;
    }
  }
}
