import React from 'react';

import { useAsyncValue } from '@neptune/shared/common-util';
import { fetchProjectServiceAccounts } from '@neptune/service-accounts-domain';

export const useProjectServiceAccounts = (projectIdentifier: string | undefined) => {
  const resolver = React.useCallback(async () => {
    if (!projectIdentifier) {
      return undefined;
    }

    return await fetchProjectServiceAccounts(projectIdentifier);
  }, [projectIdentifier]);

  return useAsyncValue({
    resolver,
  });
};
