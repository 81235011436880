import { AnyAction } from 'redux';
import { AvatarSource } from '@neptune/shared/core-users-domain';
import { namedColors, EMOJIS_LIST } from '@neptune/shared/venus-domain';
import { NewProject, ProjectWithRole } from '@neptune/shared/core-project-domain';
import {
  createProject,
  updateProjectActions as persistProjectActions,
} from '../../../projects/actions';
import { showGenericModal } from 'state/ui/modals/generic/actions';
import { Organization, ProjectPrivacy } from '@neptune/shared/core-organizations-domain';
import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';
import { isOnPremiseDeployment } from 'common/deploymentModes';
import { makeProjectIdentifier } from 'common/project';
import { getCurrentOrganization, getProjectsAvatars } from 'state/selectors';
import { calculateProjectPrivacyAvailability } from '@neptune/shared/core-project-business-logic';
import { IntercomTour } from '@neptune/onboarding-business-logic';

import type { AppState, NThunkDispatch } from 'state/types';
import { getCurrentUsername } from 'state/users/current-user/selectors';
import { DeleteProjectModalData } from 'components/projects/delete-project-modal/DeleteProjectModal';

export type ProjectBeingEdited = {
  id?: string;
  name?: string;
  description?: string;
  projectKey?: string;
  organizationId?: string;
  displayClass?: string;
  avatarUrl?: string;
  avatarSource?: AvatarSource;
  visibility?: ProjectPrivacy;
  version?: number;
  timeOfCreation?: Date;
};

export enum ProjectActionTypes {
  cancel = 'UI_PROJECT_CANCEL_EDIT',
  startEdit = 'UI_PROJECT_START_EDIT',
  update = 'UI_PROJECT_UPDATE',
  requestDelete = 'UI_PROJECT_REQUEST_DELETE',
}

export function closeEditProjectModal() {
  return { type: ProjectActionTypes.cancel } as const;
}

export function startEditProject(params?: ProjectWithRole) {
  return (dispatch: NThunkDispatch<AnyAction>, getState: () => AppState) => {
    const state = getState();
    const organization = getCurrentOrganization(state) ?? {};

    const availablePrivacyOptions = calculateProjectPrivacyAvailability(organization.traits || {});
    const firstAvailablePrivacy = availablePrivacyOptions.find(
      ({ available }) => available,
    )?.privacy;
    const defaultVisibility = firstAvailablePrivacy || ProjectPrivacy.private;

    const {
      id,
      name,
      description = '',
      projectKey,
      organizationId,
      timeOfCreation = new Date(),
      visibility = defaultVisibility,
      avatarSource = AvatarSource.unicode,
      version = 2,
    } = params || {};

    let { avatarUrl, displayClass } = params || {};

    if (!avatarUrl && avatarSource === AvatarSource.unicode) {
      const takenAvatars = getProjectsAvatars(state);
      let availableEmojis = EMOJIS_LIST.filter((code) => !takenAvatars.has(code));

      if (!availableEmojis.length) {
        availableEmojis = [...EMOJIS_LIST];
      }

      avatarUrl = availableEmojis[Math.floor(Math.random() * availableEmojis.length)];
    }

    if (!displayClass) {
      displayClass = namedColors[Math.floor(Math.random() * namedColors.length)];
    }

    const project: ProjectBeingEdited = {
      id,
      name,
      displayClass,
      description,
      projectKey,
      visibility,
      version,
      avatarUrl,
      avatarSource,
      timeOfCreation,
    };

    if (organizationId) {
      project.organizationId = organizationId;
    }

    dispatch(startEditProjectRaw(project));
  };
}

export function updateProject(project: ProjectBeingEdited) {
  return { type: ProjectActionTypes.update, payload: { project } } as const;
}

function startEditProjectRaw(project: ProjectBeingEdited | {}) {
  return { type: ProjectActionTypes.startEdit, payload: { project } } as const;
}

function requestDeleteProjectRaw(project: ProjectWithRole, runningExperimentsCount: number) {
  return {
    type: ProjectActionTypes.requestDelete,
    payload: { project, runningExperimentsCount },
  } as const;
}

const isNewProject = (project: ProjectBeingEdited): project is NewProject => {
  const { name, avatarUrl, avatarSource, organizationId } = project;

  return !!(name && avatarUrl && avatarSource && organizationId);
};

export function saveProject(project: ProjectBeingEdited, organizationId: string) {
  return (dispatch: NThunkDispatch<AnyAction>, getState: () => AppState) => {
    const organization: Organization | undefined = getCurrentOrganization(getState());
    const username = getCurrentUsername(getState());

    if (project.id != null) {
      const { id, ...projectUpdate } = project;

      dispatch(persistProjectActions.execute({ projectIdentifier: id, projectUpdate })).then(() =>
        dispatch(closeEditProjectModal()),
      );

      return;
    }

    const projectWithOrganizationId = { ...project, organizationId };

    if (isNewProject(projectWithOrganizationId)) {
      dispatch(createProject(projectWithOrganizationId))
        .then(() => dispatch(closeEditProjectModal()))
        .then(() => {
          if (organization?.organizationType !== 'individual' || isOnPremiseDeployment()) {
            return;
          }

          return IntercomTour.updateFirstCreatedProject(username);
        });
    }
  };
}

export function requestDeleteProject(project: ProjectWithRole, modalData?: DeleteProjectModalData) {
  return (dispatch: NThunkDispatch<AnyAction>) => {
    leaderboardClient
      .searchLeaderboardEntries({
        projectIdentifier: makeProjectIdentifier(project.organizationName, project.name),
        params: {
          pagination: { limit: 0 },
          query: {
            query: 'sys/state:experimentState = running',
          },
        },
        type: ['run'],
      })
      .then(({ matchingItemCount }) => matchingItemCount)
      .catch(() => 0)
      .then((runningExperimentsCount) => {
        dispatch(showGenericModal<DeleteProjectModalData>('deleteProject', modalData));
        dispatch(requestDeleteProjectRaw(project, runningExperimentsCount));
      });
  };
}

export type ProjectActions = ReturnType<
  | typeof closeEditProjectModal
  | typeof updateProject
  | typeof startEditProjectRaw
  | typeof requestDeleteProjectRaw
>;
