// Libs
import React from 'react';
import { bemBlock } from '@neptune/shared/venus-ui';

// Module
import './ResponsiveGridDashboard.less';

export type ResponsiveGridDashboardLayout = 'mosaic' | 'row';
export type ResponsiveGridDashboardRow = 'md' | 'lg';

export type ResponsiveGridDashboardProps = {
  layout: ResponsiveGridDashboardLayout;
  rowSize?: ResponsiveGridDashboardRow;
};

const block = bemBlock('responsive-grid-dashboard');

export const ResponsiveGridDashboard: React.FC<ResponsiveGridDashboardProps> = React.memo(
  function ResponsiveGridDashboard({ children, layout, rowSize = 'md' }) {
    return (
      <div
        className={block({ modifiers: { layout, row: rowSize } })}
        data-role="responsive-grid-dashboard"
        data-layout={layout}
      >
        {children}
      </div>
    );
  },
);
