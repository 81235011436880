import { WidgetDefinition } from '../core/domain/widget-definition';
import { Widget } from '../core/widget';

export type SingleValueWidget = Widget & {
  type: 'singleValue';
  options: SingleValueWidgetOptions;
};
export type SingleValueWidgetOptions = {
  alignment: SingleValueTextAlignment;
  color: string;
  fractionalDigits: number;
  size: SingleValueTextSize;
  wrapping: SingleValueTextWrapping;
};
export type SingleValueTextAlignment = 'center' | 'top-left';
export type SingleValueTextSize = 'regular' | 'medium' | 'large';
export type SingleValueTextWrapping = 'wrap' | 'truncate';

export function isSingleValueWidget(widget: Widget): widget is SingleValueWidget {
  return widget.type === 'singleValue';
}

export function singleValueWidgetOptionsFromApiToDomain(options?: Record<string, any>) {
  return {
    alignment: options?.alignment,
    color: options?.color,
    fractionalDigits: options?.fractionalDigits,
    size: options?.size,
    wrapping: options?.wrapping,
  };
}

export const singleValueWidgetName = 'Single value';

export const singleValueWidgetDefinition: WidgetDefinition = {
  type: 'singleValue',
  name: singleValueWidgetName,
  fromApiToDomain: singleValueWidgetOptionsFromApiToDomain,
};
