import { BaseColumn, ColumnIdentifier, FloatColumn, FloatSeriesColumn } from './column-model';
import { getReadableAttributeName } from 'domain/experiment/attribute';

export function isFloatColumn(col: BaseColumn): col is FloatColumn {
  return col.columnType === 'float';
}

export function isFloatSeriesColumn(col: BaseColumn): col is FloatSeriesColumn {
  return isFloatSeriesColumnIdentifier(col);
}

export function isFloatSeriesColumnIdentifier(col: ColumnIdentifier): boolean {
  return col.columnType === 'floatSeries';
}

export function columnIdentityComparator(col1: ColumnIdentifier, col2: ColumnIdentifier): boolean {
  return (
    col1.id === col2.id &&
    col1.columnType === col2.columnType &&
    col1.aggregationMode === col2.aggregationMode
  );
}

export function serializeColumnIdentifier(colId: ColumnIdentifier): string {
  return `${colId.id}-${colId.columnType}-${colId.aggregationMode}`;
}

export function getColumnName(
  col: Pick<BaseColumn, 'id' | 'attributeName' | 'displayName'>,
): string {
  return col.displayName || getReadableColumnName(col.attributeName || col.id);
}

export function getColumnNameWithAggregation(col: BaseColumn): string {
  const baseName = getColumnName(col);
  const aggregationName = col.aggregationMode ? ` (${col.aggregationMode})` : '';
  return `${baseName}${aggregationName}`;
}

export function getReadableColumnName(columnName: string): string {
  return getReadableAttributeName(columnName);
}

export function getReadableColumnIdentifier({ id, aggregationMode }: ColumnIdentifier): string {
  let identifier = id;

  if (aggregationMode && aggregationMode !== 'auto') {
    identifier = `${identifier} | ${aggregationMode.toUpperCase()}`;
  }

  return identifier;
}

export function computePinnedColumnsWidth(columns: Pick<BaseColumn, 'pinned' | 'width'>[]) {
  return columns.reduce((result: number, current) => {
    if (current.pinned) {
      return result + current.width;
    }

    return result;
  }, 0);
}
