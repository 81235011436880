// Libs
import React from 'react';

// App
import { backendClient, UserProfileDTO } from '@neptune/shared/core-apis-backend-domain';

// Module
export const useUserProfile = () => {
  const [profile, setProfile] = React.useState<UserProfileDTO | undefined>();

  React.useEffect(() => {
    async function fetch() {
      const response = await backendClient.getUserProfile();
      setProfile(response);
    }

    fetch();
  }, []);

  return profile;
};
