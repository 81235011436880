/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActiveProjectsTraitDTO
 */
export interface ActiveProjectsTraitDTO {
    /**
     * 
     * @type {number}
     * @memberof ActiveProjectsTraitDTO
     */
    currentQuota: number;
    /**
     * 
     * @type {number}
     * @memberof ActiveProjectsTraitDTO
     */
    maxQuota?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ActiveProjectsTraitDTO
     */
    quotaEditable: boolean;
}

export function ActiveProjectsTraitDTOFromJSON(json: any): ActiveProjectsTraitDTO {
    return ActiveProjectsTraitDTOFromJSONTyped(json, false);
}

export function ActiveProjectsTraitDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveProjectsTraitDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentQuota': json['currentQuota'],
        'maxQuota': !exists(json, 'maxQuota') ? undefined : json['maxQuota'],
        'quotaEditable': json['quotaEditable'],
    };
}

export function ActiveProjectsTraitDTOToJSON(value?: ActiveProjectsTraitDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentQuota': value.currentQuota,
        'maxQuota': value.maxQuota,
        'quotaEditable': value.quotaEditable,
    };
}


