import { neptuneUrls } from '@neptune/shared/common-domain';
import type { Integration } from './types';

export const r: Integration = {
  id: 'R',
  title: 'Any framework (R)',
  scriptName: 'train.R',
  link: neptuneUrls.integrationRDoc,
  script: `install.packages("reticulate")
library(reticulate)
install_miniconda()

install.packages("neptune")
library(neptune)
neptune_install()

run <- neptune_init(project = "<%= projectIdentifier %>",
                    api_token = "<%= apiToken %>")

parameters <- list("learning_rate" = 0.001,
                   "optimizer" = "Adam")
run["parameters"] <- parameters

for(epoch in 1:10){
  neptune_log(run["train/loss"], 0.9 ** epoch)
  # run["train/loss"]$log(0.9 ** epoch) # You can also use reticulate based syntax
}

run["eval/f1_score"] <- 0.66

neptune_stop(run)`,
};
