import React from 'react';
import { Dialog, Layout, Text } from '@neptune/shared/venus-ui';

import { ServiceAccount } from '@neptune/service-accounts-domain';
import { ProjectRole, projectRoleLabels } from '@neptune/shared/core-project-domain';
import { withIndefiniteArticle } from 'common/tools';

type ChangeServiceAccountRoleModalProps = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(): void;
  role: ProjectRole;
  serviceAccount: ServiceAccount;
};

export const ChangeServiceAccountRoleModal: React.FC<ChangeServiceAccountRoleModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  role,
  serviceAccount,
}) => {
  const roleName = withIndefiniteArticle(projectRoleLabels[role]);

  return (
    <Dialog
      isOpen={isOpen}
      onPrimaryButtonClick={onSubmit}
      onSecondaryButtonClick={onClose}
      primaryButtonLabel="Change role"
      primaryButtonRole="change-service-account-role-submit"
      role="change-service-account-role-modal"
      title="Change role in project"
    >
      <Layout.Column spacedChildren="md">
        <Text>
          You are about to give <Text fontWeight="semibold">{serviceAccount.displayName}</Text>{' '}
          {roleName} role. Are you sure?
        </Text>
      </Layout.Column>
    </Dialog>
  );
};
