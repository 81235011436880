// Libs
import React from 'react';
import { isFunction } from 'lodash';
import { Dropdown, DropdownProps, Menu, bemBlock } from '@neptune/shared/venus-ui';
import { useFitDropdown } from '@neptune/shared/common-ui';

// Module
import { FilterSearchLayoutProps } from './interfaces';
import { FilterSearchDropdownToggle } from './FilterSearchDropdownToggle';
import './FilterSearchWithDropdownLayout.less';

const block = bemBlock('filter-search-with-dropdown');

const MENU_HEIGHT = 500;

export type FilterSearchWithDropdownLayoutProps = Omit<FilterSearchLayoutProps, 'query'> & {
  autoFocus?: boolean;
  className?: string;
  collapseOnSelect?: boolean;
  dropdownDataRole?: string;
  inputClassName?: string;
  onBlur?: (event: React.FocusEvent) => void;
  onExpand?: () => void;
  onFocus?: (event: React.FocusEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
} & Pick<DropdownProps, 'autoWidthMenu' | 'onOutsideClick' | 'children'>;

export const FilterSearchWithDropdownLayout: React.FC<FilterSearchWithDropdownLayoutProps> = ({
  autoFocus,
  autoWidthMenu = 'width',
  className,
  children,
  collapseOnSelect = false,
  dropdownDataRole = 'filter-search-dropdown-menu',
  input,
  inputClassName,
  message,
  onBlur,
  onExpand,
  onFocus,
  onKeyDown,
  onOutsideClick,
  searchResults,
}) => {
  const { targetRef, height: menuHeight } = useFitDropdown({
    defaultHeight: MENU_HEIGHT,
    minHeight: 200,
    side: 'bottom',
  });

  return (
    <Dropdown
      className={block({ extra: className })}
      autoWidthMenu={autoWidthMenu}
      attachment="top left"
      targetAttachment="bottom left"
      toggle={
        <FilterSearchDropdownToggle
          autoFocus={autoFocus}
          className={inputClassName}
          elementRef={targetRef}
          input={input || <input />}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      }
      constraints={[
        {
          to: 'window',
          attachment: 'together',
          pin: true,
        },
      ]}
      onOutsideClick={onOutsideClick}
      onExpand={onExpand}
    >
      {(props) => {
        const searchResultsWithCollapseOnSelect =
          searchResults &&
          React.cloneElement(searchResults, {
            onSelect: (event: any /* T */) => {
              props.collapse();
              searchResults.props?.onSelect(event);
            },
          });

        const searchToDisplay = collapseOnSelect
          ? searchResultsWithCollapseOnSelect
          : searchResults;

        return (
          <Dropdown.Menu
            style={{ maxHeight: menuHeight }}
            overflow="hidden"
            data-role={dropdownDataRole}
            onKeyDown={onKeyDown}
          >
            <Menu.Title className={block('menu-title')}>{message}</Menu.Title>
            {searchToDisplay}
            {isFunction(children) ? children(props) : children}
          </Dropdown.Menu>
        );
      }}
    </Dropdown>
  );
};
