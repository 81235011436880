import React from 'react';
import { getTextVariantByValue } from '@neptune/shared/common-util';
import { Layout, Text } from '@neptune/shared/venus-ui';

type CompareToolbarInfoProps = {
  compareCount: number;
};

export const CompareToolbarInfo: React.FC<CompareToolbarInfoProps> = ({ compareCount }) => {
  return (
    <Layout.Row spacedChildren="sm" alignItems="center" span="shrink">
      {compareCount > 0 && <Text data-role="compare-ids-count">{compareCount}</Text>}
      &nbsp;
      {getTextVariantByValue(compareCount, 'runs', 'run', 'Compare runs')}
    </Layout.Row>
  );
};
