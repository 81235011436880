import { ComplexSearchFilter, isRegexFilter } from './compound-search-filters';

export const isEmptyFilter = (filter?: ComplexSearchFilter) => {
  if (!filter) {
    return true;
  }

  if (isRegexFilter(filter)) {
    return filter.value.length <= 0 || filter.value[0].value.length <= 0;
  }

  return filter.value.length <= 0;
};
