// App
import { createSelector } from 'reselect';
import { fetchStatus as FetchStatus } from 'state/fetch-status';
import { AppState } from 'state/types';
import {
  UsageFlattenedPricing,
  UsagePricing,
  UserBasedSubscriptionPricing,
} from '@neptune/pricing-commons-domain';
import { flattenUsagePricing } from '../pricing';
// Module
import { OrganizationPricingState } from './organization-pricing-reducer';

function getOrganizationPricingState(state: AppState): OrganizationPricingState {
  return (state as any).pricing.organizationPricing;
}

export function getOrganizationPricingFetchStatus(state: AppState): FetchStatus {
  return getOrganizationPricingState(state).fetchStatus;
}

export function getSupportsPayments(state: AppState): boolean | undefined {
  return getOrganizationPricingState(state).pricing?.supportsPayments;
}

export function getWorkspaceStoragePricing(state: AppState): UsagePricing | undefined {
  return getOrganizationPricingState(state).pricing?.storage;
}

function getWorkspaceActiveProjectsPricing(state: AppState): UsagePricing | undefined {
  return getOrganizationPricingState(state).pricing?.activeProjects;
}

export function getWorkspaceUsersPricing(
  state: AppState,
): UserBasedSubscriptionPricing | undefined {
  return getOrganizationPricingState(state).pricing?.users;
}

export function getWorkspaceMonitoringPricing(state: AppState): UsagePricing | undefined {
  return getOrganizationPricingState(state).pricing?.monitoringTime;
}

export function getWorkspacePricingFlatFee(state: AppState): number | undefined {
  const flatPriceInCents = getOrganizationPricingState(state).pricing?.flatFeeInCents;
  return flatPriceInCents === undefined ? flatPriceInCents : flatPriceInCents / 100;
}

type PricingDetails = { pricing?: UsageFlattenedPricing; fetchStatus: FetchStatus };
export const getStoragePricingDetails = createSelector(
  getWorkspaceStoragePricing,
  getOrganizationPricingFetchStatus,
  (pricing, fetchStatus): PricingDetails => ({
    pricing: pricing ? flattenUsagePricing(pricing) : undefined,
    fetchStatus: fetchStatus || FetchStatus.NONE,
  }),
);

export const getActiveProjectsPricingDetails = createSelector(
  getWorkspaceActiveProjectsPricing,
  getOrganizationPricingFetchStatus,
  (pricing, fetchStatus): PricingDetails => ({
    pricing: pricing ? flattenUsagePricing(pricing) : undefined,
    fetchStatus: fetchStatus || FetchStatus.NONE,
  }),
);

export const getTotalPricingDetails = createSelector(
  getOrganizationPricingFetchStatus,
  getStoragePricingDetails,
  getActiveProjectsPricingDetails,
  (
    fetchStatus: FetchStatus,
    ...pricingDetails: PricingDetails[]
  ): { price: number; fetchStatus: FetchStatus } => {
    const totalPrice = pricingDetails.reduce((acc, { pricing }) => {
      if (!pricing) {
        return acc;
      }

      return acc + pricing.totalPriceForCurrentUnits;
    }, 0);

    return {
      price: totalPrice,
      fetchStatus,
    };
  },
);
