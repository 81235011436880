import React from 'react';
import { Dialog, Layout, Text } from '@neptune/shared/venus-ui';

import { ServiceAccount } from '@neptune/service-accounts-domain';

type DeactivateServiceAccountModalProps = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(): void;
  serviceAccount: ServiceAccount;
};

export const DeactivateServiceAccountModal: React.FC<DeactivateServiceAccountModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  serviceAccount,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onPrimaryButtonClick={onSubmit}
      onSecondaryButtonClick={onClose}
      primaryButtonLabel="Deactivate account"
      primaryButtonRole="deactivate-service-account-submit"
      role="deactivate-service-account-modal"
      title="Deactivate service account"
    >
      <Layout.Column spacedChildren="md">
        <Text>
          You are about to deactivate{' '}
          <Text fontWeight="semibold">{serviceAccount.displayName}</Text> service account. All
          scripts and services using this service account will stop working. No data created or
          modified by this account will be deleted.
        </Text>
        <Text>Are you sure?</Text>
      </Layout.Column>
    </Dialog>
  );
};
