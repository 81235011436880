import { IntercomTour } from '@neptune/onboarding-business-logic';
import { useVisibilityChange } from '@neptune/shared/common-ui';
import { OrganizationType, OrganizationWithRole } from '@neptune/shared/core-organizations-domain';
import { isOnPremiseDeployment } from 'common/deploymentModes';
import { makeProjectIdentifier } from 'common/project';
import React from 'react';
import { useSelector } from 'react-redux';
import { getOrganizationsList } from 'state/selectors';
import { getOrganizationNameFromRouteParams } from 'state/selectors-global';
import { getCurrentUserCreated, getCurrentUsername } from 'state/users/current-user/selectors';

const enrollmentDateOfPromptToCreateNewProjectTour = new Date('2022-10-24');
const exampleProjectName = 'example-project-tensorflow-keras';
const enoughTimeSinceBasicTourInMs = 5 * 60 * 1000;

export const PromptToCreateNewProjectTourTrigger: React.FC = () => {
  const onPrem = isOnPremiseDeployment();
  const username = useSelector(getCurrentUsername);
  const userCreated = useSelector(getCurrentUserCreated);
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const organizationsList = useSelector(getOrganizationsList);
  const lastVisitedProjectIdentifier: string | undefined = IntercomTour.getLastVisitedProject();
  const [started, setStarted] = React.useState(false);
  const [hidden, setHidden] = React.useState<boolean>();
  const [elapsedEnoughTimeSinceBasicTour, setElapsedEnoughTimeSinceBasicTour] =
    React.useState<boolean>();
  useVisibilityChange(setHidden);

  const userBelongsToATeamWorkspace = organizationsList.some(
    (organization: OrganizationWithRole) => organization.organizationType === OrganizationType.team,
  );
  const userSignedUpAfterEnrollment = userCreated
    ? userCreated > enrollmentDateOfPromptToCreateNewProjectTour
    : false;

  const shouldSkipTrigger =
    onPrem || started || userBelongsToATeamWorkspace || !userSignedUpAfterEnrollment || !username;

  React.useEffect(() => {
    // flag to prevent memory leak
    let running = true;
    checkTime();

    return () => {
      running = false;
    };

    async function checkTime() {
      if (!running || shouldSkipTrigger) {
        return;
      }

      const basicUiTourTime = await IntercomTour.getLastTriggerTime(
        username,
        'onboarding/day_1/basic_ui_tour',
      );

      if (!basicUiTourTime) {
        // very first users haven't seen basic_ui_tour yet, so we need to recheck in some future
        setTimeout(checkTime, enoughTimeSinceBasicTourInMs * 0.45);
        return;
      }

      const timeSinceTourTime = basicUiTourTime ? Date.now() - +basicUiTourTime : 0;
      // either future and past can be handled in the same way (in case of past the action will take place in a next tick)
      setTimeout(
        () => running && setElapsedEnoughTimeSinceBasicTour(true),
        enoughTimeSinceBasicTourInMs - timeSinceTourTime,
      );
    }
  }, [shouldSkipTrigger, username]);

  React.useEffect(() => {
    if (shouldSkipTrigger || hidden) {
      return;
    }

    if (
      lastVisitedProjectIdentifier ===
        makeProjectIdentifier(organizationName, exampleProjectName) ||
      elapsedEnoughTimeSinceBasicTour
    ) {
      trigger();
    }

    async function trigger() {
      setStarted(true);

      const firstCreatedProjectTriggerTime =
        await IntercomTour.getFirstCreatedProjectTriggerTime(username);

      if (firstCreatedProjectTriggerTime) {
        return;
      }

      await IntercomTour.triggerTour(username, 'onboarding/day_1/prompt-to-create-new-project');
    }
  }, [
    elapsedEnoughTimeSinceBasicTour,
    hidden,
    lastVisitedProjectIdentifier,
    organizationName,
    shouldSkipTrigger,
    username,
  ]);

  return null;
};
