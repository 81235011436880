import { sortBy } from 'lodash';
import { FilterResultItem } from '@neptune/shared/search-domain';
import { endOfMatchMark, startOfMatchMark } from '@neptune/shared/common-domain';

/**
 * Filters and sorts collection according to substring search score.
 * @param query
 * @param collection
 * @param searchBy have to return string against which is search performed.
 */
export function substringSearchFilter<T>(
  query: string,
  collection: T[],
  searchBy?: (value: T) => string,
): FilterResultItem<T>[] {
  if (query.length < 1) {
    return collection.map(wrapIntoFilterResultLike);
  }

  const includingFullMatch: FilterResultItem<T>[] = collection
    .filter((value: T) => extract(value).includes(query))
    .map(wrapIntoFilterResultLike);

  return sortBy(includingFullMatch, [
    (item) => (extract(item.original).startsWith(query) ? -1 : 1),
    (item) => extract(item.original),
  ]);

  function extract(value: T): string {
    return searchBy ? searchBy(value) : `${value}`;
  }

  function addMarks(serializedItem: string): string {
    return serializedItem.replace(query, `${startOfMatchMark}${query}${endOfMatchMark}`);
  }

  function wrapIntoFilterResultLike(item: T) {
    const serializedItem = extract(item);

    return {
      original: item,
      string: query.length > 0 ? addMarks(serializedItem) : serializedItem,
    };
  }
}
