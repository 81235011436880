import { scissorsClient } from '@neptune/shared/core-apis-scissors-domain';
import { hashString } from './hash-string';

function persistStringImplOptimistic(content: string) {
  // The following call is intended to run in the background
  /** DO NOT await */ scissorsClient.snip({
    request: {
      content,
    },
  });

  return hashString(content);
}

export async function persistObjectOptimistic(
  objectToStore: Record<string, unknown> | unknown[],
): Promise<string> {
  const content = JSON.stringify(objectToStore);
  return persistStringImplOptimistic(content);
}

async function persistStringImpl(content: string) {
  await scissorsClient.snip({
    request: {
      content,
    },
  });

  return hashString(content);
}

export async function persistObject(
  objectToStore: Record<string, unknown> | unknown[],
): Promise<string> {
  const content = JSON.stringify(objectToStore);
  return persistStringImpl(content);
}

export async function loadObject<T>(
  key: string,
  typeGuard: (value: unknown) => value is T,
): Promise<T | undefined> {
  try {
    const { content } = await scissorsClient.expand({ key });
    const parsed = JSON.parse(content);
    return typeGuard(parsed) ? parsed : undefined;
  } catch (e) {}
}
