import { backendClient } from '@neptune/shared/core-apis-backend-domain';

/**
 * @deprecated use @neptune/shared/core-project-util instead
 */
export function makeProjectIdentifier(organizationName: string, projectName: string) {
  return `${organizationName}/${projectName}`;
}

export type GetProjectsForOrganizationParams = {
  username?: string;
  organizationIdentifier: string;
  isTeam: boolean;
  searchTerm?: string;
  sortBy?: string[];
  sortDirection?: string[];
  offset?: number;
  limit?: number;
};

/** @deprecated use getProjectsForOrganization from @neptune/projects-domain */
export function getProjectsForOrganization(params: GetProjectsForOrganizationParams) {
  const { username, isTeam, organizationIdentifier, ...restParams } = params;

  if (isTeam || !username) {
    return backendClient.listOrganizationProjects({
      organizationIdentifier,
      ...restParams,
    });
  }

  return backendClient.listUserProjects({
    projectsForUserMode: 'usersOrganizationIndividual',
    viewedUsername: username,
    ...restParams,
  });
}
