import React from 'react';

import { bemBlock, Toolbar } from '@neptune/shared/venus-ui';

import './CommonTabToolbar.less';

const block = bemBlock('common-toolbar');

type CommonTabDetailsToolbarProps = {
  children: React.ReactNode;
  'data-role': string;
};

export const CommonTabToolbar = ({
  'data-role': dataRole,
  children,
}: CommonTabDetailsToolbarProps) => {
  return (
    <>
      <Toolbar
        className={block()}
        data-role={dataRole}
        spacedChildren="sm"
        withPadding="md"
        size="md"
        span="auto"
        alignItems="center"
        withCustomScrollbar
        overflow={{
          horizontal: 'auto',
          vertical: 'hidden',
        }}
      >
        {children}
      </Toolbar>
      <Toolbar.Separator />
    </>
  );
};
