import { Params } from 'router5/types/types/base';
import { SortOptions, GroupOptions } from '@neptune/shared/leaderboard-domain';
import { LeaderboardView } from './leaderboard-view-model';

export function makeLbViewRouteState(
  newParams: Record<string, unknown>,
  routeParams: Params,
  activeView?: Partial<LeaderboardView>,
) {
  if (!routeParams.lbViewUnpacked) {
    /*
     * quick filters updated for the first time - unpack current lb view to url.
     */
    const { query, sortOptions, groupOptions, experimentsOnly, runsLineage } = activeView ?? {};

    let suggestionsEnabled = activeView?.suggestionsEnabled;

    if ('suggestionsEnabled' in routeParams) {
      suggestionsEnabled = routeParams.suggestionsEnabled;
    }

    const emptySortAndGroupOptions: {
      [key in keyof SortOptions | keyof GroupOptions]-?: undefined;
    } = {
      sortBy: undefined,
      sortFieldType: undefined,
      sortFieldAggregationMode: undefined,
      sortDirection: undefined,
      groupBy: undefined,
      groupByFieldType: undefined,
      groupByFieldAggregationMode: undefined,
      openedGroups: undefined,
    };

    return {
      ...routeParams,
      query,
      ...emptySortAndGroupOptions, // Make sure all sort and group options are overwritten.
      ...sortOptions,
      ...groupOptions,
      experimentsOnly,
      runsLineage,
      suggestionsEnabled,
      ...newParams,
      lbViewUnpacked: true,
    };
  }

  return {
    ...routeParams,
    ...newParams,
  };
}
