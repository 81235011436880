// Libs
import { actions as routerActions } from 'redux-router5';
import { NavigationOptions } from 'router5';

// App
import { OrganizationWithRole, OrganizationType } from '@neptune/shared/core-organizations-domain';
import { getCommunityOrganization } from 'state/selectors';
import { AppState, NThunkDispatch } from 'state/types';
import { getContextOrganization, getCurrentRouteParams } from 'state/selectors-global';

// Module
import { convertRouteName } from './helpers';
import { isEnterpriseContext } from './selectors';
import { isOnPremiseDeployment } from 'common/deploymentModes';

/**
 * @deprecated use @neptune/shared/routing-business-logic instead.
 */
export function navigateTo(
  routeName: string,
  routeParams?: { [key: string]: any },
  options: NavigationOptions & {
    forceContext?: /* ORGANIZATION_TYPES */ string;
    extendParams?: boolean;
  } = {},
) {
  return (
    dispatch: NThunkDispatch<ReturnType<typeof routerActions.navigateTo>>,
    getState: () => AppState,
  ) => {
    const { forceContext, extendParams, ...routeOptions } = options;

    const isEnterprise = forceContext
      ? forceContext === OrganizationType.team
      : isEnterpriseContext(getState());

    const params = extendParams
      ? {
          ...getCurrentRouteParams(getState()),
          ...routeParams,
        }
      : routeParams;

    const convertedRouteName = convertRouteName(routeName, isEnterprise);
    dispatch(routerActions.navigateTo(convertedRouteName, params, routeOptions));
  };
}

export function navigateToHome() {
  return (dispatch: NThunkDispatch<ReturnType<typeof routerActions.navigateTo>>) => {
    dispatch(routerActions.navigateTo('home', undefined, { replace: true }));
  };
}

export function redirectToWorkspaceDashboard() {
  return (
    dispatch: NThunkDispatch<ReturnType<typeof routerActions.navigateTo>>,
    getState: () => AppState,
  ) => {
    const organization = getContextOrganization(getState());

    if (!organization) {
      if (isOnPremiseDeployment()) {
        dispatch(routerActions.navigateTo('join-workspace', {}, { replace: true }));
        return;
      }

      // Redirect to workspace creation if none is present.
      dispatch(routerActions.navigateTo('create-workspace', {}, { replace: true }));
      return;
    }

    const isEnterprise = organization.organizationType !== OrganizationType.individual;

    const routeName = convertRouteName('organization.projects', isEnterprise);

    dispatch(
      routerActions.navigateTo(
        routeName,
        { organizationName: organization.name },
        { replace: true },
      ),
    );
  };
}

export function redirectToIndividualSubscription() {
  return (
    dispatch: NThunkDispatch<ReturnType<typeof routerActions.navigateTo>>,
    getState: () => AppState,
  ) => {
    const communityWorkspace: OrganizationWithRole | undefined =
      getCommunityOrganization(getState());

    if (!communityWorkspace) {
      // fallback
      return dispatch(
        routerActions.navigateTo('home', undefined, {
          replace: true,
          forceContext: OrganizationType.individual,
        }),
      );
    }

    const routeName = convertRouteName('organization.subscription', false);

    dispatch(
      routerActions.navigateTo(
        routeName,
        { organizationName: communityWorkspace.name },
        { replace: true },
      ),
    );
  };
}
