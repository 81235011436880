// Libs
import React from 'react';
import { LegacyEmblem, bemBlock } from '@neptune/shared/venus-ui';

// App
import { UserAvatar } from '@neptune/shared/avatars-ui';

// Module
import './UserEmblem.less';

type UserEmblemProps = {
  username: string;
  avatarUrl: string;
  onRemove: () => void;
};

const block = bemBlock('user-emblem');

export const UserEmblem: React.FC<UserEmblemProps> = ({ username, avatarUrl, onRemove }) => {
  return (
    <LegacyEmblem className={block()} spacedChildren="sm" onRemove={onRemove}>
      <UserAvatar username={username} imageSrc={avatarUrl} size="xs" />
      <div>{username}</div>
    </LegacyEmblem>
  );
};
