import { AppRoute } from '@neptune/shared/routing-domain';
import { authClient } from '@neptune/shared/core-auth-domain';

import { isClassicDeployment } from 'common/deploymentModes';
import { setTeamTrialCookieForKeycloakRegistration } from 'common/team-trial-cookies';

const createWorkspaceRoute: AppRoute = {
  name: 'create-workspace',
  path: '/-/create-workspace',
  canActivate: () => () => {
    if (!authClient.authenticated) {
      setTeamTrialCookieForKeycloakRegistration();
      const registerUrl = authClient.createRegisterUrl({
        redirectUri: `${window.location.origin}/-/create-workspace`,
      });
      window.location.replace(registerUrl);
      return;
    }

    return true;
  },
};

const askToJoinWorkspaceRoute: AppRoute = {
  name: 'join-workspace',
  path: '/-/join-workspace',
  onlyLoggedUser: true,
};

// Compatibility route for old team trial URL yet used on www, blogposts etc
const createWorkspaceCompatbililityRoute: AppRoute = {
  name: 'create-workspace-compatibility',
  path: '/-/team-trial-create',
  forwardTo: 'create-workspace',
};

export const createWorkspaceRoutes = isClassicDeployment()
  ? [createWorkspaceRoute, createWorkspaceCompatbililityRoute]
  : [askToJoinWorkspaceRoute];
