import React from 'react';
import { Dialog } from '@neptune/shared/venus-ui';

export const AlreadyInvitedModal: React.FC<{ isOpen: boolean; close: () => void }> = ({
  isOpen,
  close,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      title="Already invited"
      primaryButtonLabel="OK"
      onPrimaryButtonClick={close}
      onClose={close}
    >
      <Dialog.Text>This user is already invited / already member.</Dialog.Text>
    </Dialog>
  );
};
