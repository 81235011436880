// Libs
import React from 'react';
import { bemBlock, Layout } from '@neptune/shared/venus-ui';

// App
import { UsageHistory } from './project-usage-history/UsageHistory';
import { fetchStatus } from 'state/fetch-status';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';

// Module
import './ProjectUsageHistory.less';

const block = bemBlock('project-usage-history-box');

type ProjectUsageHistoryProps = React.ComponentProps<typeof UsageHistory> & {
  fetchStatus: fetchStatus;
};

export const ProjectUsageHistory: React.FC<ProjectUsageHistoryProps> = ({ data, fetchStatus }) => {
  return (
    <Layout.Column className={block()} span="shrink" data-role="project-usage-history">
      <UsageHistory data={data} />
      <LoadingIndicator fetchStatus={fetchStatus} centered />
    </Layout.Column>
  );
};
