import React from 'react';

import { Dropdown, DropdownProps, DropdownToggleProps } from '@neptune/shared/venus-ui';
import { DotsHButton } from '@neptune/shared/common-ui';

type DetailsDropdownMenuProps = {
  children: React.ReactElement;
} & Pick<DropdownProps, 'onCollapse' | 'onExpand' | 'constraints'>;

const DROPDOWN_ANIMATION: React.ComponentProps<typeof Dropdown>['menuAnimation'] = {
  direction: 'top-bottom',
};
const DROPDOWN_CONSTRAINTS: React.ComponentProps<typeof Dropdown>['constraints'] = [
  { to: 'scrollParent', attachment: 'together', pin: true },
];

export const DetailsDropdownMenu = ({
  children,
  onExpand,
  onCollapse,
  constraints,
}: DetailsDropdownMenuProps) => {
  const renderChildren = React.useCallback(
    ({ collapse }) => React.cloneElement(children, { collapse }),
    [children],
  );

  return (
    <Dropdown
      toggle={<Toggle />}
      attachment="top left"
      targetAttachment="bottom left"
      menuAnimation={DROPDOWN_ANIMATION}
      constraints={constraints ?? DROPDOWN_CONSTRAINTS}
      onExpand={onExpand}
      onCollapse={onCollapse}
    >
      {renderChildren}
    </Dropdown>
  );
};

const Toggle = ({ onToggle, pressed }: Pick<DropdownToggleProps, 'onToggle' | 'pressed'>) => {
  return <DotsHButton onToggle={onToggle} pressed={pressed} data-role="details-menu-button" />;
};
