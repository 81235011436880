import React from 'react';
import { neptuneUrls } from '@neptune/shared/common-domain';
import { heap } from 'analytics/heap';
import { isOnPremiseDeployment } from 'common/deploymentModes';
import { ErrorPlaceholder } from '@neptune/shared/common-ui';

type ErrorPlaceholderContainerProps = Omit<
  React.ComponentProps<typeof ErrorPlaceholder>,
  'statusPageUrl' | 'isOnPremiseDeployment'
> & {
  context: string;
};

export const ErrorPlaceholderContainer: React.FC<ErrorPlaceholderContainerProps> = ({
  context,
  ...restProps
}) => {
  React.useEffect(() => {
    try {
      heap.track('ui_error_shown', {
        context,
      });
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorPlaceholder
      statusPageUrl={neptuneUrls.statusPage}
      isOnPremiseDeployment={isOnPremiseDeployment()}
      {...restProps}
    />
  );
};
