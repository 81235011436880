import { sortBy } from 'lodash';
import { LayoutMinDimensions, LayoutRect } from '@neptune/shared/grid-layout-domain';

type LayoutForSingleColumnGeneration = LayoutRect &
  Partial<LayoutMinDimensions> & { static?: boolean };

export function generateSingleColumnLayouts<ItemLayoutT extends LayoutForSingleColumnGeneration>(
  sourceLayouts: ItemLayoutT[],
  {
    getDefaultHeight,
    layoutColumns = 1,
    isStatic = true,
  }: {
    getDefaultHeight: (layout: ItemLayoutT) => number;
    layoutColumns?: number;
    isStatic?: boolean;
  },
): ItemLayoutT[] {
  // Avoid edge case in code below.
  if (!sourceLayouts.length) {
    return [];
  }

  const sorted = sortBy(sourceLayouts, 'y', 'x');
  const result: ItemLayoutT[] = [];

  let nextY = 0;

  for (const layout of sorted) {
    const h = getDefaultHeight(layout);

    result.push({
      ...layout,
      x: 0,
      y: nextY,
      w: layoutColumns,
      h,
      // Make sure that constraints don't clash with actual dimensions,
      // especially width.
      minW: layout.minW && Math.min(layout.minW, layoutColumns),
      minH: layout.minH && Math.min(layout.minH, h),
      static: isStatic,
    });

    nextY += h;
  }

  return result;
}
