import React from 'react';
import { neptuneUrls } from '@neptune/shared/common-domain';
import { NotifyAdmin, StatusBanner } from '@neptune/shared/status-banner-ui';
import { Link, Text } from '@neptune/shared/venus-ui';

type ProjectMemberStorageErrorBannerProps = {
  adminEmails: string[];
};

export const ProjectMemberStorageErrorBanner: React.FC<ProjectMemberStorageErrorBannerProps> = ({
  adminEmails,
}) => {
  return (
    <StatusBanner data-role="member-project-storage-status-banner" error>
      <Text size="xs">
        This project <Text fontWeight="semibold">has reached its storage limit</Text>. You can
        continue using Neptune in a{' '}
        <Link href={neptuneUrls.workspaceOrProjectReadOnlyDoc} external>
          limited read-only mode
        </Link>
        . Ask the Owner of the project to increase the storage limit, or delete some data.
      </Text>
      <NotifyAdmin recipients={adminEmails} />
    </StatusBanner>
  );
};
