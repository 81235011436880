import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State as RouterState } from 'router5/types/types/base';
import { startsWithSegment } from 'router5-helpers';
import { toNormalizedRouteName } from 'router/helpers';
import { getCurrentRoute, getPreviousRoute } from 'state/selectors-global';

import { resetPagination } from './redux/pagination-actions';

type PaginationResetOnNavigationParams = {
  leaderboardId: string;
  routeName: string;
  watchedParams: string[];
};

export const usePaginationResetOnNavigation = ({
  leaderboardId,
  routeName,
  watchedParams,
}: PaginationResetOnNavigationParams) => {
  const dispatch = useDispatch();
  const currentRoute = useSelector(getCurrentRoute);
  const previousRoute = useSelector(getPreviousRoute);

  React.useEffect(() => {
    if (!(isRoute(currentRoute, routeName) && previousRoute && isRoute(previousRoute, routeName))) {
      return;
    }

    if (watchedParams.some((key) => currentRoute.params[key] !== previousRoute.params[key])) {
      dispatch(resetPagination(leaderboardId));
    }
  }, [currentRoute, dispatch, leaderboardId, previousRoute, routeName, watchedParams]);
};

function isRoute(route: RouterState, name: string): boolean {
  return startsWithSegment(toNormalizedRouteName(route.name), name);
}
