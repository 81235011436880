import { AnyAction } from 'redux';
import { NThunkAction } from 'state/types';
import { getContextOrganization } from 'state/selectors-global';
import { isV3Pricing } from '@neptune/shared/organization-business-logic';
import { changePlanModalName, v3ChangePlanModalName } from '@neptune/pricing-commons-domain';
import { hideGenericModal, showGenericModal } from 'state/ui/modals/generic/actions';

export function openChangePlanModal(): NThunkAction<void, AnyAction> {
  return async (dispatch, getState) => {
    const organization = getContextOrganization(getState());
    const modalName = isV3Pricing(organization?.pricingPlan)
      ? v3ChangePlanModalName
      : changePlanModalName;
    dispatch(showGenericModal(modalName));
  };
}

export function closeChangePlanModal(): NThunkAction<void, AnyAction> {
  return async (dispatch, getState) => {
    const organization = getContextOrganization(getState());
    const modalName = isV3Pricing(organization?.pricingPlan)
      ? v3ChangePlanModalName
      : changePlanModalName;
    dispatch(hideGenericModal(modalName));
  };
}
