import React from 'react';
import { startOfDay } from 'date-fns';
import { groupBy } from 'lodash';
import { formatDateShort } from '@neptune/shared/common-util';
import { Layout, Text, Link } from '@neptune/shared/venus-ui';
import { Version } from '@neptune/shared/dashboard-domain';
import { RouterSimpleState } from '@neptune/shared/routing-domain';
import { VersionItem } from './VersionItem';

type VersionsListProps = {
  versions?: Version[];
  makeVersionRoute: (id: string) => RouterSimpleState | undefined;
  branchViewRoute?: RouterSimpleState;
  onShareLink?: (id: string) => void;
  onRestore?: (id: string) => void;
  onEditName?: (id: string) => void;
  onEditDescription?: (id: string) => void;
  latestVersionId?: string;
  currentVersionId?: string;
};

function isWithTimestamp(version: Version | undefined): version is Version & { timestamp: Date } {
  return version?.timestamp !== undefined;
}

export const VersionsList: React.FC<VersionsListProps> = ({
  versions,
  makeVersionRoute,
  branchViewRoute,
  onShareLink,
  onRestore,
  onEditName,
  onEditDescription,
  latestVersionId,
  currentVersionId,
}: VersionsListProps) => {
  const versionsSortedGroupedByDate = groupBy(versions?.filter(isWithTimestamp), (version) =>
    startOfDay(version.timestamp),
  );

  return (
    <Layout.Column spacedChildren="md" overflow="auto" withCustomScrollbar>
      {Object.entries(versionsSortedGroupedByDate).map(([groupDate, group]) => {
        return (
          <Layout.Column spacedChildren="xs" key={groupDate}>
            <Text size="xs" color="text-secondary" fontWeight="semibold">
              {formatDateShort(startOfDay(new Date(groupDate)))}
            </Text>
            {latestVersionId === group[0].id && (
              <Layout.Row justifyContent="space-between">
                <Text italic size="xs">
                  Latest version
                </Text>
                {onEditName && (
                  <Link onClick={() => onEditName(group[0].id)} size="xs">
                    Name version
                  </Link>
                )}
              </Layout.Row>
            )}
            <Layout.Column spacedChildren="sm">
              {group.map((version) => {
                const isLatestVersion = version.id === latestVersionId;
                const routeParams = makeVersionRoute(version.id);

                if (!routeParams) {
                  return null;
                }

                const { name: versionRouteName, params: versionRouteParams } = routeParams;

                const route =
                  isLatestVersion && branchViewRoute
                    ? { name: branchViewRoute.name, params: branchViewRoute.params }
                    : { name: versionRouteName, params: versionRouteParams };

                /* if no currentVersionId is provided, it means that we're in branch view
                and latest version should be selected */
                const selected = currentVersionId
                  ? currentVersionId === version.id
                  : isLatestVersion;

                return (
                  <VersionItem
                    selected={selected}
                    key={version.id}
                    version={version}
                    route={route}
                    onShareLink={onShareLink}
                    onRestore={isLatestVersion ? undefined : onRestore}
                    onEditName={onEditName}
                    onEditDescription={onEditDescription}
                  />
                );
              })}
            </Layout.Column>
          </Layout.Column>
        );
      })}
    </Layout.Column>
  );
};
