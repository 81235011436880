// App
import {
  ExperimentStateCriterion,
  PartialSearchCriterion,
  PluralExperimentStateCriterion,
  SingularExperimentStateCriterion,
} from '../search-query-model';
import { getReadableState } from 'common/state';
import {
  criterionHasAttributeAndOperator,
  getReadableArrayValue,
} from '../search-query-model-core-utils';

// Module
import { CriterionHelpers } from './types';

export function isExperimentStateCriterion(
  criterion: PartialSearchCriterion,
): criterion is ExperimentStateCriterion {
  return (
    isSingularExperimentStateCriterion(criterion) || isPluralExperimentStateCriterion(criterion)
  );
}

export function isSingularExperimentStateCriterion(
  criterion: PartialSearchCriterion,
): criterion is SingularExperimentStateCriterion {
  return (
    criterionHasAttributeAndOperator(criterion) &&
    criterion.type === 'experimentState' &&
    criterion.operator === '='
  );
}

export function isPluralExperimentStateCriterion(
  criterion: PartialSearchCriterion,
): criterion is PluralExperimentStateCriterion {
  return (
    criterionHasAttributeAndOperator(criterion) &&
    criterion.type === 'experimentState' &&
    criterion.operator === 'oneOf'
  );
}

export const experimentStateCriterionHelpers: CriterionHelpers<ExperimentStateCriterion> = {
  getReadableValue(criterion) {
    if (isPluralExperimentStateCriterion(criterion)) {
      return getReadableArrayValue(criterion.value.map(getReadableState));
    }

    return getReadableState(criterion.value);
  },
  getShortReadableValue(criterion) {
    if (Array.isArray(criterion.value)) {
      return criterion.value.map(getReadableState).join(', ');
    }

    return getReadableState(criterion.value);
  },
  isComplete(criterion) {
    if (isSingularExperimentStateCriterion(criterion)) {
      return criterion.value != null;
    }

    if (isPluralExperimentStateCriterion(criterion)) {
      return criterion.value?.length > 0;
    }

    return false;
  },
};
