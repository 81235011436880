// Libs
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Module
import { fetchPastInvoicesAction } from '../redux/past-invoices-actions';
import { getPastInvoices } from '../redux/past-invoices-selectors';

export const useInvoicesData = (organizationName?: string) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!organizationName) {
      return;
    }

    dispatch(fetchPastInvoicesAction(organizationName));
  }, [dispatch, organizationName]);

  return useSelector(getPastInvoices);
};
