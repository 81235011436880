import {
  getIdFromAttributes,
  getNameFromAttributes,
  knownAttributes,
} from 'domain/experiment/attribute';
import { SimpleState } from 'router5/types/types/base';
import {
  getEntityTrashed,
  Entity,
  EntityType,
  fetchEntityChunk,
} from '@neptune/shared/entity-domain';
import { toNormalizedRouteName } from '@neptune/shared/enterprise-context-util';
import { getSingleRunAutoFollowEnabled } from 'common/featureFlag';
import { makeEntityIdentifier } from '@neptune/shared/entity-util';

/**
 * Fetches entity for in-project display route.
 * WARN: Fetched entity will contain only well-known attributes.
 * @param requestedRoute
 */
export async function fetchEntityForInProjectDisplayRoute(
  requestedRoute: SimpleState,
): Promise<Entity> {
  const { organizationName, projectName, runIdentificationKey, type } = requestedRoute.params;

  return fetchEntityChunk({
    id: makeEntityIdentifier(organizationName, projectName, runIdentificationKey),
    fieldsToFetch: knownAttributes,
    ...(type ? { type } : { holderType: 'experiment' }),
  });
}

type InProjectEntityDisplayType = {
  type: Exclude<EntityType, 'project'> | 'experiment';
  trashed: boolean;
};

export function entityMatchesDisplayType(
  entity: Entity,
  { type, trashed }: InProjectEntityDisplayType,
) {
  return entity.type === type && getEntityTrashed(entity) === trashed;
}

export function typeOfProjectEntityDisplayRoute(
  route: SimpleState,
): InProjectEntityDisplayType | undefined {
  const name = toNormalizedRouteName(route.name);
  const params = route.params;
  const type: string | undefined = params.type;

  if ('runIdentificationKey' in params) {
    if ((name === 'project.runs' || name === 'project.runs-tab') && params.tab === 'details') {
      return { type: 'run', trashed: false };
    }

    if (name === 'project.model-registry' && (type === 'model' || type === 'modelVersion')) {
      return { type, trashed: false };
    }

    if (
      name === 'project.trash' &&
      (type === 'run' || type === 'experiment' || type === 'model' || type === 'modelVersion')
    ) {
      return { type, trashed: true };
    }
  }

  return undefined;
}

export function getInProjectEntityDisplayRoute(entity: Entity): SimpleState | undefined {
  if (entity.type === 'project') {
    // We want to show the entity in context of the project, so it makes no sense for this entity to be a project.
    return undefined;
  }

  let runIdentificationKey = getIdFromAttributes(entity.attributes);
  let type: EntityType | 'experiment' = entity.type;

  if (getSingleRunAutoFollowEnabled()) {
    const experimentName = getNameFromAttributes(entity.attributes);

    type = experimentName ? 'experiment' : type;
    runIdentificationKey = experimentName || runIdentificationKey;
  }

  const commonParams = {
    runIdentificationKey,
    projectName: entity.projectName,
    organizationName: entity.organizationName,
  };

  if (getEntityTrashed(entity)) {
    return { name: 'project.trash', params: { ...commonParams, type } };
  }

  switch (type) {
    case 'run':
    case 'experiment':
      return {
        name: 'project.runs',
        params: { ...commonParams, tab: 'details', type },
      };

    case 'model':
    case 'modelVersion':
      return { name: 'project.model-registry', params: { ...commonParams, type } };
  }

  return undefined;
}
