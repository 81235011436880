import React from 'react';
import {
  FilterResultItem,
  filterSearchDefaultLimit,
  QueryFilterSearch,
  SearchFilter,
} from '@neptune/shared/search-domain';
import { Many, sortBy } from 'lodash';

type LodashCompatibleSortByIteratee<T> = Many<
  (value: FilterResultItem<T>, index: number, collection: ArrayLike<FilterResultItem<T>>) => unknown
>;

type UseStaticFilterSearchDataSourceProps<T> = {
  collection: T[];
  limit?: number;
  searchBy?: (value: T) => string;
  searchEngine: SearchFilter<T>;
  sortByIteratee?: LodashCompatibleSortByIteratee<T>;
};

export const useStaticFilterSearchDataSource = <T>({
  collection,
  limit = filterSearchDefaultLimit,
  searchBy,
  searchEngine,
  sortByIteratee,
}: UseStaticFilterSearchDataSourceProps<T>): QueryFilterSearch<T> => {
  const dataSource: QueryFilterSearch<T> = React.useCallback(
    async (query: string) => {
      const allResults = searchEngine(query.trim(), collection, searchBy);
      const allResultsSorted = sortByIteratee ? sortBy(allResults, sortByIteratee) : allResults;
      return {
        entries: allResultsSorted.slice(0, limit),
        collectionCount: collection.length,
      };
    },
    [collection, limit, searchBy, searchEngine, sortByIteratee],
  );
  dataSource.isStatic = true;
  return dataSource;
};
