import React from 'react';
import { useDispatch } from 'react-redux';

import { ServiceAccount, updateProjectServiceAccount } from '@neptune/service-accounts-domain';
import { ProjectRole } from '@neptune/shared/core-project-domain';
import { ChangeServiceAccountRoleModal } from '@neptune/service-accounts-ui';
import { useGenericModal } from '@neptune/shared/common-business-logic';
import { showServerErrorModal } from 'state/ui/modals/server-error/actions';

type ChangeServiceAccountRoleModalContainerProps = {
  onSubmit(): void;
};

export const CHANGE_SERVICE_ACCOUNT_ROLE_MODAL_NAME = 'CHANGE_SERVICE_ACCOUNT_ROLE_MODAL_NAME';

export type ChangeServiceAccountRoleModalData = {
  projectIdentifier: string;
  serviceAccount: ServiceAccount;
  role: ProjectRole;
};

export const ChangeServiceAccountRoleModalContainer: React.FC<
  ChangeServiceAccountRoleModalContainerProps
> = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const { isOpen, close, data } = useGenericModal<ChangeServiceAccountRoleModalData>(
    CHANGE_SERVICE_ACCOUNT_ROLE_MODAL_NAME,
  );

  const handleSubmit = React.useCallback(async () => {
    try {
      await updateProjectServiceAccount({
        projectIdentifier: data.projectIdentifier,
        serviceAccountId: data.serviceAccount.id,
        role: data.role,
      });
      close();
      onSubmit();
    } catch (e) {
      dispatch(showServerErrorModal());
    }
  }, [close, data, dispatch, onSubmit]);

  if (!data) {
    return null;
  }

  return (
    <ChangeServiceAccountRoleModal
      isOpen={isOpen}
      onClose={close}
      onSubmit={handleSubmit}
      role={data.role}
      serviceAccount={data.serviceAccount}
    />
  );
};
