// Libs
import React from 'react';
import { Layout, LayoutColumnProps, bemBlock } from '@neptune/shared/venus-ui';

// Module
const block = bemBlock('app-sidebar-section');

export const AppSidebarSection: React.FC<LayoutColumnProps<{}>> = ({ className, ...props }) => {
  const ownProps = {
    className: block({ extra: className }),
  };

  return <Layout.Column {...props} {...ownProps} />;
};
