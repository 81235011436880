import React from 'react';
import { buildTrashLeaderboardQuery } from '@neptune/shared/entity-leaderboard-business-logic';
import { searchTags } from '@neptune/shared/tags-domain';
import { difference } from 'lodash';
import { substringSearchFilter } from '@neptune/shared/search-business-logic';
import { QueryFilterSearch } from '@neptune/shared/search-domain';

export const TagsDataSourceContext = React.createContext<QueryFilterSearch<string, string>>(
  async () => ({
    entries: [],
    collectionCount: Number.NaN,
  }),
);

type UseTagsDataSourceProps = {
  limit?: number;
  projectIdentifier: string;
  types: string[];
  trash: boolean;
  disabledTags?: string[];
};

export const useProjectTagsDataSource = ({
  limit = 50,
  projectIdentifier,
  types,
  trash,
  disabledTags,
}: UseTagsDataSourceProps): QueryFilterSearch<string, string> => {
  const serializedTypes = types && JSON.stringify(types);
  const rawQuery = React.useCallback(
    async (search: string, attributePath: string | undefined) => {
      return searchTags({
        projectIdentifier,
        query: buildTrashLeaderboardQuery(trash),
        search,
        type: serializedTypes && JSON.parse(serializedTypes),
        attributePath,
      });
    },
    [projectIdentifier, serializedTypes, trash],
  );

  const prepareOutput = React.useCallback(
    (collection: string[], search: string) => {
      const filteredResults = disabledTags ? difference(collection, disabledTags) : collection;
      const allResults = substringSearchFilter(search, filteredResults);
      return allResults.slice(0, limit);
    },
    [disabledTags, limit],
  );

  return React.useCallback(
    async (search: string, attributePath: string | undefined) => {
      const collection = await rawQuery(search.trim(), attributePath);

      const entries = prepareOutput(collection, search.trim());
      return {
        entries,
        collectionCount: collection.length,
      };
    },
    [prepareOutput, rawQuery],
  );
};
