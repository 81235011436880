// Libs
import React from 'react';

// App
import { Icon, Link } from '@neptune/shared/venus-ui';

// Module

export const recommendedBrowsersLinks = (
  <>
    <Link external href="https://www.google.com/chrome/">
      Chrome&nbsp;
      <Icon glyph="external-link" />
    </Link>
    {', '}
    <Link external href="https://www.mozilla.org/firefox/">
      Firefox&nbsp;
      <Icon glyph="external-link" />
    </Link>
    {', '}
    <Link external href="https://www.opera.com/">
      Opera&nbsp;
      <Icon glyph="external-link" />
    </Link>
    {', '}
    <Link external href="https://www.microsoft.com/edge">
      Edge&nbsp;
      <Icon glyph="external-link" />
    </Link>{' '}
    or{' '}
    <Link external href="https://www.apple.com/safari/">
      Safari&nbsp;
      <Icon glyph="external-link" />
    </Link>
    .
  </>
);
