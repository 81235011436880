import { FilterResultItem } from '@neptune/shared/search-domain';
import { UserRegExp } from '@neptune/shared/search-util';
import { endOfMatchMark, startOfMatchMark } from '@neptune/shared/common-domain';

const createRegexpForQuery = (query: string): RegExp | undefined => {
  try {
    return new UserRegExp(`${query.trim()}`);
  } catch (error) {
    return;
  }
};

const WORD_SEPARATOR = '\u0003';

/**
 * Filters collection according to regexp.
 * @param query
 * @param collection
 * @param searchBy have to return string against which is search performed.
 */
export function regexpSearchFilter<T>(
  query: string,
  collection: T[],
  searchBy?: (value: T) => string,
): FilterResultItem<T>[] {
  if (query.length < 1) {
    return collection.map(wrapIntoFilterResultLike);
  }

  const queryRegExp = createRegexpForQuery(query);

  const includingFullMatch: FilterResultItem<T>[] = collection
    .filter((value: T) => extract(value).some((part) => queryRegExp?.test(part) ?? false))
    .map(wrapIntoFilterResultLike);

  return includingFullMatch;

  function extract(value: T): string[] {
    const text = searchBy ? searchBy(value) : `${value}`;
    return text.split(WORD_SEPARATOR);
  }

  function addMarks(serializedItem: string): string {
    if (query.length < 1) {
      return serializedItem;
    }

    return serializedItem.replace(
      // need to create new instance of RegExp with global flag to iterate over all matches
      new UserRegExp(`${query.trim()}`, 'g'),
      (match) => `${startOfMatchMark}${match}${endOfMatchMark}`,
    );
  }

  function wrapIntoFilterResultLike(item: T) {
    const serializedItem = extract(item);

    return {
      original: item,
      string: serializedItem.map(addMarks).join(WORD_SEPARATOR),
    };
  }
}
