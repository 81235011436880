// Libs
import React from 'react';
import { Layout, RadioWithLabel } from '@neptune/shared/venus-ui';

// App
import { KeycloakLabel } from 'components/keycloak';

const AVAILABLE_OPTIONS = [
  { key: 'individual', value: 'Individual projects only' },
  { key: 'team', value: 'Team and individual projects' },
  { key: 'individual-and-maybe-team', value: 'Individual, team maybe later' },
] as const;
type OptionType = { key: string; value: string };

type OrganizationTypeFormOptions = {
  data: { organizationType?: string };
  onChange: (form: { organizationType?: string }) => void;
};

export const NewOrganizationTypeForm: React.FC<OrganizationTypeFormOptions> = ({
  data,
  onChange,
}) => {
  return (
    <Layout.Column component="form" spacedChildren="md" data-role="organization-type-form">
      <KeycloakLabel>How do you want to use Neptune?</KeycloakLabel>
      <Layout.Column spacedChildren="sm" span="auto">
        {AVAILABLE_OPTIONS.map((option: OptionType, i: number) => (
          <RadioWithLabel
            key={option.key}
            id={`survey-organization-type-${i}`}
            data-role={option.key}
            value={option.value}
            name="survey-organization-type"
            checked={data.organizationType === option.key}
            onChange={() => onChange({ organizationType: option.key })}
            label={option.value}
          />
        ))}
      </Layout.Column>
    </Layout.Column>
  );
};
