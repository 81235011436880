import { actionTypes as routerActionTypes } from 'redux-router5';

import { TransitionSuccessAction } from '@neptune/shared/routing-domain';

import { RouteHistoryState } from './route-history-types';

export function routeHistoryReducer(
  state = [],
  action: TransitionSuccessAction,
): RouteHistoryState {
  switch (action.type) {
    case routerActionTypes.TRANSITION_SUCCESS: {
      const { route } = action.payload;

      return [...state, { ...route }].slice(-100);
    }

    default: {
      return state;
    }
  }
}
