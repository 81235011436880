import React from 'react';
import { requestOperation } from 'state/attributes/actions';
import { EntityChanges, EntityType } from '@neptune/shared/entity-domain';
import { useDispatch } from 'react-redux';

type UseEntityChangeParams = {
  entityId: string | undefined;
  entityType: EntityType | undefined;
};

export const useEntityChange = ({ entityId, entityType }: UseEntityChangeParams) => {
  const dispatch = useDispatch();

  return React.useCallback(
    async (entityChanges: Partial<EntityChanges>) => {
      if (!entityId || !entityType) {
        return undefined;
      }

      await dispatch(
        requestOperation({
          parentId: entityId,
          parentType: entityType,
          entityChanges,
        }),
      );
    },
    [entityId, entityType, dispatch],
  );
};
