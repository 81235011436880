import { DashboardVersionDTO, DashboardVersionDTOTypeEnum } from 'generated/leaderboard-client';
import { DashboardType } from 'domain/dashboard';
import { DashboardVersion } from './version';

const dashboardTypeMapping: Record<DashboardVersionDTOTypeEnum, DashboardType> = {
  [DashboardVersionDTOTypeEnum.Compare]: 'compare',
  [DashboardVersionDTOTypeEnum.Experiment]: 'experiment',
  [DashboardVersionDTOTypeEnum.Report]: 'report',
};

export abstract class DashboardVersionModelConverter {
  static dashboardVersionFromApiToDomain(dashboard: DashboardVersionDTO): DashboardVersion {
    return {
      ...dashboard,
      versionBranchId: dashboard.versionBranch,
      type: this.dashboardVersionTypeFromApiToDomain(dashboard.type),
    };
  }

  static dashboardVersionTypeFromApiToDomain(type: DashboardVersionDTOTypeEnum): DashboardType {
    return dashboardTypeMapping[type];
  }
}
