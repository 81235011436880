import { KnownAttributes } from 'domain/experiment/attribute';
import { formatMonitoringExtended } from '@neptune/shared/common-util';

import { FloatCriterion, IntegerCriterion, PartialSearchCriterion } from '../search-query-model';
import { criterionHasAttributeAndOperator } from '../search-query-model-core-utils';
import { CriterionHelpers } from './types';

export function isTimeCriterion(
  criterion: PartialSearchCriterion,
): criterion is IntegerCriterion | FloatCriterion {
  return (
    criterionHasAttributeAndOperator(criterion) &&
    (criterion.attribute === KnownAttributes.MonitoringTime ||
      criterion.attribute === KnownAttributes.RunningTime)
  );
}

export const timeCriterionHelpers: CriterionHelpers<IntegerCriterion | FloatCriterion> = {
  getReadableValue: (criterion) => formatMonitoringExtended(criterion.value / 60),
  getShortReadableValue: (criterion) => formatMonitoringExtended(criterion.value / 60),
  isComplete(criterion) {
    return criterion.value != null;
  },
};
