import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';
import { setColumns } from '@neptune/shared/columns-business-logic';
import { ColumnsRootKey } from '@neptune/shared/columns-domain';
import { loadState } from 'common/localStorage';
import { getViewById } from 'state/project-leaderboard/lb-views/selectors';
import { AppState, NThunkDispatch } from 'state/types';

type Params = {
  state: AppState;
  dispatch: NThunkDispatch<any>;
  viewId: string;
};

export function setOrderedColumnsByViewId({ state, dispatch, viewId }: Params) {
  const projectIdentifier = getCurrentProjectIdentifier(state);

  const persistedState = loadState() || {};
  const { orderedColumns = {} } = persistedState;

  // allow columns state to be restored from the local storage when viewing leaderboard for the first time
  // TODO remove in Phase 2
  if (orderedColumns.runs?.[projectIdentifier]) {
    return;
  }

  const view = getViewById(state, viewId);

  if (view) {
    dispatch(
      setColumns({
        columns: view.columnList,
        override: true,
        columnsSetId: projectIdentifier,
        columnsRootKey: ColumnsRootKey.Runs,
      }),
    );
  }
}
