import { Integrations } from '@sentry/browser';

export const breadcrumbsIntegration = new Integrations.Breadcrumbs({
  dom: {
    // if serializeAttribute is/are provided, then:
    // * id and classes will not be logged
    // * attributes described below will be logged
    // * 'type', 'name', 'title', 'alt' will be logged unconditionally (if present)
    // @see '@sentry/utils/browser' htmlTreeAsString
    serializeAttribute: [
      'data-role',
      'id',
      'data-id',
      'data-type',
      'data-column',
      'data-name',
      'aria-label',
      'role',
      'class',
    ],
  },
});
