import { Banner, Text, bemBlock } from '@neptune/shared/venus-ui';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

// Module
import './ProjectNameChangeWarning.less';

const block = bemBlock('project-name-change-warning');

export const WARNING_TEXT =
  'You are about to change the name of this project. This could cause problems if there are active or not synchronized runs in a project: messages can be lost or rejected.';

export const ProjectNameChangeWarning: React.FC<{
  isVisible?: boolean;
}> = ({ isVisible = false }) => {
  return (
    <CSSTransition
      classNames={block('animation')}
      in={isVisible}
      timeout={{ enter: 500, exit: 500 }}
      mountOnEnter
      unmountOnExit
    >
      <Banner.Warning size="small" className={block()}>
        <Text>{WARNING_TEXT}</Text>
      </Banner.Warning>
    </CSSTransition>
  );
};
