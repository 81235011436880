// Libs

// App
import { ExperimentState } from 'domain/experiment/experiment-state';

// Module

const translationMap: Record<ExperimentState, string> = {
  idle: 'inactive',
  running: 'active',
};

export function getReadableState(state: ExperimentState): string {
  return translationMap[state];
}
