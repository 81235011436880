import React from 'react';

import { Dialog } from '@neptune/shared/venus-ui';

type AlreadyAssignedToProjectModalProps = {
  isOpen: boolean;
  onClose(): void;
};

export const AlreadyAssignedToProjectModal: React.FC<AlreadyAssignedToProjectModalProps> = ({
  isOpen,
  onClose,
}) => (
  <Dialog
    isOpen={isOpen}
    onClose={onClose}
    primaryButtonLabel="OK"
    primaryButtonRole="already-assigned-submit"
    onPrimaryButtonClick={onClose}
    role="already-assigned-modal"
    title="Already assigned"
  >
    <Dialog.Text>This service account is already assigned to the project.</Dialog.Text>
  </Dialog>
);
