import React from 'react';
import { Button, Layout, bemBlock } from '@neptune/shared/venus-ui';
import { ButtonWithLoading } from 'components/button-with-loading/ButtonWithLoading';

import './SurveyNavigation.less';

type SurveyNavigationProps = {
  showPrev: boolean;
  showNext: boolean;
  showCancel?: boolean;
  disablePrev: boolean;
  disableNext: boolean;
  isGoingPrev?: boolean;
  isGoingNext?: boolean;
  handlePrev: () => void;
  handleNext: () => void;
  handleCancel?: () => void;
  labelCancel?: string;
  labelNext?: string;
  labelPrev?: string;
  'data-step'?: string;
};

const block = bemBlock('survey-navigation');

export const SurveyNavigation: React.FC<SurveyNavigationProps> = ({
  showPrev,
  showNext,
  showCancel,
  disablePrev,
  disableNext,
  isGoingPrev,
  isGoingNext,
  handlePrev,
  handleNext,
  handleCancel,
  labelCancel = 'Cancel',
  labelNext = 'Next',
  labelPrev = 'Back',
  'data-step': dataStep,
}) => {
  const isLoading = isGoingPrev || isGoingNext;

  return (
    <Layout.Row spacedChildren="md" data-role="survey-navigation" data-step={dataStep}>
      {showCancel && handleCancel && (
        <Button
          data-role="cancel-button"
          size="lg"
          variant="basic"
          withPadding={['none', 'xl']}
          children={labelCancel}
          onClick={handleCancel}
          disabled={isLoading}
        />
      )}

      {showPrev && (
        <ButtonWithLoading
          data-role="prev-button"
          size="lg"
          variant="basic"
          withPadding={['none', 'xl']}
          children={labelPrev}
          disabled={disablePrev || isLoading}
          onClick={handlePrev}
          loading={isGoingPrev}
        />
      )}

      {showNext && (
        <ButtonWithLoading
          className={block('next-button')}
          data-role="next-button"
          size="lg"
          withPadding={['none', 'xl']}
          children={labelNext}
          disabled={disableNext || isLoading}
          onClick={handleNext}
          loading={isGoingNext}
        />
      )}
    </Layout.Row>
  );
};
