// Libs
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rx';

// App
import {
  backendClient,
  UsernameValidationStatusEnumDTO,
} from '@neptune/shared/core-apis-backend-domain';
import config from 'config';

const USERNAME_KEY_REGEX = new RegExp(config.neptuneUsernameRegExp);

type UseUsernameValidationParams = {
  onValidationChange: ({ status }: { status: UsernameValidationStatusEnumDTO }) => void;
  onValidationStart?: () => void;
};

type UseUsernameValidationReturn = {
  validate: (username: string) => void;
};

type UseUsernameValidationProps = {
  username: string;
  status: UsernameValidationStatusEnumDTO;
};

export const useUsernameValidation = ({
  onValidationChange,
  onValidationStart,
}: UseUsernameValidationParams): UseUsernameValidationReturn => {
  const subjectRef = React.useRef(new Rx.Subject<UseUsernameValidationProps>());

  React.useEffect(() => {
    const subscription = subjectRef.current
      .debounce(300)
      .tap(() => onValidationStart?.())
      .map(validateFormat)
      .flatMapLatest<UseUsernameValidationProps>((props) =>
        Rx.Observable.fromPromise(validateUniqueness(props)),
      )
      .retryWhen((errors) => errors)
      .subscribe(({ status }) => onValidationChange({ status }));

    return () => subscription.dispose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = React.useCallback((username: string) => {
    subjectRef.current.onNext({ username, status: UsernameValidationStatusEnumDTO.Available });
  }, []);

  function validateFormat(props: UseUsernameValidationProps): UseUsernameValidationProps {
    return {
      ...props,
      status: USERNAME_KEY_REGEX.test(props.username)
        ? UsernameValidationStatusEnumDTO.Available
        : UsernameValidationStatusEnumDTO.Invalid,
    };
  }

  async function validateUniqueness(props: UseUsernameValidationProps) {
    // we pass it further if status is not valid
    if (props.status === UsernameValidationStatusEnumDTO.Invalid) {
      return props;
    }

    const response = await backendClient.validateUsername({ username: props.username });
    return {
      ...props,
      status: response.status,
    };
  }

  return {
    validate,
  };
};
