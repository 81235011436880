import React from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';
import { EntityType, EntityModelConverter } from '@neptune/shared/entity-domain';
import { useAsyncValue } from '@neptune/shared/common-util';
import { makeExperimentIdentifier } from 'common/experiment';

export const useEntityType = ({ id }: { id?: string }) => {
  const resolver = React.useCallback(async () => {
    if (!id) {
      return;
    }

    try {
      const entry = await leaderboardClient.getExperiment({ experimentId: id });

      if (
        makeExperimentIdentifier(entry.organizationName, entry.projectName, entry.shortId) === id
      ) {
        return EntityModelConverter.entityTypeFromApiToDomain(entry.type);
      }
    } catch (e) {
      return;
    }
  }, [id]);

  return useAsyncValue<EntityType | undefined>({ resolver });
};
