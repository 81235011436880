import React from 'react';

export type TagColorsContextContent = {
  getColor: (tag: string) => string | undefined;
  updateColor: (tag: string, color: string) => void;
};

export const TagColorsContext = React.createContext<TagColorsContextContent>({
  get getColor(): (tag: string) => string | undefined {
    throw new Error('TagColorsContextProvider not initialized');
  },
  get updateColor(): (tag: string, color: string) => void {
    throw new Error('TagColorsContextProvider not initialized');
  },
});
