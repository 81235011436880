import React from 'react';
import { useSelector } from 'react-redux';

import { ShareModal } from '@neptune/project-ui';
import { isOnPremiseDeployment } from 'common/deploymentModes';
import { Organization } from '@neptune/shared/core-organizations-domain';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';
import { useRouter } from 'react-router5';
import {
  getCurrentOrganization,
  getOrganizationNameFromRouteParams,
  getUserNameFromRouteParams,
} from 'state/selectors-global';
import { AppState } from 'state/types';

type ShareModalContainerProps = {
  isOpen: boolean;
  close: () => void;
  project: Pick<ProjectWithRole, 'name' | 'visibility'>;
};

const getOrganizationName = (state: AppState) => {
  // NOTE: When there is no organizationName in the route it means that userName is the organizationName (organizationType is individual)
  return getOrganizationNameFromRouteParams(state) ?? getUserNameFromRouteParams(state);
};

const useProjectLink = ({
  projectName,
  organizationName,
}: {
  projectName: string;
  organizationName: string | undefined;
}) => {
  const router = useRouter();
  // NOTE: For non-member we don't have organizationName in the state, we need to take it from the URL.
  const routeOrganizationName = useSelector(getOrganizationName);

  const routeName = 'project';
  const routeParams = { organizationName: organizationName ?? routeOrganizationName, projectName };
  const projectLink = new URL(
    router.buildUrl(routeName, routeParams),
    window.location.origin,
  ).toString();

  return {
    projectLink,
  };
};

export const ShareModalContainer: React.FC<ShareModalContainerProps> = ({
  isOpen,
  close,
  project,
}) => {
  const organization: Organization | undefined = useSelector(getCurrentOrganization);
  const { projectLink } = useProjectLink({
    projectName: project.name,
    organizationName: organization?.name,
  });

  const handleEmail = () => {
    window.open(
      'mailto:?body=Hey!%0D%0A' +
        'Here is a direct link to one thing I wanted to show you in Neptune:%0D%0A' +
        projectLink +
        '&subject=I share a Neptune link with you',
      '__blank',
      'noopener, noreferrer',
    );
  };

  const handleTwitter = () => {
    window.open('https://twitter.com/intent/tweet', '__blank', 'noopener, noreferrer');
  };

  return (
    <ShareModal
      isOnPremiseDeployment={isOnPremiseDeployment()}
      link={projectLink}
      projectPrivacy={project?.visibility}
      organizationName={organization?.name}
      organizationType={organization?.organizationType}
      isOpen={isOpen}
      onClose={close}
      onSendMail={handleEmail}
      onSendTwitter={handleTwitter}
    />
  );
};
