import React from 'react';

import { noop } from 'lodash';

import { AttributeDefinition } from 'domain/experiment/attribute';
import { GroupByStorageStrategy } from './group-by-model';
import { ColumnIdentifier } from './column/column-model';

export enum GroupViewMode {
  Groups = 'groups',
  List = 'list',
}

export type GroupByContext = {
  id: string;
  isGroupByAvailable: boolean;
  storageStrategy: GroupByStorageStrategy;
  addToGroupBy: (...attrs: AttributeDefinition[]) => void;
  removeFromGroupBy: (column: ColumnIdentifier) => void;
  resetGroupBy: () => void;
  attributesInUse: AttributeDefinition[];
  reorderGroupBy: (attributes: AttributeDefinition[]) => void;
  viewMode: GroupViewMode;
  setIsGroupByEdited: React.Dispatch<React.SetStateAction<boolean>>;
};

export const GroupByContext = React.createContext<GroupByContext>({
  id: '',
  isGroupByAvailable: false,
  storageStrategy: {
    selector: () => undefined,
    currentQuerySelector: () => '',
    actionCreator: () => () => {},
  },
  addToGroupBy: () => undefined,
  resetGroupBy: () => undefined,
  reorderGroupBy: () => undefined,
  attributesInUse: [],
  viewMode: GroupViewMode.List,
  removeFromGroupBy: noop,
  setIsGroupByEdited: noop,
});
