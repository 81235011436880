// Libs
import React from 'react';
import { Button, Layout, Text, bemBlock } from '@neptune/shared/venus-ui';

// App
import { formatDate } from '@neptune/shared/common-util';

// Module
import './ServiceAccountDeactivatedContent.less';

type ServiceAccountDeactivatedContentProps = {
  deactivatedBy?: string;
  deactivatedDate?: Date;
  onReactivateToken?: () => void;
  userComponent: React.FC<{ user: string }>;
};

const block = bemBlock('service-account-deactivated-content');

export const ServiceAccountDeactivatedContent: React.FC<ServiceAccountDeactivatedContentProps> = ({
  deactivatedBy,
  deactivatedDate,
  userComponent: UserWithAvatar,
  onReactivateToken,
}) => {
  if (!deactivatedBy || !deactivatedDate) {
    return null;
  }

  return (
    <Layout.Column
      className={block()}
      alignItems="center"
      justifyContent="center"
      withPadding="md"
      spacedChildren="xs"
    >
      <Text>This account was deactivated</Text>
      <Layout.Row spacedChildren="xs">
        <Text size="sm">{formatDate(deactivatedDate)} by </Text>
        <Text size="sm">
          <UserWithAvatar user={deactivatedBy} />{' '}
        </Text>
      </Layout.Row>
      {onReactivateToken ? (
        <Button
          variant="secondary"
          size="lg"
          onClick={onReactivateToken}
          data-role="reactivate-account"
        >
          Reactivate
        </Button>
      ) : undefined}
    </Layout.Column>
  );
};
