import {
  python,
  pyTorch,
  pyTorchLightning,
  r,
  tensorflowKeras,
  scikitLearn,
  xGBoost,
  optuna,
  otherFrameworks,
} from './frameworks';
export type { Integration } from './frameworks/types';

export const integrations = [
  python,
  r,
  tensorflowKeras,
  pyTorch,
  pyTorchLightning,
  scikitLearn,
  xGBoost,
  optuna,
  otherFrameworks,
];
