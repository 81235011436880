import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';
import {
  Attribute,
  AttributeDefinitionConverter,
  AttributeModelConverter,
  AttributeType,
} from 'domain/experiment/attribute';

type queryUniqueAttributeValues = {
  projectIdentifier: string;
  search?: string;
  limit?: number;
  attributeType: AttributeType;
  attributeName: string;
};

type queryUniqueAttributeValuesResult = {
  attributes: Attribute[];
};

export async function queryUniqueAttributeValues({
  projectIdentifier,
  search,
  limit,
  attributeType,
  attributeName,
}: queryUniqueAttributeValues): Promise<queryUniqueAttributeValuesResult> {
  const attributeFilter = AttributeDefinitionConverter.attributeTypeToApi(attributeType);

  if (!attributeFilter) {
    throw new Error('queryUniqueAttributeValues requires a valid attributeType');
  }

  const result = await leaderboardClient.queryUniqueAttributeValues({
    projectIdentifier,
    query: {
      attributeName,
      attributeType: attributeFilter,
      limit,
      regexValueFilter: search,
    },
  });

  return {
    attributes: result.attributes.map(AttributeModelConverter.attributeFromApiToDomain),
  };
}
