import { isClassicDeployment } from 'common/deploymentModes';
import config from 'config';

declare global {
  interface Window {
    dataLayer?: DataLayer[];
  }
}

type DataLayer = object;

export function pushToDataLayer(data: DataLayer): void {
  if (!isClassicDeployment()) {
    return;
  }

  if (Array.isArray(data)) {
    // GTM ignores tags given as plain array, it has to be arguments-like array with attached invocation metadata
    // eslint-disable-next-line prefer-spread
    pushToDataLayerAsArguments.apply(null, data);
  } else {
    pushToDataLayerAsObject(data);
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function pushToDataLayerAsArguments(...args: any[]) {
  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

function pushToDataLayerAsObject(data: DataLayer): void {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
}

type SendEventToGTMProps = {
  eventName: string;
  senderUsername: string;
  attributes?: Record<string, string>;
};

export async function sendEventToGTM({
  eventName,
  senderUsername,
  attributes,
}: SendEventToGTMProps) {
  if (!config.gtmUrl) {
    return;
  }

  const data = JSON.stringify([
    {
      event_name: eventName,
      user_name: senderUsername,
      timestamp: new Date().toISOString(),
      attributes,
    },
  ]);

  try {
    await fetch(`${config.gtmUrl}/track`, {
      method: 'POST',
      credentials: 'include',
      body: data,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error sending event to GTM');
  }
}
