// Libs
import { isEmpty, isEqual, isUndefined, omitBy, xorWith } from 'lodash';

// App
import { toggleArrayValue } from 'common/array';
import { ArrayElementType } from 'common/utility-types';
import { AttributeType, getReadableAttributeName } from 'domain/experiment/attribute';

// Module
import {
  CriterionType,
  PartialSearchCriterion,
  PartialSearchCriterionWithAttribute,
  PartialSearchCriterionWithAttributeAndType,
  PartialSearchCriterionWithOperator,
  SearchCriterion,
  SearchCriterionWithValue,
  SearchOperator,
  SearchQuery,
} from './search-query-model';
import { operatorNames } from './operator-names';
import { operatorRequiresValue } from './operator-utils';
import { CriterionHelpers } from './criterion/types';
import {
  artifactCriterionHelpers,
  isArtifactCriterion,
} from './criterion/artifact-criterion-helpers';
import { booleanCriterionHelpers, isBooleanCriterion } from './criterion/boolean-criterion-helpers';
import {
  dateTimeCriterionHelpers,
  isDateTimeCriterion,
} from './criterion/date-time-criterion-helpers';
import { isEmptyCriterion } from './criterion/empty-criterion-helpers';
import {
  experimentStateCriterionHelpers,
  isExperimentStateCriterion,
} from './criterion/experiment-state-criterion-helpers';
import { floatCriterionHelpers, isFloatCriterion } from './criterion/float-criterion-helpers';
import {
  floatSeriesCriterionHelpers,
  isFloatSeriesCriterion,
} from './criterion/float-series-criterion-helpers';
import { integerCriterionHelpers, isIntegerCriterion } from './criterion/integer-criterion-helpers';
import { isOwnerCriterion, ownerCriterionHelpers } from './criterion/owner-criterion-helpers';
import { isStringCriterion, stringCriterionHelpers } from './criterion/string-criterion-helpers';
import {
  isStringSeriesCriterion,
  stringSeriesCriterionHelpers,
} from './criterion/string-series-criterion-helpers';
import {
  isStringSetCriterion,
  stringSetCriterionHelpers,
} from './criterion/string-set-criterion-helpers';
import { isStageCriterion, stageCriterionHelpers } from './criterion/stage-criterion-helpers';
import { isTimeCriterion, timeCriterionHelpers } from './criterion/time-criterion-helpers';
import { isSizeCriterion, sizeCriterionHelpers } from './criterion/size-criterion-helpers';
import { criterionHasAttributeAndOperator } from './search-query-model-core-utils';

export function isSearchQuery(item: SearchCriterion | SearchQuery): item is SearchQuery {
  return Array.isArray((item as SearchQuery).criteria);
}

export function isSearchCriterion(item: SearchCriterion | SearchQuery): item is SearchCriterion {
  return item.hasOwnProperty('attribute');
}

export function criterionHasValue(
  criterion: PartialSearchCriterion,
): criterion is SearchCriterionWithValue {
  return (
    criterionHasAttributeAndOperator(criterion) &&
    criterionRequiresValue(criterion) &&
    isCriterionComplete(criterion)
  );
}

export function isCriterionComplete(
  criterion: PartialSearchCriterion,
): criterion is SearchCriterion {
  if (isEmptyCriterion(criterion)) {
    return true;
  }

  const helpers = getCriterionHelpers(criterion);

  return (
    criterionHasAttributeAndOperator(criterion) &&
    (!criterionRequiresValue(criterion) || helpers?.isComplete(criterion) || false)
  );
}

export function criterionRequiresValue(criterion: PartialSearchCriterion): boolean {
  return criterionHasAttributeAndOperator(criterion) && operatorRequiresValue(criterion.operator);
}

export function serializeCriterion(criterion: SearchCriterion): string {
  const { attribute, subproperty, type, operator } = criterion;
  return `${attribute}-${subproperty || ''}-${type}-${operator}-${
    criterionHasValue(criterion) ? criterion.value : ''
  }`;
}

export function getReadableOperator(operator: SearchOperator) {
  return operatorNames[operator] || operator;
}

export function getReadableCriterion(criterion: SearchCriterion): string {
  if (criterionRequiresValue(criterion)) {
    const value = getCriterionReadableValue(criterion);

    return `${getCriterionReadableName(criterion)} ${getReadableOperator(
      criterion.operator,
    )} ${value}`;
  }

  return `${getCriterionReadableName(criterion)} ${getReadableOperator(criterion.operator)}`;
}

export function getCriterionReadableOperator({
  operator,
}: PartialSearchCriterionWithOperator): string {
  return getReadableOperator(operator);
}

export function getCriterionReadableName({
  attribute,
  subproperty,
}: PartialSearchCriterionWithAttributeAndType): string {
  return `${getReadableAttributeName(attribute)}${
    subproperty ? ` | ${subproperty.toUpperCase()}` : ''
  }`;
}

export function getCriterionReadableValue(criterion: SearchCriterion): string {
  if (!criterionRequiresValue(criterion)) {
    return '';
  }

  const helpers = getCriterionHelpers(criterion);

  return helpers?.getReadableValue(criterion) || '';
}

export function getShortCriterionReadableName({
  attribute,
  subproperty,
}: PartialSearchCriterionWithAttribute): string {
  const [name, ...path] = getReadableAttributeName(attribute).split('/').reverse();

  return `${path.length > 0 ? '../' : ''}${name}${
    subproperty ? ` | ${subproperty.toUpperCase()}` : ''
  }`;
}

export function getShortCriterionReadableValue(criterion: SearchCriterion): string {
  if (!criterionRequiresValue(criterion)) {
    return '';
  }

  const helpers = getCriterionHelpers(criterion);

  return helpers?.getShortReadableValue(criterion) || '';
}

export function getReadableSearchQuery(query: SearchQuery, wrapInParens?: boolean): string {
  const content = query.criteria
    .map((entry) => {
      if (isSearchQuery(entry)) {
        return getReadableSearchQuery(entry, true);
      }

      return getReadableCriterion(entry);
    })
    .join(` ${query.operator.toUpperCase()} `);

  return wrapInParens ? `(${content})` : content;
}

export function areSearchQueriesEqual(first: SearchQuery, second: SearchQuery): boolean {
  return (
    first.operator === second.operator &&
    isEmpty(
      xorWith(first.criteria, second.criteria, (a, b) => {
        if (isSearchQuery(a) && isSearchQuery(b)) {
          return areSearchQueriesEqual(a, b);
        }

        return isEqual(omitBy(a, isUndefined), omitBy(b, isUndefined));
      }),
    )
  );
}

export function toggleCriterionValue<T extends { value: any[] }>(
  criterion: T,
  value: ArrayElementType<T['value']>,
): T {
  return {
    ...criterion,
    value: toggleArrayValue(criterion.value || [], value),
  };
}

export function isSearchQueryComplete(query: SearchQuery): boolean {
  if (!query.criteria.length) {
    return false;
  }

  return query.criteria.every((criterion) => {
    return isSearchQuery(criterion)
      ? isSearchQueryComplete(criterion)
      : isCriterionComplete(criterion);
  });
}

export function getCriterionAttributeType(
  criterion: PartialSearchCriterionWithAttributeAndType,
): AttributeType {
  return criterionTypeToAttributeTypeMap[criterion.type];
}

const criterionTypeToAttributeTypeMap: Record<CriterionType, AttributeType> = {
  artifact: 'artifact',
  bool: 'bool',
  datetime: 'datetime',
  experimentState: 'experimentState',
  float: 'float',
  floatSeries: 'floatSeries',
  gitRef: 'gitRef',
  int: 'int',
  owner: 'string',
  stage: 'string',
  string: 'string',
  stringSeries: 'stringSeries',
  stringSet: 'stringSet',
};

function getCriterionHelpers(
  criterion: PartialSearchCriterion,
): CriterionHelpers<SearchCriterion> | undefined {
  if (isArtifactCriterion(criterion)) {
    return artifactCriterionHelpers;
  }

  if (isBooleanCriterion(criterion)) {
    return booleanCriterionHelpers;
  }

  if (isDateTimeCriterion(criterion)) {
    return dateTimeCriterionHelpers;
  }

  if (isTimeCriterion(criterion)) {
    return timeCriterionHelpers;
  }

  if (isExperimentStateCriterion(criterion)) {
    return experimentStateCriterionHelpers;
  }

  if (isSizeCriterion(criterion)) {
    return sizeCriterionHelpers;
  }

  if (isFloatCriterion(criterion)) {
    return floatCriterionHelpers;
  }

  if (isFloatSeriesCriterion(criterion)) {
    return floatSeriesCriterionHelpers;
  }

  if (isIntegerCriterion(criterion)) {
    return integerCriterionHelpers;
  }

  if (isOwnerCriterion(criterion)) {
    return ownerCriterionHelpers;
  }

  if (isStageCriterion(criterion)) {
    return stageCriterionHelpers;
  }

  if (isStringCriterion(criterion)) {
    return stringCriterionHelpers;
  }

  if (isStringSeriesCriterion(criterion)) {
    return stringSeriesCriterionHelpers;
  }

  if (isStringSetCriterion(criterion)) {
    return stringSetCriterionHelpers;
  }
}
