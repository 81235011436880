// Libs
import React from 'react';
import { Layout, Text } from '@neptune/shared/venus-ui';

// App
import { formatDate } from '@neptune/shared/common-util';

// Module
type ServiceAccountCreatedByProps = {
  createdBy: string;
  createdDate: Date;
  userComponent: React.FC<{ user: string }>;
};

export const ServiceAccountCreatedBy: React.FC<ServiceAccountCreatedByProps> = ({
  createdBy,
  createdDate,
  userComponent: UserWithAvatar,
}) => {
  return (
    <Text size="sm">
      <Layout.Row spacedChildren="xs" wrap="wrap">
        <Text>Created {formatDate(createdDate)} by </Text>
        <UserWithAvatar user={createdBy} />
      </Layout.Row>
    </Text>
  );
};
