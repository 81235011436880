import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';
import { Attribute, AttributeModelConverter } from 'domain/experiment/attribute';

type QueryAttributesRequest = {
  fields: string[];
  limit?: number;
  nextPageToken?: string;
  projectIdentifier: string;
  shortIds: string[];
};
export type QueryAttributesResponse = {
  nextPageToken?: string;
  result: { entityId: string; shortId: string; attributes: Attribute[] }[];
};

export async function queryAttributes({
  fields: attributeNamesFilter,
  limit,
  nextPageToken,
  projectIdentifier,
  shortIds: experimentIdsFilter,
}: QueryAttributesRequest): Promise<QueryAttributesResponse> {
  const nextPage =
    limit === undefined && nextPageToken === undefined ? undefined : { limit, nextPageToken };

  const result = await leaderboardClient.queryAttributesWithinProject({
    projectIdentifier,
    query: {
      experimentIdsFilter,
      attributeNamesFilter,
      nextPage,
    },
  });

  return {
    nextPageToken: result.nextPage.nextPageToken,
    result: result.entries.map(
      ({ attributes, experimentShortId: shortId, experimentId: entityId }) => ({
        entityId,
        shortId,
        attributes: attributes.map(AttributeModelConverter.attributeFromApiToDomain),
      }),
    ),
  };
}

const maxCellDataToFetchInSingleRequest = 10_000;

/**
 * Fetches all attributes for given fields and shortIds. Bare endpoint returns up to
 * `maxCellDataToFetchInSingleRequest` (10_000) cells per request. This function handles
 * pagination and returns all cells for given fields and shortIds.
 */
export async function queryFieldsFromAllPages({
  fields,
  projectIdentifier,
  shortIds,
}: Pick<QueryAttributesRequest, 'fields' | 'projectIdentifier' | 'shortIds'> & {
  pagesToFetch?: number;
}): Promise<QueryAttributesResponse['result']> {
  const shortIdChunkSize = Math.floor(maxCellDataToFetchInSingleRequest / fields.length);
  const totalChunks = Math.ceil(shortIds.length / shortIdChunkSize);
  const amortizedChunkSize = Math.ceil(shortIds.length / totalChunks);

  const result = await Promise.all(
    Array.from({ length: totalChunks }).map((_, i) =>
      queryAttributes({
        fields,
        projectIdentifier,
        shortIds: shortIds.slice(i * amortizedChunkSize, (i + 1) * amortizedChunkSize),
      }),
    ),
  );

  return result.flatMap(({ result: pageResult }) => pageResult);
}
