/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrganizationTraitName {
    ProjectVisibilityRestricted = 'projectVisibilityRestricted',
    PlanUpgrade = 'planUpgrade',
    ServiceAccounts = 'serviceAccounts',
    OperationsLimit = 'operationsLimit',
    UiWorkspaceSwitching = 'uiWorkspaceSwitching',
    ActiveProjects = 'activeProjects',
    DashboardsLimits = 'dashboardsLimits',
    TableViewsLimits = 'tableViewsLimits',
    ModelRegistryLimits = 'modelRegistryLimits'
}

export function OrganizationTraitNameFromJSON(json: any): OrganizationTraitName {
    return OrganizationTraitNameFromJSONTyped(json, false);
}

export function OrganizationTraitNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationTraitName {
    return json as OrganizationTraitName;
}

export function OrganizationTraitNameToJSON(value?: OrganizationTraitName | null): any {
    return value as any;
}

