import React from 'react';
import { useGlobalFullscreenProvider } from '@neptune/shared/common-business-logic';
import { GlobalFullscreenContext } from '@neptune/shared/common-domain';

export const GlobalFullscreenProvider: React.FC<{
  onFullscreen?: () => void;
  onRestore?: () => void;
}> = ({ children, onFullscreen, onRestore }) => {
  const { globalFullscreenContext } = useGlobalFullscreenProvider({
    onFullscreen,
    onRestore,
  });
  return <GlobalFullscreenContext.Provider value={globalFullscreenContext} children={children} />;
};
