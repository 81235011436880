import { GRID_LAYOUT_COLUMNS, LayoutRect } from '@neptune/shared/grid-layout-domain';

export function getValidLayoutXAndWidth(
  layout: LayoutRect,
  minW: number,
  gridWidth = GRID_LAYOUT_COLUMNS,
) {
  const initialX = layout.x;
  const bestWidth = Math.max(Math.min(layout.w, gridWidth), minW);

  if (initialX + bestWidth > gridWidth) {
    // This could happen if we increase minW for the widget.
    // We need to make sure the layout is consistent, even if messy.
    const w = Math.max(minW, gridWidth - initialX); // First try to squash the layout from the right.
    const x = gridWidth - w; // Push x to the left as much as necessary.
    return { x, w };
  }

  return { x: initialX, w: bestWidth };
}
