import { catchError, from, pipe, switchMap, tap } from 'rxjs';

export const handleFetch = <T, R, E = any>({
  resolver,
  onFetchSucceed,
  onFetchStarted,
  onFetchFailed,
}: {
  resolver: (params: T) => Promise<R>;
  onFetchSucceed?: (value: R, query: T) => void;
  onFetchStarted?: (query: T) => void;
  onFetchFailed?: (value: E | undefined, query: T) => void;
}) =>
  pipe(
    tap<T>((query) => onFetchStarted?.(query)),
    switchMap((query: T) =>
      from(resolver(query)).pipe(
        tap((value) => onFetchSucceed?.(value, query)),
        catchError((error: E | undefined) => {
          onFetchFailed?.(error, query);
          throw error;
        }),
      ),
    ),
  );
