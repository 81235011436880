import {
  selectIsReadonlyDueToMonitoring,
  selectIsReadonlyDueToPayment,
  selectIsReadonlyDueToStorage,
  selectIsReadonlyDueToTrial,
  selectShowMemberMonitoringBanner,
  selectShowMemberPaymentBanner,
  selectShowMemberStorageBanner,
  selectShowMemberTrialBanner,
} from './selectors';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

export function useMemberBannersVisibility(
  organizationName: string,
  hasCreditCardAttached: boolean | undefined,
) {
  const showTrialBanner = useSelector((state: AppState) =>
    selectShowMemberTrialBanner(state, organizationName),
  );
  const showPaymentBanner = useSelector((state: AppState) =>
    selectShowMemberPaymentBanner(state, organizationName),
  );
  const showMonitoringBanner = useSelector((state: AppState) =>
    selectShowMemberMonitoringBanner(state, organizationName),
  );
  const showStorageBanner = useSelector((state: AppState) =>
    selectShowMemberStorageBanner(state, organizationName),
  );

  const isReadonlyDueToTrial = useSelector((state: AppState) =>
    selectIsReadonlyDueToTrial(state, organizationName),
  );
  const isReadonlyDueToPayment = useSelector((state: AppState) =>
    selectIsReadonlyDueToPayment(state, organizationName),
  );
  const isReadonlyDueToMonitoring = useSelector((state: AppState) =>
    selectIsReadonlyDueToMonitoring(state, organizationName),
  );
  const isReadonlyDueToStorage = useSelector((state: AppState) =>
    selectIsReadonlyDueToStorage(state, organizationName),
  );

  return {
    showMemberTrialErrorBanner: isReadonlyDueToTrial && showTrialBanner,
    showMemberCardErrorBanner:
      hasCreditCardAttached === false && isReadonlyDueToPayment && showPaymentBanner,
    showMemberPaymentErrorBanner:
      hasCreditCardAttached && isReadonlyDueToPayment && showPaymentBanner,
    showMemberMonitoringErrorBanner: isReadonlyDueToMonitoring && showMonitoringBanner,
    showMemberStorageErrorBanner: isReadonlyDueToStorage && showStorageBanner,
    showMemberTrialWarnBanner: !isReadonlyDueToTrial && showTrialBanner,
    showMemberCardWarnBanner:
      hasCreditCardAttached === false && !isReadonlyDueToPayment && showPaymentBanner,
    showMemberPaymentWarnBanner:
      hasCreditCardAttached && !isReadonlyDueToPayment && showPaymentBanner,
    showMemberMonitoringWarnBanner: !isReadonlyDueToMonitoring && showMonitoringBanner,
    showMemberStorageWarnBanner: !isReadonlyDueToStorage && showStorageBanner,
  };
}
