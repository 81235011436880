// Libs
import { createSelector } from 'reselect';


// App
import getParentState from '../selectors';
import { getCurrentUser } from 'state/selectors';


// Module
export default function getState(state) {
  return getParentState(state).users;
}


const usersEntries = (state) => getState(state).entries;

export const getUsers = createSelector(
  [usersEntries, getCurrentUser],
  (users, currentUser) => {
    if (!currentUser || !users[currentUser.username]) {
      return users;
    }

    // This is what actionTypes.fetchUsers.success returns for single user
    const {
      username,
      shortInfo,
      biography,
      avatarUrl,
      avatarSource,
      links,
    } = currentUser;

    return {
      ...users,
      [username]: {
        username,
        shortInfo,
        biography,
        avatarUrl,
        avatarSource,
        links,
      },
    };
  },
);


export function getUser(state, username) {
  return getUsers(state)[username];
}
