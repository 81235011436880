import { mapValues } from 'lodash';
import { createErrorDescriptor } from 'common/error-handler';
import { fetchStatus as FetchStatus } from 'state/fetch-status';
import { ExperimentAttributeOperation, OperationActions } from 'state/attributes/actions';
import {
  BaseLeaderboardEntry,
  LeaderboardListGroup,
  LeaderboardListSuggestion,
} from '@neptune/shared/leaderboard-domain';
import { LeaderboardsActions, LeaderboardActionTypes } from './leaderboards-actions';
import { reconcileArrayWithId } from '@neptune/shared/common-util';

export type LeaderboardsState = Record<string, LeaderboardState | undefined>;

export type LeaderboardState<T extends BaseLeaderboardEntry = BaseLeaderboardEntry> = {
  fetchStatus: FetchStatus;
  entries: T[];
  groups?: LeaderboardListGroup[];
  matchingItemCount: number;
  suggestions?: LeaderboardListSuggestion[];
  totalGroupCount?: number;
  error?: any;
  lastDeclinedSuggestionTimestamp?: number;
};

const initialState: LeaderboardsState = {};

export const leaderboardInitialState: LeaderboardState = {
  fetchStatus: FetchStatus.NONE,
  entries: [],
  groups: [],
  suggestions: [],
  matchingItemCount: 0,
  totalGroupCount: undefined,
  error: undefined,
  lastDeclinedSuggestionTimestamp: undefined,
};

const leaderboardReducer = (
  state = leaderboardInitialState,
  action: LeaderboardsActions | OperationActions,
): LeaderboardState => {
  switch (action.type) {
    case LeaderboardActionTypes.fetchStart: {
      return {
        ...state,
        fetchStatus: FetchStatus.PENDING,
        error: undefined,
      };
    }

    case LeaderboardActionTypes.fetchSuccess: {
      const { entries, groups, suggestions, matchingItemCount, totalGroupCount } = action.payload;

      const stabilizedEntries = reconcileArrayWithId(entries, state.entries);
      const stabilizedGroups = reconcileArrayWithId(groups, state.groups);

      return {
        ...state,
        entries: stabilizedEntries,
        groups: stabilizedGroups,
        suggestions,
        matchingItemCount,
        totalGroupCount,
        fetchStatus: FetchStatus.SUCCESS,
        error: undefined,
      };
    }

    case LeaderboardActionTypes.fetchFail: {
      return {
        ...state,
        fetchStatus: FetchStatus.FAILED,
        error: action.payload.error && createErrorDescriptor(action.payload.error),
      };
    }

    case LeaderboardActionTypes.declineSuggestion: {
      const { suggestion, timestamp } = action.payload;

      return {
        ...state,
        lastDeclinedSuggestionTimestamp: timestamp,
        suggestions: state.suggestions?.filter(
          ({ type, name }) => !(type === suggestion.type && name === suggestion.name),
        ),
      };
    }

    case ExperimentAttributeOperation.success: {
      const { experiment } = action.payload;
      const entries = state.entries.map((entry) => {
        if (entry.id === experiment.id) {
          const newAttributes = entry.attributes.map((attribute) => {
            const updatedAttribute = experiment.attributes.find(
              (expAttr) =>
                expAttr.attributeName === attribute.attributeName &&
                expAttr.attributeType === attribute.attributeType,
            );

            return updatedAttribute ?? attribute;
          });

          return {
            ...experiment,
            attributes: newAttributes,
          };
        }

        return entry;
      });

      return {
        ...state,
        entries,
      };
    }

    default:
      return state;
  }
};

export const leaderboardsReducer = (
  state: LeaderboardsState = initialState,
  action: LeaderboardsActions | OperationActions,
): LeaderboardsState => {
  switch (action.type) {
    case LeaderboardActionTypes.declineSuggestion:
    case LeaderboardActionTypes.fetchFail:
    case LeaderboardActionTypes.fetchStart:
    case LeaderboardActionTypes.fetchSuccess:
      return {
        ...state,
        [action.id]: leaderboardReducer(state[action.id], action),
      };

    case ExperimentAttributeOperation.success:
      return mapValues(state, (substate) => leaderboardReducer(substate, action));
  }

  return state;
};
