import { WorkspaceStatus } from '@neptune/pricing-workspace-status-domain';

function storageTrigger(status: WorkspaceStatus, usageRatio: number) {
  return (
    (status.storageBytesLimit || 0) > 0 &&
    status.storageBytesAvailable / status.storageBytesLimit <= 1 - usageRatio
  );
}

export const storageAdminTrigger = (status: WorkspaceStatus): boolean =>
  storageTrigger(status, 0.75);
export const storageMemberTrigger = (status: WorkspaceStatus): boolean =>
  storageTrigger(status, 0.9);
export const storageReadOnlyTrigger = (status: WorkspaceStatus): boolean =>
  storageTrigger(status, 1);
