import React from 'react';
import type { IntegerAttribute } from 'domain/experiment/attribute';
import { PropertyRow } from 'components/layouts/rows';
import { formatMonitoringShort } from '@neptune/shared/common-util';

type MonitoringTimeRendererProps = {
  attribute?: IntegerAttribute;
  labelWidth?: string;
  label?: string;
  'data-name'?: string;
};

export const MonitoringTimeRenderer: React.FC<MonitoringTimeRendererProps> = ({
  attribute,
  labelWidth = '100px',
  label,
  'data-name': dataName,
}) => {
  if (!attribute) {
    return null;
  }

  const monitoringTime = attribute.value;

  return (
    <PropertyRow
      value={formatMonitoringShort(monitoringTime / 60)}
      labelWidth={labelWidth}
      label={label}
      data-name={dataName}
    />
  );
};
