import React from 'react';
import {
  Entity,
  EntityDetailsModalData,
  EntityTypeToDetailsModalName,
  getEntityShortId,
  getEntityTrashed,
} from '@neptune/shared/entity-domain';
import { DetailsDropdownMenuContent } from '@neptune/entity-common-ui';
import { useEntityControlActions } from '@neptune/entity-common-business-logic';
import { WithPermissions } from '@neptune/shared/project-domain';
import { useGenericModal } from '@neptune/shared/common-business-logic';
import { HiddenColorInput } from '@neptune/shared/common-ui';
import { getNameFromAttributes } from 'domain/experiment/attribute';
import { useRunsColorsContext } from '@neptune/compare-business-logic';

export type DetailsDropdownMenuContentContainerProps = {
  onCopy?: () => void;
  collapse: () => void;
  requestTrash: () => void;
  entityWithPermissions: WithPermissions<Entity>;
};

export const DetailsDropdownMenuContentContainer = ({
  onCopy,
  collapse,
  requestTrash,
  entityWithPermissions,
}: DetailsDropdownMenuContentContainerProps) => {
  const trashed = getEntityTrashed(entityWithPermissions);
  const { openWithData } = useGenericModal<EntityDetailsModalData>(
    EntityTypeToDetailsModalName[entityWithPermissions.type],
  );
  const { getColor, setColor } = useRunsColorsContext();

  const shortId = getEntityShortId(entityWithPermissions);
  const experimentName = getNameFromAttributes(entityWithPermissions.attributes);
  const runIdentificationKey = experimentName || shortId;

  const handleColorChange = React.useCallback(
    (color: string) => {
      if (!runIdentificationKey) {
        return;
      }

      setColor(runIdentificationKey, color);
      collapse();
    },
    [runIdentificationKey, setColor, collapse],
  );

  const handleCopy = React.useCallback(async () => {
    if (!onCopy) {
      return;
    }

    onCopy();
    collapse();
  }, [onCopy, collapse]);

  const handleShowDetails = React.useCallback(() => {
    openWithData({ entityId: entityWithPermissions.id });
    collapse();
  }, [collapse, entityWithPermissions.id, openWithData]);

  const { onAbort, onRestore, onStop } = useEntityControlActions(entityWithPermissions);

  const canManageTags = entityWithPermissions.canModify && !trashed;
  const canRemove = entityWithPermissions.canDelete && !trashed;

  return (
    <DetailsDropdownMenuContent
      collapse={collapse}
      colorInput={
        <HiddenColorInput
          defaultValue={runIdentificationKey ? getColor(runIdentificationKey) : undefined}
          onChange={handleColorChange}
        />
      }
      entityType={entityWithPermissions.type}
      onShowDetails={handleShowDetails}
      onRestore={onRestore}
      onAbort={onAbort}
      onStop={onStop}
      onCopy={onCopy ? handleCopy : undefined}
      onRemove={canRemove ? requestTrash : undefined}
      onManageTags={canManageTags ? handleShowDetails : undefined}
    />
  );
};
