import { createErrorDescriptor } from 'common/error-handler';

export function composeInvalidInviteeErrorMessage<ERR = any>(error: ERR) {
  const USER_ALREADY_IN_ENTITY = 409;
  const USER_ALREADY_AN_ADMIN = 412;
  const errorDescriptor = createErrorDescriptor(error);

  if (
    errorDescriptor &&
    (errorDescriptor.code === USER_ALREADY_IN_ENTITY ||
      errorDescriptor.code === USER_ALREADY_AN_ADMIN)
  ) {
    return errorDescriptor.message || 'Invalid invitee';
  }
}
