import { WidgetDefinition } from '../core/domain/widget-definition';
import { Widget } from '../core/widget';

export const interactiveTableWidgetName = 'Table';

export function isInteractiveTableWidget(widget: Widget): boolean {
  return widget.type === 'interactiveTable';
}

export const interactiveTableWidgetDefinition: WidgetDefinition = {
  type: 'interactiveTable',
  name: interactiveTableWidgetName,
};
