import { Configuration, DefaultApi } from 'generated/admin-client';

import { defaultApiClientConfiguration } from '@neptune/shared/core-apis-common-domain';

export * from 'generated/admin-client/apis';
export * from 'generated/admin-client/models';

const config = new Configuration(defaultApiClientConfiguration);

export const adminClient = new DefaultApi(config);
