/* tslint:disable */
/* eslint-disable */
/**
 * scissors
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExpandResponse
 */
export interface ExpandResponse {
    /**
     * 
     * @type {string}
     * @memberof ExpandResponse
     */
    content: string;
}

export function ExpandResponseFromJSON(json: any): ExpandResponse {
    return ExpandResponseFromJSONTyped(json, false);
}

export function ExpandResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExpandResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': json['content'],
    };
}

export function ExpandResponseToJSON(value?: ExpandResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
    };
}


