import React from 'react';
import { Icon, Layout, Menu, Text } from '@neptune/shared/venus-ui';

import { VersionTab } from '@neptune/model-registry-model-version-domain';

type Item = Pick<VersionTab, 'id' | 'glyph' | 'label' | 'badge'>;
export type LinkRenderer = (item: Item, children: React.ReactNode) => React.ReactElement;

type VersionMenuProps = {
  items: Item[];
  renderLink: LinkRenderer;
  expanded: boolean;
  isActive: (itemId: string) => boolean;
};

export const VersionMenu: React.FC<VersionMenuProps> = ({
  items,
  renderLink,
  expanded,
  isActive,
}) => {
  return (
    <Menu>
      {items.map((item) => {
        const label = expanded ? (
          <Text fontWeight="normal" title={item.label} ellipsis>
            {item.label}
          </Text>
        ) : null;

        return renderLink(
          item,
          <Menu.Item
            size="lg"
            active={isActive(item.id)}
            withGutter={expanded ? 'md' : 'sm'}
            title={item.label}
          >
            <Layout.Column width={18} alignItems="center" span="auto">
              <Icon glyph={item.glyph} />
            </Layout.Column>
            {label}
            <Layout.Fill />
            {expanded && item.badge}
          </Menu.Item>,
        );
      })}
    </Menu>
  );
};
