import type { GlyphName } from '@neptune/shared/core-glyphs-domain';

export const dashboardIcons: GlyphName[] = [
  'bell',
  'calendar',
  'caret-square-right',
  'chart-line',
  'check-circle',
  'clipboard',
  'clock',
  'clone',
  'code',
  'cog',
  'comment',
  'credit-card',
  'crown',
  'database',
  'exclamation-triangle',
  'external-link',
  'file-code',
  'file-export',
  'flask',
  'folder',
  'globe-americas',
  'grid',
  'image',
  'light-bulb',
  'link',
  'list',
  'lock',
  'paragraph',
  'pen',
  'question-circle',
  'random',
  'rocket',
  'sliders-h',
  'tag',
  'thumbtack',
  'undo',
  'upload',
  'users',
];
