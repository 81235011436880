import { useRouter } from 'react-router5';

import { fetchStatus, isFetchStatusFailed } from 'state/fetch-status';
import { createErrorDescriptor } from 'common/error-handler';
import { authClient } from '@neptune/shared/core-auth-domain';

type UseNotFoundInput = {
  fetchStatus: fetchStatus;
  error: unknown;
};

export function useNoAccess({ fetchStatus, error }: UseNotFoundInput): void {
  const router = useRouter();

  if (!isFetchStatusFailed(fetchStatus)) {
    return;
  }

  const errorDescriptor = createErrorDescriptor(error);

  if (errorDescriptor.code === 401 || errorDescriptor.code === 403) {
    if (!authClient.authenticated) {
      authClient.login();
    } else {
      // omit solo/enterprise context
      router.navigate('home', {}, { replace: true });
    }
  }
}
