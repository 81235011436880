import React from 'react';
import { Link } from '@neptune/shared/venus-ui';
import { RouterLink } from '@neptune/shared/routing-ui';

// Module
type UpgradeSubscriptionRoute = {
  routeName: string;
  routeParams: {
    organizationName: string;
  };
};

export type UpgradePlanLinkProps = {
  workspaceName: string;
  onClick?(event: React.MouseEvent): void;
};

export const UpgradePlanLink: React.FC<UpgradePlanLinkProps> = ({
  children,
  onClick,
  workspaceName,
}) => {
  return (
    <Link<UpgradeSubscriptionRoute>
      data-role="upgrade-plan-link"
      component={RouterLink}
      routeName="organization.subscription"
      routeParams={{ organizationName: workspaceName }}
      children={children}
      onClick={onClick}
    />
  );
};
