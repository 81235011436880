import { Observable } from 'rxjs';

import config from 'config';
import { authClient } from '@neptune/shared/core-auth-domain';
import { createWebSocketStream } from '@neptune/shared/core-websockets-util';

const protocol = config.neptuneApiSecure ? 'wss' : 'ws';
const basePath = config.neptuneApiBasePath;
const port = config.neptuneApiWSPort;

type ProjectId = string;

export type ProjectNotification = {
  messageType: string;
  messageBody: any;
};

const projectDataStreams: Record<ProjectId, Observable<any> | undefined> = {};

type GetProjectNotificationStreamParams = {
  projectId: ProjectId;
  getAccessToken?: () => string | undefined;
};

export function getProjectNotificationStream<T extends ProjectNotification>({
  projectId,
  getAccessToken = () => authClient.token,
}: GetProjectNotificationStreamParams): Observable<T> {
  const url = buildUrl(protocol, basePath, port, getAccessToken(), projectId);

  if (isProjectStreamCreated(projectId)) {
    return getProjectStream<T>(projectId);
  }

  const projectnNotifications$ = createWebSocketStream<T>({
    url,
    identifier: `project_${projectId}`,
    retryBackoffConfig: {
      backoffDelay: undefined,
      initialInterval: 250,
      maxInterval: 300_000,
      maxRetries: 100,
      resetOnSuccess: true,
    },
  });

  addProjectStream(projectId, projectnNotifications$);

  return projectnNotifications$;
}

function isProjectStreamCreated(projectId: string): boolean {
  return getProjectStream(projectId) != null;
}

function getProjectStream<T extends ProjectNotification>(projectId: string): Observable<T> {
  return projectDataStreams[projectId] as Observable<T>;
}

function addProjectStream(projectId: string, stream$: Observable<any>) {
  projectDataStreams[projectId] = stream$;
}

function buildUrl(
  protocol: string,
  basePath: string,
  port: string,
  token: string | undefined,
  projectId: string,
): string {
  const prefix = config.neptuneApiWSBasePath;
  const origin = `${protocol}://${basePath}:${port}`;
  const params = token ? `token=${token}` : '';

  return `${origin}${prefix}/project/${projectId}/updates?${params}`;
}
