import { AttributeDefinition } from 'domain/experiment/attribute';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentEntityAttributeDefinitions } from './redux/actions';
import {
  getCurrentEntityAttributeDefinitions,
  getCurrentEntityAttributeDefinitionsError,
  getCurrentEntityAttributeDefinitionsStatus,
} from './redux/selectors';

/**
 * @deprecated this hook fetches all attrs definitions for the current entity. The payload may be huge for big entities (500K+ attrs).
 * Do not use it and fetch only needed attributes instead for your use case.
 */
export const useEntityAttributeDefinitions = (
  organizationName: string,
  projectName: string,
  runIdentificationKey?: string,
) => {
  const dispatch = useDispatch();

  const attributeDefinitions: AttributeDefinition[] | undefined = useSelector(
    getCurrentEntityAttributeDefinitions,
  );
  const fetchStatus = useSelector(getCurrentEntityAttributeDefinitionsStatus);
  const error = useSelector(getCurrentEntityAttributeDefinitionsError);

  const fetchAttributeDefinitions = React.useCallback(() => {
    if (!runIdentificationKey) {
      return;
    }

    dispatch(
      fetchCurrentEntityAttributeDefinitions({
        organizationName,
        projectName,
        runIdentificationKey,
      }),
    );
  }, [dispatch, organizationName, projectName, runIdentificationKey]);

  return {
    attributeDefinitions,
    error,
    fetchAttributeDefinitions,
    fetchStatus,
  };
};

/**
 * @deprecated this hook fetches all attrs definitions for the current entity. The payload may be huge for big entities (500K+ attrs).
 * Do not use it and fetch only needed attributes instead for your use case.
 */
export const useEntityAttributeDefinitionsWithFetching = (
  organizationName: string,
  projectName: string,
  runIdentificationKey?: string,
) => {
  const result = useEntityAttributeDefinitions(organizationName, projectName, runIdentificationKey);
  const { fetchAttributeDefinitions } = result;

  React.useEffect(fetchAttributeDefinitions, [fetchAttributeDefinitions]);

  return result;
};
