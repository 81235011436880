import React from 'react';
import { Layout, Text } from '@neptune/shared/venus-ui';
import { EmptyView, EmptyViewButtonData } from '@neptune/shared/common-ui';
import emptyStateIcon from '../assets/empty-state-service-accounts.svg';

export enum ProjectEmptyStateCtaType {
  manage = 'manage',
  notify = 'notify',
}

type ProjectEmptyStateProps = {
  cta: ProjectEmptyStateCtaType;
  notifyRecipients?: string[];
  onManage?: () => void;
};

export const ProjectEmptyState: React.FC<ProjectEmptyStateProps> = ({
  cta,
  notifyRecipients,
  onManage,
}) => {
  const title = 'Assign service accounts to your project!';
  const description = (
    <Layout.Column spacedChildren="md" alignItems="center">
      <Text>
        A service account is a special type of Neptune account intended to represent a non-human
        user that needs to access Neptune such as a CI/CD pipeline or a report generating script.
      </Text>
      <Text
        children={
          cta === ProjectEmptyStateCtaType.manage
            ? 'Go to your workspace settings and create the first one.'
            : 'Service accounts can be created only by workspace Admins.'
        }
      />
    </Layout.Column>
  );

  const buttons: EmptyViewButtonData[] = [];

  if (cta === ProjectEmptyStateCtaType.manage && onManage) {
    buttons.push({
      caption: 'Manage service accounts',
      'data-role': 'manage-service-accounts',
      onClick: onManage,
    });
  }

  if (cta === ProjectEmptyStateCtaType.notify && notifyRecipients?.length) {
    buttons.push({
      caption: 'Notify Admin',
      'data-role': 'service-accounts-notify',
      href: `mailto:${notifyRecipients.join()}`,
    });
  }

  const icon = <img src={emptyStateIcon} alt="" />;

  return (
    <EmptyView
      variant="legacy"
      title={title}
      icon={icon}
      description={description}
      buttons={buttons}
      data-role="empty-state"
    />
  );
};
