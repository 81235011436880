// Libs
import React from 'react';

// App
import { StageChangeConfirmation } from '@neptune/model-registry-common-ui';
import {
  StageConverter,
  getEntityModelId,
  getEntityShortId,
  getEntityStage,
} from '@neptune/shared/entity-domain';
import { useStageChangeConfirmationModal } from '@neptune/model-registry-common-business-logic';

// Module
export const StageChangeConfirmationContainer: React.FC = () => {
  const { isOpen, closeModal, data } = useStageChangeConfirmationModal();
  const { entity, targetStage } = data || {};
  const modelVersionShortId = entity ? getEntityShortId(entity) : undefined;
  const modelShortId = entity ? getEntityModelId(entity) : undefined;
  const stageText = entity ? getEntityStage(entity) : undefined;
  const stage = stageText !== undefined ? StageConverter.stringToStage(stageText) : undefined;

  const execute = React.useCallback(async () => {
    if (!targetStage || !data) {
      return;
    }

    await data.onEntityChange({
      stage: targetStage,
    });

    closeModal();
  }, [targetStage, closeModal, data]);

  if (!stage || !modelVersionShortId || !targetStage) {
    return null;
  }

  return (
    <StageChangeConfirmation
      isOpen={isOpen}
      cancel={closeModal}
      execute={execute}
      fromStage={stage}
      targetStage={targetStage}
      modelVersionShortId={modelVersionShortId}
      modelShortId={modelShortId}
    />
  );
};
