import React from 'react';
import { useAsyncValue } from '@neptune/shared/common-util';
import { getProjectStorage } from '@neptune/project-domain';

export const useGetProjectStorage = (projectIdentifier: string) => {
  const getProjectStorageHandler = React.useCallback(
    async () => getProjectStorage(projectIdentifier),
    [projectIdentifier],
  );

  return useAsyncValue({ resolver: getProjectStorageHandler });
};

export const useHitProjectStorageLimit = (projectIdentifier: string) => {
  const getReachedProjectStorageLimitHandler = React.useCallback(async () => {
    const { storageLimit, storageUsage } = await getProjectStorage(projectIdentifier);

    if (storageLimit === undefined) {
      return false;
    }

    return storageUsage >= storageLimit;
  }, [projectIdentifier]);

  return useAsyncValue({ resolver: getReachedProjectStorageLimitHandler });
};
