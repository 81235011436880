import {
  backendClient,
  MonitoringTimePackageDTO,
  MonitoringTimePackageListDTO,
} from '@neptune/shared/core-apis-backend-domain';

export type MonitoringTimePackage = {
  minutesInPackage: number;
  expirationDate: Date;
  description?: string;
  label?: string;
  minutesUsed: number;
  creationDate: Date;
  priceInCents: number;
};

export type MonitoringTimePackageList = {
  entries: MonitoringTimePackage[];
  matchingItemCount: number;
  totalItemCount: number;
};

type FetchMonitoringTimePackageListRequestParams = {
  organizationIdentifier: string;
  limit: number;
  offset: number;
  dateMin?: string;
  dateMax?: string;
};

export async function fetchMonitoringTimePackageList(
  params: FetchMonitoringTimePackageListRequestParams,
): Promise<MonitoringTimePackageList> {
  const result = await backendClient.monitoringTimePackages({
    ...params,
    dateMin: params.dateMin ? new Date(params.dateMin) : undefined,
    dateMax: params.dateMax ? new Date(params.dateMax) : undefined,
  });

  return monitoringTimePackageListFromApiToDomain(result);
}

function monitoringTimePackageListFromApiToDomain(
  source: MonitoringTimePackageListDTO,
): MonitoringTimePackageList {
  return {
    entries: source.packages.map(monitoringTimePackageFromApiToDomain),
    matchingItemCount: source.matchingItemCount,
    totalItemCount: source.totalItemCount,
  };
}

function monitoringTimePackageFromApiToDomain(
  source: MonitoringTimePackageDTO,
): MonitoringTimePackage {
  return {
    creationDate: source.creationDate,
    description: source.description,
    label: source.label,
    minutesInPackage: source.secondsInPackage / 60,
    expirationDate: source.expirationDate,
    minutesUsed: source.secondsUsed / 60,
    priceInCents: source.priceInCents,
  };
}
