import { LastNotificationTimestampState } from './last-notification-timestamp-reducer';

export const getLastNotificationTimestampState = (state: any): LastNotificationTimestampState => {
  return state.leaderboardsLastNotificationTimestamp;
};

export function getLastNotificationTimestamp(
  state: any,
  leaderboardId: string,
): number | undefined {
  return getLastNotificationTimestampState(state)[leaderboardId];
}
