import {
  endOfMonth,
  endOfYear,
  getMonth,
  getYear,
  setMonth,
  setYear,
  startOfMonth,
  startOfYear,
  subMinutes,
} from 'date-fns';

import { DateRangeOptions } from '@neptune/pricing-commons-domain';

export function dateRangeOptionsToDateRanges(
  option: DateRangeOptions,
  now: Date,
): [string | undefined, string | undefined] {
  let from: Date;
  let to: Date;

  switch (option) {
    case DateRangeOptions.AnyTime:
      return [undefined, undefined];

    case DateRangeOptions.PastMonth: {
      const currMonth = getMonth(now);
      const prevMonth = setMonth(now, currMonth - 1);

      from = startOfMonth(prevMonth);
      to = endOfMonth(prevMonth);
      break;
    }

    case DateRangeOptions.ThisMonth:
      from = startOfMonth(now);
      to = endOfMonth(now);
      break;

    case DateRangeOptions.PastYear: {
      const currYear = getYear(now);
      const prevYear = setYear(now, currYear - 1);

      from = startOfYear(prevYear);
      to = endOfYear(prevYear);
      break;
    }

    case DateRangeOptions.ThisYear:
      from = startOfYear(now);
      to = endOfYear(now);
      break;
  }

  const fromStr = subMinutes(from, from.getTimezoneOffset()).toISOString();
  const toStr = subMinutes(to, to.getTimezoneOffset()).toISOString();
  return [fromStr, toStr];
}
