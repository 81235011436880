import React from 'react';

import { Layout, View } from '@neptune/shared/venus-ui';
import { EmptyView } from '@neptune/shared/common-ui';
import { neptuneUrls } from '@neptune/shared/common-domain';

export const ModelRegistryNotAvailableFeature: React.FC = () => {
  const action = (
    <Layout.Row spacedChildren="md">
      <EmptyView.ActionLink href={neptuneUrls.modelRegistryOverviewDoc} label="Read the docs" />

      <EmptyView.ActionLink
        href="https://www.youtube.com/watch?v=xK6FqjCBa0k&ab_channel=neptune_ai"
        label="Watch video"
        variant="basic"
      />
    </Layout.Row>
  );

  return (
    <View withPadding="md">
      <EmptyView
        variant="default"
        icon="construction"
        action={action}
        data-role="empty-models"
        title="This feature isn't available in your installation yet"
        subtitle="While we're working on it, explore how to register and version models in Neptune's docs."
      />
    </View>
  );
};
