import { GlyphName } from '@neptune/shared/core-glyphs-domain';
import React from 'react';

export type MenuTab = {
  icon: GlyphName;
  label: React.ReactNode;
  shortcut: string;
  component?: React.ComponentType;
  onlyComponent?: boolean;
};
export type RunsTabName = 'table' | 'details' | 'compare';

const tabs: Partial<Record<RunsTabName, MenuTab>> = {};

export const addTab = (runTabName: RunsTabName, tab: MenuTab) => {
  tabs[runTabName] = tabs[runTabName] ?? tab;
};

export const isTab = (name: string): name is RunsTabName => name in tabs;

export const getTab = (name: string) => (isTab(name) ? tabs[name] : null) ?? null;

export const toRunsTabName = (name: string, defaultTab: RunsTabName): RunsTabName =>
  isTab(name) ? name : defaultTab;
