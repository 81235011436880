// App
import { AppState } from 'state/types';
import { LoginActionDTO } from '@neptune/shared/core-apis-backend-domain';
import getParentState from '../selectors';

// Module
import { RegisterState } from './reducer';

export function getRegisterState(state: AppState): RegisterState {
  return getParentState(state).register;
}

export function getLoginActions(state: AppState): LoginActionDTO[] {
  return getRegisterState(state).actions;
}

export function isRegistrationPath(state: AppState): true | undefined {
  return getRegisterState(state).flags.registration;
}

export function isTeamTrialPath(state: AppState): true | undefined {
  return getRegisterState(state).flags.teamTrial;
}
