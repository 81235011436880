// Libs
import React from 'react';

// Module
import PropertyRow from './PropertyRow';
import './PreFormattedRow.less';

const PreFormattedRow = ({ label, value, ...props }) => (
  <PropertyRow
    label={label}
    value={value}
    {...props}
  >
    <pre className="pre-formatted-row">{ value }</pre>
  </PropertyRow>
);

export default PreFormattedRow;
