import {
  LastNotificationTimestampActions,
  LastNotificationTimestampActionTypes,
} from './last-notification-timestamp-actions';

type Identifier = string;
export type LastNotificationTimestampState = Record<Identifier, number | undefined>;

const initialState: LastNotificationTimestampState = {};

export function lastNotificationTimestampReducer(
  state: LastNotificationTimestampState = initialState,
  action: LastNotificationTimestampActions,
): LastNotificationTimestampState {
  switch (action.type) {
    case LastNotificationTimestampActionTypes.LeaderboardsChanged: {
      const { leaderboardIds, timestamp } = action.payload;

      const changedSubState = leaderboardIds.reduce(
        (subState: LastNotificationTimestampState, leaderboardId) => {
          subState[leaderboardId] = timestamp;
          return subState;
        },
        {},
      );

      return {
        ...state,
        ...changedSubState,
      };
    }

    default:
      return state;
  }
}
