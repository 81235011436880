import React from 'react';
import { useSelector } from 'react-redux';
import { useLocalModal } from '@neptune/shared/common-util';
import { LicenseStatusModalContainer } from './LicenseStatusModalContainer';
import { Button, ButtonProps, Icon, Layout } from '@neptune/shared/venus-ui';
import { getLicense } from 'state/license/selectors';
import { isOnPremiseDeployment } from 'common/deploymentModes';
import { isNeptuneAdmin } from '@neptune/shared/core-super-reader-business-logic';

export const LicenseStatusInfoContainer: React.FC = () => {
  const { isOpen, open, close } = useLocalModal();

  const neptuneAdmin = useSelector(isNeptuneAdmin);
  const expirationDate = useSelector(getLicense).expirationDate;

  if (!isOnPremiseDeployment()) {
    return null;
  }

  if (!neptuneAdmin) {
    return null;
  }

  if (!expirationDate) {
    return null;
  }

  return (
    <>
      <LicenseStatusModalContainer expirationDate={expirationDate} isOpen={isOpen} close={close} />

      <Layout.Element<ButtonProps<{}>>
        component={Button}
        onClick={open}
        square
        size="lg"
        variant="secondary-text"
        title="On-prem license info"
      >
        <Icon glyph="key" />
      </Layout.Element>
    </>
  );
};
