export enum UserManagementLevel {
  organization = 'organization',
  project = 'project',
}

export function convertStringToUserManagementLevel(value: string): UserManagementLevel | undefined {
  if (value === UserManagementLevel.organization || value === UserManagementLevel.project) {
    return value;
  }
}
