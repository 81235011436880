/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NewOrganizationMemberDTO,
    NewOrganizationMemberDTOFromJSON,
    NewOrganizationMemberDTOFromJSONTyped,
    NewOrganizationMemberDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewOrganizationDTO
 */
export interface NewOrganizationDTO {
    /**
     * 
     * @type {string}
     * @memberof NewOrganizationDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NewOrganizationDTO
     */
    email: string;
    /**
     * 
     * @type {Array<NewOrganizationMemberDTO>}
     * @memberof NewOrganizationDTO
     */
    members: Array<NewOrganizationMemberDTO>;
}

export function NewOrganizationDTOFromJSON(json: any): NewOrganizationDTO {
    return NewOrganizationDTOFromJSONTyped(json, false);
}

export function NewOrganizationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewOrganizationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'email': json['email'],
        'members': ((json['members'] as Array<any>).map(NewOrganizationMemberDTOFromJSON)),
    };
}

export function NewOrganizationDTOToJSON(value?: NewOrganizationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'members': ((value.members as Array<any>).map(NewOrganizationMemberDTOToJSON)),
    };
}


