import { listOrganizations, OrganizationWithRole } from '@neptune/shared/core-organizations-domain';
import { AnyAction } from 'redux';
import { NThunkDispatch } from 'state/types';
import { organizationsActionTypes } from './action-types';

export function fetchOrganizations() {
  return async (dispatch: NThunkDispatch<AnyAction>) => {
    dispatch({
      type: organizationsActionTypes.fetch,
    });

    try {
      const organizations = await listOrganizations();
      dispatch(fetchOrganizationsSuccess(organizations));
    } catch (error) {
      dispatch(fetchOrganizationsFail(error));
    }
  };
}

export function fetchOrganizationsSuccess(organizations: OrganizationWithRole[]) {
  return {
    type: organizationsActionTypes.fetchSuccess,
    payload: {
      organizations,
    },
  };
}

export function fetchOrganizationsFail(error: any) {
  return {
    type: organizationsActionTypes.fetchFail,
    payload: {
      error,
    },
  };
}
