import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';

import { Entity, EntityType, HolderType } from './entity-model';
import { EntityModelConverter } from './entity-model-converter';

type FetchEntityChunkParams =
  | {
      id: string;
      fieldsToFetch: string[];
      type: EntityType;
      holderType?: undefined;
    }
  | {
      id: string;
      fieldsToFetch: string[];
      type?: undefined;
      holderType: HolderType;
    };

// The API actually uses holder type, so all of run/model/modelVersion
// translate to 'experiment' and can be used interchangeably in practice.
// To make it explicit that it can be used to fetch any experiment type,
// we surface the holderType directly as an option.
export async function fetchEntityChunk(params: FetchEntityChunkParams): Promise<Entity> {
  const response = await leaderboardClient.getAttributesWithPathsFilter({
    holderIdentifier: params.id,
    holderType: params.holderType ?? EntityModelConverter.entityTypeToHolderType(params.type),
    attributeQuery: { attributePathsFilter: params.fieldsToFetch },
  });

  return EntityModelConverter.entityFromApiToDomain(response);
}
