import { AsyncActionsReturnType, createAsyncActions } from 'state/async-actions';
import { fetchEntity } from '@neptune/shared/entity-domain';

export enum ModelVersionActionTypes {
  request = 'MODEL_VERSION_ENTITY_FETCH',
  success = 'MODEL_VERSION_ENTITY_FETCH_SUCCESS',
  fail = 'MODEL_VERSION_ENTITY_FETCH_FAIL',
}

const actions = createAsyncActions({
  types: ModelVersionActionTypes,
  resolver(versionIdentifier: string) {
    return fetchEntity({ id: versionIdentifier, type: 'modelVersion' });
  },
});

export const { execute: fetchModelVersionEntity } = actions;
export type ModelVersionActions = AsyncActionsReturnType<typeof actions>;
