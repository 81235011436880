/* eslint-disable max-len */

export default {
  CURRENT_PROJECT_FETCH: 'CURRENT_PROJECT_FETCH',
  TAGS_MANAGE_OPEN: 'TAGS_MANAGE_OPEN',
  TAGS_MANAGE_CLOSE: 'TAGS_MANAGE_CLOSE',
  CONFIRMATION_MODAL_CLOSE: ' CONFIRMATION_MODAL_CLOSE',
  CONFIRMATION_MODAL_OPEN: ' CONFIRMATION_MODAL_OPEN',
  UI_SIDE_BAR_COLLAPSE: 'UI_SIDE_BAR_COLLAPSE',
  UI_SIDE_BAR_EXPAND: 'UI_SIDE_BAR_EXPAND',
  UI_NOTIFICATION_POPUP_ADD_NOTIFICATION: 'UI_NOTIFICATION_POPUP_ADD_NOTIFICATION',
  UI_NOTIFICATION_POPUP_REMOVE_NOTIFICATION: 'UI_NOTIFICATION_POPUP_REMOVE_NOTIFICATION',
  UI_COMMENTS_VISIBILITY_TOGGLE: 'UI_COMMENTS_VISIBILITY_TOGGLE',
  UI_COMMENTS_VISIBILITY_SHOW: 'UI_COMMENTS_VISIBILITY_SHOW',
  PROJECT_CREATE: 'PROJECT_CREATE',
  PROJECT_CREATE_SUCCESS: 'PROJECT_CREATE_SUCCESS',
  PROJECT_CREATE_FAIL: 'PROJECT_CREATE_FAIL',
  PROJECT_DELETE: 'PROJECT_DELETE',
  PROJECT_DELETE_SUCCESS: 'PROJECT_DELETE_SUCCESS',
  PROJECT_DELETE_FAIL: 'PROJECT_DELETE_FAIL',
  SET_DEFAULT_ORGANIZATION_NAME: 'SET_DEFAULT_ORGANIZATION_NAME',
  PUBLIC_USER_FETCH: 'PUBLIC_USER_FETCH',
  PUBLIC_USER_FETCH_SUCCESS: 'PUBLIC_USER_FETCH_SUCCESS',
  PUBLIC_USER_FETCH_FAIL: 'PUBLIC_USER_FETCH_FAIL',
  NOTIFICATIONS_FETCH: 'NOTIFICATIONS_FETCH',
  NOTIFICATIONS_FETCH_SUCCESS: 'NOTIFICATIONS_FETCH_SUCCESS',
  NOTIFICATIONS_FETCH_FAIL: 'NOTIFICATIONS_FETCH_FAIL',
  NOTIFICATIONS_UNREAD_COUNT_FETCH: 'NOTIFICATIONS_UNREAD_COUNT_FETCH',
  NOTIFICATIONS_UNREAD_COUNT_FETCH_SUCCESS: 'NOTIFICATIONS_UNREAD_COUNT_FETCH_SUCCESS',
  NOTIFICATIONS_UNREAD_COUNT_FETCH_FAIL: 'NOTIFICATIONS_UNREAD_COUNT_FETCH_FAIL',
  NOTIFICATIONS_NOTIFICATION_MARK_READ_REQUEST: 'NOTIFICATIONS_NOTIFICATION_MARK_READ_REQUEST',
  NOTIFICATIONS_NOTIFICATION_MARK_READ_SUCCESS: 'NOTIFICATIONS_NOTIFICATION_MARK_READ_SUCCESS',
  NOTIFICATIONS_NOTIFICATION_MARK_READ_FAIL: 'NOTIFICATIONS_NOTIFICATION_MARK_READ_FAIL',
  NOTIFICATIONS_MARK_ALL_READ_REQUEST: 'NOTIFICATIONS_MARK_ALL_READ_REQUEST',
  NOTIFICATIONS_MARK_ALL_READ_SUCCESS: 'NOTIFICATIONS_MARK_ALL_READ_SUCCESS',
  NOTIFICATIONS_MARK_ALL_READ_FAILURE: 'NOTIFICATIONS_MARK_ALL_READ_FAILURE',
  NOTIFICATIONS_ADD_ORGANIZATIONS_SUCCESS: 'NOTIFICATIONS_ADD_ORGANIZATIONS_SUCCESS',
  PAGES_FETCH: 'PAGES_FETCH',
  PAGES_FETCH_SUCCESS: 'PAGES_FETCH_SUCCESS',
  PAGES_FETCH_FAIL: 'PAGES_FETCH_FAIL',
  PAGES_CREATE: 'PAGES_CREATE',
  PAGES_CREATE_SUCCESS: 'PAGES_CREATE_SUCCESS',
  PAGES_CREATE_FAIL: 'PAGES_CREATE_FAIL',
  PAGES_UPDATE: 'PAGES_UPDATE',
  PAGES_UPDATE_SUCCESS: 'PAGES_UPDATE_SUCCESS',
  PAGES_UPDATE_FAIL: 'PAGES_UPDATE_FAIL',
  PAGES_DELETE: 'PAGES_DELETE',
  PAGES_DELETE_SUCCESS: 'PAGES_DELETE_SUCCESS',
  PAGES_DELETE_FAIL: 'PAGES_DELETE_FAIL',
  SCROLL_INTO_VIEW: 'SCROLL_INTO_VIEW',
};
