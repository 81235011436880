import React from 'react';
import { Layout, LayoutElementProps } from '@neptune/shared/venus-ui';

import { ExperimentState } from 'domain/experiment/experiment-state';

import { ExperimentStateLabel } from './ExperimentStateLabel';

type ExperimentIdProps<T extends object> = LayoutElementProps<T> & {
  shortId: string;
  state?: ExperimentState;
};

export function ExperimentId<T extends object>({
  shortId,
  state,
  ...layoutProps
}: ExperimentIdProps<T>): React.ReactElement {
  return (
    <Layout.Element withPadding={['none', 'sm']} {...layoutProps}>
      <ExperimentStateLabel label={shortId} state={state} />
    </Layout.Element>
  );
}
