import config from 'config';

export function isValidCompareTab(value = ''): boolean {
  return (
    value === 'table' ||
    value === 'leaderboard' ||
    value === 'dashboard' ||
    value === 'charts' ||
    value === 'images' ||
    (value === 'parallel-coordinates-plot' && config.parallelPlotEnabled) ||
    value === 'artifacts'
  );
}
