import React from 'react';

export const useManageTags = (tagsCount: Record<string, number>, maxCount: number) => {
  const [tagsToAdd, setTagsToAdd] = React.useState<string[]>([]);
  const [tagsToRemove, setTagsToRemove] = React.useState<string[]>([]);
  const [error, setError] = React.useState<string>();

  const reset = React.useCallback(() => {
    setTagsToAdd([]);
    setTagsToRemove([]);
    setError(undefined);
  }, []);

  const addTag = React.useCallback(
    (tag: string) => {
      if ((tagsCount[tag] ?? 0) < maxCount) {
        // if tag is on every entry, adding is no-op
        setTagsToAdd([...tagsToAdd, tag]);
      }

      setTagsToRemove(tagsToRemove.filter((tagToRemove) => tagToRemove !== tag));
      setError(undefined);
    },
    [maxCount, tagsCount, tagsToAdd, tagsToRemove],
  );

  const removeTag = React.useCallback(
    (tag: string) => {
      setTagsToAdd(tagsToAdd.filter((tagToAdd) => tagToAdd !== tag));
      setTagsToRemove([...tagsToRemove, tag]);
    },
    [tagsToAdd, tagsToRemove],
  );

  const toggleTag = React.useCallback(
    (tag: string, isChecked: boolean) => (!isChecked ? removeTag(tag) : addTag(tag)),
    [removeTag, addTag],
  );

  const resetTag = React.useCallback(
    (tag: string) => {
      setTagsToAdd(tagsToAdd.filter((tagToAdd) => tagToAdd !== tag));
      setTagsToRemove(tagsToRemove.filter((tagToRemove) => tagToRemove !== tag));
    },
    [tagsToAdd, tagsToRemove],
  );

  const addTagWithValidation = React.useCallback(
    (tag: string) => {
      if (tagsCount[tag] > 0 || tagsToAdd.includes(tag)) {
        setError('This tag is already used in at least one of selected runs. Check above.');
      }

      addTag(tag);
    },
    [tagsToAdd, tagsCount, addTag],
  );

  return {
    tagsToAdd,
    tagsToRemove,
    error,
    reset,
    toggleTag,
    resetTag,
    addTagWithValidation,
  };
};
