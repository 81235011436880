import { useLeaderboardCount } from '@neptune/shared/leaderboard-business-logic';
import { buildSearchLeaderboardQuery } from './buildSearchLeaderboardQuery';

type UseEntityLeaderboardCountParams = {
  entityId: string;
  entityType: string;
  projectIdentifier: string;
  trash: boolean;
  types: string[];
};

export const useEntityLeaderboardCount = ({
  entityId,
  entityType,
  projectIdentifier,
  trash,
  types,
}: UseEntityLeaderboardCountParams) => {
  const query = buildSearchLeaderboardQuery(entityType, entityId, trash);

  return useLeaderboardCount({
    types,
    projectIdentifier,
    query,
  });
};
