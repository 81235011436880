import React from 'react';
import { bemBlock, Modal, Layout } from '@neptune/shared/venus-ui';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';

import './EntityDetailsModal.less';

const block = bemBlock('entity-details-modal');

type EntityDetailsModalProps = {
  isOpen: boolean;
  close(): void;
  title: string;
  isLoading?: boolean;
  'data-role'?: string;
};

export const EntityDetailsModal: React.FC<EntityDetailsModalProps> = ({
  isOpen,
  close,
  title,
  children,
  isLoading,
  'data-role': dataRole,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={close} data={{ role: dataRole }}>
      <Modal.Header
        title={
          <Layout.Row spacedChildren="sm">
            <Layout.Element>{title}</Layout.Element>
            <LoadingIndicator size="medium" theme="dark" isVisible={isLoading} />
          </Layout.Row>
        }
        onClose={close}
      />
      <Modal.Body className={block('content-wrapper')}>{children}</Modal.Body>
    </Modal>
  );
};
