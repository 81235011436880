// Libs
import React from 'react';
import { Layout, Link, Text } from '@neptune/shared/venus-ui';

// App
import { formatDate } from '@neptune/shared/common-util';

// Module
type ServiceAccountActivatedContentProps = {
  keyGeneratedBy?: string;
  keyGeneratedDate?: Date;
  onResetToken?: () => void;
  userComponent: React.FC<{ user: string }>;
  apiTokenSectionElement?: React.ReactElement;
};

export const ServiceAccountActivatedContent: React.FC<ServiceAccountActivatedContentProps> = ({
  apiTokenSectionElement,
  keyGeneratedBy,
  keyGeneratedDate,
  onResetToken,
  userComponent: UserWithAvatar,
}) => {
  return (
    <Text size="sm">
      <Layout.Column withPadding={['none', 'lg', 'lg']}>
        <Layout.Column spacedChildren="sm">
          {apiTokenSectionElement}
          {keyGeneratedBy && keyGeneratedDate ? (
            <Layout.Row spacedChildren="xs" wrap="wrap">
              <Text>Generated {formatDate(keyGeneratedDate)} by </Text>
              <UserWithAvatar user={keyGeneratedBy} />
            </Layout.Row>
          ) : undefined}
        </Layout.Column>
        {onResetToken ? (
          <Text>
            <Link data-role="reset-api-token" onClick={onResetToken}>
              Reset Token
            </Link>
          </Text>
        ) : undefined}
      </Layout.Column>
    </Text>
  );
};
