import React from 'react';
import { Button, Icon, Layout, Text } from '@neptune/shared/venus-ui';
import { UnifiedExperimentStateIcon } from 'components/experiment-id';
import { ExperimentState } from 'domain/experiment/attribute';
import { MiddleEllipsis } from '@neptune/shared/common-ui';

type ExperimentToolbarInfoProps = {
  failed?: boolean;
  menu: React.ReactNode;
  state: ExperimentState;
  heading: string;
  caption: string;
  onCopy?: () => void;
  trashBackLink?: React.ReactElement;
};

export const ExperimentToolbarInfo = ({
  trashBackLink,
  heading,
  caption,
  onCopy,
  failed,
  state,
  menu,
}: ExperimentToolbarInfoProps) => {
  return (
    <>
      {trashBackLink && (
        <Layout.Column span="auto" alignItems="center">
          {trashBackLink}
        </Layout.Column>
      )}
      <Layout.Column span="4" style={{ flex: 'none' }}>
        <Layout.Row span="auto" alignItems="center" spacedChildren="xs">
          <MiddleEllipsis
            data-role="toolbar-experiment-heading"
            fontWeight="semibold"
            text={heading}
          />
          {onCopy && (
            <Button
              square
              size="md"
              onClick={onCopy}
              title="Copy run name"
              variant="secondary-text"
            >
              <Icon glyph="clone" />
            </Button>
          )}
        </Layout.Row>
        <Layout.Row span="grow" spacedChildren="xs" alignItems="center">
          <Text size="sm" color="text-secondary">
            {caption}
          </Text>
          <UnifiedExperimentStateIcon state={state} failed={failed} />
        </Layout.Row>
      </Layout.Column>
      {menu}
    </>
  );
};
