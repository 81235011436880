import React from 'react';
import { NormalizedLink, NormalizedLinkProps } from '@neptune/shared/routing-ui';
import { Banner, bemBlock, Button, Link, Layout, Text } from '@neptune/shared/venus-ui';

import './HistoricVersionBanner.less';

type HistoricVersionBannerProps = {
  actionsDropdown: React.ReactNode;
  onShare: () => void;
  versionsDropdown: React.ReactNode;
  branchViewRouteName: string;
  branchViewRouteParams?: Record<string, string>;
};

const block = bemBlock('historic-version-banner');

export const HistoricVersionBanner = ({
  actionsDropdown,
  onShare,
  branchViewRouteParams,
  branchViewRouteName,
  versionsDropdown,
}: HistoricVersionBannerProps) => {
  return (
    <Banner.Info span="greedy" size="small" className={block()}>
      <Layout.Row
        className={block('content')}
        spacedChildren="sm"
        wrap="wrap"
        alignItems="baseline"
        data-role="historic-version-banner"
      >
        <Layout.Column span="shrink">
          <Text className={block('text')}>You’re viewing an old version in read-only mode </Text>
        </Layout.Column>
        <Layout.Row spacedChildren="sm" alignItems="baseline">
          {versionsDropdown}
          <Button onClick={onShare}>Share</Button>
          {actionsDropdown}
          <Layout.Fill />
          <Link<NormalizedLinkProps>
            component={NormalizedLink}
            data-role="back-to-newest-version"
            routeName={branchViewRouteName}
            routeParams={branchViewRouteParams}
          >
            <Text className={block('text')}>Back to newest</Text>
          </Link>
        </Layout.Row>
      </Layout.Row>
    </Banner.Info>
  );
};
