// App
import { BooleanCriterion, PartialSearchCriterion } from '../search-query-model';
import { criterionHasAttributeAndOperator } from '../search-query-model-core-utils';

// Module
import { CriterionHelpers } from './types';

export function isBooleanCriterion(
  criterion: PartialSearchCriterion,
): criterion is BooleanCriterion {
  return criterionHasAttributeAndOperator(criterion) && criterion.type === 'bool';
}

export const booleanCriterionHelpers: CriterionHelpers<BooleanCriterion> = {
  getReadableValue(criterion): string {
    return toPythonLikeBoolean(criterion.value);
  },
  getShortReadableValue(criterion): string {
    return toPythonLikeBoolean(criterion.value);
  },
  isComplete(criterion): boolean {
    return criterion.value != null;
  },
};

// copy from `common/format` in order to cut circular dependency fast
function toPythonLikeBoolean(value: boolean): 'True' | 'False' {
  return (value && 'True') || 'False';
}
