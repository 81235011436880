import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModelVersionState, getModelVersionState, fetchModelVersionEntity } from './redux';

export function useModelVersion(modelVersionIdentifier: string): ModelVersionState {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchModelVersionEntity(modelVersionIdentifier));
  }, [dispatch, modelVersionIdentifier]);

  return useSelector(getModelVersionState);
}
