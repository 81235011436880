import React from 'react';
import type { Entity, EntityChanges, Stage } from '@neptune/shared/entity-domain';
import { useStageChangeConfirmationModal } from './useStageChangeConfirmationModal';

type UseStageChangeParams = {
  modelVersion: Entity | undefined;
  onEntityChange: (entityChanges: Partial<EntityChanges>) => Promise<void>;
};

type UseStageChange = {
  requestStageChange?: (stage: Stage) => void;
};

export function useStageChange({
  modelVersion,
  onEntityChange,
}: UseStageChangeParams): UseStageChange {
  const { openModal } = useStageChangeConfirmationModal();

  const requestStageChange = React.useCallback(
    (stage: Stage) => {
      modelVersion && openModal({ entity: modelVersion, targetStage: stage, onEntityChange });
    },
    [modelVersion, onEntityChange, openModal],
  );

  return {
    requestStageChange: modelVersion ? requestStageChange : undefined,
  };
}
