// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from '@neptune/shared/venus-ui';

const propTypes = {
  primaryButton: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  secondaryButton: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  submissionMessage: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

const FormSubmissionLayout = ({
  primaryButton,
  secondaryButton,
  submissionMessage,
}) => {
  return (
    <Layout.Row
      spacedChildren="lg"
      alignItems="center"
      span="auto"
    >
      <Layout.Row
        spacedChildren="sm"
        span="auto"
      >
        {primaryButton}
        {secondaryButton}
      </Layout.Row>
      {submissionMessage}
    </Layout.Row>
  );
};

FormSubmissionLayout.propTypes = propTypes;

export default FormSubmissionLayout;
