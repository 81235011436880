import React from 'react';
import { ChangeProjectStorageQuotaModal } from '@neptune/project-ui';
import { deleteProjectStorageLimit, updateProjectStorageLimit } from '@neptune/project-domain';
import { convertSizeToUnit } from '@neptune/shared/common-util';

type ChangeProjectStorageQuotaModalContainerProps = {
  projectIdentifier: string;
  isOpen: boolean;
  onClose: () => void;
  storageLimit: number | undefined;
  refresh: () => Promise<void>;
};

const STORAGE_UNIT = 'GB';

export const ChangeProjectStorageQuotaModalContainer: React.FC<
  ChangeProjectStorageQuotaModalContainerProps
> = ({ isOpen, onClose, projectIdentifier, storageLimit, refresh }) => {
  if (!isOpen) {
    return null;
  }

  const handleSave = async (storageLimit: number, isEnforcedLimit: boolean) => {
    if (isEnforcedLimit) {
      await updateProjectStorageLimit(
        projectIdentifier,
        convertSizeToUnit(storageLimit, 'B', STORAGE_UNIT),
      );
    } else {
      await deleteProjectStorageLimit(projectIdentifier);
    }

    await refresh();
  };

  return (
    <ChangeProjectStorageQuotaModal
      isOpen={isOpen}
      onClose={onClose}
      storageLimit={
        storageLimit !== undefined ? convertSizeToUnit(storageLimit, STORAGE_UNIT) : undefined
      }
      onSave={handleSave}
      storageUnit={STORAGE_UNIT}
    />
  );
};
