// App
import {
  ServiceAccountDTO,
  ServiceAccountWithRoleDTO,
} from '@neptune/shared/core-apis-backend-domain';
import { projectRoleFromApiToDomain } from '@neptune/shared/core-project-domain';

// Module
import { ServiceAccount, ServiceAccountWithRole } from './service-account-model';

export abstract class ServiceAccountModelConverter {
  static serviceAccountFromApiToDomain(serviceAccountFromApi: ServiceAccountDTO): ServiceAccount {
    return {
      id: serviceAccountFromApi.id,
      name: serviceAccountFromApi.name,
      displayName: serviceAccountFromApi.displayName,
      organizationId: serviceAccountFromApi.organizationId,
      activated: !serviceAccountFromApi.deactivated,

      createdBy: serviceAccountFromApi.createdBy,
      createdDate: serviceAccountFromApi.created,
      keyGeneratedBy: serviceAccountFromApi.keyGeneratedBy,
      keyGeneratedDate: serviceAccountFromApi.keyGeneratedAt,
      deactivatedBy: serviceAccountFromApi.deactivatedBy,
      deactivatedDate: serviceAccountFromApi.deactivated,
    };
  }

  static serviceAccountWithRoleFromApiToDomain(
    serviceAccountWithRole: ServiceAccountWithRoleDTO,
  ): ServiceAccountWithRole {
    return {
      serviceAccountInfo: ServiceAccountModelConverter.serviceAccountFromApiToDomain(
        serviceAccountWithRole.serviceAccountInfo,
      ),
      role: projectRoleFromApiToDomain(serviceAccountWithRole.role),
    };
  }
}
