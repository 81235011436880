import { useSelector } from 'react-redux';

import { getIsExpired } from 'state/organization-limits/selectors';
import { getContextOrganization } from 'state/selectors-global';

export const useTrialExpiration = () => {
  const isExpired = useSelector(getIsExpired);
  const { pricingPlan, limitsEnforced } = useSelector(getContextOrganization) || {};

  const trialExpired =
    !limitsEnforced &&
    isExpired &&
    (pricingPlan === 'v2_trial' || pricingPlan === 'v2_trial_organization');

  return { trialExpired };
};
