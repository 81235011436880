import React from 'react';
import { Icon, Layout, Link, Text } from '@neptune/shared/venus-ui';

import {
  Entity,
  getEntityModificationTime,
  getEntityMonitoringTime,
  getEntityShortId,
} from '@neptune/shared/entity-domain';
import { formatDate, formatMonitoringShort } from '@neptune/shared/common-util';

// Module
type VersionBasicInfoProps = {
  backLink: React.ReactNode;
  modelVersion: Entity;
  expanded: boolean;
  renderStage: (short?: boolean) => React.ReactElement | null;
  onDetailsOpen: (versionId: string) => void;
  onTrash?: (versionId: string) => void;
};

export const VersionBasicInfo: React.FC<VersionBasicInfoProps> = ({
  backLink,
  modelVersion,
  expanded,
  renderStage,
  onDetailsOpen,
  onTrash,
}) => {
  const versionId = getEntityShortId(modelVersion);
  const lastModified = getEntityModificationTime(modelVersion);
  const monitoringTime = getEntityMonitoringTime(modelVersion);

  if (!expanded) {
    return (
      <CollapsedInfo
        versionId={versionId}
        renderStage={renderStage}
        onDetailsOpen={onDetailsOpen}
        onTrash={onTrash}
      />
    );
  }

  return (
    <Layout.Column spacedChildren="sm" withPadding="sm" overflow="hidden">
      <Layout.Row alignItems="end">{backLink}</Layout.Row>
      <Layout.Row spacedChildren="sm" alignItems="center" justifyContent="space-between">
        <Layout.Row spacedChildren="sm" alignItems="center" overflow="hidden">
          <Text fontWeight="semibold" size="sm" title={versionId} ellipsis>
            {versionId}
          </Text>
          {renderStage()}
        </Layout.Row>
        {onTrash && (
          <Link data-role="trash-version-link" onClick={() => onTrash(versionId)}>
            <Icon glyph="trash" />
          </Link>
        )}
      </Layout.Row>
      <Link
        fontWeight="normal"
        onClick={() => onDetailsOpen(versionId)}
        data-role="version-details-link"
      >
        <Layout.Row spacedChildren="xs" alignItems="center">
          <Icon glyph="i-circled" size="sm" />
          <Text size="xs">Model version information</Text>
        </Layout.Row>
      </Link>
      <Layout.Column spacedChildren="xxs">
        {lastModified !== undefined && (
          <Text size="xs" color="text-secondary" title={formatDate(lastModified)} ellipsis>
            Last modified: {formatDate(lastModified)}
          </Text>
        )}
        {monitoringTime !== undefined && (
          <Text
            size="xs"
            color="text-secondary"
            data-role="basic-info-monitoring-time"
            title={formatMonitoringShort(monitoringTime / 60)}
            ellipsis
          >
            Monitoring time: {formatMonitoringShort(monitoringTime / 60)}
          </Text>
        )}
      </Layout.Column>
    </Layout.Column>
  );
};

const CollapsedInfo: React.FC<
  Pick<VersionBasicInfoProps, 'onDetailsOpen' | 'onTrash' | 'renderStage'> & { versionId: string }
> = ({ versionId, onDetailsOpen, renderStage, onTrash }) => {
  return (
    <Layout.Column spacedChildren="sm" withPadding="sm">
      <Layout.Element height={18} />
      <Layout.Column alignItems="center">{renderStage(true)}</Layout.Column>
      {onTrash && (
        <Layout.Column alignItems="center">
          <Link onClick={() => onTrash(versionId)}>
            <Icon glyph="trash" />
          </Link>
        </Layout.Column>
      )}
      <Layout.Column alignItems="center">
        <Link onClick={() => onDetailsOpen(versionId)}>
          <Icon glyph="i-circled" />
        </Link>
      </Layout.Column>
    </Layout.Column>
  );
};
