import React from 'react';

import { useGroupTagSettings } from '@neptune/shared/tags-business-logic';
import { Attribute, getAttribute, KnownAttributes } from 'domain/experiment/attribute';
import { useTagColors } from './use-tag-colors';

export function useTagColorsEnhancer(defaultColor: string) {
  const { getColorForTag } = useTagColors();
  const { enabledRunsMatchTags } = useGroupTagSettings();

  const getColorFromTag = React.useCallback(
    (attributes: Attribute[], enabledRunsMatchTags: Set<string>) => {
      if (!enabledRunsMatchTags.size) {
        return undefined;
      }

      const groupTags =
        getAttribute(attributes, KnownAttributes.GroupTags, 'stringSet')?.values ?? [];

      const enabledRunsMatchOnGroup = groupTags.filter((tag) => enabledRunsMatchTags.has(tag));

      if (enabledRunsMatchOnGroup.length > 0 && groupTags.length === 1) {
        return getColorForTag(enabledRunsMatchOnGroup[0]);
      }

      if (enabledRunsMatchOnGroup.length > 0 && groupTags.length > 1) {
        return defaultColor;
      }

      return undefined;
    },
    [defaultColor, getColorForTag],
  );

  return React.useCallback(
    (attributes: Attribute[]) => getColorFromTag(attributes, enabledRunsMatchTags),
    [enabledRunsMatchTags, getColorFromTag],
  );
}
