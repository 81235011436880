import React from 'react';
import { bemBlock, Avatar, AvatarSize } from '@neptune/shared/venus-ui';
import { getGenerativeAvatarImageDataURI } from '@neptune/shared/avatars-util';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { backendClient } from '@neptune/shared/core-apis-backend-domain';

type WithAvatarGetter = {
  // Username is mandatory when passing avatarGetter.
  username: string;
  avatarGetter: (params: { username: string }) => string;
};
export type UserAvatarProps = {
  username?: string;
  fallbackSeed?: string;
  alt?: string;
  className?: string;
  imageSrc?: string;
  size?: AvatarSize;
} & (WithAvatarGetter | { avatarGetter?: undefined });

const block = bemBlock('user-avatar');

// TODO(NPT-12185): Move this out of the UI layer.
// Probably also replace with hardcoded string substitution (which makes more sense for this single url).
// Config may be a right place for such a function.
function getUserAvatarURL(params: { username: string }): string {
  return backendClient.getUserAvatarURL(params);
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  alt,
  className,
  username = '',
  fallbackSeed = username,
  imageSrc = '',
  avatarGetter = getUserAvatarURL,
  size,
  ...restProps
}) => {
  const ownProps = {
    alt,
    className: block({
      extra: className,
    }),
    src: imageSrc || avatarGetter({ username }),
    size,
    onError: getErrorHandler(fallbackSeed),
  };

  return <Avatar shape="round" {...ownProps} {...restProps} />;
};

function getErrorHandler(fallbackPictureSeed: string): React.ReactEventHandler<HTMLImageElement> {
  return (ev) => {
    ev.currentTarget.src = getGenerativeAvatarImageDataURI(fallbackPictureSeed);
  };
}
