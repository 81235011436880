// Libs
import React from 'react';

// App
import { InfoBar } from '@neptune/shared/venus-ui';

// Module
import { recommendedBrowsersLinks } from './recommendedBrowsersLinks';

export const BrowserNotAllowedInfo: React.FC = () => {
  return (
    <InfoBar level="warning">
      <span>
        Your browser is not supported by Neptune due to insufficient security features. Please use a
        newer browser. We advise you to use the latest version of {recommendedBrowsersLinks}
      </span>
    </InfoBar>
  );
};
