// App
import { OwnerCriterion, PartialSearchCriterion } from '../search-query-model';
import {
  criterionHasAttributeAndOperator,
  getReadableArrayValue,
} from '../search-query-model-core-utils';

// Module
import { CriterionHelpers } from './types';

export function isOwnerCriterion(criterion: PartialSearchCriterion): criterion is OwnerCriterion {
  return criterionHasAttributeAndOperator(criterion) && criterion.type === 'owner';
}

export const ownerCriterionHelpers: CriterionHelpers<OwnerCriterion> = {
  getReadableValue(criterion) {
    return getReadableArrayValue(criterion.value);
  },
  getShortReadableValue(criterion) {
    return criterion.value.join(', ');
  },
  isComplete(criterion) {
    return Array.isArray(criterion.value) ? criterion.value.length > 0 : criterion.value != null;
  },
};
