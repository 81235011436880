import React from 'react';

import { GlyphName } from '@neptune/shared/core-glyphs-domain';
import { getRegistry, getRegistryEntries } from '@neptune/project-domain';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';

type UseOverlaySubviewsReturnType = {
  currentSubviewLabel?: string;
  CurrentSubviewComponent?: React.ComponentType<{ project: ProjectWithRole }>;
  openSubview: (subviewId: string) => void;
  closeCurrentSubview: () => void;
  subviewEntries: {
    id: string;
    icon: GlyphName;
    label: string;
    disabledReason?: string;
    disabled: boolean;
  }[];
};

export const useOverlaySubviews: (project?: ProjectWithRole) => UseOverlaySubviewsReturnType = (
  project,
) => {
  const [subviewId, setSubviewId] = React.useState<string>();
  const subViewsMap = getRegistry();
  const currentSubview = subviewId ? subViewsMap[subviewId] : undefined;
  const subviewEntries = React.useMemo(() => {
    return getRegistryEntries().map(({ id, label, icon, permission, noPermissionCopy }) => {
      const hasPermission = permission ? permission(project) : true;
      const disabled = !hasPermission;
      return {
        id,
        label,
        icon,
        disabled,
        disabledReason: disabled ? noPermissionCopy : undefined,
      };
    });
  }, [project]);
  const closeCurrentSubview = React.useCallback(() => setSubviewId(undefined), []);

  return {
    CurrentSubviewComponent: currentSubview?.Component,
    currentSubviewLabel: currentSubview?.label,
    openSubview: setSubviewId,
    closeCurrentSubview,
    subviewEntries,
  };
};
