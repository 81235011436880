// Libs
import React from 'react';
import { Dialog, Text } from '@neptune/shared/venus-ui';

// App
import { Stage } from '@neptune/shared/entity-domain';

// Module
import { StageTransition } from './StageTransition';

type StageChangeConfirmationProps = {
  isOpen: boolean;
  cancel(): void;
  execute(): void;
  fromStage: Stage;
  targetStage: Stage;
  modelVersionShortId: string;
  modelShortId?: string;
};

export const StageChangeConfirmation: React.FC<StageChangeConfirmationProps> = ({
  isOpen,
  cancel,
  execute,
  modelVersionShortId,
  modelShortId,
  fromStage,
  targetStage,
}) => {
  const modelText = modelShortId ? (
    <>
      {' '}
      of the <Text fontWeight="semibold">{modelShortId}</Text> model.
    </>
  ) : (
    '.'
  );

  return (
    <Dialog
      isOpen={isOpen}
      title="Change stage"
      onClose={cancel}
      primaryButtonRole="version-stage-change-confirmation"
      primaryButtonLabel="Change stage"
      onPrimaryButtonClick={execute}
      onSecondaryButtonClick={cancel}
    >
      You are about to change the stage{' '}
      <span>
        <StageTransition from={fromStage} to={targetStage} />
      </span>{' '}
      of version <Text fontWeight="semibold">{modelVersionShortId}</Text>
      {modelText}
    </Dialog>
  );
};
