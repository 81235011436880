import { backendClient } from '@neptune/shared/core-apis-backend-domain';

export async function cancelSubscription({
  organizationId,
}: {
  organizationId: string;
}): Promise<void> {
  await backendClient.cancelSubscription({
    organizationId,
  });
}
