import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';

type FetchEntityParams = {
  entityIds: string[];
  projectIdentifier: string;
};

export async function trashEntities({ entityIds, projectIdentifier }: FetchEntityParams) {
  return await leaderboardClient.trashExperiments({
    experimentIdentifiers: entityIds,
    projectIdentifier,
  });
}
