import React from 'react';
import {
  getNestedNamespacesFromItems,
  getShallowNamespacesFromItems,
} from '@neptune/shared/common-util';
import { substringSearchFilter } from '@neptune/shared/search-business-logic';
import { QueryFilterSearch } from '@neptune/shared/search-domain';
import { searchAttributes } from '@neptune/shared/leaderboard-domain';
import { AttributeDefinition, AttributeType } from 'domain/experiment/attribute';
import { buildSearchLeaderboardQuery } from './buildSearchLeaderboardQuery';
import { unwindSubpropertyStrategies, UnwindSubpropertyStrategy } from './unwind-property-strategy';

type UseStaticNamespacesDataSourceProps = {
  attributeTypes: AttributeType[];
  entityId: string;
  entityType: string;
  projectIdentifier: string;
  trash: boolean;
  types: string[];
};

export const useNamespacesDataSource = ({
  attributeTypes,
  entityId,
  entityType,
  projectIdentifier,
  trash,
  types,
}: UseStaticNamespacesDataSourceProps): QueryFilterSearch<string, { nested: boolean }> => {
  const rawQuery = React.useCallback(
    (search: string) =>
      searchAttributes({
        projectIdentifier,
        query: buildSearchLeaderboardQuery(entityType, entityId, trash),
        search,
        type: types,
      }),
    [entityId, entityType, projectIdentifier, trash, types],
  );

  const prepareOutput = React.useCallback(
    (input: AttributeDefinition[], search: string, param = { nested: true }) => {
      const collection = unwindSubpropertyStrategies[UnwindSubpropertyStrategy.attach](input)
        .filter(<T>(item: T | undefined): item is T => !!item)
        .filter((entry) => attributeTypes.includes(entry.type))
        .sort();
      const namespaces = param.nested
        ? getNestedNamespacesFromItems(collection, getName)
        : getShallowNamespacesFromItems(collection, getName);

      return substringSearchFilter(search, namespaces);
    },
    [attributeTypes],
  );

  return React.useCallback(
    async (search: string, param = { nested: true }) => {
      const collection = await rawQuery(search.trim() + '.*/');
      const entries = prepareOutput(collection, search.trim(), param);
      return {
        entries,
        collectionCount: collection.length,
      };
    },
    [prepareOutput, rawQuery],
  );
};

function getName({ name }: AttributeDefinition) {
  return name;
}
