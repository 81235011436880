import { OrganizationTraits } from '@neptune/shared/core-organizations-domain';

export const hasTableViewLimits = (traits: OrganizationTraits): boolean =>
  !!traits.tableViewsLimits;
export const getTableViewPerProjectLimit = (traits: OrganizationTraits): number | undefined =>
  traits.tableViewsLimits?.maxTableViewsPerProject;
export const getTableViewsPerProjectEffectiveLimit = (traits: OrganizationTraits): number =>
  traits.tableViewsLimits?.maxTableViewsPerProject !== undefined
    ? traits.tableViewsLimits?.maxTableViewsPerProject
    : Number.POSITIVE_INFINITY;
