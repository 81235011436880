// Libs
import React from 'react';
import {
  Icon,
  IconProps,
  Layout,
  LayoutColumnProps,
  Text,
  TextProps,
  bemBlock,
} from '@neptune/shared/venus-ui';

// Module
import './BenefitsList.less';

type BenefitsListProps = LayoutColumnProps<{
  items: React.ReactNode[];
  iconGlyph?: IconProps['glyph'];
  iconColor?: IconProps['color'];
  iconSize?: IconProps['size'];
  textSize?: TextProps<any>['size'];
}>;

const block = bemBlock('benefits-list');

export const BenefitsList: React.FC<BenefitsListProps> = ({
  items,
  iconGlyph = 'check',
  iconColor = 'success',
  iconSize,
  textSize,
  ...restProps
}) => {
  return (
    <Layout.Column {...restProps}>
      {items.map((benefit, index) => (
        <Layout.Row<React.ComponentProps<typeof Text>>
          key={index}
          component={Text}
          span="auto"
          spacedChildren="sm"
          color="text"
          size={textSize}
        >
          <Icon className={block('icon')} glyph={iconGlyph} color={iconColor} size={iconSize} />
          <span>{benefit}</span>
        </Layout.Row>
      ))}
    </Layout.Column>
  );
};
