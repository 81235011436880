import { EntityType } from '@neptune/shared/entity-domain';

export const STRING_SET_VALUES_REMOVE_MODAL_ID = 'STRING_SET_VALUES_REMOVE_MODAL_ID';

export type StringSetValuesRemovalData = {
  entityId: string;
  entityType: EntityType;
  valuesToRemove: string[];
  attributePath: string;
};
