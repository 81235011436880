
import { createRouteNodeSelector } from 'redux-router5';

import { isEnterpriseRouteName } from './helpers';

/**
 * @deprecated use @neptune/shared/enterprise-context-business-logic instead
 */
export function isEnterpriseContext(state) {
  const { route } = createRouteNodeSelector('')(state);
  return isEnterpriseRouteName(route?.name || '');
}

