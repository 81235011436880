import { getReadableFileSize } from '@neptune/shared/common-util';
import { KnownAttributes } from 'domain/experiment/attribute';
import { IntegerCriterion, PartialSearchCriterion } from '../search-query-model';
import { criterionHasAttributeAndOperator } from '../search-query-model-core-utils';

import { CriterionHelpers } from './types';

export function isSizeCriterion(criterion: PartialSearchCriterion): criterion is IntegerCriterion {
  return (
    criterionHasAttributeAndOperator(criterion) && criterion.attribute === KnownAttributes.Size
  );
}

export const sizeCriterionHelpers: CriterionHelpers<IntegerCriterion> = {
  getReadableValue(criterion) {
    return getReadableFileSize(criterion.value);
  },
  getShortReadableValue(criterion) {
    return getReadableFileSize(criterion.value);
  },
  isComplete(criterion) {
    return criterion.value != null;
  },
};
