import React from 'react';

import { Sidebar } from '@neptune/shared/venus-ui';
import { AppSidebar, AppSidebarElement, AppSidebarSection } from 'components/app-sidebar';

type VersionSidebarProps = {
  versionBasicInfoElement: React.ReactElement;
  menuElement: React.ReactElement;
  expanded: boolean;
  onToggle: () => void;
};

export const VersionSidebar: React.FC<VersionSidebarProps> = ({
  versionBasicInfoElement,
  menuElement,
  expanded,
  onToggle,
}) => {
  return (
    <AppSidebar expanded={expanded} onToggle={onToggle}>
      <AppSidebarElement expanded={expanded}>{versionBasicInfoElement}</AppSidebarElement>
      <Sidebar.Separator />
      <AppSidebarSection>{menuElement}</AppSidebarSection>
    </AppSidebar>
  );
};
