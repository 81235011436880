// Libs
import React from 'react';
import { Icon } from '@neptune/shared/venus-ui';
import type { GlyphName } from '@neptune/shared/core-glyphs-domain';

type AppSidebarToggleProps = {
  className?: string;
  expanded: boolean;
  alignment: 'left' | 'right';
  onToggle: () => void;
};

export const AppSidebarToggle: React.FC<AppSidebarToggleProps> = ({
  className,
  expanded,
  alignment,
  onToggle,
}) => {
  let collapseIcon: GlyphName;

  if (alignment === 'left') {
    collapseIcon = expanded ? 'chevron-double-left' : 'chevron-double-right';
  } else {
    collapseIcon = expanded ? 'chevron-double-right' : 'chevron-double-left';
  }

  return <Icon glyph={collapseIcon} className={className} onClick={onToggle} />;
};
