import { AnyAction } from 'redux';
import { createSelector } from 'reselect';

import {
  LeaderboardSortingParams,
  SortOptionsModelConverter,
} from '@neptune/shared/leaderboard-domain';
import { navigateTo } from 'router/actions';
import { parseRouteParams } from 'router/router-utils';
import { getRawSortOptions } from 'state/ui/route-params/selectors';
import { getCurrentRouteName } from 'state/selectors-global';
import { AppState, NThunkAction } from 'state/types';

type SortOptionsStorageStrategy = {
  selector: (state: AppState) => LeaderboardSortingParams | undefined;
  actionCreator: (sortOptions: LeaderboardSortingParams) => NThunkAction<void, AnyAction>;
};

export const inUrlSortOptionsStorageStrategy: SortOptionsStorageStrategy = {
  selector: createSelector(
    getRawSortOptions,
    (options) => options && SortOptionsModelConverter.fromLegacyToDomain(options),
  ),
  actionCreator(sortOptions: LeaderboardSortingParams) {
    return (dispatch, getState) => {
      const routeName = getCurrentRouteName(getState());

      dispatch(
        navigateTo(
          routeName,
          parseRouteParams(SortOptionsModelConverter.fromDomainToLegacy(sortOptions)),
          { extendParams: true },
        ),
      );
    };
  },
};
