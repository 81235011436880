import React from 'react';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rx';

import { fetchProject } from '@neptune/shared/core-project-domain';
import { createErrorDescriptor } from 'common/error-handler';
import { makeProjectIdentifier } from '@neptune/shared/core-project-util';

const PROJECT_NAME_REGEX = /^[A-Za-z0-9-_]{3,50}$/;

const withNameChecked = (Component) =>
  class extends React.Component {
    componentDidMount() {
      this.nameUpdatesStream = new Rx.Subject();

      this.nameUpdatesSubscription = this.nameUpdatesStream
        .debounce(300)
        .map(this.validateFormat)
        .flatMapLatest(this.validateUniqueness)
        .subscribe((status) => this.props.onValidationChange(status));
    }

    componentDidUpdate(prevProps) {
      const { projectId, organizationName, value } = this.props;

      if (prevProps.value !== value || (prevProps.organizationName !== organizationName && value)) {
        this.nameUpdatesStream.onNext({ projectId, organizationName, projectName: value });
      }
    }

    componentWillUnmount() {
      this.nameUpdatesSubscription.dispose();
      this.nameUpdatesStream = null;
    }

    validateFormat(props) {
      const { projectName = '' } = props;
      const res = PROJECT_NAME_REGEX.test(projectName);

      return {
        error: res ? '' : 'Use 3 or more characters. You can use letters, digits, and hyphens.',
        ...props,
      };
    }

    async validateUniqueness(props) {
      // we pass it further if error occurred before
      if (props.error) {
        return Promise.resolve(props);
      }

      const { organizationName, projectName } = props;
      const projectIdentifier = makeProjectIdentifier(organizationName, projectName);

      try {
        const project = await fetchProject({ projectIdentifier });

        // if someone edits project name, we should allow typing the same name as existing project
        if (props.projectId === project.id) {
          return { error: '' }; // no error
        }

        return { error: 'Project with given name already exists' };
      } catch (e) {
        const error = createErrorDescriptor(e);

        if (error.code === 404) {
          return { error: '' }; // no error
        }

        return { error: 'Project name is invalid' };
      }
    }

    render() {
      const { organizationName, projectId, onValidationChange, ...rest } = this.props;

      return <Component {...rest} />;
    }
  };

export default withNameChecked;
