import React from 'react';
import { bemBlock, Toolbar, Layout } from '@neptune/shared/venus-ui';
import './DashboardToolbar.less';

const block = bemBlock('dashboard-toolbar-layout');

type DashboardLayoutToolbarProps = {
  className?: string;
};

export const DashboardToolbar: React.FC<DashboardLayoutToolbarProps> = ({
  children,
  className,
}) => {
  return (
    <Layout.Row
      className={block({ extra: [className] })}
      span="greedy"
      alignItems="center"
      data-role="dashboard-layout-toolbar"
    >
      <Toolbar.Separator className={block('separator')} />
      <Toolbar
        className={block('toolbar')}
        size="md"
        withPadding={['sm', 'md', 'sm', 'none']}
        alignItems="center"
      >
        {children && Array.isArray(children)
          ? children.map((item, i) => (
              <React.Fragment key={i}>
                {i > 0 && !!item && <Toolbar.Separator />}
                {item}
              </React.Fragment>
            ))
          : children}
      </Toolbar>
    </Layout.Row>
  );
};
