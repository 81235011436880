// Libs

// App
import {
  leaderboardClient,
  DeleteExperimentsRequest,
  RestoreExperimentsRequest,
  TrashExperimentsRequest,
} from '@neptune/shared/core-apis-leaderboard-domain';
import { createAsyncActions, AsyncActionsReturnType } from 'state/async-actions';
import { openConfirmationModal } from 'state/ui/global/confirmation-modal/actions';
import {
  ABORT_CONFIRMATION_MODAL,
  STOP_CONFIRMATION_MODAL,
} from 'components/stop-abort-confirmations/modalTypes';

export enum TrashEntriesActionTypes {
  request = 'LEADERBOARD_ENTRIES_TRASH_REQUEST',
  success = 'LEADERBOARD_ENTRIES_TRASH_SUCCESS',
  fail = 'LEADERBOARD_ENTRIES_TRASH_FAIL',
}

export type ActionOrigin = 'bulk-operations';
export type BulkActionWithOriginRequest<TYPE extends object> = TYPE & { origin?: ActionOrigin };

const trashEntriesActions = createAsyncActions({
  types: TrashEntriesActionTypes,
  resolver: async (params: BulkActionWithOriginRequest<TrashExperimentsRequest>) => {
    const { origin, ...requestParams } = params;
    const response = await leaderboardClient.trashExperiments(requestParams);
    return {
      origin: params.origin,
      response,
    };
  },
});

export enum RestoreEntriesActionTypes {
  request = 'LEADERBOARD_ENTRIES_RESTORE_REQUEST',
  success = 'LEADERBOARD_ENTRIES_RESTORE_SUCCESS',
  fail = 'LEADERBOARD_ENTRIES_RESTORE_FAIL',
}

const restoreEntriesActions = createAsyncActions({
  types: RestoreEntriesActionTypes,
  resolver: async (params: BulkActionWithOriginRequest<RestoreExperimentsRequest>) => {
    const { origin, ...requestParams } = params;
    const response = await leaderboardClient.restoreExperiments(requestParams);
    return {
      origin: params.origin,
      response,
    };
  },
});

export enum DeleteEntriesActionTypes {
  request = 'LEADERBOARD_ENTRIES_DELETE_REQUEST',
  success = 'LEADERBOARD_ENTRIES_DELETE_SUCCESS',
  fail = 'LEADERBOARD_ENTRIES_DELETE_FAIL',
}

const deleteEntriesActions = createAsyncActions({
  types: DeleteEntriesActionTypes,
  resolver: async (params: BulkActionWithOriginRequest<DeleteExperimentsRequest>) => {
    const { origin, ...requestParams } = params;
    const response = await leaderboardClient.deleteExperiments(requestParams);
    return {
      origin: params.origin,
      response,
    };
  },
});

export type AbortRequestParams = {
  experimentIdentifiers: string[];
  projectIdentifier: string;
  origin?: string;
};

export enum AbortEntriesActionTypes {
  request = 'LEADERBOARD_ENTRIES_ABORT',
  success = 'LEADERBOARD_ENTRIES_ABORT_SUCCESS',
  fail = 'LEADERBOARD_ENTRIES_ABORT_FAIL',
}

const abortEntriesActions = createAsyncActions({
  types: AbortEntriesActionTypes,
  resolver: async (params: AbortRequestParams) => {
    const { experimentIdentifiers, projectIdentifier, origin } = params;
    const response = await leaderboardClient.signalRuns({
      signal: {
        body: {},
        type: 'neptune/abort',
        experimentIdentifiers,
      },
      projectIdentifier,
    });
    return {
      response,
      origin,
    };
  },
});

export function requestAbort(requestParams: AbortRequestParams) {
  return openConfirmationModal(ABORT_CONFIRMATION_MODAL, { requestParams });
}

export type StopRequestParams = {
  experimentIdentifiers: string[];
  projectIdentifier: string;
  origin?: string;
};

export enum StopEntriesActionTypes {
  request = 'LEADERBOARD_ENTRIES_STOP',
  success = 'LEADERBOARD_ENTRIES_STOP_SUCCESS',
  fail = 'LEADERBOARD_ENTRIES_STOP_FAIL',
}

const stopEntriesActions = createAsyncActions({
  types: StopEntriesActionTypes,
  resolver: async (params: StopRequestParams) => {
    const { experimentIdentifiers, projectIdentifier, origin } = params;
    const response = await leaderboardClient.signalRuns({
      signal: {
        body: {},
        type: 'neptune/stop',
        experimentIdentifiers,
      },
      projectIdentifier,
    });
    return {
      response,
      origin,
    };
  },
});

export function requestStop(requestParams: StopRequestParams) {
  return openConfirmationModal(STOP_CONFIRMATION_MODAL, { requestParams });
}

const { execute: requestTrashLeaderboardEntries } = trashEntriesActions;
const { execute: requestRestoreLeaderboardEntries } = restoreEntriesActions;
const { execute: requestDeleteLeaderboardEntries } = deleteEntriesActions;
const { execute: abortLeaderboardEntries } = abortEntriesActions;
const { execute: stopLeaderboardEntries } = stopEntriesActions;

export {
  requestDeleteLeaderboardEntries,
  requestRestoreLeaderboardEntries,
  requestTrashLeaderboardEntries,
  abortLeaderboardEntries,
  stopLeaderboardEntries,
};

export type EntriesActions = AsyncActionsReturnType<
  | typeof trashEntriesActions
  | typeof restoreEntriesActions
  | typeof deleteEntriesActions
  | typeof abortEntriesActions
  | typeof stopEntriesActions
>;
