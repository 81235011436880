import { storage } from 'common/storage';
import { isEmpty, omitBy, isUndefined } from 'lodash';

import { makeProjectIdentifier } from '@neptune/shared/core-project-util';
import {
  getLastProjectRunsTabRecovery,
  getLbViewRecoveryParams,
  getRunDetailsRecoveryParams,
  getRunsCompareRecoveryParams,
  persistCompareDashboardTab,
  persistLbViewId,
  persistRunsDashboardTabMetadata,
  persistProjectRunsTab,
  setOrderedColumnsByViewId,
} from '@neptune/project-runs-business-logic';
import {
  convertStateToContextAwareState,
  makeEnterpriseRoute,
} from '@neptune/shared/enterprise-context-business-logic';
import { rejectActivation } from '@neptune/shared/routing-business-logic';
import { AppRoute, RouterSimpleState, RouterStateParams } from '@neptune/shared/routing-domain';
import { isEnterpriseRouteName } from '@neptune/shared/enterprise-context-util';

const projectRunsDefaultRoute: AppRoute = {
  name: 'project.runs',
  path: '/runs',
  canActivate: () => (toState) => {
    const { params } = toState;

    const paramsToApply: RouterStateParams[] = [];

    const applyParams = (params: RouterStateParams) => {
      if (!isEmpty(omitBy(params, isUndefined))) {
        paramsToApply.push(params);
      }
    };

    if (!params.tab) {
      applyParams(getLastProjectRunsTabRecovery('compare'));
    }

    const isEnterprise = isEnterpriseRouteName(toState.name);
    const targetState: RouterSimpleState = {
      name: 'project.runs-tab',
      params: Object.assign({}, toState.params, ...paramsToApply),
    };
    return rejectActivation(convertStateToContextAwareState(targetState, isEnterprise));
  },
};

const projectRunsRoute: AppRoute = {
  name: 'project.runs-tab',
  path: '/runs/:tab?:viewId&:detailsTab&:dash&:dashboardId&:dashVersionId&:runIdentificationKey',
  onActivate: (dispatch, getState) => (state, previousRoute) => {
    persistLbViewId(state);
    storage.local.setItem('lastProjectTab', 'runs');
    persistRunsDashboardTabMetadata(state);
    persistCompareDashboardTab(state);
    persistProjectRunsTab(state);

    if (state.params.viewId && state.params.viewId !== previousRoute?.params.viewId) {
      setOrderedColumnsByViewId({
        state: getState(),
        dispatch,
        viewId: state.params.viewId,
      });
    }
  },
  canActivate: () => (toState) => {
    const { params } = toState;

    const paramsToApply: RouterStateParams[] = [];
    const shouldRedirect = () => paramsToApply.length > 0;

    const applyParams = (params: RouterStateParams) => {
      if (!isEmpty(omitBy(params, isUndefined))) {
        paramsToApply.push(params);
      }
    };

    const projectIdentifier = makeProjectIdentifier(params.organizationName, params.projectName);

    if (!params.viewId) {
      applyParams(getLbViewRecoveryParams(projectIdentifier));
    }

    if (params.tab === 'details') {
      applyParams(getRunDetailsRecoveryParams(projectIdentifier, toState));
    } else if (params.tab === 'compare') {
      applyParams(getRunsCompareRecoveryParams(projectIdentifier, toState));
    }

    if (shouldRedirect()) {
      const targetState: RouterSimpleState = {
        name: toState.name,
        params: Object.assign({}, toState.params, ...paramsToApply),
      };
      return rejectActivation(targetState);
    }

    return true;
  },
};

export const projectRunsRoutes: AppRoute[] = [
  projectRunsDefaultRoute,
  makeEnterpriseRoute(projectRunsDefaultRoute),
  projectRunsRoute,
  makeEnterpriseRoute(projectRunsRoute),
];
