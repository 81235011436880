import { WidgetDefinition } from '../core/domain/widget-definition';
import { Widget } from '../core/widget';

export type ArtifactWidget = Widget & {
  type: 'artifact';
};

export function isArtifactWidget(widget: Widget): widget is ArtifactWidget {
  return widget.type === 'artifact';
}

export const artifactWidgetName = 'Artifact';

export const artifactWidgetDefinition: WidgetDefinition = {
  type: 'artifact',
  name: artifactWidgetName,
};
