// Libs
import React from 'react';
import { downloadTextFile } from 'common/download';
import { Layout, Select, bemBlock } from '@neptune/shared/venus-ui';

// App

// Module
import { integrations, Integration } from '@neptune/onboarding-domain';
import { IntegrationExample, OtherFrameworksExample } from '@neptune/onboarding-ui';
import './SelectIntegrationExample.less';

const block = bemBlock('select-integration-example');

type SelectIntegrationExampleProps = {
  apiToken?: string;
  projectIdentifier: string;
  integration: Integration;
  handleChange(int: Integration): void;
};

export const SelectIntegrationExample: React.FC<SelectIntegrationExampleProps> = ({
  apiToken,
  projectIdentifier,
  integration,
  handleChange,
}) => {
  const getId = React.useCallback((i: Integration) => i.id, []);
  const getTitle = React.useCallback((i: Integration) => i.title, []);

  return (
    <Layout.Column className={block()} spacedChildren="sm" data-role="select-integration">
      <Layout.Row justifyContent="end">
        <Select
          variant="primary"
          width={220}
          getKey={getId}
          getLabel={getTitle}
          selected={integration}
          options={integrations}
          onChange={handleChange}
        />
      </Layout.Row>
      {integration.id !== 'otherFrameworks' ? (
        <IntegrationExample
          key={integration.id}
          className={block('example')}
          apiToken={apiToken}
          integration={integration}
          projectIdentifier={projectIdentifier}
          onDownload={downloadTextFile}
          context="empty-leaderboard"
        />
      ) : (
        <OtherFrameworksExample />
      )}
    </Layout.Column>
  );
};
