import { LeaderboardListPaginationOptions } from '@neptune/shared/leaderboard-domain';

export enum PaginationActionTypes {
  changePageSize = 'PAGINATION_CHANGE_PAGE_SIZE',
  paginate = 'PAGINATION_PAGINATE',
  reset = 'PAGINATION_RESET',
}

export const changePageSize = (id: string, pageSize: number) => {
  return {
    type: PaginationActionTypes.changePageSize,
    id,
    payload: {
      pageSize,
    },
  } as const;
};

export const paginate = (
  id: string,
  paginationOptions: Partial<LeaderboardListPaginationOptions>,
) => {
  return {
    type: PaginationActionTypes.paginate,
    id,
    payload: paginationOptions,
  } as const;
};

export const resetPagination = (id: string) => {
  return {
    type: PaginationActionTypes.reset,
    id,
  } as const;
};

export type PaginationActions = ReturnType<
  typeof changePageSize | typeof paginate | typeof resetPagination
>;
