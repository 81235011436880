export enum ColumnsRootKey {
  Runs = 'runs',
  Models = 'models',
  ModelVersions = 'modelVersions',
  Reports = 'reports',
  Trash = 'trash',
  WidgetChartsComparison = 'widgetChartsComparison',
  WidgetImageComparison = 'widgetImageComparison',
  NoKey = 'undefined',
}
