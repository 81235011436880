import {
  createColumnByColumnIdentifier,
  getColumnName,
  LeaderboardListSuggestion,
  SuggestedLeaderboardColumn,
} from '@neptune/shared/leaderboard-domain';

export const enhanceSuggestedColumn = ({
  name,
  type,
}: LeaderboardListSuggestion): SuggestedLeaderboardColumn => {
  const column = createColumnByColumnIdentifier({
    id: name,
    columnType: type,
  });

  return {
    ...column,
    isSuggestion: true,
    title: getColumnName(column),
  };
};
