import React from 'react';
import { Layout, Dropdown, bemBlock } from '@neptune/shared/venus-ui';
import { UserEntry } from '@neptune/shared/core-users-domain';
import { UserAvatar } from '@neptune/shared/avatars-ui';

import './UserItem.less';

const block = bemBlock('dropdown-user-item');

type UserItemProps = {
  user: UserEntry;
  active?: boolean;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent) => void;
  onMouseMove?: (event: React.MouseEvent) => void;
};

export const UserItem: React.FC<UserItemProps> = ({ user, onClick, ...props }) => {
  const { username, firstName, lastName, avatarUrl } = user || {};

  const nameString = `${firstName} ${lastName}`;

  return (
    <Dropdown.Item
      className={block()}
      spacedChildren="sm"
      alignItems="center"
      size="lg"
      span="auto"
      onClick={onClick}
      {...props}
    >
      <UserAvatar size="xs" username={username} imageSrc={avatarUrl} />
      <Layout.Element className={block('name')} span="greedy" title={username}>
        {username}
      </Layout.Element>
      <Layout.Element className={block('info')} span="greedy" title={nameString}>
        {nameString}
      </Layout.Element>
    </Dropdown.Item>
  );
};
