export function extractNamespaceAndField(fieldName: string) {
  const lastSlashIndex = fieldName.lastIndexOf('/');

  if (lastSlashIndex === -1) {
    return { namespace: '', field: fieldName };
  }

  return {
    namespace: fieldName.slice(0, lastSlashIndex),
    field: fieldName.slice(lastSlashIndex + 1),
  };
}
