import { startsWithSegment } from 'router5-helpers';

export function calculateSelectedTab(routeName: string) {
  if (startsWithSegment(routeName, 'project.runs-tab')) {
    return 'runs';
  }

  if (startsWithSegment(routeName, 'project.model-registry')) {
    return 'models';
  }

  if (startsWithSegment(routeName, 'project.metadata')) {
    return 'metadata';
  }

  if (startsWithSegment(routeName, 'project.trash')) {
    return 'trash';
  }

  if (startsWithSegment(routeName, 'project.reports')) {
    return 'reports';
  }
}
