import {
  OrganizationRole,
  organizationRoleFromDomainToApi,
} from '@neptune/shared/core-organizations-domain';
import { ProjectRole, projectRoleFromDomainToApi } from '@neptune/shared/core-project-domain';
import { UserManagementLevel } from '@neptune/user-management-common-domain';
import { makeProjectIdentifier } from 'common/project';

import { backendClient } from '@neptune/shared/core-apis-backend-domain';

import { isOrganizationInvitation, isProjectInvitation } from './invitation-accessors';
import {
  Invitation,
  InvitationType,
  InvitationStatus,
  OrganizationInvitation,
  ProjectInvitation,
  NewOrganizationInvitationProjectEntry,
} from './invitation-model';
import { InvitationModelConverter } from './invitation-model-converter';

type FetchInvitationProps = {
  level: UserManagementLevel;
  invitationId: string;
};

export async function fetchInvitation({
  level,
  invitationId,
}: FetchInvitationProps): Promise<Invitation> {
  if (level === UserManagementLevel.organization) {
    const data = await backendClient.getOrganizationInvitation({ invitationId });
    return InvitationModelConverter.organizationInvitationFromApiToDomain(data);
  }

  const data = await backendClient.getProjectInvitation({ invitationId });
  return InvitationModelConverter.projectInvitationFromApiToDomain(data);
}

export async function acceptInvitation(invitation: Invitation) {
  const invitationId = invitation.id;

  if (!invitationId) {
    return;
  }

  if (isOrganizationInvitation(invitation)) {
    return await backendClient.acceptOrganizationInvitation({ invitationId });
  }

  if (isProjectInvitation(invitation)) {
    return await backendClient.acceptProjectInvitation({ invitationId });
  }
}

export async function resendInvitation(invitation: Invitation) {
  if (invitation.type !== InvitationType.email) {
    return;
  }

  if (isOrganizationInvitation(invitation)) {
    const resendOrganizationInvitation = {
      inviteeEmail: invitation.invitee,
      organizationIdentifier: invitation.organizationName,
    };
    return await backendClient.resendOrganizationInvitation({ resendOrganizationInvitation });
  }

  if (isProjectInvitation(invitation)) {
    const resendProjectInvitation = {
      inviteeEmail: invitation.invitee,
      projectIdentifier: makeProjectIdentifier(invitation.organizationName, invitation.projectName),
    };
    return await backendClient.resendProjectInvitation({ resendProjectInvitation });
  }
}

export async function fetchPendingInvitations(): Promise<Invitation[]> {
  const data = await backendClient.listInvitations();
  return [
    ...data.organizationInvitations.map(
      InvitationModelConverter.organizationInvitationFromApiToDomain,
    ),
    ...data.projectInvitations.map(InvitationModelConverter.projectInvitationFromApiToDomain),
  ]
    .filter((item) => item.status === InvitationStatus.pending)
    .sort((a, b) => +b.createdAt - +a.createdAt);
}

type NewProjectInvitationProps = {
  projectIdentifier: string;
  invitationType: InvitationType;
  invitee: string;
  roleGrant: ProjectRole;
};

export async function newProjectInvitation({
  projectIdentifier,
  invitationType,
  invitee,
  roleGrant,
}: NewProjectInvitationProps): Promise<ProjectInvitation> {
  const data = await backendClient.createProjectInvitation({
    newProjectInvitation: {
      projectIdentifier,
      invitationType: InvitationModelConverter.invitationOriginFromDomainToApi(invitationType),
      invitee,
      roleGrant: projectRoleFromDomainToApi(roleGrant),
    },
  });
  return InvitationModelConverter.projectInvitationFromApiToDomain(data);
}

export async function deleteProjectInvitation({
  invitationId,
}: {
  invitationId: string;
}): Promise<void> {
  await backendClient.revokeProjectInvitation({ invitationId });
}

type NewOrganizationInvitationsProps = {
  organizationIdentifier: string;
  invitationsEntries: Array<{
    invitee: string;
    invitationType: InvitationType;
    roleGrant: OrganizationRole;
    addToProjects?: NewOrganizationInvitationProjectEntry[];
    addToAllProjects: boolean;
  }>;
};

export async function newOrganizationInvitation({
  organizationIdentifier,
  invitationsEntries,
}: NewOrganizationInvitationsProps): Promise<OrganizationInvitation[]> {
  const data = await backendClient.createOrganizationInvitations({
    newOrganizationInvitations: {
      organizationIdentifier,
      invitationsEntries: invitationsEntries.map(
        ({ invitee, invitationType, roleGrant, addToProjects, addToAllProjects }) => ({
          invitee,
          invitationType: InvitationModelConverter.invitationOriginFromDomainToApi(invitationType),
          roleGrant: organizationRoleFromDomainToApi(roleGrant),
          addToAllProjects: addToAllProjects ? addToAllProjects : undefined,
          addToProjects: addToProjects
            ? addToProjects.map(InvitationModelConverter.convertAddToProjectsFromDomainToApi)
            : undefined,
        }),
      ),
    },
  });

  return data.invitations.map(InvitationModelConverter.organizationInvitationFromApiToDomain);
}

export async function deleteOrganizationInvitation({
  invitationId,
}: {
  invitationId: string;
}): Promise<void> {
  await backendClient.revokeOrganizationInvitation({ invitationId });
}

export async function resendOrganizationInvitation({
  invitationId,
}: {
  invitationId: string;
}): Promise<void> {
  await backendClient.resendExistingOrganizationInvitation({ invitationId });
}
