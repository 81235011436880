import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';
import config from 'config';
import {
  AttributeDefinition,
  AttributeDefinitionConverter,
  AttributeType,
} from 'domain/experiment/attribute';

export type SearchAttributesRequest = {
  limit?: number;
  projectIdentifier: string;
  filterAttributeTypes?: AttributeType[];
  query: string;
  search?: string;
  type?: string[];
};

const getCustomSearchAttributesLimit = (limitFromConfig: number, limit?: number) => {
  if (limit == null) {
    return undefined;
  }

  if (limit === 0) {
    return limit;
  }

  return Math.max(limit, limitFromConfig);
};

export async function searchAttributes({
  limit,
  projectIdentifier,
  filterAttributeTypes,
  query,
  search,
  type,
}: SearchAttributesRequest): Promise<AttributeDefinition[]> {
  const limitFromConfig = config.searchAttributesLimit;
  const customLimit = getCustomSearchAttributesLimit(limitFromConfig, limit);

  const result = await leaderboardClient.searchLeaderboardAttributes({
    limit: customLimit,
    projectIdentifier,
    attributeType: filterAttributeTypes,
    params: { query: { query } },
    search,
    type,
  });

  return result.entries.map(AttributeDefinitionConverter.attributeDefinitionFromApiToDomain);
}
