import React from 'react';
import { shuffle } from 'lodash';
import { Layout, Text, bemBlock, RadioWithLabel } from '@neptune/shared/venus-ui';
import { KeycloakLabel } from 'components/keycloak';
import './TeamTypeForm.less';

type TeamTypeFormProps = {
  data: {
    teamType?: string;
    otherTeamTypeDescription?: string;
  };
  onChange: (form: { teamType?: string; otherTeamTypeDescription?: string }) => void;
};

const block = bemBlock('team-type-form');

const TeamTypeName: React.FC = ({ children }) => <Text fontWeight="semibold">{children}</Text>;
const teamTypeGroups = shuffle([
  [
    {
      name: 'Commercial research',
      element: (
        <>
          <TeamTypeName>Commercial research</TeamTypeName> - developing ML models (not used on
          production yet)
        </>
      ),
    },
    {
      name: 'LLM commercial research',
      element: (
        <>
          <TeamTypeName>LLM commercial research</TeamTypeName> - developing LLM models (not used in
          production yet)
        </>
      ),
    },
    {
      name: 'ML Team',
      element: (
        <>
          <TeamTypeName>ML Team</TeamTypeName> - developing production ML models (that are being
          deployed and operated by another team)
        </>
      ),
    },
    {
      name: 'Full-stack ML Team',
      element: (
        <>
          <TeamTypeName>Full-stack ML Team</TeamTypeName> - developing and operating production ML
          models
        </>
      ),
    },
    {
      name: 'ML Platform',
      element: (
        <>
          <TeamTypeName>ML Platform</TeamTypeName> - managing ML/MLOps tech stack and processes used
          by other teams
        </>
      ),
    },
  ],
  [
    {
      name: 'Consulting',
      element: (
        <>
          <TeamTypeName>Consulting</TeamTypeName> - helping other companies use ML in their
          businesses
        </>
      ),
    },
    {
      name: 'Academic research',
      element: (
        <>
          <TeamTypeName>Academic research</TeamTypeName> - researching state-of-the-art AI models
        </>
      ),
    },
  ],
]);
const otherOption = {
  name: 'Not in team',
  element: <>I don’t belong to a team / Other</>,
};

export const TEAM_TYPE_OTHER_OPTION_NAME = otherOption.name;

export const TeamTypeForm: React.FC<TeamTypeFormProps> = ({ onChange, data }) => {
  return (
    <Layout.Column
      component="form"
      spacedChildren="md"
      data-role="team-type-form"
      className={block()}
    >
      <Layout.Column span="auto">
        <KeycloakLabel>What best describes the team you work in?</KeycloakLabel>
      </Layout.Column>
      <Layout.Column spacedChildren="sm" span="auto">
        {teamTypeGroups.map((group) =>
          group.map(({ name, element }) => (
            <RadioWithLabel
              key={name}
              className={block('radio')}
              label={element}
              name={name}
              data-role={name}
              id={name}
              checked={data.teamType === name}
              value={name}
              onChange={() => onChange({ teamType: name })}
            />
          )),
        )}
        <RadioWithLabel
          className={block('radio')}
          label={otherOption.element}
          name={otherOption.name}
          id={otherOption.name}
          checked={data.teamType === otherOption.name}
          value={otherOption.name}
          onChange={() => onChange({ teamType: otherOption.name })}
        />
      </Layout.Column>
    </Layout.Column>
  );
};
