// Libs
import React from 'react';
import { LayoutRowProps, bemBlock } from '@neptune/shared/venus-ui';

// Module
type CellRendererProps = LayoutRowProps<{
  numeric?: boolean;
}>;

const block = bemBlock('n-table-content');
const className = block({ modifiers: { 'with-padding': true } });
const classNameNumeric = block({ modifiers: { 'with-padding': true, numeric: true } });

export const CellRenderer: React.FC<CellRendererProps> = ({ numeric, ...props }) => {
  return <div className={numeric ? classNameNumeric : className} {...props} />;
};
