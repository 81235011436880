import React from 'react';
import { useSelector } from 'react-redux';
import { NormalizedLinkContext, NormalizedLinkContextType } from '@neptune/shared/routing-domain';
import { isEnterpriseContext } from 'router/selectors';

export const NormalizedLinkProvider: React.FC = ({ children }) => {
  const inEnterpriseContext = useSelector(isEnterpriseContext);

  const normalizedLinkContextValue = React.useMemo<NormalizedLinkContextType>(
    () => ({
      inEnterpriseContext,
    }),
    [inEnterpriseContext],
  );

  return <NormalizedLinkContext.Provider value={normalizedLinkContextValue} children={children} />;
};
