/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LicenseDTO,
    LicenseDTOFromJSON,
    LicenseDTOToJSON,
    NeptuneApiToken,
    NeptuneApiTokenFromJSON,
    NeptuneApiTokenToJSON,
    NewOrganizationDTO,
    NewOrganizationDTOFromJSON,
    NewOrganizationDTOToJSON,
    NewUsersDTO,
    NewUsersDTOFromJSON,
    NewUsersDTOToJSON,
    OrganizationTraitName,
    OrganizationTraitNameFromJSON,
    OrganizationTraitNameToJSON,
    OrganizationTraitsSetDTO,
    OrganizationTraitsSetDTOFromJSON,
    OrganizationTraitsSetDTOToJSON,
    SetOrganizationMembersLimitDTO,
    SetOrganizationMembersLimitDTOFromJSON,
    SetOrganizationMembersLimitDTOToJSON,
    UserActionsDTO,
    UserActionsDTOFromJSON,
    UserActionsDTOToJSON,
    UserRegistrationDTO,
    UserRegistrationDTOFromJSON,
    UserRegistrationDTOToJSON,
} from '../models';

export interface AddProjectMonitoringTimeUsageRequest {
    projectIdentifier: string;
    date: Date;
    hoursAmount: number;
}

export interface AddProjectStorageUsageRequest {
    projectIdentifier: string;
    storageBytes: number;
}

export interface CopyProjectRequest {
    projectIdentifier: string;
    targetOrganizationIdentifier: string;
}

export interface CreateOrUpdateOrganizationMembersLimitRequest {
    organizationIdentifier: string;
    membersLimit: SetOrganizationMembersLimitDTO;
}

export interface CreateOrUpdateOrganizationTraitsRequest {
    organizationIdentifier: string;
    organizationTraits: OrganizationTraitsSetDTO;
}

export interface CreateOrganizationRequest {
    newOrganization: NewOrganizationDTO;
}

export interface CreateUsersRequest {
    newUsers: NewUsersDTO;
}

export interface DeleteOrganizationTraitsRequest {
    organizationIdentifier: string;
    traitNames: Array<OrganizationTraitName>;
}

export interface GetUserApiTokenRequest {
    username: string;
}

export interface MarkProjectAsStockRequest {
    projectIdentifier: string;
    stockType: string;
    stockSupply?: number;
}

export interface MoveProjectRequest {
    projectIdentifier: string;
    targetOrganizationIdentifier: string;
}

export interface RegisterUserRequest {
    userData: UserRegistrationDTO;
}

export interface SetLicenseRequest {
    license: LicenseDTO;
}

export interface SetLoginActionsRequest {
    userData: UserActionsDTO;
}

export interface SynchronizeUsersRequest {
    onlyLdap?: boolean;
    xNeptuneApiToken?: string;
}

export interface UpdateOrganizationRequest {
    organizationIdentifier: string;
    nextTopUpTime?: Date;
    limitsEnforced?: boolean;
}

/**
 * no description
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async addProjectMonitoringTimeUsageRaw(requestParameters: AddProjectMonitoringTimeUsageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling addProjectMonitoringTimeUsage.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling addProjectMonitoringTimeUsage.');
        }

        if (requestParameters.hoursAmount === null || requestParameters.hoursAmount === undefined) {
            throw new runtime.RequiredError('hoursAmount','Required parameter requestParameters.hoursAmount was null or undefined when calling addProjectMonitoringTimeUsage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        if (requestParameters.hoursAmount !== undefined) {
            queryParameters['hoursAmount'] = requestParameters.hoursAmount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/v1/projects/tracking`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addProjectMonitoringTimeUsage(requestParameters: AddProjectMonitoringTimeUsageRequest): Promise<void> {
        await this.addProjectMonitoringTimeUsageRaw(requestParameters);
    }

    /**
     */
    async addProjectStorageUsageRaw(requestParameters: AddProjectStorageUsageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling addProjectStorageUsage.');
        }

        if (requestParameters.storageBytes === null || requestParameters.storageBytes === undefined) {
            throw new runtime.RequiredError('storageBytes','Required parameter requestParameters.storageBytes was null or undefined when calling addProjectStorageUsage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.storageBytes !== undefined) {
            queryParameters['storageBytes'] = requestParameters.storageBytes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/v1/projects/storage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addProjectStorageUsage(requestParameters: AddProjectStorageUsageRequest): Promise<void> {
        await this.addProjectStorageUsageRaw(requestParameters);
    }

    /**
     */
    async copyProjectRaw(requestParameters: CopyProjectRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling copyProject.');
        }

        if (requestParameters.targetOrganizationIdentifier === null || requestParameters.targetOrganizationIdentifier === undefined) {
            throw new runtime.RequiredError('targetOrganizationIdentifier','Required parameter requestParameters.targetOrganizationIdentifier was null or undefined when calling copyProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.targetOrganizationIdentifier !== undefined) {
            queryParameters['targetOrganizationIdentifier'] = requestParameters.targetOrganizationIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/v1/projects/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async copyProject(requestParameters: CopyProjectRequest): Promise<string> {
        const response = await this.copyProjectRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createOrUpdateOrganizationMembersLimitRaw(requestParameters: CreateOrUpdateOrganizationMembersLimitRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationIdentifier === null || requestParameters.organizationIdentifier === undefined) {
            throw new runtime.RequiredError('organizationIdentifier','Required parameter requestParameters.organizationIdentifier was null or undefined when calling createOrUpdateOrganizationMembersLimit.');
        }

        if (requestParameters.membersLimit === null || requestParameters.membersLimit === undefined) {
            throw new runtime.RequiredError('membersLimit','Required parameter requestParameters.membersLimit was null or undefined when calling createOrUpdateOrganizationMembersLimit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.organizationIdentifier !== undefined) {
            queryParameters['organizationIdentifier'] = requestParameters.organizationIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/v1/organizations/limits/members`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetOrganizationMembersLimitDTOToJSON(requestParameters.membersLimit),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createOrUpdateOrganizationMembersLimit(requestParameters: CreateOrUpdateOrganizationMembersLimitRequest): Promise<void> {
        await this.createOrUpdateOrganizationMembersLimitRaw(requestParameters);
    }

    /**
     */
    async createOrUpdateOrganizationTraitsRaw(requestParameters: CreateOrUpdateOrganizationTraitsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationIdentifier === null || requestParameters.organizationIdentifier === undefined) {
            throw new runtime.RequiredError('organizationIdentifier','Required parameter requestParameters.organizationIdentifier was null or undefined when calling createOrUpdateOrganizationTraits.');
        }

        if (requestParameters.organizationTraits === null || requestParameters.organizationTraits === undefined) {
            throw new runtime.RequiredError('organizationTraits','Required parameter requestParameters.organizationTraits was null or undefined when calling createOrUpdateOrganizationTraits.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.organizationIdentifier !== undefined) {
            queryParameters['organizationIdentifier'] = requestParameters.organizationIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/v1/organizations/traits`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationTraitsSetDTOToJSON(requestParameters.organizationTraits),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createOrUpdateOrganizationTraits(requestParameters: CreateOrUpdateOrganizationTraitsRequest): Promise<void> {
        await this.createOrUpdateOrganizationTraitsRaw(requestParameters);
    }

    /**
     */
    async createOrganizationRaw(requestParameters: CreateOrganizationRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.newOrganization === null || requestParameters.newOrganization === undefined) {
            throw new runtime.RequiredError('newOrganization','Required parameter requestParameters.newOrganization was null or undefined when calling createOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/v1/organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewOrganizationDTOToJSON(requestParameters.newOrganization),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async createOrganization(requestParameters: CreateOrganizationRequest): Promise<string> {
        const response = await this.createOrganizationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createUsersRaw(requestParameters: CreateUsersRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.newUsers === null || requestParameters.newUsers === undefined) {
            throw new runtime.RequiredError('newUsers','Required parameter requestParameters.newUsers was null or undefined when calling createUsers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/v1/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewUsersDTOToJSON(requestParameters.newUsers),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async createUsers(requestParameters: CreateUsersRequest): Promise<string> {
        const response = await this.createUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteOrganizationTraitsRaw(requestParameters: DeleteOrganizationTraitsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationIdentifier === null || requestParameters.organizationIdentifier === undefined) {
            throw new runtime.RequiredError('organizationIdentifier','Required parameter requestParameters.organizationIdentifier was null or undefined when calling deleteOrganizationTraits.');
        }

        if (requestParameters.traitNames === null || requestParameters.traitNames === undefined) {
            throw new runtime.RequiredError('traitNames','Required parameter requestParameters.traitNames was null or undefined when calling deleteOrganizationTraits.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.organizationIdentifier !== undefined) {
            queryParameters['organizationIdentifier'] = requestParameters.organizationIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/v1/organizations/traits`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.traitNames.map(OrganizationTraitNameToJSON),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteOrganizationTraits(requestParameters: DeleteOrganizationTraitsRequest): Promise<void> {
        await this.deleteOrganizationTraitsRaw(requestParameters);
    }

    /**
     */
    async getUserApiTokenRaw(requestParameters: GetUserApiTokenRequest): Promise<runtime.ApiResponse<NeptuneApiToken>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling getUserApiToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/users/{username}/api-token`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NeptuneApiTokenFromJSON(jsonValue));
    }

    /**
     */
    async getUserApiToken(requestParameters: GetUserApiTokenRequest): Promise<NeptuneApiToken> {
        const response = await this.getUserApiTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async markProjectAsStockRaw(requestParameters: MarkProjectAsStockRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling markProjectAsStock.');
        }

        if (requestParameters.stockType === null || requestParameters.stockType === undefined) {
            throw new runtime.RequiredError('stockType','Required parameter requestParameters.stockType was null or undefined when calling markProjectAsStock.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.stockType !== undefined) {
            queryParameters['stockType'] = requestParameters.stockType;
        }

        if (requestParameters.stockSupply !== undefined) {
            queryParameters['stockSupply'] = requestParameters.stockSupply;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/v1/projects/stock`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async markProjectAsStock(requestParameters: MarkProjectAsStockRequest): Promise<void> {
        await this.markProjectAsStockRaw(requestParameters);
    }

    /**
     */
    async moveProjectRaw(requestParameters: MoveProjectRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling moveProject.');
        }

        if (requestParameters.targetOrganizationIdentifier === null || requestParameters.targetOrganizationIdentifier === undefined) {
            throw new runtime.RequiredError('targetOrganizationIdentifier','Required parameter requestParameters.targetOrganizationIdentifier was null or undefined when calling moveProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.targetOrganizationIdentifier !== undefined) {
            queryParameters['targetOrganizationIdentifier'] = requestParameters.targetOrganizationIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/v1/projects/move`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async moveProject(requestParameters: MoveProjectRequest): Promise<void> {
        await this.moveProjectRaw(requestParameters);
    }

    /**
     */
    async registerUserRaw(requestParameters: RegisterUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userData === null || requestParameters.userData === undefined) {
            throw new runtime.RequiredError('userData','Required parameter requestParameters.userData was null or undefined when calling registerUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/users/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRegistrationDTOToJSON(requestParameters.userData),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async registerUser(requestParameters: RegisterUserRequest): Promise<void> {
        await this.registerUserRaw(requestParameters);
    }

    /**
     */
    async setLicenseRaw(requestParameters: SetLicenseRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.license === null || requestParameters.license === undefined) {
            throw new runtime.RequiredError('license','Required parameter requestParameters.license was null or undefined when calling setLicense.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/admin/license`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LicenseDTOToJSON(requestParameters.license),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setLicense(requestParameters: SetLicenseRequest): Promise<void> {
        await this.setLicenseRaw(requestParameters);
    }

    /**
     */
    async setLoginActionsRaw(requestParameters: SetLoginActionsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userData === null || requestParameters.userData === undefined) {
            throw new runtime.RequiredError('userData','Required parameter requestParameters.userData was null or undefined when calling setLoginActions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/users/actions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserActionsDTOToJSON(requestParameters.userData),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setLoginActions(requestParameters: SetLoginActionsRequest): Promise<void> {
        await this.setLoginActionsRaw(requestParameters);
    }

    /**
     */
    async synchronizeUsersRaw(requestParameters: SynchronizeUsersRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.onlyLdap !== undefined) {
            queryParameters['onlyLdap'] = requestParameters.onlyLdap;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xNeptuneApiToken !== undefined && requestParameters.xNeptuneApiToken !== null) {
            headerParameters['X-Neptune-Api-Token'] = String(requestParameters.xNeptuneApiToken);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/users/synchronize`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async synchronizeUsers(requestParameters: SynchronizeUsersRequest): Promise<void> {
        await this.synchronizeUsersRaw(requestParameters);
    }

    /**
     */
    async updateOrganizationRaw(requestParameters: UpdateOrganizationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationIdentifier === null || requestParameters.organizationIdentifier === undefined) {
            throw new runtime.RequiredError('organizationIdentifier','Required parameter requestParameters.organizationIdentifier was null or undefined when calling updateOrganization.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.organizationIdentifier !== undefined) {
            queryParameters['organizationIdentifier'] = requestParameters.organizationIdentifier;
        }

        if (requestParameters.nextTopUpTime !== undefined) {
            queryParameters['nextTopUpTime'] = (requestParameters.nextTopUpTime as any).toISOString();
        }

        if (requestParameters.limitsEnforced !== undefined) {
            queryParameters['limitsEnforced'] = requestParameters.limitsEnforced;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("oauth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/api/admin/v1/organizations`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateOrganization(requestParameters: UpdateOrganizationRequest): Promise<void> {
        await this.updateOrganizationRaw(requestParameters);
    }

}
