import cookies from 'js-cookie';
import { useEffect, useState } from 'react';

export const useAbTest = <R extends string = string>(testName: string): R | undefined => {
  const [testValue, setTestValue] = useState<string>();

  useEffect(() => {
    const value = cookies.get(testName);
    setTestValue(value);
  }, [testName]);

  return testValue as R;
};
