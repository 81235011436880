/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardsLimitsTraitDTO
 */
export interface DashboardsLimitsTraitDTO {
    /**
     * 
     * @type {number}
     * @memberof DashboardsLimitsTraitDTO
     */
    maxDashboardsPerProject?: number;
}

export function DashboardsLimitsTraitDTOFromJSON(json: any): DashboardsLimitsTraitDTO {
    return DashboardsLimitsTraitDTOFromJSONTyped(json, false);
}

export function DashboardsLimitsTraitDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardsLimitsTraitDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maxDashboardsPerProject': !exists(json, 'maxDashboardsPerProject') ? undefined : json['maxDashboardsPerProject'],
    };
}

export function DashboardsLimitsTraitDTOToJSON(value?: DashboardsLimitsTraitDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maxDashboardsPerProject': value.maxDashboardsPerProject,
    };
}


