import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createCurrentRouteParamSelector,
  getCurrentRouteName,
  navigateTo,
} from '@neptune/shared/routing-business-logic';

import { isValidCompareTab } from './isValidCompareTab';
const getDashFromRouteParams = createCurrentRouteParamSelector('dash');

export const useCompareDashboardTab = (): [string, (nevValue: string) => void] => {
  const dispatch = useDispatch();
  const routeName = useSelector(getCurrentRouteName);
  const dash: string | undefined = useSelector(getDashFromRouteParams);
  const normalizedDash = dash && isValidCompareTab(dash) ? dash : 'charts';

  const handleChange = React.useCallback(
    (clicked: string) => {
      dispatch(
        navigateTo(
          routeName,
          {
            dash: isValidCompareTab(clicked) ? clicked : 'charts',
          },
          {
            extendParams: true,
          },
        ),
      );
    },
    [dispatch, routeName],
  );

  return [normalizedDash, handleChange];
};
