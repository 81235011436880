import { useRecentSearches } from './use-recent-searches';

export const useRecentSearchesMemos = ({ namespace }: { namespace: string }) => {
  return useRecentSearches<string>({
    namespace,
    key: 'recentMemos',
    getInitialState: (lsState, projectIdentifier) =>
      lsState?.recentMemos?.[namespace]?.[projectIdentifier],
  });
};
