import { deleteProjectInvitation, newProjectInvitation } from '@neptune/invitations-domain';
import {
  ALREADY_AN_ADMIN_MODAL,
  ALREADY_INVITED_MODAL_TYPE,
  assignProjectMember,
  fetchProjectMembers,
  leaveProject as leaveProjectRequest,
  MEMBERS_LIMIT_REACHED_MODAL,
  modifyProjectMember,
  ONLY_ADMIN_MODAL,
  removeProjectMember,
} from '@neptune/user-management-domain';
import { handleError } from 'common/error-handler';
import { AnyAction } from 'redux';
import { navigateToHome } from 'router/actions';
import { AsyncActionsReturnType, createAsyncActions, RejectPayload } from 'state/async-actions';
import { NThunkDispatch } from 'state/types';
import { openConfirmationModal } from 'state/ui/global/confirmation-modal/actions';
import { showGenericModal } from 'state/ui/modals/generic/actions';

export enum ProjectMemberListFetchListActionTypes {
  request = 'PROJECT_MEMBER_LIST_FETCH',
  success = 'PROJECT_MEMBER_LIST_FETCH_SUCCESS',
  fail = 'PROJECT_MEMBER_LIST_FETCH_FAIL',
}

export enum ProjectMemberListAddMemberActionTypes {
  request = 'PROJECT_MEMBER_ADD',
  success = 'PROJECT_MEMBER_ADD_SUCCESS',
  fail = 'PROJECT_MEMBER_ADD_FAIL',
}

export enum ProjectMemberListUpdateMemberActionTypes {
  request = 'PROJECT_MEMBER_UPDATE',
  success = 'PROJECT_MEMBER_UPDATE_SUCCESS',
  fail = 'PROJECT_MEMBER_UPDATE_FAIL',
}

export enum ProjectMemberListDeleteMemberActionTypes {
  request = 'PROJECT_MEMBER_DELETE',
  success = 'PROJECT_MEMBER_DELETE_SUCCESS',
  fail = 'PROJECT_MEMBER_DELETE_FAIL',
}

export enum ProjectMemberListCreateInvitationActionTypes {
  request = 'PROJECT_INVITE_CREATE',
  success = 'PROJECT_INVITE_CREATE_SUCCESS',
  fail = 'PROJECT_INVITE_CREATE_FAIL',
}

export enum ProjectMemberListRevokeInvitationActionTypes {
  request = 'PROJECT_INVITE_REVOKE',
  success = 'PROJECT_INVITE_REVOKE_SUCCESS',
  fail = 'PROJECT_INVITE_REVOKE_FAIL',
}

export enum ProjectMemberListLeaveProjectActionTypes {
  request = 'PROJECT_LEAVE_PROJECT',
  success = 'PROJECT_LEAVE_PROJECT_SUCCESS',
  fail = 'PROJECT_LEAVE_PROJECT_FAIL',
}

export const projectMemberListSortActionType = 'PROJECT_MEMBER_LIST_SORT';

const fetchProjectMembersActions = createAsyncActions({
  types: ProjectMemberListFetchListActionTypes,
  resolver: async (params: Parameters<typeof fetchProjectMembers>[0]) => {
    const entries = await fetchProjectMembers(params);
    return { entries };
  },
});

const addProjectMemberActions = createAsyncActions({
  types: ProjectMemberListAddMemberActionTypes,
  resolver: async (params: Parameters<typeof assignProjectMember>[0]) => {
    const data = await assignProjectMember(params);
    return { data };
  },
  onReject: handleAddingErrors,
});

const updateProjectMemberActions = createAsyncActions({
  types: ProjectMemberListUpdateMemberActionTypes,
  resolver: async (params: Parameters<typeof modifyProjectMember>[0]) => {
    const updatedMember = await modifyProjectMember(params);
    return { updatedMember };
  },
  onReject: handleAdminErrors,
});

const deleteProjectMemberActions = createAsyncActions({
  types: ProjectMemberListDeleteMemberActionTypes,
  resolver: async (params: Parameters<typeof removeProjectMember>[0]) => {
    const data = await removeProjectMember(params);
    return { data };
  },
});

const inviteProjectMemberActions = createAsyncActions({
  types: ProjectMemberListCreateInvitationActionTypes,
  resolver: async (params: Parameters<typeof newProjectInvitation>[0]) => {
    const data = await newProjectInvitation(params);
    return { data };
  },
  onReject: handleAddingErrors,
});

const revokeProjectInvitationActions = createAsyncActions({
  types: ProjectMemberListRevokeInvitationActionTypes,
  resolver: deleteProjectInvitation,
});

const leaveProjectActions = createAsyncActions({
  types: ProjectMemberListLeaveProjectActionTypes,
  resolver: leaveProjectRequest,
  onResolve: (payload, dispatch) => {
    dispatch(navigateToHome);
  },
});

function handleAddingErrors<T>(payload: RejectPayload<T>, dispatch: NThunkDispatch<AnyAction>) {
  handleError(409, payload.error, () => {
    dispatch(openConfirmationModal(ALREADY_INVITED_MODAL_TYPE, payload));
  });
  handleError(422, payload.error, () => {
    dispatch(showGenericModal(MEMBERS_LIMIT_REACHED_MODAL));
  });
}

function handleAdminErrors<T>(payload: RejectPayload<T>, dispatch: NThunkDispatch<AnyAction>) {
  handleError(412, payload.error, () => {
    dispatch(showGenericModal(ALREADY_AN_ADMIN_MODAL));
  });
  handleError(409, payload.error, () => {
    dispatch(showGenericModal(ONLY_ADMIN_MODAL));
  });
}

export const { execute: fetchProjectMemberList } = fetchProjectMembersActions;
export const { execute: addProjectMember } = addProjectMemberActions;
export const { execute: updateProjectMember } = updateProjectMemberActions;
export const { execute: deleteProjectMember } = deleteProjectMemberActions;
export const { execute: createProjectInvitation } = inviteProjectMemberActions;
export const { execute: revokeProjectInvitation } = revokeProjectInvitationActions;
export const { execute: leaveProject } = leaveProjectActions;

export function sortProjectMemberList(sortOptions: { sortBy: string; sortOrder: string }) {
  return {
    type: projectMemberListSortActionType,
    payload: { sortOptions },
  } as const;
}

export type ProjectUserManagementActions =
  | AsyncActionsReturnType<typeof fetchProjectMembersActions>
  | AsyncActionsReturnType<typeof addProjectMemberActions>
  | AsyncActionsReturnType<typeof updateProjectMemberActions>
  | AsyncActionsReturnType<typeof deleteProjectMemberActions>
  | AsyncActionsReturnType<typeof inviteProjectMemberActions>
  | AsyncActionsReturnType<typeof revokeProjectInvitationActions>
  | AsyncActionsReturnType<typeof leaveProjectActions>
  | ReturnType<typeof sortProjectMemberList>;
