import { ProjectCodeAccessDTO } from '@neptune/shared/core-apis-backend-domain';

export enum ProjectCodeAccess {
  default = 'default',
  restricted = 'restricted',

  notSupported = 'notSupported',
}

export function projectCodeAccessFromApiToDomain(source?: ProjectCodeAccessDTO): ProjectCodeAccess {
  switch (source) {
    case ProjectCodeAccessDTO.Default:
      return ProjectCodeAccess.default;

    case ProjectCodeAccessDTO.Restricted:
      return ProjectCodeAccess.restricted;

    default:
      return ProjectCodeAccess.notSupported;
  }
}

export function projectCodeAccessFromDomainToApi(source?: ProjectCodeAccess): ProjectCodeAccessDTO {
  switch (source) {
    case ProjectCodeAccess.default:
      return ProjectCodeAccessDTO.Default;

    case ProjectCodeAccess.restricted:
      return ProjectCodeAccessDTO.Restricted;

    default:
      return ProjectCodeAccessDTO.Default;
  }
}
