import actionTypes from 'state/actionTypes';

import { getContextOrganization } from 'state/selectors-global';
import { getDefaultOrganizationName } from 'state/selectors';


export function setDefaultOrganizationName() {
  return (dispatch, getState) => {
    const organization = getContextOrganization(getState());
    const defaultOrganizationName = getDefaultOrganizationName(getState());
    if (!organization || organization.name === defaultOrganizationName) {
      return;
    }

    dispatch({
      type: actionTypes.SET_DEFAULT_ORGANIZATION_NAME,
      payload: { organizationName: organization.name },
    });
  };
}

