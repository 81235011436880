import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';

import { LeaderboardViewConverter } from './leaderboard-view-converter';

export const getLeaderboardView = async (projectIdentifier: string, viewId: string) => {
  const rawView = await leaderboardClient.getLeaderboardView({
    projectIdentifier,
    viewId,
  });

  return LeaderboardViewConverter.fromApiToDomain(rawView);
};
