import {
  InvitationModelConverter,
  OrganizationInvitation,
  ProjectInvitation,
} from '@neptune/invitations-domain';
import {
  ProjectRole,
  projectRoleFromApiToDomain,
  projectRoleFromDomainToApi,
} from '@neptune/shared/core-project-domain';
import { backendClient, ProjectMemberDTO } from '@neptune/shared/core-apis-backend-domain';
import {
  RegisteredMemberInfo,
  registeredMemberInfoFromApiToDomain,
} from './registered-member-info';

export interface ProjectMember {
  role: ProjectRole;
  registeredMemberInfo?: RegisteredMemberInfo;
  organizationInvitationInfo?: OrganizationInvitation;
  invitationInfo?: ProjectInvitation;
  editableRole: boolean;
  canLeaveProject: boolean;
}

export function projectMemberFromApiToDomain(source: ProjectMemberDTO): ProjectMember {
  return {
    role: projectRoleFromApiToDomain(source.role),
    registeredMemberInfo:
      source.registeredMemberInfo &&
      registeredMemberInfoFromApiToDomain(source.registeredMemberInfo),
    organizationInvitationInfo:
      source.organizationInvitationInfo &&
      InvitationModelConverter.organizationInvitationFromApiToDomain(
        source.organizationInvitationInfo,
      ),
    invitationInfo:
      source.invitationInfo &&
      InvitationModelConverter.projectInvitationFromApiToDomain(source.invitationInfo),
    editableRole: source.editableRole,
    canLeaveProject: source.canLeaveProject,
  };
}

export async function fetchProjectMembers(params: {
  projectIdentifier: string;
}): Promise<ProjectMember[]> {
  const result = await backendClient.listProjectMembers({
    projectIdentifier: params.projectIdentifier,
  });
  return result.map(projectMemberFromApiToDomain);
}

type ModifyProjectMemberProps = {
  projectIdentifier: string;
  userId: string;
  member: {
    role: ProjectRole;
  };
};

export async function modifyProjectMember({
  projectIdentifier,
  userId,
  member,
}: ModifyProjectMemberProps): Promise<ProjectMember> {
  const result = await backendClient.updateProjectMember({
    projectIdentifier,
    userId,
    member: { role: projectRoleFromDomainToApi(member.role) },
  });
  return projectMemberFromApiToDomain(result);
}

export async function removeProjectMember({
  projectIdentifier,
  userId,
}: {
  projectIdentifier: string;
  userId: string;
}): Promise<void> {
  await backendClient.deleteProjectMember({ projectIdentifier, userId });
}

type AssignProjectMemberProps = {
  projectIdentifier: string;
  member: {
    userId: string;
    role: ProjectRole;
  };
};

export async function assignProjectMember({
  projectIdentifier,
  member,
}: AssignProjectMemberProps): Promise<ProjectMember> {
  const result = await backendClient.addProjectMember({
    projectIdentifier,
    member: {
      role: projectRoleFromDomainToApi(member.role),
      userId: member.userId,
    },
  });
  return projectMemberFromApiToDomain(result);
}

export async function leaveProject({
  projectIdentifier,
}: {
  projectIdentifier: string;
}): Promise<void> {
  await backendClient.leaveProject({ projectIdentifier });
}
