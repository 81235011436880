// App
import { GitRefCriterion, PartialSearchCriterion } from '../search-query-model';
import { criterionHasAttributeAndOperator } from '../search-query-model-core-utils';

// Module
import { CriterionHelpers } from './types';

export function isGitRefCriterion(criterion: PartialSearchCriterion): criterion is GitRefCriterion {
  return criterionHasAttributeAndOperator(criterion) && criterion.type === 'gitRef';
}

export const stringCriterionHelpers: CriterionHelpers<GitRefCriterion> = {
  getReadableValue(criterion) {
    return `"${criterion.value}"`;
  },
  getShortReadableValue(criterion) {
    return criterion.value;
  },
  isComplete(criterion) {
    return criterion.value != null;
  },
};
