// Libs
import React from 'react';
import { bemBlock, Text } from '@neptune/shared/venus-ui';

// App
import { ExperimentState } from 'domain/experiment/experiment-state';
import Font, { FontProps } from 'components/text/Font';

// Module
import { UnifiedExperimentStateIcon } from './experiment-state-icon/UnifiedExperimentStateIcon';
import './ExperimentStateLabel.less';

export type ExperimentStateLabelProps = {
  label: React.ReactNode;
  small?: boolean;
  state?: ExperimentState;
};

const block = bemBlock('experiment-state-label');

export const ExperimentStateLabel: React.FC<
  ExperimentStateLabelProps & Pick<FontProps, 'weight'>
> = ({ label, state, weight = 'semibold' }) => {
  return (
    <Text className={block()} wordBreak="keep-all">
      <UnifiedExperimentStateIcon state={state} />
      {state && ' '}
      <Font weight={weight}>{label}</Font>
    </Text>
  );
};
