import React from 'react';
import { bemBlock, Button, Icon, IconStack } from '@neptune/shared/venus-ui';

import './ChartsConfigurationButton.less';

const block = bemBlock('charts-configuration-button');

type ChartsConfigurationButtonProps = {
  onClick: () => void;
  showDotIndicator?: boolean;
};

export const ChartsConfigurationButton: React.FC<ChartsConfigurationButtonProps> = ({
  onClick,
  showDotIndicator,
}) => {
  return (
    <Button
      className={block()}
      square
      variant="basic"
      onClick={onClick}
      data-role="open-charts-configuration"
      size="md"
    >
      <IconStack>
        <Icon color="text-alt" size="lg" fixedWidth glyph="x-axis-options" />
        {showDotIndicator && (
          <Icon
            className={block('dot-indicator')}
            size="lg"
            transform={{ x: 0.4, y: 0.4 }}
            glyph="dot"
          />
        )}
      </IconStack>
    </Button>
  );
};
