import { getMutedInvitations, Invitation, setMutedInvitations } from '@neptune/invitations-domain';
import { set } from 'lodash';
import { UserManagementLevel } from '@neptune/user-management-common-domain';

export function muteInvitation(invitation: Invitation) {
  if (invitation.id) {
    muteInvitationImpl('byId', invitation.id);
  } else if (invitation.level === UserManagementLevel.organization) {
    muteInvitationImpl('organizations', invitation.organizationName);
  } else if (invitation.level === UserManagementLevel.project) {
    muteInvitationImpl('projects', invitation.projectName);
  }
}

function muteInvitationImpl(criteria: 'byId' | 'organizations' | 'projects', key: string) {
  const muted = getMutedInvitations();
  const updated = set(muted, [criteria, key], Date.now());
  setMutedInvitations(updated);
}

export function isInvitationMuted(invitation: Invitation, now: number): boolean {
  const muted = getMutedInvitations();
  const then = now - 12 * 60 * 60 * 1000;
  const lastMuted =
    (invitation.id && muted?.byId?.[invitation.id]) ||
    (invitation.level === UserManagementLevel.organization &&
      muted?.organizations?.[invitation.organizationName]) ||
    (invitation.level === UserManagementLevel.project && muted?.projects?.[invitation.projectName]);
  return !!(lastMuted && lastMuted >= then);
}
