import React from 'react';
import { bemBlock, Dialog } from '@neptune/shared/venus-ui';

import './AcceptInvitation.less';

const block = bemBlock('accept-invitation');

type InvitationErrorProps = {
  message?: string;
  onClose?: () => Promise<void> | void;
};

export const InvitationError: React.FC<InvitationErrorProps> = ({
  message = 'Invitation is invalid',
  onClose,
}) => {
  return (
    <div className={block()}>
      <Dialog
        isOpen
        title="Failed"
        closeOnBackgroundClick={false}
        children={message}
        primaryButtonRole="confirm-button"
        onPrimaryButtonClick={onClose}
      />
    </div>
  );
};
