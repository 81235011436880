import { isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { toNormalizedRouteName } from 'router/helpers';
import { OrganizationType } from '@neptune/shared/core-organizations-domain';
import { getPageId } from 'common/pages';
import { getSuperReaderOrganization } from '@neptune/shared/core-super-reader-business-logic';

/**
 * @deprecated
 * This whole module(file) is read/remove only.
 * Do NOT add new selectors here.
 * If you need to create new selector try to keep it as close to reducer as possible.
 * If for some reason your selector has to be global, add to 'state/selectors-global.ts'
 */
export default function getState(state) {
  return state;
}

const getLeaderboardsUIState = (state) => state.ui.leaderboards;

const getSideBarsVisibility = (state) => state.ui.sideBars;

const getSideBarVisibility = (state, id) => {
  return getSideBarsVisibility(state)[id] !== false;
};

const getConfirmationModalStateByType = (state, type) => {
  return state.ui.globalUI.confirmationModal.modalOpened[type];
};

const getConfirmationModalDataByType = (state, type) => {
  return state.ui.globalUI.confirmationModal.modalData[type];
};

const getNotifications = (state) => {
  return state.ui.globalUI.notificationPopup;
};

const getNotificationById = createSelector([getNotifications, (state, id) => id], (state, id) => {
  return state.notifications.find((notification) => notification.id === id);
});

/**
 * @deprecated
 * Use typed version from 'selectors-global.ts'
 */
const getCurrentRoute = createSelector(
  (state) => state.router.route,
  (route) => {
    if (route == null) {
      return route;
    }

    const { name, ...other } = route;
    return {
      name: toNormalizedRouteName(name),
      ...other,
    };
  },
);

/**
 * @deprecated
 * Use typed version from 'selectors-global.ts'
 */
const getCurrentRouteName = (state) => {
  return getCurrentRoute(state)?.name;
};

/**
 * @deprecated
 * Use typed version from 'selectors-global.ts'
 */
const getCurrentRouteParams = (state) => {
  const { params } = getCurrentRoute(state) || {};
  return params || {};
};

const getCurrentRoutePath = (state) => {
  return getCurrentRouteParams(state).path || '';
};

const getCurrentRouteFile = (state) => {
  return getCurrentRouteParams(state).file || '';
};

const getCurrentRouteFilePath = (state) => {
  return getCurrentRouteParams(state).filePath || '';
};

const getCurrentRouteAttribute = (state) => {
  return getCurrentRouteParams(state).attribute || '';
};

const getCurrentRouteAttributePath = (state) => {
  return getCurrentRouteParams(state).path || '';
};

// {{{ Current User
/**
 * @deprecated Use selector defined locally in currentUser reducer
 */
const getCurrentUserState = (state) => state.users.currentUser;

/**
 * @deprecated Use selector defined locally in currentUser reducer
 */
const getCurrentUser = (state) => {
  return getCurrentUserState(state).user;
};

/**
 * @deprecated Use selector defined locally in currentUser reducer
 */
const getCurrentUsername = (state) => {
  const user = getCurrentUser(state);

  return user != null ? user.username : '';
};
// Current User }}}

const getPublicUserState = (state) => state.users.publicUser;

/* Dashboards selectors --> */

const getDashboards = (state) => state.dashboards;
/** @deprecated */
const getExperimentDashboard = (state) => getDashboards(state).experimentDashboard;
/* <-- Dashboards selectors */

const getProjectLeaderboard = (state) => state.projectLeaderboard;

const getOrganizations = (state) => state.organizations;
const getOrganizationsList = createSelector(
  (state) => getOrganizations(state).organizations,
  (state) => getSuperReaderOrganization(state),
  (organizations, superReaderOrganization) =>
    superReaderOrganization ? [superReaderOrganization, ...organizations] : organizations,
);
const getDefaultOrganizationName = (state) => getOrganizations(state).defaultOrganizationName;

/**
 * @deprecated
 * Use typed version from 'selectors-global.ts'
 */
const getCurrentOrganization = (state) => {
  const { organizationName } = getCurrentRouteParams(state);

  return getOrganizationsList(state).find((organization) => organization.name === organizationName);
};

const getCommunityOrganization = (state) => {
  return getOrganizationsList(state).find(
    (organization) => organization.organizationType === OrganizationType.individual,
  );
};

const getDefaultOrganization = (state) => {
  const organizationName = getDefaultOrganizationName(state);
  const lastOrganization = getOrganizationsList(state).find(
    (organization) => organization.name === organizationName,
  );

  return lastOrganization || getCommunityOrganization(state) || getOrganizationsList(state)[0];
};

/**
 * @deprecated
 * Use typed version from 'selectors-global.ts'
 */
const getContextOrganization = (state) => {
  const routeName = getCurrentRouteName(state);

  /* home and accept-invitation routes are the only context-less routes and contains no organization name - imply */
  if (['home', 'accept-invitation'].includes(routeName)) {
    return getDefaultOrganization(state);
  }

  return getCurrentOrganization(state) || getDefaultOrganization(state);
};

const getContextOrganizationId = (state) => {
  return (getContextOrganization(state) || {}).id;
};

const getModals = (state) => state.ui.modals;

const getDeletedProjectInfo = (state) => {
  const { projectBeingDeleted, runningExperimentsCount } = state.ui.modals.project;

  return {
    projectBeingDeleted,
    runningExperimentsCount,
  };
};

const getGenericModalState = (state, name) => {
  return getModals(state).generic.modalState[name];
};

const getGenericModalData = (state, name) => {
  return getModals(state).generic.modalData[name];
};

// Views selectors --->
const viewsSelector = (selector) => (state) => selector(state.views);

// Projects view
const getProjectsView = viewsSelector((state) => state.projectsView);
const getProjectsViewProjects = (state) => getProjectsView(state).projects;

const getProjectsAvatars = createSelector(
  getProjectsViewProjects,
  (projects) => new Set(projects?.list?.map((project) => project.avatarUrl) || []),
);

const getProjectView = viewsSelector((state) => state.projectView);
const getPropertiesView = (state) => getProjectView(state).properties;

// User profile view
const getUserProfileView = viewsSelector((state) => state.userProfileView);
const getUserProfileViewProjects = (state) => getUserProfileView(state).projects;

// <--- views selectors

const getPages = (state) => state.pages;

const getCurrentPage = createSelector([getPages, getCurrentRouteParams], (pages, routeParams) => {
  const pageId = getPageId(routeParams.pageId);
  return pages.entries.find((page) => page.id === pageId);
});

function getLastSelectedEntry(state) {
  return getLeaderboardsUIState(state).lastSelectedEntry;
}

function getScrolledIntoView(state) {
  return state.global.scrolledIntoView;
}

const canScrollIntoView = createSelector(
  [getScrolledIntoView, (state, params) => params],
  (scrolledIntoView, params) => {
    return !scrolledIntoView.some((storedParams) => isEqual(storedParams, params));
  },
);

export {
  getCommunityOrganization,
  getLeaderboardsUIState,
  getSideBarsVisibility,
  getSideBarVisibility,
  getConfirmationModalStateByType,
  getConfirmationModalDataByType,
  getNotifications,
  getNotificationById,
  getCurrentRoute,
  getCurrentRouteName,
  getCurrentRouteParams,
  getCurrentRoutePath,
  getCurrentRouteFile,
  getCurrentRouteFilePath,
  getCurrentRouteAttribute,
  getCurrentRouteAttributePath,
  getContextOrganization,
  getContextOrganizationId,
  getCurrentUser,
  getCurrentUserState,
  getCurrentUsername,
  getPublicUserState,
  getDashboards,
  getExperimentDashboard,
  getProjectLeaderboard,
  getOrganizations,
  getOrganizationsList,
  getDefaultOrganizationName,
  getCurrentOrganization,
  getModals,
  getDeletedProjectInfo,
  getGenericModalState,
  getGenericModalData,
  getPropertiesView,
  getProjectsView,
  getProjectsViewProjects,
  getUserProfileViewProjects,
  getPages,
  getCurrentPage,
  getLastSelectedEntry,
  canScrollIntoView,
  getProjectsAvatars,
  getState,
};
