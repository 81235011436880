import { KnownAttributes } from 'domain/experiment/attribute';
import { SearchQueryModelConverter } from './search-query-model-converter';

export function enhanceQueryWithExperimentsOnly(query?: string, experimentsOnly?: boolean) {
  if (!experimentsOnly) {
    return query;
  }

  return SearchQueryModelConverter.convertSearchQueryToNql({
    criteria: [
      SearchQueryModelConverter.convertNqlToSearchQuery(query),
      {
        attribute: KnownAttributes.Name,
        type: 'string',
        operator: '!=',
        value: '',
      },
    ],
    operator: 'and',
  });
}
