import {
  OrganizationTraits,
  PricingPlan,
  ProjectPrivacy,
} from '@neptune/shared/core-organizations-domain';
import { isProjectPrivacyAvailable } from '@neptune/shared/core-project-business-logic';

const isPlanLowerThanOrganization: Record<PricingPlan, boolean> = {
  v1_free: false,
  v1_team: false,
  v1_academia: false,
  v2_trial: true,
  v2_team: true,
  v2_academia: false,
  v1_individual: false,
  v2_individual: true,
  organization: false,
  v2_trial_organization: false,
  enterprise: false,

  // todo @project-based-pricing: fix values for new plans and check usages of tooltips
  v3_free_individual: false,
  v3_team: false,
  v3_scale: false,
  v3_trial_scale: false,
};

export const shouldShowProjectRoleTooltip = (
  pricingPlan: PricingPlan,
  privacy: ProjectPrivacy,
): boolean => {
  return privacy === ProjectPrivacy.workspace && isPlanLowerThanOrganization[pricingPlan];
};

export const shouldShowProjectAccessTooltip = (
  pricingPlan: PricingPlan,
  organizationTraits: OrganizationTraits,
): boolean => {
  const userCannotCreatePrivateProjects = !isProjectPrivacyAvailable(
    ProjectPrivacy.private,
    organizationTraits,
  );

  return userCannotCreatePrivateProjects && isPlanLowerThanOrganization[pricingPlan];
};
