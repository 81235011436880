import { concatMap, delay, identity, Observable, of, OperatorFunction, share } from 'rxjs';
import { webSocket, WebSocketSubjectConfig } from 'rxjs/webSocket';
import { retryBackoff, RetryBackoffConfig } from 'backoff-rxjs';

type CreateWebSocketStreamParams<T = unknown> = WebSocketSubjectConfig<T> & {
  identifier?: string;
  retryBackoffConfig?: RetryBackoffConfig;
};

export function createWebSocketStream<T = unknown>({
  url,
  identifier,
  retryBackoffConfig,
  ...rest
}: CreateWebSocketStreamParams<T>): Observable<T> {
  const defaultConfig: Partial<WebSocketSubjectConfig<T>> = {
    serializer: (value: T) => JSON.stringify(value),
    deserializer: parseEvent,
    openObserver: {
      next() {
        // eslint-disable-next-line no-console
        console.debug('Socket for', identifier, 'is opened!');
      },
    },
    closingObserver: {
      next() {
        // eslint-disable-next-line no-console
        console.debug('Socket for', identifier, 'is going to close now!');
      },
    },
    closeObserver: {
      next() {
        // eslint-disable-next-line no-console
        console.debug('Connection for', identifier, 'is closed!');
      },
    },
  };

  /**
   * We convert observable to asynchronous because of strange bug that ends up with race condition between
   * unsubscribe and unsubscribe. Websocket connection closes in a result and doesn't open with new subscription.
   * When using async scheduler this problem does not exist.
   */
  const websocket$ = asyncObservable(0).pipe(
    concatMap(() =>
      webSocket<T>({
        ...defaultConfig,
        ...rest,
        url,
      }),
    ),
  );

  return websocket$.pipe(optionalRetry(retryBackoffConfig), share());
}

// region internals
function asyncObservable(delayTime: number): Observable<any> {
  return of(true).pipe(delay(delayTime));
}

function optionalRetry<T = unknown>(retryConfig?: RetryBackoffConfig): OperatorFunction<T, T> {
  return retryConfig != null ? retryBackoff(retryConfig) : identity;
}

function parseEvent<T = any>(event: MessageEvent): T {
  let data = {};

  try {
    data = JSON.parse(event.data);
  } catch (e) {}

  return data as T;
}
// endregion
