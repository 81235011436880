import { deserializeAsStringArray } from '@neptune/shared/routing-business-logic';
import { TransitionSuccessAction } from '@neptune/shared/routing-domain';
import { isEmpty } from 'lodash';
import { actionTypes as routerActionTypes } from 'redux-router5';
import { GroupCompareActions, LeaderboardGroupCompareActionTypes } from './group-compare-actions';

interface GroupCompareInnerState {
  selectedGroups: Record<string, number | undefined>;
  runIdsInGroup: Record<string, string[]>;
  last: number;
}

const groupCompareInnerInitialState: GroupCompareInnerState = {
  selectedGroups: {},
  runIdsInGroup: {},
  last: 0,
};

export type GroupCompareState = Record<string, GroupCompareInnerState | undefined>;

const groupCompareInnerReducer = (
  state: GroupCompareInnerState = groupCompareInnerInitialState,
  action: GroupCompareActions | TransitionSuccessAction,
): GroupCompareInnerState => {
  switch (action.type) {
    case routerActionTypes.TRANSITION_SUCCESS: {
      const currentRoute = action.payload.route;
      const previousRoute = action.payload.previousRoute;

      if (!previousRoute || !currentRoute) {
        return state;
      }

      const currentGroupByParams = deserializeAsStringArray(currentRoute.params.groupBy);
      const previousGroupByParams = deserializeAsStringArray(previousRoute.params.groupBy);

      const routeChanged = currentRoute.name !== previousRoute.name;
      const groupByColumnsRemoved =
        !isEmpty(previousGroupByParams) && isEmpty(currentGroupByParams);

      if (routeChanged || groupByColumnsRemoved) {
        return { ...groupCompareInnerInitialState };
      }

      return state;
    }

    case LeaderboardGroupCompareActionTypes.success: {
      if (state.last > action.payload.created) {
        return state;
      }

      const selectedGroups = Object.fromEntries(
        action.payload.groupCount.map(({ id, itemCount }) => [id, itemCount]),
      );

      return {
        ...state,
        last: action.payload.created,
        selectedGroups,
        runIdsInGroup: action.payload.runIdsInGroup,
      };
    }

    default:
      return state;
  }
};

export const groupCompareReducer = (
  state: GroupCompareState = {},
  action: GroupCompareActions | TransitionSuccessAction,
) => {
  if (action.type === routerActionTypes.TRANSITION_SUCCESS) {
    return Object.entries(state).reduce<GroupCompareState>((acc, [id, state]) => {
      acc[id] = groupCompareInnerReducer(state, action);
      return acc;
    }, {});
  }

  if (action.payload?.leaderboardIdentifier) {
    const oldSubstate = state[action.payload.leaderboardIdentifier];
    const newSubstate = groupCompareInnerReducer(
      state[action.payload.leaderboardIdentifier],
      action,
    );

    if (oldSubstate === newSubstate) {
      return state;
    }

    return {
      ...state,
      [action.payload.leaderboardIdentifier]: newSubstate,
    };
  }

  return state;
};
