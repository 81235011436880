import React from 'react';
import { Link } from '@neptune/shared/venus-ui';
import { neptuneUrls } from '@neptune/shared/common-domain';

export const FailedIconTooltipText: React.ReactNode = (
  <>
    Failed due to crash or abort during execution. <br />
    <Link href={neptuneUrls.apiSysFailedDoc}>Learn more in the docs.</Link>
  </>
);
