import React from 'react';
import { isServiceAccount, useWorkingProject } from '@neptune/shared/common-business-logic';
import { prepareRegexpSearchWithAnchors } from '@neptune/shared/common-util';
import { Attribute, isStringAttribute, KnownAttributes } from 'domain/experiment/attribute';
import { QueryFilterSearch } from '@neptune/shared/search-domain';
import { regexpSearchFilter } from '@neptune/shared/search-business-logic';
import { OwnerEntry, OwnerType } from '@neptune/object-table-search-domain';
import { queryUniqueAttributeValues } from '@neptune/shared/leaderboard-domain';

export function useQueryOwnerDataSource(): QueryFilterSearch<OwnerEntry> {
  const { projectIdentifier } = useWorkingProject();

  const rawQuery = React.useCallback(
    async (query: string): Promise<Attribute[]> => {
      const result = await queryUniqueAttributeValues({
        projectIdentifier,
        attributeName: KnownAttributes.Owner,
        attributeType: 'string',
        limit: 250,
        search: prepareRegexpSearchWithAnchors(query),
      });

      return result.attributes;
    },
    [projectIdentifier],
  );

  const prepareOutput = React.useCallback((collection: Attribute[], search: string) => {
    const userNames: string[] = collection
      .map((attribute) => (isStringAttribute(attribute) ? attribute.value : null))
      .filter((val): val is string => !!val);

    const owners: OwnerEntry[] = userNames.map((userName) => {
      return {
        value: userName,
        type: isServiceAccount(userName) ? OwnerType.ServiceAccount : OwnerType.User,
      };
    });

    const allResults = regexpSearchFilter<OwnerEntry>(search, owners, ({ value }) => value);

    return allResults;
  }, []);

  return React.useCallback(
    async (query: string) => {
      const collection = await rawQuery(query);
      const entries = prepareOutput(collection, query);

      return {
        entries,
      };
    },
    [rawQuery, prepareOutput],
  );
}
