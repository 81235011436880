import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { getLeaderboardGroups } from '@neptune/shared/leaderboard-business-logic';
import { extractGroupTagValue } from 'components/advanced-table/renderers/colors';
import { isNotUndefined } from 'common/tsHelpers';

type GroupTagSettingsContextProps = {
  isTagsSettingEnabled: boolean;
  enabledRunsMatchTags: Set<string>;
  toggleRunsMatchTag?: (groupTag: string) => void;
  toggleAllRunsMatchTags?: () => void;
  partiallyEnabledRunsMatchTags: boolean;
  allRunsMatchTagsEnabled: boolean;
};

const STABLE_REF_EMPTY_ARRAY: [] = [];

type GroupTagSettingsProviderProps = {
  id: string;
  matchTagsDisabled?: boolean;
  children: React.ReactNode;
};

const GROUP_TAG_SETTINGS_DEFAULT_VALUE: GroupTagSettingsContextProps = {
  enabledRunsMatchTags: new Set(),
  isTagsSettingEnabled: false,
  allRunsMatchTagsEnabled: false,
  partiallyEnabledRunsMatchTags: false,
  toggleRunsMatchTag: undefined,
  toggleAllRunsMatchTags: undefined,
};

const GroupTagSettingsContext = React.createContext<GroupTagSettingsContextProps>(
  GROUP_TAG_SETTINGS_DEFAULT_VALUE,
);

export function GroupTagSettingsProvider({
  matchTagsDisabled,
  children,
  id,
}: GroupTagSettingsProviderProps) {
  const groups = useSelector((state) => getLeaderboardGroups(state, id));

  const groupValues = React.useMemo(
    () => groups?.map(extractGroupTagValue).filter(isNotUndefined) ?? STABLE_REF_EMPTY_ARRAY,
    [groups],
  );

  const [enabledRunsMatchTags, setEnabledRunsMatchTags] = React.useState(new Set<string>());

  const allRunsMatchTagsEnabled = React.useMemo(
    () => !isEmpty(groupValues) && groupValues.every((group) => enabledRunsMatchTags.has(group)),
    [enabledRunsMatchTags, groupValues],
  );

  const partiallyEnabledRunsMatchTags = React.useMemo(
    () => !allRunsMatchTagsEnabled && groupValues.some((group) => enabledRunsMatchTags.has(group)),
    [allRunsMatchTagsEnabled, groupValues, enabledRunsMatchTags],
  );

  const toggleAllRunsMatchTags = React.useCallback(() => {
    if (allRunsMatchTagsEnabled) {
      setEnabledRunsMatchTags(new Set());
    } else {
      setEnabledRunsMatchTags(new Set(groupValues));
    }
  }, [allRunsMatchTagsEnabled, groupValues]);

  const toggleRunsMatchTag = React.useCallback((groupTag: string) => {
    setEnabledRunsMatchTags((prev) => {
      const newValue = new Set(prev);

      if (newValue.has(groupTag)) {
        newValue.delete(groupTag);
      } else {
        newValue.add(groupTag);
      }

      return newValue;
    });
  }, []);

  const context = React.useMemo(() => {
    if (matchTagsDisabled) {
      return { ...GROUP_TAG_SETTINGS_DEFAULT_VALUE, isTagsSettingEnabled: true };
    }

    return {
      enabledRunsMatchTags,
      toggleRunsMatchTag,
      toggleAllRunsMatchTags,
      allRunsMatchTagsEnabled,
      partiallyEnabledRunsMatchTags,
      isTagsSettingEnabled: true,
    };
  }, [
    matchTagsDisabled,
    enabledRunsMatchTags,
    toggleRunsMatchTag,
    toggleAllRunsMatchTags,
    allRunsMatchTagsEnabled,
    partiallyEnabledRunsMatchTags,
  ]);

  return (
    <GroupTagSettingsContext.Provider value={context}>{children}</GroupTagSettingsContext.Provider>
  );
}

export const useGroupTagSettings = () => {
  return React.useContext(GroupTagSettingsContext);
};
