// Libs
import React from 'react';
import { Icon, IconProps, bemBlock, Layout } from '@neptune/shared/venus-ui';

// App
import { ExperimentState, isStateIdle, isStateRunning } from 'domain/experiment/experiment-state';

// Module
import './SeparateExperimentStateIcon.less';
import { InfoTooltip } from '@neptune/shared/common-ui';
import { FailedIconTooltipText } from './FailedIconTooltipText';

const block = bemBlock('separate-experiment-state-icon');

type SeparateExperimentStateIconProps = {
  className?: string;
  state?: ExperimentState;
  title?: string;
  failed?: boolean;
  showIdle?: boolean;
} & Pick<IconProps, 'size'>;

export const SeparateExperimentStateIcon: React.FC<SeparateExperimentStateIconProps> = ({
  state,
  showIdle,
  failed,
  size,
  className,
}) => {
  if (!state) {
    return null;
  }

  const isRunning = isStateRunning(state);
  const isIdle = isStateIdle(state);

  return (
    <Layout.Row
      className={className}
      inline
      spacedChildren="sm"
      data-role="state-icon"
      data-activity={state}
      data-failed={failed}
    >
      {showIdle && isIdle && (
        <Icon className={block({ modifiers: state })} size={size} glyph="circle-alt" />
      )}
      {isRunning && (
        <Icon className={block({ modifiers: state })} size={size} glyph="circle-notch" spin />
      )}
      {failed && (
        <InfoTooltip text={FailedIconTooltipText}>
          <Icon glyph="exclamation-circle" color="error" size={size} data-failed />
        </InfoTooltip>
      )}
    </Layout.Row>
  );
};
