import React from 'react';
import { Banner, Link, Text } from '@neptune/shared/venus-ui';

type VersionConflictBannerProps = {
  type: 'report' | 'dashboard';
  onSave: () => void;
  onOverride: () => void;
};

export const VersionConflictBanner = ({ onSave, onOverride, type }: VersionConflictBannerProps) => {
  return (
    <Banner.Warning size="small" dataRole="dashboard-save-conflict">
      <Text>
        The contents of this report have been modified by another user. You can{' '}
        <Link data-role="dashboard-conflict-save" onClick={onSave}>
          save your changes as a new {type === 'report' ? 'report' : 'dashboard'}
        </Link>
        , or{' '}
        <Link data-role="dashboard-conflict-override" onClick={onOverride}>
          override the changes
        </Link>
        .
      </Text>
    </Banner.Warning>
  );
};
