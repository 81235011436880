import { OrganizationRole, OrganizationWithRole } from '@neptune/shared/core-organizations-domain';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';
import { hasAtLeastRole } from './organization-role-utils';

import {
  isAtLeastMemberOfProject,
  isManagerOfProject,
  isMemberOfProject,
  isOwnerOfOrganization,
} from './project-role-utils';

export function hasProjectSettingsAccessPermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isManagerOfProject(project);
}

export function hasDashboardCreatePermission(project: ProjectWithRole | undefined): boolean {
  return isAtLeastMemberOfProject(project);
}

export function hasDashboardModifyPermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isAtLeastMemberOfProject(project);
}

export function hasLbViewModifyPermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isAtLeastMemberOfProject(project);
}

export function hasSuggestionsAccessPermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isAtLeastMemberOfProject(project);
}

export function hasEntityModifyPermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isAtLeastMemberOfProject(project);
}

export function hasEntityDeletePermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isAtLeastMemberOfProject(project);
}

export function hasEntityAbortPermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isAtLeastMemberOfProject(project);
}

export function hasOrganizationAccessPermission(project: ProjectWithRole | undefined): boolean {
  return (
    isSuperReader(project) ||
    isOwnerOfOrganization(project) ||
    project?.userRoleInOrganization === OrganizationRole.member
  );
}

export function hasProjectMembersManagePermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isOwnerOfOrganization(project) || isManagerOfProject(project);
}

export function hasProjectArchivePermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isManagerOfProject(project);
}

export function hasProjectEditPermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isManagerOfProject(project);
}

export function hasProjectDeletePermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isOwnerOfOrganization(project);
}

export function hasProjectUnarchivePermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isManagerOfProject(project);
}

export function hasProjectServiceAccountPermissions(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isManagerOfProject(project);
}

export function hasProjectUsageManagingPermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isManagerOfProject(project);
}

export function hasProjectUsageViewPermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isMemberOfProject(project);
}

export function hasProjectUsageAccessPermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isAtLeastMemberOfProject(project);
}

export function hasMembersManagePermission(
  organization: OrganizationWithRole | undefined,
): boolean {
  return isSuperReader(organization) || hasAtLeastRole(organization, OrganizationRole.owner);
}

export function hasWorkspaceDeletePermission(
  organization: OrganizationWithRole | undefined,
): boolean {
  return isSuperReader(organization) || hasAtLeastRole(organization, OrganizationRole.owner);
}

export function hasSubscriptionManagePermission(
  organization: OrganizationWithRole | undefined,
): boolean {
  return isSuperReader(organization) || hasAtLeastRole(organization, OrganizationRole.owner);
}

export function hasWorkspaceServiceAccountPermissions(
  organization: OrganizationWithRole | undefined,
): boolean {
  return isSuperReader(organization) || hasAtLeastRole(organization, OrganizationRole.owner);
}

export function hasWorkspaceSettingsPermissions(
  organization: OrganizationWithRole | undefined,
): boolean {
  return isSuperReader(organization) || hasAtLeastRole(organization, OrganizationRole.owner);
}

export function hasOrganizationViewAccessPermission(
  organization: OrganizationWithRole | undefined,
): boolean {
  return isSuperReader(organization) || hasAtLeastRole(organization, OrganizationRole.member);
}

export function hasReportsListModifyPermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isAtLeastMemberOfProject(project);
}

export function hasReportModifyPermission(project: ProjectWithRole | undefined): boolean {
  return isSuperReader(project) || isAtLeastMemberOfProject(project);
}

export function hasReportCreatePermission(project: ProjectWithRole | undefined): boolean {
  return isAtLeastMemberOfProject(project);
}

function isSuperReader(
  organizationOrProject: OrganizationWithRole | ProjectWithRole | undefined,
): boolean {
  return !!organizationOrProject?.superUser;
}
