import { IntercomTour } from '@neptune/onboarding-business-logic';
import { isOnPremiseDeployment } from 'common/deploymentModes';
import { useVisibilityChange } from '@neptune/shared/common-ui';
import { OrganizationWithRole, OrganizationType } from '@neptune/shared/core-organizations-domain';
import React from 'react';
import { useSelector } from 'react-redux';
import { getOrganizationsList } from 'state/selectors';
import { getCurrentUserCreated, getCurrentUsername } from 'state/users/current-user/selectors';

const enrollmentDateOfPromptToIntegrateNeptuneWithCodeTour = new Date('2021-09-15');

export const PromptToIntegrateNeptuneWithCodeTourTrigger: React.FC = () => {
  const onPrem = isOnPremiseDeployment();
  const username = useSelector(getCurrentUsername);
  const userCreated = useSelector(getCurrentUserCreated);
  const organizationsList = useSelector(getOrganizationsList);
  const [started, setStarted] = React.useState(false);
  const [hidden, setHidden] = React.useState<boolean>();
  useVisibilityChange(setHidden);

  const userBelongsToATeamWorkspace = organizationsList.some(
    (organization: OrganizationWithRole) => organization.organizationType === OrganizationType.team,
  );
  const userSignedUpAfterEnrollment = userCreated
    ? userCreated > enrollmentDateOfPromptToIntegrateNeptuneWithCodeTour
    : false;

  React.useEffect(() => {
    if (
      onPrem ||
      started ||
      hidden ||
      userBelongsToATeamWorkspace ||
      !userSignedUpAfterEnrollment
    ) {
      return;
    }

    trigger();

    async function trigger() {
      setStarted(true);

      const priorTourPlayedDate = await IntercomTour.getLastTriggerTime(
        username,
        'onboarding/day_1/run_details_ui_tour',
      );

      if (!priorTourPlayedDate) {
        return;
      }

      await IntercomTour.triggerTour(username, 'onboarding/day_1/prompt_to_integrate_with_code');
    }
  }, [onPrem, started, hidden, userBelongsToATeamWorkspace, userSignedUpAfterEnrollment, username]);

  return null;
};
