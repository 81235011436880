import React from 'react';
import { neptuneUrls } from '@neptune/shared/common-domain';
import { BannerNotice } from '@neptune/shared/common-ui';
import { Text } from '@neptune/shared/venus-ui';

export const ProjectNameNotice: React.FC<{ projectIdentifier: string; isVisible?: boolean }> = ({
  projectIdentifier,
  isVisible = false,
}) => {
  return (
    <BannerNotice
      command={`run = neptune.init_run(project='${projectIdentifier}')`}
      isVisible={isVisible}
      seeMoreHref={neptuneUrls.settingProjectNameDoc}
    >
      <Text>
        <span>
          To log runs to this project, you need to pass the{' '}
          <Text fontWeight="semibold">project name</Text> in your Python code.
        </span>
        <span> </span>
      </Text>
    </BannerNotice>
  );
};
