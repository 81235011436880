// Libs
import { AnyAction } from 'redux';

// App
import { AppState, NThunkAction, NThunkDispatch } from 'state/types';

// Module
type OneOrAnother<T1, T2> = T1 extends undefined ? T2 : T1;
type AsyncActionTypes = {
  request: string;
  success: string;
  fail: string;
};

export type GetState = () => AppState;
export type RejectPayload<R_PAYLOAD> = {
  requestPayload: R_PAYLOAD;
  error: any;
};

type CreatorOptions<T extends AsyncActionTypes, R, ARGS, R_PAYLOAD = ARGS> = {
  types: T;
  resolver: (args: ARGS, getState: GetState) => Promise<R>;
  shouldResolve?: (getState: GetState) => boolean;
  requestPayloadSelector?: (getState: GetState, args: ARGS) => R_PAYLOAD;
  onResolve?: (payload: R, dispatch: NThunkDispatch<AnyAction>, getState: GetState) => void;
  onReject?: (
    payload: RejectPayload<R_PAYLOAD | ARGS>,
    dispatch: NThunkDispatch<AnyAction>,
    getState: GetState,
  ) => void;
};

export function createAsyncActions<T extends AsyncActionTypes, R, ARGS, R_PAYLOAD = ARGS>(
  options: CreatorOptions<T, R, ARGS, R_PAYLOAD>,
) {
  const {
    types,
    resolver,
    shouldResolve = () => true,
    requestPayloadSelector,
    onResolve,
    onReject,
  } = options;

  const execute = (args: ARGS): NThunkAction<Promise<R | undefined>, AnyAction> => {
    return async (dispatch, getState) => {
      if (!shouldResolve(getState)) {
        return;
      }

      const requestPayload = requestPayloadSelector ? requestPayloadSelector(getState, args) : args;
      dispatch(request(requestPayload));

      try {
        const result = await resolver(args as ARGS, getState);
        dispatch(success(result));

        if (onResolve) {
          onResolve(result, dispatch, getState);
        }

        return result;
      } catch (e) {
        dispatch(fail(e, args));

        if (onReject) {
          onReject(createRejectPayload(requestPayload, e), dispatch, getState);
        }
      }
    };
  };

  function createRejectPayload(payload: R_PAYLOAD | ARGS, error: any) {
    return {
      requestPayload: payload as OneOrAnother<R_PAYLOAD, ARGS>,
      error,
    } as const;
  }

  function request(payload: R_PAYLOAD | ARGS) {
    return {
      type: types.request as T['request'],
      payload: payload as OneOrAnother<R_PAYLOAD, ARGS>,
    } as const;
  }

  function success(payload: R) {
    return {
      type: types.success as T['success'],
      payload,
    } as const;
  }

  function fail(error: any, payload: ARGS) {
    return {
      type: types.fail as T['fail'],
      error,
      payload,
    } as const;
  }

  return {
    execute,
    request,
    success,
    fail,
  };
}

// helper types }}}
type AsyncActionsObject = {
  execute(...args: any[]): any;
  request(...args: any[]): any;
  success(...args: any[]): any;
  fail(...args: any[]): any;
};

export type AsyncActionsReturnType<A extends AsyncActionsObject> = ReturnType<
  A['request'] | A['success'] | A['fail']
>;
// helper types {{{
