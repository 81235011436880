import React from 'react';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';
import { Entity, getEntityShortId } from '@neptune/shared/entity-domain';
import { TrashEntityModal } from '@neptune/shared/entity-ui';
import { DetailsDropdownMenu } from '@neptune/entity-common-ui';
import { useTrashEntity } from '@neptune/shared/entity-business-logic';
import {
  getOrganizationNameFromRouteParams,
  getProjectNameFromRouteParams,
} from 'state/selectors-global';
import { WithPermissions } from '@neptune/shared/project-domain';
import { UnderEditContext } from '@neptune/shared/venus-domain';
import { DetailsDropdownMenuContentContainer } from './DetailsDropdownMenuContentContainer';

export type DetailsDropdownMenuContainerProps = {
  onCopy?: () => void;
  entityWithPermissions: WithPermissions<Entity>;
  constraints?: React.ComponentProps<typeof DetailsDropdownMenu>['constraints'];
};

export const DetailsDropdownMenuContainer: React.FC<DetailsDropdownMenuContainerProps> = ({
  onCopy,
  constraints,
  entityWithPermissions,
}) => {
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const projectName = useSelector(getProjectNameFromRouteParams);
  const shortId = getEntityShortId(entityWithPermissions);

  const { startEdit, stopEdit } = React.useContext(UnderEditContext);

  const {
    cancelTrash,
    executeTrash,
    isOpen: isTrashModalOpen,
    requestTrash,
  } = useTrashEntity({
    organizationName,
    projectName,
    entity: entityWithPermissions,
  });

  // TODO: remove once extended for other object types
  if (entityWithPermissions.type !== 'run') {
    return null;
  }

  return (
    <>
      <DetailsDropdownMenu constraints={constraints} onExpand={startEdit} onCollapse={stopEdit}>
        <DetailsDropdownMenuContentContainer
          collapse={noop}
          onCopy={onCopy}
          requestTrash={requestTrash}
          entityWithPermissions={entityWithPermissions}
        />
      </DetailsDropdownMenu>
      <TrashEntityModal
        isOpen={isTrashModalOpen}
        cancel={cancelTrash}
        execute={executeTrash}
        type={entityWithPermissions.type}
        shortId={shortId}
      />
    </>
  );
};
