// Libs
import React from 'react';
import { bemBlock, Sidebar } from '@neptune/shared/venus-ui';

// Module
import './AppSidebarElement.less';

type AppSidebarElementProps = React.ComponentProps<typeof Sidebar.Element> & {
  expanded?: boolean;
};

const block = bemBlock('app-sidebar-element');

export const AppSidebarElement: React.FC<AppSidebarElementProps> = ({
  children,
  title,
  className,
  selected = false,
  expanded = true,
  onClick,
  ...props
}) => {
  const cssClass = block({
    modifiers: {
      collapsed: !expanded,
    },
    extra: [className],
  });

  return (
    <Sidebar.Element
      {...props}
      className={cssClass}
      title={title}
      selected={selected}
      onClick={onClick}
    >
      {children}
    </Sidebar.Element>
  );
};
