import { makeEnterpriseRoute } from 'router/helpers';

const serviceAccountsRoute = {
  name: 'organization.service-accounts',
  path: '~/:organizationName/-/service-accounts',
};

export const serviceAccountsRoutes = [
  serviceAccountsRoute,
  makeEnterpriseRoute({
    ...serviceAccountsRoute,
    path: '~/o/:organizationName/-/service-accounts',
  }),
];
