import { makeEntityIdentifier } from '@neptune/shared/entity-util';

import { getEntityShortId } from './entity-model-utils';
import { Entity } from './entity-model';

export function matchEntityById(identifier: string, entity?: Entity) {
  if (!entity) {
    return false;
  }

  const idFromEntity = entity.id;
  const identifierFromEntity = makeEntityIdentifier(
    entity.organizationName,
    entity.projectName,
    getEntityShortId(entity),
  );

  return [idFromEntity, identifierFromEntity].includes(identifier);
}
