import React from 'react';
import { useRecentSearchesMemos } from './use-recent-searches-memos';
import { useRecentCompoundQueries } from './use-recent-search-queries';
import {
  ComplexSearchFilter,
  ComplexSearchFilterConverter,
  CompoundSearchCriterion,
  isRegexFilter,
  trimQuery,
  isEmptyFilter,
} from '@neptune/recent-searches-domain';
import { SearchMode } from '@neptune/shared/search-domain';

export const useCompoundSearchHistory = ({
  namespace,
  mode,
}: {
  namespace: string;
  mode: SearchMode;
}) => {
  const { searches: textSearches, saveSearch: saveTextSearch } = useRecentSearchesMemos({
    namespace,
  });
  const { searches: compoundSearches, saveSearch: saveCompoundSearch } = useRecentCompoundQueries({
    namespace: `${namespace}-compound`,
  });

  const textSearchesFilters: ComplexSearchFilter[] = React.useMemo(
    () => textSearches.map(ComplexSearchFilterConverter.fromChartFilter),
    [textSearches],
  );

  const compoundSearchFilters: ComplexSearchFilter[] = React.useMemo(
    () => compoundSearches.map(ComplexSearchFilterConverter.fromRegexCriterions),
    [compoundSearches],
  );

  const searches = mode === SearchMode.REGEX ? compoundSearchFilters : textSearchesFilters;

  const handleSaveSearch = React.useCallback(
    (filter?: ComplexSearchFilter): void => {
      if (filter && !isEmptyFilter(filter)) {
        if (isRegexFilter(filter)) {
          const newValue = trimQuery(filter.value);
          saveCompoundSearch(newValue, areSearchesEqual);
        } else {
          saveTextSearch(filter.value);
        }
      }
    },
    [saveCompoundSearch, saveTextSearch],
  );

  return {
    searches,
    handleSaveSearch,
  };
};

function areSearchesEqual(a: CompoundSearchCriterion[], b: CompoundSearchCriterion[]) {
  const aText = a.map((criterion) => criterion.value).join(' ');
  const bText = b.map((criterion) => criterion.value).join(' ');
  return aText === bText;
}
