import { ChartGlobalConfig, Widget, WidgetLayout } from 'domain/widget';
import type { GlyphName } from '@neptune/shared/core-glyphs-domain';
import { RunsLineage, TableViewParams } from '@neptune/shared/leaderboard-domain';

export type Dashboard = {
  autoComparePool?: number;
  color?: string;
  icon?: GlyphName;
  draft?: boolean;
  id: string;
  name: string;
  gridLayouts: WidgetLayouts;
  runIds?: string[];
  type: DashboardType;
  widgets: Widget[];
  autoGenerated?: boolean;
  lastUpdatedTime?: Date;
  createdTime?: Date;
  experimentShortId?: string;
  owner?: string;
  versionBranchId: string;
  versionName?: string;
  versionDescription?: string;
  viewParams?: TableViewParams;
  config?: DashboardConfig;
  userExperimentNames?: string[];
  colorsConfig?: Record<string, string>;
};

export interface NewDashboard {
  autoComparePool?: number;
  color?: string;
  experimentShortId?: string;
  icon?: string;
  gridLayouts: WidgetLayouts;
  name: string;
  isDraft?: boolean;
  runIds?: string[];
  type: DashboardType;
  runsLineage?: RunsLineage;
  experimentsOnly?: boolean;
  versionDescription?: string;
  versionName?: string;
  widgets: Widget[];
  viewParams?: TableViewParams;
  config?: DashboardConfig;
  userExperimentNames?: string[];
  colorsConfig?: Record<string, string>;
}

export type NewVersionDashboard = Omit<NewDashboard, 'experimentShortId'> & {
  versionBranchId: string;
};

export type WidgetLayouts = WidgetLayout[];

export type DashboardType = 'compare' | 'experiment' | 'report';

export function isSingleEntityDashboard(type: DashboardType) {
  return type === 'experiment';
}

export type DashboardConfig = {
  metricsStepsRange?: {
    from?: number;
    to?: number;
  };

  chartGlobalConfig?: ChartGlobalConfig;
};
