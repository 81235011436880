import React from 'react';
import { useDispatch } from 'react-redux';

import { DeactivateServiceAccountModal } from '@neptune/service-accounts-ui';
import { ServiceAccount, deactivateServiceAccount } from '@neptune/service-accounts-domain';
import { useGenericModal } from '@neptune/shared/common-business-logic';
import { showServerErrorModal } from 'state/ui/modals/server-error/actions';

type DeactivateServiceAccountModalContainerProps = {
  onSubmit(): void;
};

export const DEACTIVATE_SERVICE_ACCOUNT_MODAL_NAME = 'DEACTIVATE_SERVICE_ACCOUNT_MODAL';

export const DeactivateServiceAccountModalContainer: React.FC<
  DeactivateServiceAccountModalContainerProps
> = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const { isOpen, close, data } = useGenericModal<ServiceAccount | undefined>(
    DEACTIVATE_SERVICE_ACCOUNT_MODAL_NAME,
  );

  const handleSubmit = React.useCallback(async () => {
    if (!data) {
      return;
    }

    try {
      await deactivateServiceAccount(data.organizationId, data.id);
      close();
      onSubmit();
    } catch (e) {
      dispatch(showServerErrorModal());
    }
  }, [close, data, dispatch, onSubmit]);

  if (!data) {
    return null;
  }

  return (
    <DeactivateServiceAccountModal
      isOpen={isOpen}
      onClose={close}
      onSubmit={handleSubmit}
      serviceAccount={data}
    />
  );
};
