// Libs
import React from 'react';
import { Layout } from '@neptune/shared/venus-ui';

// App
import { ProjectMemberListContainer } from '@neptune/user-management-feature';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';

type PeopleProps = {
  project: ProjectWithRole;
};

export const People = ({ project }: PeopleProps) => (
  <Layout.Column>
    <ProjectMemberListContainer project={project} />
  </Layout.Column>
);
