// Libs
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router5';
import { Store } from 'redux';
import { Router } from 'router5/types/types/router';

// App
import { Registration } from 'views/register/Registration';
import { renderInvitations } from '@neptune/invitations-api';
import SplashScreen from 'components/splash/SplashScreen';
import { ErrorBoundaryContainer } from '@neptune/shared/common-feature';
import { navigateTo } from 'router/actions';
import { NormalizedLinkProvider } from '@neptune/shared/routing-feature';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'initialization/query-client';

export const renderApplication = (store: Store, router: Router, Component: React.ComponentType) => {
  render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <RouterProvider router={router}>
          <NormalizedLinkProvider>
            <ErrorBoundaryContainer context="root">
              <Component />
            </ErrorBoundaryContainer>
          </NormalizedLinkProvider>
        </RouterProvider>
      </Provider>
    </QueryClientProvider>,
    document.getElementById('app'),
  );
};

export const renderSplashScreen = () => {
  render(<SplashScreen />, document.getElementById('app'));
};

export const renderInvitation = (store: Store, router: Router) =>
  new Promise<Parameters<typeof navigateTo> | undefined>((callback) =>
    renderInvitations(store, router, callback, document.getElementById('app')),
  );

export const renderRegistration = (store: Store, router: Router) =>
  new Promise<void>((resolve) => {
    render(
      <Provider store={store}>
        <RouterProvider router={router}>
          <NormalizedLinkProvider>
            <Registration onFinish={resolve} />
          </NormalizedLinkProvider>
        </RouterProvider>
      </Provider>,
      document.getElementById('app'),
    );
  });
