import { AsyncActionsReturnType, createAsyncActions } from 'state/async-actions';

import { fetchProject, ProjectWithRole } from '@neptune/shared/core-project-domain';

export enum FetchCurrentProjectActionTypes {
  request = 'CURRENT_PROJECT_FETCH',
  success = 'CURRENT_PROJECT_FETCH_SUCCESS',
  fail = 'CURRENT_PROJECT_FETCH_FAIL',
}

export enum SetIndicatedProjectEntitiesActionTypes {
  set = 'SET_INDICATED_PROJECT_ENTITIES',
  remove = 'REMOVE_INDICATED_PROJECT_ENTITIES',
}

const actions = createAsyncActions({
  types: FetchCurrentProjectActionTypes,
  resolver({ projectIdentifier }: { projectIdentifier: string }): Promise<ProjectWithRole> {
    return fetchProject({ projectIdentifier });
  },
});

type SetIndicatedEntityParams = {
  ids: string[];
};

export function setIndicatedProjectEntity(payload: SetIndicatedEntityParams) {
  return { type: SetIndicatedProjectEntitiesActionTypes.set, payload } as const;
}

export function removeIndicatedProjectEntity() {
  return { type: SetIndicatedProjectEntitiesActionTypes.remove } as const;
}

export const { execute: fetchCurrentProject } = actions;
export type CurrentProjectAction =
  | AsyncActionsReturnType<typeof actions>
  | ReturnType<typeof setIndicatedProjectEntity | typeof removeIndicatedProjectEntity>;
