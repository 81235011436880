import React from 'react';
import {
  getInvitationEntityName,
  getInvitationTarget,
  Invitation,
} from '@neptune/invitations-domain';
import { bemBlock, Dialog, Text } from '@neptune/shared/venus-ui';

import './AcceptInvitation.less';

type AcceptEmailInvitationProps = {
  invitation: Invitation;
  onClose: () => void;
  onResend: () => void;
};
const block = bemBlock('accept-invitation');

export const AcceptEmailInvitation: React.FC<AcceptEmailInvitationProps> = ({
  invitation,
  onClose,
  onResend,
}) => {
  const target = getInvitationTarget(invitation);
  const entityString = getInvitationEntityName(invitation);

  return (
    <div className={block()}>
      <Dialog
        isOpen
        title={`Join ${target}`}
        closeOnBackgroundClick={false}
        onClose={onClose}
        primaryButtonLabel="Resend invite"
        primaryButtonRole="confirm-button"
        onPrimaryButtonClick={onResend}
        onSecondaryButtonClick={onClose}
      >
        <Text fontWeight="semibold">{invitation.invitedBy}</Text> invited{' '}
        <Text fontWeight="semibold">{invitation.invitee}</Text> to join{' '}
        <Text fontWeight="semibold">{entityString}</Text>.<br />
        <br />
        To confirm your identity as <Text fontWeight="semibold">{invitation.invitee}</Text> and
        accept the invitation you need to open the invitation link you received in the email.
      </Dialog>
    </div>
  );
};
