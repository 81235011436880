import { createSelector } from 'reselect';
import { AppState } from 'state/types';

import { ColumnsRootKey, OrderedColumnsState } from '@neptune/shared/columns-domain';
import { LeaderboardColumn } from '@neptune/shared/leaderboard-domain';

import { ensureDefaultColumns, orderedColumnsSorter } from '../utils';

export function getOrderedColumnsState(state: any): OrderedColumnsState {
  return state.orderedColumns;
}

export function getOrderedColumns(
  state: AppState,
  columnsRootKey: ColumnsRootKey,
  columnsSetId: string,
): LeaderboardColumn[] | undefined {
  return getOrderedColumnsState(state)[columnsRootKey]?.columns?.[columnsSetId];
}

export function getDefaultColumnsIds(state: AppState, columnsRootKey: ColumnsRootKey): string[] {
  return getOrderedColumnsState(state)[columnsRootKey]?.defaultColumnsIds || [];
}

export const getNonEmptyColumnsSet = createSelector(
  [getDefaultColumnsIds, getOrderedColumns],
  (defaultColumnsIds, columns) =>
    ensureDefaultColumns(columns || [], defaultColumnsIds).sort(orderedColumnsSorter),
);

export default getOrderedColumnsState;
