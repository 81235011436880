import actionTypes from 'state/actionTypes';

export function closeConfirmationModal(modalType: string) {
  return { type: actionTypes.CONFIRMATION_MODAL_CLOSE, modalType } as const;
}

export function openConfirmationModal(modalType: string, modalData?: any) {
  return { type: actionTypes.CONFIRMATION_MODAL_OPEN, modalType, modalData } as const;
}

export type ConfirmationActions = ReturnType<
  typeof closeConfirmationModal | typeof openConfirmationModal
>;
