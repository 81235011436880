import React from 'react';

type SuggestionsOptionsContextType = {
  suggestions?: {
    enabled: boolean;
    viewId?: string;
  };
};
export const SuggestionsOptions = React.createContext<SuggestionsOptionsContextType>({});

export const useSuggestionsOptions = () => {
  const { suggestions } = React.useContext(SuggestionsOptions);

  return suggestions;
};
