import { OrganizationWithRole } from '@neptune/shared/core-organizations-domain';

export const UPDATE_NEPTUNE_ROLE = 'UPDATE_NEPTUNE_ROLE' as const;
export const SUPER_READER_ORGANIZATION_FETCH_SUCCESS =
  'SUPER_READER_ORGANIZATION_FETCH_SUCCESS' as const;

export function updateNeptuneRole(neptuneInstanceRole?: string, neptuneRole?: string) {
  return {
    type: UPDATE_NEPTUNE_ROLE,
    payload: {
      neptuneRole,
      neptuneInstanceRole,
    },
  } as const;
}

export function fetchSuperReaderOrganizationSuccess(superReaderOrganization: OrganizationWithRole) {
  const payload = {
    superReaderOrganization,
  };
  return { type: SUPER_READER_ORGANIZATION_FETCH_SUCCESS, payload };
}

type ActionCreators = typeof updateNeptuneRole | typeof fetchSuperReaderOrganizationSuccess;

export type NeptuneInternalsActions = ReturnType<ActionCreators>;
