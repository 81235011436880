import React from 'react';
import { useSelector } from 'react-redux';
import { EntityDetailsModal } from '@neptune/shared/entity-ui';
import { EntityPropertiesContainer } from '@neptune/entity-common-feature';
import { VersionPropertiesLayout } from './VersionPropertiesLayout';
import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';
import { KnownAttributes } from 'domain/experiment/attribute';
import { useEntityModalDetails } from '@neptune/shared/entity-business-logic';
import { EntityTypeToDetailsModalName } from '@neptune/shared/entity-domain';

const versionDetailFieldsToFetch = [
  KnownAttributes.ModelId,
  KnownAttributes.Id,
  KnownAttributes.Stage,
  KnownAttributes.Owner,
  KnownAttributes.CreationTime,
  KnownAttributes.ModificationTime,
  KnownAttributes.Size,
  KnownAttributes.MonitoringTime,
  KnownAttributes.State,
  KnownAttributes.Description,
  KnownAttributes.Tags,
  KnownAttributes.Trashed,
  KnownAttributes.GroupTags,
];

export const VersionDetailsModal: React.FC = () => {
  const {
    isOpen,
    closeModal,
    isLoading,
    entityWithPermissions: versionWithPermissions,
    updateEntity: updateVersion,
  } = useEntityModalDetails(versionDetailFieldsToFetch, EntityTypeToDetailsModalName.modelVersion);
  const projectIdentifier = useSelector(getCurrentProjectIdentifier);

  return (
    <EntityDetailsModal
      title="Model version information"
      isOpen={isOpen}
      close={closeModal}
      isLoading={isLoading}
    >
      {versionWithPermissions && (
        <EntityPropertiesContainer
          entity={versionWithPermissions}
          LayoutComponent={VersionPropertiesLayout}
          onEntityChange={updateVersion}
          projectIdentifier={projectIdentifier}
        />
      )}
    </EntityDetailsModal>
  );
};
