export enum GenericModalActionTypes {
  show = 'GENERIC_MODAL_SHOW',
  hide = 'GENERIC_MODAL_HIDE',
}

export function showGenericModal<T = any>(name: string, data?: T) {
  return {
    type: GenericModalActionTypes.show,
    payload: {
      name,
      data,
    },
  } as const;
}

export function hideGenericModal(name: string) {
  return {
    type: GenericModalActionTypes.hide,
    payload: {
      name,
    },
  } as const;
}

export type GenericModalActions = ReturnType<typeof showGenericModal | typeof hideGenericModal>;
