import { LeaderboardListPaginationOptions } from '@neptune/shared/leaderboard-domain';
import { PaginationActions, PaginationActionTypes } from './pagination-actions';
import { storage } from 'common/storage';

export type PaginationState = Record<string, LeaderboardListPaginationOptions | undefined>;

export const PAGE_SIZES = [10, 25, 50, 100, 200];
export const DEFAULT_PAGE_SIZE = 50;
export const MIN_PAGE_SIZE = PAGE_SIZES[0];

export const defaultPaginationOptions: LeaderboardListPaginationOptions = {
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
  total: 0,
};

const reducer = (
  state = defaultPaginationOptions,
  action: PaginationActions,
): LeaderboardListPaginationOptions => {
  switch (action.type) {
    case PaginationActionTypes.changePageSize: {
      const { pageSize } = action.payload;
      const { offset } = state;
      const newOffset = offset - (offset % pageSize);

      return {
        ...state,
        offset: newOffset,
        limit: pageSize,
      };
    }

    case PaginationActionTypes.paginate: {
      return {
        ...state,
        ...action.payload,
        limit: state.limit,
      };
    }

    case PaginationActionTypes.reset: {
      return {
        ...defaultPaginationOptions,
        limit: state.limit,
      };
    }
  }

  return state;
};

const localStoragePageSizes = storage.local.getItem('leaderboardPageSize');

const initialState: PaginationState = localStoragePageSizes
  ? Object.fromEntries(
      Object.entries(localStoragePageSizes)
        .filter((pageSizeEntry): pageSizeEntry is [string, number] => !!pageSizeEntry[1])
        .map(([id, pageSize]) => [id, { ...defaultPaginationOptions, limit: pageSize }]),
    )
  : {};

export const paginationReducer = (
  state: PaginationState = initialState,
  action: PaginationActions,
): PaginationState => {
  switch (action.type) {
    case PaginationActionTypes.changePageSize:
    case PaginationActionTypes.paginate:
    case PaginationActionTypes.reset:
      return {
        ...state,
        [action.id]: reducer(state[action.id], action),
      };
  }

  return state;
};
