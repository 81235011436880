export function toggleArrayValue<T>(array: T[], value: T): T[] {
  const updatedArray = [...array];
  const index = updatedArray.indexOf(value);

  if (index === -1) {
    updatedArray.push(value);
  } else {
    updatedArray.splice(index, 1);
  }

  return updatedArray;
}
