import React from 'react';
import { bemBlock, Button, Icon } from '@neptune/shared/venus-ui';

import './LargeNewSectionButton.less';

const block = bemBlock('large-new-section-button');

export const LargeNewSectionButton: React.FC<{
  onAddSection?: () => void;
}> = ({ onAddSection }) => {
  return (
    <>
      {onAddSection && (
        <Button
          size="xl"
          className={block()}
          variant="basic"
          onClick={onAddSection}
          data-role="add-section-widget"
        >
          <Icon glyph="plus" /> New section
        </Button>
      )}
    </>
  );
};
