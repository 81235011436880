import React from 'react';
import {
  StageChangeConfirmationContext,
  StageChangeModalData,
} from '@neptune/model-registry-common-domain';

export const StageChangeConfirmationProvider: React.FC = ({ children }) => {
  const [data, setData] = React.useState<StageChangeModalData | null>(null);

  const closeModal = React.useCallback(() => {
    setData(null);
  }, []);

  const value = React.useMemo(
    () => ({
      data,
      openModal: setData,
      closeModal,
      isOpen: !!data,
    }),
    [data, closeModal],
  );

  return (
    <StageChangeConfirmationContext.Provider value={value}>
      {children}
    </StageChangeConfirmationContext.Provider>
  );
};
