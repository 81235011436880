// Libs
import transitionPath from 'router5-transition-path';
import urlParse from 'url-parse';

// App
import { authClient } from '@neptune/shared/core-auth-domain';
import { isUserAuthenticated } from 'state/users/current-user/selectors';

// Module
export const routerForOnlyLoggedUserMW = (router, dependencies) => (toState, fromState) => {
  const {
    store,
    routes,
  } = dependencies;

  const authenticated = isUserAuthenticated( store.getState() );

  if (authenticated) {
    // user authenticated, allowed to enter any route because permissions are checked on a component level
    return true;
  }

  const { toActivate } = transitionPath(toState, fromState);

  const shouldLogin = toActivate.some(segment => {
    const routeSegment = routes.find(r => r.name === segment);

    if (!routeSegment) {
      return false;
    }

    return routeSegment.onlyLoggedUser === true;
  });

  if (shouldLogin) {
    // make user land on requested url after authentication
    const redirectUri = (
      urlParse(window.location.origin).set('pathname', toState.path).toString()
    );

    const loginUrl = authClient.createLoginUrl({
      redirectUri,
    });
    window.location.replace(loginUrl);
    /*
      location.replace() is asynchronous and does not block script execution so don't return value (bool) here because
      it gives control back to router that will trigger TRANSITION_ERROR and make the app state inconsistent
      until location.replace() has its effect.
     */

    return undefined;
  }

  // pass
  return true;

};
