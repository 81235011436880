// Libs
import React from 'react';

// App
import { fetchStatus } from 'state/fetch-status';
import { fetchServiceAccountApiToken, TokenState } from '@neptune/service-accounts-domain';

// Module

type UseServiceAccountsTokensProps = {
  organizationName?: string;
};

type TokensMap = Record<string, TokenState | undefined>;

export const useServiceAccountsTokens = ({ organizationName }: UseServiceAccountsTokensProps) => {
  const [tokensMap, setTokensMap] = React.useState<TokensMap>({});

  const updateState = React.useCallback(
    (key: string, value: Partial<TokenState>) => {
      const currentState: TokenState = tokensMap?.[key] || {
        isShown: false,
        fetchStatus: fetchStatus.NONE,
      };
      const newState = {
        ...currentState,
        ...value,
      };
      setTokensMap({
        ...tokensMap,
        [key]: newState,
      });
    },
    [tokensMap, setTokensMap],
  );

  const fetchApiToken = React.useCallback(
    async (serviceAccountId: string) => {
      if (!organizationName) {
        return;
      }

      updateState(serviceAccountId, { fetchStatus: fetchStatus.PENDING });

      try {
        const token = await fetchServiceAccountApiToken(serviceAccountId);
        updateState(serviceAccountId, {
          fetchStatus: fetchStatus.SUCCESS,
          token,
        });
        return token;
      } catch (e) {
        updateState(serviceAccountId, { fetchStatus: fetchStatus.FAILED });
      }
    },
    [organizationName, updateState],
  );

  const getApiToken = React.useCallback(
    async (serviceAccountId: string) => {
      return await fetchApiToken(serviceAccountId);
    },
    [fetchApiToken],
  );

  const toggleApiToken = React.useCallback(
    async (serviceAccountId: string) => {
      const state = tokensMap[serviceAccountId];

      if (!state?.isShown) {
        if (state?.token) {
          updateState(serviceAccountId, { isShown: true });
          return;
        }

        const token = await fetchApiToken(serviceAccountId);
        updateState(serviceAccountId, {
          fetchStatus: fetchStatus.SUCCESS,
          token,
          isShown: true,
        });
        return;
      }

      updateState(serviceAccountId, { isShown: false });
    },
    [tokensMap, updateState, fetchApiToken],
  );

  const resetApiToken = React.useCallback(
    (id: string) => {
      setTokensMap({
        ...tokensMap,
        [id]: undefined,
      });
    },
    [tokensMap],
  );

  React.useEffect(() => {
    setTokensMap({});
  }, [organizationName]);

  return {
    getApiToken,
    resetApiToken,
    toggleApiToken,
    tokensMap,
  };
};
