import React from 'react';
import { Banner, Icon, Layout, Link } from '@neptune/shared/venus-ui';

type StatusBannerProps = {
  'data-role'?: string;
  error?: boolean;
  onClose?: () => void;
};

export const StatusBanner: React.FC<StatusBannerProps> = ({
  children,
  'data-role': dataRole,
  error,
  onClose,
}) => {
  return (
    <Banner
      data-role={dataRole}
      data-type={error ? 'error' : 'warning'}
      type={error ? 'error' : 'warning'}
      size="small"
    >
      <Layout.Row alignItems="center" spacedChildren="md">
        {children}
        <Layout.Fill />
        {onClose && (
          <Link<React.ComponentProps<typeof Icon>>
            component={Icon}
            glyph="times"
            size="lg"
            onClick={onClose}
          />
        )}
      </Layout.Row>
    </Banner>
  );
};
