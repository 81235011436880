import {
  AttributeStyleSettingsDTO,
  leaderboardClient,
} from '@neptune/shared/core-apis-leaderboard-domain';
import { KnownAttributes } from 'domain/experiment/attribute';

export const updateGroupTagColor = async (
  projectIdentifier: string,
  tag: string,
  color: string,
) => {
  await leaderboardClient.putAttributeStyleSettings({
    params: {
      projectIdentifier,
      styleSettings: {
        attribute: {
          name: KnownAttributes.GroupTags,
          value: tag,
        },
        color: convertColorHexFromDomainToApi(color),
      },
    },
  });
};

export const fetchAllGroupTagColors = async (
  projectIdentifier: string,
): Promise<Map<string, string> | undefined> => {
  const { settings } = await leaderboardClient.queryAttributeStyleSettings({
    params: { projectIdentifier },
  });

  return settings ? convertAttributeStyleFromApiToDomain(settings) : undefined;
};

function convertAttributeStyleFromApiToDomain(
  source: AttributeStyleSettingsDTO[],
): Map<string, string> {
  return new Map(source?.map(({ attribute, color }) => [attribute.value, `#${color}`]));
}

function convertColorHexFromDomainToApi(source: string): string {
  return source.replace('#', '');
}
