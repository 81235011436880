import { OrganizationRole, OrganizationType } from '@neptune/shared/core-organizations-domain';
import { ProjectRole, ProjectWithRole } from '@neptune/shared/core-project-domain';
import { isObject } from 'lodash';

export function isAtLeastMemberOfProject(project: ProjectWithRole | undefined): boolean {
  if (!isObject(project)) {
    return false;
  }

  return isManagerOfProject(project) || isMemberOfProject(project);
}

export function isMemberOfProject(project: ProjectWithRole | undefined): boolean {
  return project?.userRoleInProject === ProjectRole.member;
}

export function isManagerOfProject(project: ProjectWithRole | undefined): boolean {
  return project?.userRoleInProject === ProjectRole.manager;
}

export function isOwnerOfOrganization(project: ProjectWithRole | undefined) {
  return project?.userRoleInOrganization === OrganizationRole.owner;
}

const roleWeights: Record<ProjectRole, number> = {
  [ProjectRole.manager]: 2,
  [ProjectRole.member]: 1,
  [ProjectRole.viewer]: 0,

  [ProjectRole.notSupported]: -1,
};

export const availableProjectRoles: Record<OrganizationType, ProjectRole[]> = {
  [OrganizationType.individual]: [ProjectRole.member, ProjectRole.viewer],
  [OrganizationType.team]: [ProjectRole.manager, ProjectRole.member, ProjectRole.viewer],
  [OrganizationType.notSupported]: [ProjectRole.notSupported],
};

export function projectRoleComparator(a: ProjectRole, b: ProjectRole): number {
  return roleWeights[b] - roleWeights[a];
}
