import React from 'react';

import {
  bemBlock,
  Button,
  Icon,
  KeyShortcut,
  Layout,
  Link,
  SimpleTooltip,
  Text,
} from '@neptune/shared/venus-ui';
import { isMacOperatingSystem, useLocalModal } from '@neptune/shared/common-util';
import { RegExpInstructionsModal } from '@neptune/shared/regexp-instructions-modal-ui';
import { SearchMode } from '@neptune/shared/search-domain';

import './SearchModeSwitcher.less';

type SearchModeSwitcherProps = {
  onClick: () => void;
  value: SearchMode;
};

const commandKeyUnicode = '\u2318';

const block = bemBlock('search-mode-switcher');

export const SearchModeSwitcher: React.FC<SearchModeSwitcherProps> = ({ onClick, value }) => {
  const { isOpen, open, close } = useLocalModal();

  return (
    <>
      <RegExpInstructionsModal onClose={close} isOpen={isOpen} />
      <SimpleTooltip
        placement="top-center"
        strictPlacement
        content={
          <Layout.Row spacedChildren="sm" alignItems="baseline">
            <Layout.Column span="greedy">
              <Text>
                Use regular expression. Refer to{' '}
                <Link data-role="regexp-explonation-button" onClick={open}>
                  helpers
                </Link>{' '}
                for assistance.
              </Text>
            </Layout.Column>
            <Layout.Column span="auto">
              <KeyShortcut theme="dark">
                <Text size="xs" fontWeight="normal">
                  {isMacOperatingSystem ? commandKeyUnicode : 'CTRL'}+R
                </Text>
              </KeyShortcut>
            </Layout.Column>
          </Layout.Row>
        }
      >
        <Button
          className={block({
            modifiers: {
              active: value === SearchMode.REGEX,
            },
          })}
          variant="transparent"
          onClick={onClick}
          data-role="search-mode-switcher-button"
          square
        >
          <Icon glyph="regexp" />
        </Button>
      </SimpleTooltip>
    </>
  );
};
