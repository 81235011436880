import React from 'react';
import { auditTime } from 'rxjs';
import { UnderEditContext } from '@neptune/shared/venus-domain';
import { applyPauseWhenNotUnderEdit } from '@neptune/shared/venus-business-logic';
import { getEntitiesNotificationStream } from './entity-notifications';

export function useProjectEntitiesUpdates(projectId: string | undefined, callback: () => void) {
  const { underEdit$ } = React.useContext(UnderEditContext);

  React.useEffect(() => {
    if (!projectId) {
      return;
    }

    const subscription = getEntitiesNotificationStream(projectId)
      .pipe(auditTime(10_000), applyPauseWhenNotUnderEdit(underEdit$))
      .subscribe(() => callback());

    return () => subscription.unsubscribe();
  }, [callback, projectId, underEdit$]);
}
