import {
  AttributesDTO,
  ExperimentAttributesDTO,
  ExperimentTypeDTO,
} from '@neptune/shared/core-apis-leaderboard-domain';
import { Entity, EntityType, HolderType } from './entity-model';
import { AttributeModelConverter } from 'domain/experiment/attribute';

const entityTypeHolderMap: Record<EntityType, HolderType> = {
  run: 'experiment',
  modelVersion: 'experiment',
  model: 'experiment',
  project: 'project',
  report: 'experiment',
};

export class EntityModelConverter {
  static entityFromApiToDomain(entity: AttributesDTO): Entity {
    const domainAttributes = entity.attributes.map(
      AttributeModelConverter.attributeFromApiToDomain,
    );

    return {
      ...entity,
      attributes: domainAttributes,
      id: entity.experimentId,
      type: EntityModelConverter.entityTypeFromApiToDomain(entity.type),
    };
  }

  static entityFromApiExperimentToDomain(experiment: ExperimentAttributesDTO): Entity {
    const attributes = experiment.attributes.map(AttributeModelConverter.attributeFromApiToDomain);

    return {
      id: experiment.id,
      type: 'run',
      attributes,
      organizationId: experiment.organizationId,
      organizationName: experiment.organizationName,
      projectId: experiment.projectId,
      projectName: experiment.projectName,
    };
  }

  static entityTypeToHolderType(type: EntityType): HolderType {
    return entityTypeHolderMap[type];
  }

  public static entityTypeFromApiToDomain(type: ExperimentTypeDTO): EntityType {
    switch (type) {
      case ExperimentTypeDTO.Model:
        return 'model';
      case ExperimentTypeDTO.ModelVersion:
        return 'modelVersion';
      case ExperimentTypeDTO.Project:
        return 'project';
      case ExperimentTypeDTO.Run:
        return 'run';
      case ExperimentTypeDTO.Report:
        return 'report';
    }
  }
}
