import React from 'react';

import { useAsyncValue } from '@neptune/shared/common-util';
import { createErrorDescriptor } from 'common/error-handler';
import { fetchOrganizationMonitoringTimeHistory } from '@neptune/pricing-commons-domain';

type FetchInput = Parameters<typeof fetchOrganizationMonitoringTimeHistory>[0];

type UseMonitoringPackagesHistoryParams = FetchInput;

export function useOrganizationMonitoringHistory({
  organizationIdentifier,
  dateMin,
  dateMax,
}: UseMonitoringPackagesHistoryParams) {
  const resolver = React.useCallback(
    () =>
      fetchOrganizationMonitoringTimeHistory({
        organizationIdentifier,
        dateMin,
        dateMax,
      }),

    [organizationIdentifier, dateMin, dateMax],
  );

  return useAsyncValue({
    resolver,
    errorFormatter: createErrorDescriptor,
  });
}
