// App
import { ArtifactCriterion, PartialSearchCriterion } from '../search-query-model';
import { criterionHasAttributeAndOperator } from '../search-query-model-core-utils';

// Module
import { CriterionHelpers } from './types';

export function isArtifactCriterion(
  criterion: PartialSearchCriterion,
): criterion is ArtifactCriterion {
  return criterionHasAttributeAndOperator(criterion) && criterion.type === 'artifact';
}

export const artifactCriterionHelpers: CriterionHelpers<ArtifactCriterion> = {
  getReadableValue(criterion) {
    return criterion.value;
  },
  getShortReadableValue(criterion) {
    return criterion.value;
  },
  isComplete(criterion) {
    return criterion.value != null;
  },
};
