import { AppRoute } from '@neptune/shared/routing-domain';
import { convertRouteName, toEnterpriseRouteName } from '@neptune/shared/enterprise-context-util';
import { SimpleState } from 'router5/types/types/base';

export function makeEnterpriseRoute(route: AppRoute) {
  const { name, forwardTo, ...props } = route;
  const forward = forwardTo ? { forwardTo: toEnterpriseRouteName(forwardTo) } : {};
  return {
    ...props,
    name: toEnterpriseRouteName(name),
    ...forward,
  };
}

export function convertStateToContextAwareState(
  state: SimpleState,
  isEnterprise: boolean,
): SimpleState {
  return {
    name: convertRouteName(state.name, isEnterprise),
    params: state.params,
  };
}
