// Libs
import React from 'react';

// Module
export const useMobileView = () => {
  React.useEffect(() => {
    setMobileView();

    return () => setDesktopView();
  }, []);
};

function setMobileView() {
  document
    .querySelector('meta[name=viewport]')
    ?.setAttribute(
      'content',
      'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
    );
}

function setDesktopView() {
  document
    .querySelector('meta[name=viewport]')
    ?.setAttribute('content', 'width=1280, initial-scale=1');
}
