import React from 'react';
import { noop } from 'lodash';
import { ColorsContext, DEFAULT_COLOR } from '@neptune/compare-domain';

export function createColorsContext(name: string) {
  const ColorsContext = React.createContext<ColorsContext>({
    key: '',
    colors: new Map(),
    setColor: noop,
    assignColors: noop,
    initializeColors: noop,
    getColor: () => DEFAULT_COLOR,
    getRGBColors: () => new Map(),
  });

  function useColorsContext() {
    const context = React.useContext(ColorsContext);

    if (!context) {
      throw new Error(`useColorsContext called outside ColorsContextProvider named ${name}`);
    }

    return context;
  }

  ColorsContext.displayName = name;

  return { ColorsContext, useColorsContext };
}
