// App
import { UserProfileDTO } from '@neptune/shared/core-apis-backend-domain';
import { AppState } from 'state/types';
import getParentState from '../selectors';

// Module
import { CurrentUserState } from './types';

export function getCurrentUserState(state: AppState): CurrentUserState {
  return getParentState(state).currentUser;
}

export function getCurrentUser(state: AppState): UserProfileDTO | null {
  return getCurrentUserState(state).user;
}

export function getCurrentUserEmail(state: AppState) {
  const user = getCurrentUser(state);

  return user != null ? user.email : undefined;
}

export function getCurrentUsername(state: AppState) {
  const user = getCurrentUser(state);

  return user != null ? user.username : '';
}

export function getCurrentUserCreated(state: AppState) {
  const user = getCurrentUser(state);
  return user?.created;
}

export function isUserAuthenticated(state: AppState) {
  return getCurrentUserState(state).authenticated;
}
