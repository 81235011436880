import {
  backendClient,
  MonitoringTimeAndPackageHistoryPeriodDTO,
} from '@neptune/shared/core-apis-backend-domain';

export type MonitoringTimeHistoryPeriod = {
  periodStart: Date;
  periodEnd: Date;
  usedMinutes: number;
  topUpMinutes: number;
};

type FetchOrganizationMonitoringTimeHistoryRequestParams = {
  organizationIdentifier: string;
  dateMin?: string;
  dateMax?: string;
};

export async function fetchOrganizationMonitoringTimeHistory(
  params: FetchOrganizationMonitoringTimeHistoryRequestParams,
): Promise<MonitoringTimeHistoryPeriod[]> {
  const { results } = await backendClient.organizationMonitoringTimeUsage({
    ...params,
    dateMin: params.dateMin ? new Date(params.dateMin) : undefined,
    dateMax: params.dateMax ? new Date(params.dateMax) : undefined,
  });

  return results.map(monitoringTimeHistoryPeriodFomApiToDomain);
}

function monitoringTimeHistoryPeriodFomApiToDomain({
  periodStart,
  periodEnd,
  usedSeconds,
  topUpSeconds,
}: MonitoringTimeAndPackageHistoryPeriodDTO): MonitoringTimeHistoryPeriod {
  return {
    periodStart,
    periodEnd,
    usedMinutes: usedSeconds / 60,
    topUpMinutes: topUpSeconds / 60,
  };
}
