import { usePolling } from '@neptune/shared/common-business-logic';
import {
  fetchAllGroupTagColors,
  TagColorsContext,
  TagColorsContextContent,
  updateGroupTagColor,
} from '@neptune/tags-domain';
import { getColorByText } from 'common/experimentsColorPalette';
import { isEqual } from 'lodash';
import React from 'react';

const BACKGROUND_FALLBACK = 'rgba(141, 145, 149, .08)';

export const useTagColorsProvider = ({
  projectIdentifier,
}: {
  projectIdentifier: string;
}): TagColorsContextContent => {
  const [tagColors, setTagColors] = React.useState<Map<string, string>>();

  const pollingParams = React.useMemo(
    () => ({
      resolver: fetchAllGroupTagColors,
      pollingInterval: 120_000,
      onFetchSucceed: (colors: Map<string, string> | undefined) => {
        if (!colors) {
          return;
        }

        setTagColors((actualColors) => (isEqual(actualColors, colors) ? actualColors : colors));
      },
    }),
    [],
  );
  usePolling(projectIdentifier, pollingParams);

  const findColor = React.useCallback(
    (tag: string) => {
      return tagColors?.get(tag);
    },
    [tagColors],
  );

  const getColor = React.useCallback(
    (tag: string) => {
      return findColor(tag) ?? getColorByText(tag);
    },
    [findColor],
  );

  const updateColor = React.useCallback(
    (tag: string, color: string) => {
      updateGroupTagColor(projectIdentifier, tag, color).catch();

      if (!tagColors || tagColors.get(tag) === color) {
        return;
      }

      const newTagColors = new Map(tagColors);
      newTagColors.set(tag, color);
      setTagColors(newTagColors);
    },
    [projectIdentifier, tagColors],
  );

  return React.useMemo(() => ({ getColor, updateColor }), [getColor, updateColor]);
};

export const useTagColors = () => {
  const { getColor, updateColor } = React.useContext(TagColorsContext);

  const getColorForTag = React.useCallback(
    (tag: string) => getColor(tag) ?? BACKGROUND_FALLBACK,
    [getColor],
  );

  return {
    getColorForTag,
    updateColorForTag: updateColor,
  };
};
