import { isEmpty } from 'lodash';

import type { ColumnIdentifier, LeaderboardColumn } from '@neptune/shared/leaderboard-domain';
import { NThunkAction } from 'state/types';
import {
  ColumnChange,
  ColumnOrder,
  ColumnsRootKey,
  OrderedColumnsAction,
  OrderedColumnsActionTypes,
} from '@neptune/shared/columns-domain';

import { getDefaultColumnsIds, getOrderedColumns } from './selectors';
import { ensureDefaultColumns } from '../utils';

export type ColumnVisibilityToggleParams = {
  column: ColumnIdentifier;
  columnsSetId: string;
  columnsRootKey: ColumnsRootKey;
  persist: boolean;
};

export type ShowPinnedColumnParams = { pinned: boolean } & ColumnVisibilityToggleParams;

export function showColumn({
  column,
  columnsSetId,
  columnsRootKey,
  persist,
}: ColumnVisibilityToggleParams): OrderedColumnsAction {
  return {
    type: OrderedColumnsActionTypes.Show,
    payload: toggleColumnPayload(column, columnsSetId, columnsRootKey, persist),
  };
}

export function showPinnedColumn({
  column,
  columnsSetId,
  columnsRootKey,
  persist,
  pinned,
}: ShowPinnedColumnParams): OrderedColumnsAction {
  return {
    type: OrderedColumnsActionTypes.ShowPinned,
    payload: showPinnedColumnPayload(column, columnsSetId, columnsRootKey, persist, pinned),
  };
}

export function hideColumn({
  column,
  columnsSetId,
  columnsRootKey,
  persist,
}: ColumnVisibilityToggleParams): OrderedColumnsAction {
  return {
    type: OrderedColumnsActionTypes.Hide,
    payload: toggleColumnPayload(column, columnsSetId, columnsRootKey, persist),
  };
}

export type MoveColumnParams = {
  source: ColumnIdentifier;
  target: ColumnIdentifier;
  order: ColumnOrder;
  columnsSetId: string;
  columnsRootKey: ColumnsRootKey;
  persist: boolean;
};

export function moveColumn({
  source,
  target,
  order,
  columnsSetId,
  columnsRootKey,
  persist,
}: MoveColumnParams): OrderedColumnsAction {
  return {
    type: OrderedColumnsActionTypes.Move,
    payload: moveColumnPayload(source, target, order, columnsSetId, columnsRootKey, persist),
  };
}

export type UpdateColumnParams = {
  column: ColumnIdentifier;
  change: ColumnChange;
  columnsSetId: string;
  columnsRootKey: ColumnsRootKey;
  persist: boolean;
};

export function updateColumn({
  column,
  change,
  columnsSetId,
  columnsRootKey,
  persist,
}: UpdateColumnParams): OrderedColumnsAction {
  return {
    type: OrderedColumnsActionTypes.Update,
    payload: updateColumnPayload(column, change, columnsSetId, columnsRootKey, persist),
  };
}

export type SetDefaultColumnsParams = {
  defaultColumnsIds: string[];
  columnsRootKey: ColumnsRootKey;
};

export function setDefaultColumns({
  defaultColumnsIds,
  columnsRootKey,
}: SetDefaultColumnsParams): OrderedColumnsAction {
  return {
    type: OrderedColumnsActionTypes.SetDefaultColumns,
    payload: setDefaultColumnsPayload(defaultColumnsIds, columnsRootKey),
  };
}

export type SetColumnsParams = {
  columns: LeaderboardColumn[];
  persist?: boolean;
  columnsSetId: string;
  columnsRootKey: ColumnsRootKey;
  override?: boolean;
};

export function setColumns({
  columns,
  persist = false,
  columnsSetId,
  columnsRootKey,
  override = false,
}: SetColumnsParams): NThunkAction<void, OrderedColumnsAction> {
  return (dispatch, getState) => {
    const columnSet = getOrderedColumns(getState(), columnsRootKey, columnsSetId);

    // if there are already stored columns, then we cannot override it unless it is explicitly enabled
    if (!isEmpty(columnSet) && !override) {
      return;
    }

    const defaultColumns = getDefaultColumnsIds(getState(), columnsRootKey);
    const sanitizedColumns = ensureDefaultColumns(columns, defaultColumns);

    dispatch({
      type: OrderedColumnsActionTypes.Set,
      payload: setColumnsPayload(sanitizedColumns, columnsSetId, columnsRootKey, persist),
    });
  };
}

function toggleColumnPayload(
  column: ColumnIdentifier,
  columnsSetId: string,
  columnsRootKey: ColumnsRootKey,
  persist: boolean,
) {
  return {
    column,
    columnsSetId,
    columnsRootKey,
    persist,
  };
}

function showPinnedColumnPayload(
  column: ColumnIdentifier,
  columnsSetId: string,
  columnsRootKey: ColumnsRootKey,
  persist: boolean,
  pinned: boolean,
) {
  return {
    column,
    columnsSetId,
    columnsRootKey,
    persist,
    pinned,
  };
}

function moveColumnPayload(
  source: ColumnIdentifier,
  target: ColumnIdentifier,
  order: ColumnOrder,
  columnsSetId: string,
  columnsRootKey: ColumnsRootKey,
  persist: boolean,
) {
  return {
    columnsSetId,
    columnsRootKey,
    source,
    target,
    order,
    persist,
  };
}

function updateColumnPayload(
  column: ColumnIdentifier,
  change: ColumnChange,
  columnsSetId: string,
  columnsRootKey: ColumnsRootKey,
  persist: boolean,
) {
  return {
    column,
    change,
    columnsSetId,
    columnsRootKey,
    persist,
  };
}

function setColumnsPayload(
  columns: LeaderboardColumn[],
  columnsSetId: string,
  columnsRootKey: ColumnsRootKey,
  persist: boolean,
) {
  return {
    columns,
    columnsSetId,
    columnsRootKey,
    persist,
  };
}

function setDefaultColumnsPayload(defaultColumnsIds: string[], columnsRootKey: ColumnsRootKey) {
  return {
    defaultColumnsIds,
    columnsRootKey,
  };
}
