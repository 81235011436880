import {
  ProjectUpdate,
  ProjectWithRole,
  fetchProject as coreFetchProject,
} from '@neptune/shared/core-project-domain';
import { getCurrentRoute } from '@neptune/shared/routing-business-logic';
import { AnyAction } from 'redux';
import { navigateTo } from 'router/actions';
import { updateProjectActions as rawUpdateProjectActions } from 'state/projects/actions';
import { AppState, NThunkDispatch } from 'state/types';
import { ProjectBeingEdited } from 'state/ui/modals/project/actions';

export enum ProjectEditActionTypes {
  startEdit = 'PROJECT_START_EDIT',
  editProperties = 'PROJECT_EDIT_PROPERTIES',
}

export function initProjectEditThunk({ projectIdentifier }: { projectIdentifier: string }) {
  return (dispatch: NThunkDispatch<AnyAction>) => {
    coreFetchProject({ projectIdentifier }).then((project) =>
      dispatch(startEditProjectProperties(project)),
    );
  };
}

export function startEditProjectProperties(project: ProjectWithRole) {
  return {
    type: ProjectEditActionTypes.startEdit,
    payload: {
      project,
    },
  };
}

export function editProjectProperty(project: ProjectBeingEdited) {
  return { type: ProjectEditActionTypes.editProperties, payload: { project } };
}

export function submitProjectPropertiesThunk(
  projectIdentifier: string,
  projectUpdate: ProjectUpdate,
) {
  return async (dispatch: NThunkDispatch<AnyAction>, getState: () => AppState) => {
    const route = getCurrentRoute(getState());
    await dispatch(rawUpdateProjectActions.execute({ projectIdentifier, projectUpdate }));

    /**
     * if project name has changed we need to navigate to the new project route
     */
    dispatch(
      navigateTo(route.name, {
        ...route.params,
        projectName: projectUpdate.name,
      }),
    );
  };
}

export type ProjectEditPropertiesActions = ReturnType<
  typeof startEditProjectProperties | typeof editProjectProperty
>;
