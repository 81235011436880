import React from 'react';
import { capitalize } from 'lodash';
import type { ExperimentStateAttribute } from 'domain/experiment/attribute';
import { PropertyRow } from 'components/layouts/rows';
import { ExperimentStateLabel } from 'components/experiment-id';
import { getReadableState } from 'common/state';

type StateRendererProps = {
  attribute?: ExperimentStateAttribute;
  labelWidth?: string;
  label?: string;
  'data-name'?: string;
};

export const StateRenderer: React.FC<StateRendererProps> = ({
  attribute,
  labelWidth = '100px',
  label,
  'data-name': dataName,
}) => {
  if (!attribute) {
    return null;
  }

  const state = attribute.value;

  return (
    <PropertyRow
      value={<ExperimentStateLabel state={state} label={getReadableState(state)} />}
      title={capitalize(getReadableState(state))}
      labelWidth={labelWidth}
      label={label}
      data-name={dataName}
    />
  );
};
