// Libs
import React from 'react';

// App
import { Layout } from '@neptune/shared/venus-ui';

// Module

type ProjectViewProps = {
  analytics: React.ReactNode;
  header: React.ReactNode;
  scene: React.ReactNode;
};

export const ProjectView: React.FC<ProjectViewProps> = ({ analytics, header, scene }) => {
  return (
    <Layout.Column className="project-view" overflow="hidden">
      {header}
      {scene}
      {analytics}
    </Layout.Column>
  );
};
