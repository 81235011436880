// Libs
import React from 'react';
import { useSelector } from 'react-redux';

// App
import type { OrganizationWithRole } from '@neptune/shared/core-organizations-domain';
import { Dialog } from '@neptune/shared/venus-ui';
import { useServiceAccountRevokeApiToken } from '@neptune/service-accounts-business-logic';
import { getCurrentOrganization } from 'state/selectors';

// Module
type ResetServiceAccountTokenModalContainerProps = {
  onRevoke(serviceAccountId: string): void;
};

export const ResetServiceAccountTokenModalContainer: React.FC<ResetServiceAccountTokenModalContainerProps> =
  React.memo(function ResetServiceAccountTokenModalContainer({ onRevoke }) {
    const organization: OrganizationWithRole | undefined = useSelector(getCurrentOrganization);
    const organizationName = organization?.name;
    const {
      execute: revokeServiceAccountApiToken,
      close: closeConfirmationModal,
      isOpen: confirmationOpened,
    } = useServiceAccountRevokeApiToken({
      organizationName,
      onRevoke,
    });

    return (
      <Dialog
        title="Reset API token"
        isOpen={confirmationOpened}
        onClose={closeConfirmationModal}
        primaryButtonLabel="Reset token"
        primaryButtonRole="reset-api-token-confirmation"
        onPrimaryButtonClick={revokeServiceAccountApiToken}
        onSecondaryButtonClick={closeConfirmationModal}
      >
        You are about to generate a new API token. This will deactivate the previous token. Remember
        to update all your scripts with the new token. <br />
        <br />
        Do you want to continue?
      </Dialog>
    );
  });
