// Libs
import React from 'react';

// Module
export const useHideIntercom = () => {
  React.useEffect(() => {
    hideIntercom();
    return showIntercom;
  }, []);
};

export function hideIntercom() {
  try {
    window.Intercom('update', { hide_default_launcher: true });
    window.Intercom('hide');
  } catch (e) {
    // do nothing
  }
}

export function showIntercom() {
  try {
    window.Intercom('update', { hide_default_launcher: false });
  } catch (e) {
    // do nothing
  }
}
