import { backendClient, WorkspaceStatusDTO } from '@neptune/shared/core-apis-backend-domain';

import { SubscriptionStatus, subscriptionStatusFromApiToStatus } from './subscription-status';
import { WorkspaceMode, workspaceModeFromApiToDomain } from './workspace-mode';

export type WorkspaceStatus = {
  activeProjectsUsage: number;
  subscriptionStatus?: SubscriptionStatus;
  subscriptionFailedChargeAttempt?: string;
  monitoringMinutesAvailable?: number;
  storageBytesAvailable: number;
  storageBytesLimit: number;
  trialEndDate?: string;
  mode: WorkspaceMode;
};

export async function fetchWorkspaceStatus(organizationIdentifier: string) {
  const result = await backendClient.workspaceStatus({ organizationIdentifier });
  const workspaceStatus = workspaceStatusFromApiToDomain(result);

  if (
    !workspaceStatus.monitoringMinutesAvailable ||
    workspaceStatus.monitoringMinutesAvailable <= 0
  ) {
    await sleep(500);
    const result = await backendClient.workspaceStatus({ organizationIdentifier });
    return workspaceStatusFromApiToDomain(result);
  }

  return workspaceStatus;
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function workspaceStatusFromApiToDomain(source: WorkspaceStatusDTO): WorkspaceStatus {
  return {
    activeProjectsUsage: source.activeProjectsUsage,
    subscriptionStatus: source.subscription
      ? subscriptionStatusFromApiToStatus(source.subscription.status)
      : undefined,
    subscriptionFailedChargeAttempt: source.subscription?.failedChargeAttempt
      ? new Date(source.subscription.failedChargeAttempt).toISOString()
      : undefined,
    monitoringMinutesAvailable:
      source.monitoringSecondsAvailable != null
        ? source.monitoringSecondsAvailable / 60
        : undefined,
    storageBytesAvailable: source.storageBytesAvailable,
    storageBytesLimit: source.storageBytesLimit,
    trialEndDate: source.trialEndDate ? new Date(source.trialEndDate).toISOString() : undefined,
    mode: workspaceModeFromApiToDomain(source.mode),
  };
}
