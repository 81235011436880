import { AppRoute, OnRouteActivateHandler, RouterState } from '@neptune/shared/routing-domain';
import { Store } from 'redux';
import transitionPath from 'router5-transition-path';
import { Router } from 'router5/types/types/router';

/**
 * This middleware will make it possible to define 'onActivate' handler
 * in particular route configuration.
 * When this handler is defined it will be executed before such route is activated.
 * It is very useful whenever you want to make some logic before view is rendered.
 * For instance it can be the call to the API to get resources or to get auth token,
 * check permissions etc.
 *
 * Ref: https://router5.js.org/advanced/middleware
 */
export const onRouteActivateMW =
  (router: Router) => (toState: RouterState, fromState: RouterState) => {
    const { routes, store } = router.getDependencies() as { routes: AppRoute[]; store: Store };

    const { toActivate } = transitionPath(toState, fromState);
    const onActivateHandlers: OnRouteActivateHandler[] = toActivate
      .map((segment) => routes.find((r) => r.name === segment)?.onActivate)
      .filter((handler): handler is OnRouteActivateHandler => handler != null);

    onActivateHandlers.forEach((callback) =>
      callback(store.dispatch, store.getState, router)(toState, fromState),
    );

    return true;
  };
