import { LayoutType } from '@neptune/shared/common-domain';
import { AppState } from 'state/types';

import { ExpandableLayoutState } from '../storage-utils';

export const getExpandableLayoutState = (state: any): ExpandableLayoutState => {
  return state.ui.expandableLayout;
};

export const getExpandableLayout = (
  state: AppState,
  id: string,
  projectIdentifier: string,
): LayoutType => {
  return getExpandableLayoutState(state)?.[projectIdentifier]?.[id] || LayoutType.Mosaic;
};
