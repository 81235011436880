import { ChartConfigurationSettings, DEFAULT_CHART_CONFIG } from '@neptune/charts-domain';
import { pick } from 'lodash';

/** For each of config fields contains a list of widgetIds, for which that field should be cleared. */
export type GlobalChartConflictedOptions = {
  [K in ChartGlobalConfigFieldNames]?: string[];
} & {
  total: number;
};

export const chartGlobalConfigFieldNames = [
  'yAxisScale',
  'xAxisScale',
  'xAxisMetric',
  'xAxisMode',
] as const;
type ChartGlobalConfigFieldNames = (typeof chartGlobalConfigFieldNames)[number];

export type ChartGlobalConfig = Pick<ChartConfigurationSettings, ChartGlobalConfigFieldNames>;

export const DEFAULT_CHART_GLOBAL_CONFIG: ChartGlobalConfig = pick(
  DEFAULT_CHART_CONFIG,
  chartGlobalConfigFieldNames,
);
