import { Input, Textarea } from '@neptune/shared/common-ui';
import { Layout, Modal, Text } from '@neptune/shared/venus-ui';
import React from 'react';

type RenameVersionModalProps = {
  isOpen: boolean;
  name: string | undefined;
  description: string | undefined;
  onSave: (values: { name: string | undefined; description: string | undefined }) => void;
  close: () => void;
};

export const RenameVersionModal: React.FC<RenameVersionModalProps> = ({
  isOpen,
  name: rawName,
  description: rawDescription,
  onSave,
  close,
}) => {
  const name = rawName ?? '';
  const description = rawDescription ?? '';

  const [nameInputValue, setNameInputValue] = React.useState(name);
  const [descriptionInputValue, setDescriptionInputValue] = React.useState(description);

  React.useEffect(() => {
    setNameInputValue(name);
    setDescriptionInputValue(description);
  }, [name, description]);

  const handleSave = React.useCallback(() => {
    onSave({
      name: nameInputValue || undefined,
      description: descriptionInputValue || undefined,
    });
  }, [onSave, nameInputValue, descriptionInputValue]);

  const noChanges = nameInputValue === name && descriptionInputValue === description;

  return (
    <Modal
      isOpen={isOpen}
      onClose={noChanges ? close : undefined}
      data={{ role: 'rename-version-modal' }}
    >
      <Modal.Header title="Edit version name and description" onClose={close} />
      <Modal.Body>
        <Layout.Column spacedChildren="md">
          <Layout.Column spacedChildren="xs" component="label">
            <Text color="text" fontWeight="semibold" size="xs">
              Name
            </Text>
            <Input
              value={nameInputValue}
              data-role="name-input"
              placeholder="Enter a name for the version"
              onChange={(e) => setNameInputValue(e.target.value)}
            />
          </Layout.Column>

          <Layout.Column spacedChildren="xs" component="label">
            <Text color="text" fontWeight="semibold" size="xs">
              Description
            </Text>
            <Textarea
              value={descriptionInputValue}
              data-role="description-input"
              placeholder="Describe what changed"
              resize="vertical"
              onChange={(e) => setDescriptionInputValue(e.target.value)}
            />
          </Layout.Column>
        </Layout.Column>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        primaryButtonRole="save"
        primaryButtonDisabled={noChanges}
        onPrimaryButtonClick={handleSave}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={close}
      />
    </Modal>
  );
};
