import React from 'react';
import { Dialog } from '@neptune/shared/venus-ui';

export const ArchiveLimitReachedModal: React.FC<{ isOpen: boolean; close: () => void }> = ({
  isOpen,
  close,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      title="Cannot unarchive project"
      primaryButtonLabel="OK"
      role="cannot-unarchive-project-modal"
      onPrimaryButtonClick={close}
      onClose={close}
    >
      <Dialog.Text>
        You’ve reached the limit of active projects set for this workspace. Increase the limit in
        Billing & payments section of your Workspace settings.
      </Dialog.Text>
    </Dialog>
  );
};
