import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hasSubscriptionManagePermission } from '@neptune/shared/core-permissions-business-logic';
import { adminOnlyUpgradeModalName } from '@neptune/pricing-commons-domain';
import { getContextOrganization } from 'state/selectors-global';
import { showGenericModal } from 'state/ui/modals/generic/actions';
import { openChangePlanModal as openChangePlanModalRaw } from './redux/change-plan-actions';

export const useUpgradeExpiredPlan = () => {
  const dispatch = useDispatch();
  const organization = useSelector(getContextOrganization);
  const canChangePlan = hasSubscriptionManagePermission(organization);

  const openChangePlanModal = React.useCallback(() => {
    dispatch(openChangePlanModalRaw());
  }, [dispatch]);

  function upgradePlan() {
    dispatch(!canChangePlan ? showGenericModal(adminOnlyUpgradeModalName) : openChangePlanModal());
  }

  return {
    workspaceName: organization?.name,
    upgradePlan,
  };
};
