import { useRecentSearches } from './use-recent-searches';
import { CompoundSearchCriterion } from '@neptune/recent-searches-domain';

export function useRecentCompoundQueries({ namespace }: { namespace: string }) {
  return useRecentSearches<CompoundSearchCriterion[]>({
    namespace,
    key: 'recentCompoundQueries',
    getInitialState: (lsState, projectIdentifier) =>
      lsState?.recentCompoundQueries?.[namespace]?.[projectIdentifier],
  });
}
