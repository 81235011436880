import React from 'react';
import { searchLeaderboard } from '@neptune/shared/leaderboard-domain';
import { fetchStatus as FetchStatus } from 'state/fetch-status';

type UseLeaderboardCountParams = {
  projectIdentifier: string;
  types: string[];
  query: string;
};

type UseLeaderboardCountReturn = {
  count: number | undefined;
  fetchStatus: FetchStatus;
  refresh(): void;
};

export const useLeaderboardCount = ({
  projectIdentifier,
  types,
  query,
}: UseLeaderboardCountParams): UseLeaderboardCountReturn => {
  const [count, setCount] = React.useState<number>();
  const [fetchStatus, setFetchStatus] = React.useState(FetchStatus.NONE);

  const refresh = React.useCallback(() => {
    fetchLeaderboardCount().catch(() => {
      setFetchStatus(FetchStatus.FAILED);
      setCount(undefined);
    });

    async function fetchLeaderboardCount() {
      setFetchStatus(FetchStatus.PENDING);
      const { matchingItemCount } = await searchLeaderboard({
        projectIdentifier,
        query,
        pagination: {
          limit: 0,
          offset: 0,
        },
        type: types,
        experimentsOnly: true,
      });

      setFetchStatus(FetchStatus.SUCCESS);
      setCount(matchingItemCount);
    }
  }, [projectIdentifier, query, types]);

  React.useEffect(refresh, [refresh]);

  return {
    count,
    fetchStatus,
    refresh,
  };
};
