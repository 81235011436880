import { ExperimentsColorManager } from 'common/experimentsColorPalette';
import { makeEntityIdentifier } from '@neptune/shared/entity-domain';
import { Attribute, getRunIdentificationKeyFromAttributes } from 'domain/experiment/attribute';
import { isNotUndefined } from './tsHelpers';

export function makeCompareColorManager(colorMap: Record<string, string>) {
  const colorPaletteManager = new ExperimentsColorManager((id) => id.split('/')[0]);
  Object.entries(colorMap).forEach(([id, color]) => {
    colorPaletteManager.setColor(id, color);
  });
  return colorPaletteManager;
}

type ColorSource = {
  attributes: Attribute[];
  type: string;
  id: string;
};

export function convertColorMapKeysToEntityIds(
  colorMap: Map<string, string>,
  entities: ColorSource[] | undefined = [],
  ...colorEnhancers: Array<undefined | ((attributes: Attribute[]) => string | undefined)>
): Record<string, string> {
  const entitiesById = entities.reduce((result: Record<string, ColorSource>, entity) => {
    const runIdentificationKey = getRunIdentificationKeyFromAttributes(entity.attributes);

    if (runIdentificationKey) {
      result[runIdentificationKey] = entity;
    }

    return result;
  }, {});

  return [...colorMap.entries()].reduce((result: Record<string, string>, [id, color]) => {
    const entity = entitiesById[id];

    if (!entity) {
      return result;
    }

    const key = makeEntityIdentifier(entity.id, entity.type);
    let nextColor;

    for (const enhancer of colorEnhancers.filter(isNotUndefined)) {
      nextColor = enhancer(entity.attributes);

      if (nextColor) {
        result[key] = nextColor;

        return result;
      }
    }

    result[key] = color;

    return result;
  }, {});
}
