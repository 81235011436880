import { EntityType } from './entity-model';

export type EntityDetailsModalData = {
  entityId: string;
};

export const EntityTypeToDetailsModalName: Record<EntityType, string> = {
  run: 'RUN_DETAILS_MODAL_NAME',
  model: 'MODEL_DETAILS_MODAL_NAME',
  modelVersion: 'VERSION_DETAILS_MODAL_NAME',
  project: 'PROJECT_DETAILS_MODAL_NAME',
  report: 'REPORT_DETAILS_MODAL_NAME',
};
