// Libs
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationWithRole } from '@neptune/shared/core-organizations-domain';
import { Layout } from '@neptune/shared/venus-ui';

// App
import {
  CreateServiceAccountTile,
  WorkspaceEmptyState,
  WorkspaceServiceAccountsList,
  ServiceAccountsPlaceholder,
  IndividualServiceAccountsPlaceholder,
} from '@neptune/service-accounts-ui';
import {
  isServiceAccountsEnabled,
  useServiceAccounts,
  useServiceAccountsTokens,
  useServiceAccountRevokeApiToken,
} from '@neptune/service-accounts-business-logic';
import { navigateTo } from 'router/actions';
import { getCurrentOrganization } from 'state/selectors';
import { ErrorPlaceholderContainer } from '@neptune/shared/common-feature';
import { useLocalModal } from '@neptune/shared/common-util';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import type {
  ServiceAccount,
  ServiceAccountReactivateData,
} from '@neptune/service-accounts-domain';
import { useGenericModal } from '@neptune/shared/common-business-logic';

// Module
import { WorkspaceCreateServiceAccountModalContainer } from './WorkspaceCreateServiceAccountModalContainer';
import {
  DeactivateServiceAccountModalContainer,
  DEACTIVATE_SERVICE_ACCOUNT_MODAL_NAME,
} from './DeactivateServiceAccountModalContainer';
import {
  REACTIVATE_SERVICE_ACCOUNT_MODAL_NAME,
  ReactivateServiceAccountModalContainer,
} from './ReactivateServiceAccountModalContainer';
import { ResetServiceAccountTokenModalContainer } from './ResetServiceAccountTokenModalContainer';

export const WorkspaceServiceAccounts = () => {
  const organization: OrganizationWithRole | undefined = useSelector(getCurrentOrganization);
  const organizationName = organization?.name;
  const dispatch = useDispatch();
  const { value: serviceAccounts, loading, error, refresh } = useServiceAccounts(organizationName);
  const {
    isOpen: isCreateServiceAccountModalOpen,
    open: requestCreateNewServiceAccount,
    close: closeCreateServiceAccountModal,
  } = useLocalModal();
  const { tokensMap, toggleApiToken, getApiToken } = useServiceAccountsTokens({ organizationName });

  const { openWithData: requestDeactivateServiceAccount } = useGenericModal<ServiceAccount>(
    DEACTIVATE_SERVICE_ACCOUNT_MODAL_NAME,
  );

  const { openWithData: requestReactivateServiceAccount } =
    useGenericModal<ServiceAccountReactivateData>(REACTIVATE_SERVICE_ACCOUNT_MODAL_NAME);

  const onRevoke = React.useCallback(
    async (serviceAccountId: string) => {
      await getApiToken(serviceAccountId);
      refresh();
    },
    [getApiToken, refresh],
  );

  const { openWithData: requestRevokeServiceAccount } = useServiceAccountRevokeApiToken({
    organizationName,
  });

  const handleTryTeamPlan = React.useCallback(() => {
    return dispatch(navigateTo('organization.subscription', { organizationName }));
  }, [dispatch, organizationName]);

  const deactivateServiceAccount = React.useCallback(
    (serviceAccount: ServiceAccount) => {
      requestDeactivateServiceAccount(serviceAccount);
    },
    [requestDeactivateServiceAccount],
  );

  const reactivateServiceAccount = React.useCallback(
    (serviceAccount: ServiceAccount) => {
      requestReactivateServiceAccount(serviceAccount);
    },
    [requestReactivateServiceAccount],
  );

  if (!organization) {
    return null;
  }

  if (!isServiceAccountsEnabled(organization.traits)) {
    return organization.organizationType === 'individual' ? (
      <IndividualServiceAccountsPlaceholder />
    ) : (
      <ServiceAccountsPlaceholder onClick={handleTryTeamPlan} />
    );
  }

  if (error) {
    return <ErrorPlaceholderContainer context="service-accounts" />;
  }

  return (
    <Layout.Column data-role="service-accounts">
      <WorkspaceServiceAccountsList
        tokenStatus={tokensMap}
        loading={loading}
        entries={serviceAccounts}
        createNewElement={<CreateServiceAccountTile onCreate={requestCreateNewServiceAccount} />}
        emptyState={<WorkspaceEmptyState onNewServiceAccount={requestCreateNewServiceAccount} />}
        onDeactivateAccount={deactivateServiceAccount}
        onReactivateAccount={reactivateServiceAccount}
        onToggleApiToken={toggleApiToken}
        getApiToken={getApiToken}
        onResetApiToken={requestRevokeServiceAccount}
      />
      <LoadingIndicator isVisible={loading} centered />
      <WorkspaceCreateServiceAccountModalContainer
        isOpen={isCreateServiceAccountModalOpen}
        onClose={closeCreateServiceAccountModal}
        onSubmit={refresh}
      />
      <DeactivateServiceAccountModalContainer onSubmit={refresh} />
      <ReactivateServiceAccountModalContainer onSubmit={refresh} />
      <ResetServiceAccountTokenModalContainer onRevoke={onRevoke} />
    </Layout.Column>
  );
};
