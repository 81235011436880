export function deserializeAsString(rawParam?: string): string {
  if (!rawParam) {
    throw new Error('Error when deserializing. Provided value cannot be empty.');
  }

  return rawParam;
}

export function deserializeAsOptString(rawParam?: string): string | undefined {
  return rawParam;
}

export function deserializeAsStringArray(rawParam?: string): string[] {
  if (rawParam == null || rawParam.length === 0) {
    return [];
  }

  return JSON.parse(rawParam);
}

export function deserializeAsBoolean(rawParam?: string): boolean {
  return String(rawParam) === 'true';
}
