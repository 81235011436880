import { WorkspaceStatus } from '@neptune/pricing-workspace-status-domain';
import { add, isAfter } from 'date-fns';

function paymentTrigger(status: WorkspaceStatus, now: Date, daysFromFailedCharge: number) {
  return status.subscriptionFailedChargeAttempt
    ? isAfter(
        now,
        add(new Date(status.subscriptionFailedChargeAttempt), { days: daysFromFailedCharge }),
      )
    : false;
}

export const paymentAdminTrigger = (status: WorkspaceStatus, now: Date): boolean =>
  paymentTrigger(status, now, 0);
export const paymentMemberTrigger = (status: WorkspaceStatus, now: Date): boolean =>
  paymentTrigger(status, now, 7);
export const paymentReadOnlyTrigger = (status: WorkspaceStatus, now: Date): boolean =>
  paymentTrigger(status, now, 14);
