// Libs
import React from 'react';
import { bemBlock, Layout } from '@neptune/shared/venus-ui';

const block = bemBlock('member-list-view');

// Module
import './MemberListView.less';

export const MemberListView: React.FC = ({ children }) => {
  return <Layout.Column className={block()} children={children} spacedChildren="md" />;
};
