import React from 'react';
import { useSelector } from 'react-redux';
import { pushToDataLayer } from '@neptune/analytics-domain';
import { useVisibilityChange } from '@neptune/shared/common-ui';
import { getCurrentUsername } from 'state/users/current-user/selectors';
import { Entity, getEntityOwner } from '@neptune/shared/entity-domain';

export const TriggerConversionTracking = ({ openedRun }: { openedRun?: Entity }) => {
  const username = useSelector(getCurrentUsername);
  const [tracked, setTracked] = React.useState(false);
  const [hidden, setHidden] = React.useState<boolean>();
  useVisibilityChange(setHidden);

  const runOwner = openedRun && getEntityOwner(openedRun);
  const userOpenedOwnRun = username === runOwner;

  React.useEffect(() => {
    if (tracked || hidden || !userOpenedOwnRun) {
      return;
    }

    pushToDataLayer({ event: 'Experiment_List', value: 1 });
    setTracked(true);
  }, [tracked, hidden, userOpenedOwnRun]);

  return null;
};
