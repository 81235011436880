import React from 'react';
import { useSelector } from 'react-redux';

import {
  attributeDefToColumnIdentifier,
  columnIdentifierToAttributeDef,
} from 'domain/common/converters';
import { AttributeDefinition } from 'domain/experiment/attribute';
import { LeaderboardColumn } from '@neptune/shared/leaderboard-domain';
import { AppState } from 'state/types';

import { useColumnsActionsContext } from './useColumnsActionsContext';

type ColumnsManagerStorageStrategy = {
  selector: (state: AppState) => LeaderboardColumn[] | undefined;
};

type HookResult = {
  attributesInUse: AttributeDefinition[];
  addColumn: (selected: AttributeDefinition) => void;
  addColumnAsPinned: (selected: AttributeDefinition) => void;
  pinnedAttributesInUse: AttributeDefinition[];
};

export const useLeaderboardColumnManagerStorage: (
  strategy: ColumnsManagerStorageStrategy,
) => HookResult = ({ selector }) => {
  const columns = useSelector(selector);
  const attributesInUse = React.useMemo(
    () => (columns ?? []).map(columnIdentifierToAttributeDef),
    [columns],
  );

  const pinnedAttributesInUse = React.useMemo(
    () => (columns ?? []).filter((column) => column.pinned).map(columnIdentifierToAttributeDef),
    [columns],
  );

  const { showColumn, showPinnedColumn } = useColumnsActionsContext();

  const addColumn = React.useCallback(
    (attribute: AttributeDefinition) => {
      showColumn({ column: attributeDefToColumnIdentifier(attribute) });
    },
    [showColumn],
  );

  const addColumnAsPinned = React.useCallback(
    (attribute: AttributeDefinition) => {
      showPinnedColumn({ column: attributeDefToColumnIdentifier(attribute), pinned: true });
    },
    [showPinnedColumn],
  );

  return { attributesInUse, addColumn, addColumnAsPinned, pinnedAttributesInUse };
};
