import organizationRoutes from './organization/routes';
import userProfileRoutes from './user-profile/routes';
import { invitationRoutes } from '@neptune/invitations-api';
import { createWorkspaceRoutes } from './team-trial-flow/routes';
import cancellationSurveyRoutes from './cancellation-survey/routes';
import { compatibilityRoutes, projectRoutes } from '@neptune/project-shell';

export default [
  ...organizationRoutes,
  ...projectRoutes,
  ...compatibilityRoutes,
  ...userProfileRoutes,
  ...invitationRoutes,
  ...createWorkspaceRoutes,
  ...cancellationSurveyRoutes,
];
