import { isObject } from 'lodash';
import { WidgetDTOTypeEnum } from 'generated/leaderboard-client';
import { WidgetOptions, WidgetType, widgetTypeFromApiToDomain } from '../widget';
import { WidgetDefinition } from './widget-definition';

const widgetRegistry = new Map<WidgetType, WidgetDefinition>();

export const registerWidget = (widgetDefinition: WidgetDefinition): void => {
  if (widgetRegistry.has(widgetDefinition.type)) {
    return;
  }

  widgetRegistry.set(widgetDefinition.type, widgetDefinition);
};

export const convertWidgetOptionsFromApiToDomain = (
  widgetType: WidgetDTOTypeEnum,
  options?: Record<string, any>,
): WidgetOptions | undefined => {
  const domainWidgetType = widgetTypeFromApiToDomain(widgetType);
  const widgetDefinition = widgetRegistry.get(domainWidgetType);
  const fromApiToDomain = widgetDefinition?.fromApiToDomain ?? defaultWidgetOptionsFromApiToDomain;
  return fromApiToDomain(options);
};

export const convertWidgetOptionsFromDomainToApi = (
  widgetType: WidgetType,
  options?: WidgetOptions,
): object | undefined => {
  const widgetDefinition = widgetRegistry.get(widgetType);
  const fromDomainToApi = widgetDefinition?.fromDomainToApi ?? defaultWidgetOptionsFromDomainToApi;
  return fromDomainToApi(options);
};

const defaultWidgetOptionsFromApiToDomain = (): WidgetOptions | undefined => {
  return undefined;
};

const defaultWidgetOptionsFromDomainToApi = (options: any): object | undefined => {
  if (!options || !isObject(options)) {
    return undefined;
  }

  return options;
};

export const getWidgetName = (widgetType: WidgetType): string => {
  const widgetDefinition = widgetRegistry.get(widgetType);
  return widgetDefinition?.name ?? 'Not supported';
};
