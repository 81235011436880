import React from 'react';
import { Button, Layout, Text } from '@neptune/shared/venus-ui';

import ContactLink from 'components/contact-link/ContactLink';

export type TrialExpiredViewProps = {
  upgradePlan: () => void;
  teamName: string;
};

export const TrialExpiredView: React.FC<TrialExpiredViewProps> = ({ upgradePlan, teamName }) => {
  return (
    <Layout.Row alignItems="center" justifyContent="center">
      <Layout.Column alignItems="center" spacedChildren="xl" span="auto">
        <Layout.Column alignItems="center" spacedChildren="md">
          <Text color="text-secondary" size="md" fontWeight="semibold">
            Looks like your trial has expired :(
          </Text>
          <Text>Would you like your team to keep tracking run results with Neptune?</Text>
          <Button size="lg" onClick={upgradePlan}>
            Upgrade your plan
          </Button>
          <Text size="sm">
            <ContactLink
              subject={`I would like to extend the free trial for the ${teamName} team`}
              children="Request another 7 days of free trial"
            />
          </Text>
        </Layout.Column>
        <Layout.Column alignItems="center" spacedChildren="sm" withPadding="lg">
          <Text size="sm">Got a question?</Text>
          <Text size="sm">
            <ContactLink
              subject="I have a question about the Team plan"
              children="Send us a message"
            />
          </Text>
        </Layout.Column>
      </Layout.Column>
    </Layout.Row>
  );
};
