// Libs
import type { State } from 'router5';
import { toNormalizedRouteName } from 'router/helpers';

// Module
export function getContextName(routerState: State): string {
  if (routerState.name !== 'project.runs-tab') {
    return toNormalizedRouteName(routerState.name);
  }

  /*
   Special logic for experiments route as it covers many different use cases that will have different performance.
   So added info about mode, chosen dashboard
   */
  const { tab, dash, compare } = routerState.params;

  const params = [computeTab(tab), computeDash(dash), computeCompare(compare)]
    .filter((param) => param.length > 0)
    .join('&');

  return `${routerState.name}?${params}`;
}

const builtinDashboards: string[] = ['charts', 'leaderboard', 'parallel-coordinates-plot'];

function computeDash(dash: string): string {
  if (dash == null) {
    return '';
  }

  let computedDash = dash;

  if (!builtinDashboards.includes(dash)) {
    // we don't want to identify transaction by user strings because it will make grouping in Sentry impossible
    computedDash = 'USER_DEFINED';
  }

  return `dash=${computedDash}`;
}

function computeCompare(compare: string): string {
  return compare != null ? 'compare=USER_DEFINED' : '';
}

function computeTab(tab: string): string {
  return tab != null ? `tab=${tab}` : '';
}
