import React from 'react';
import { useSelector } from 'react-redux';

import {
  Entity,
  StageConverter,
  getEntityStage,
  getEntityTrashed,
  getEntityModelId,
  getEntityShortId,
  EntityDetailsModalData,
  EntityTypeToDetailsModalName,
} from '@neptune/shared/entity-domain';
import { VersionBasicInfo } from '@neptune/model-registry-model-version-ui';
import {
  getOrganizationNameFromRouteParams,
  getProjectNameFromRouteParams,
} from 'state/selectors-global';
import { useTrashVersion } from '@neptune/model-registry-model-version-business-logic';
import { StageDropdownContainer } from '@neptune/model-registry-common-feature';
import { getCurrentProject } from '@neptune/current-project-business-logic';
import { hasEntityModifyPermission } from '@neptune/shared/core-permissions-business-logic';
import { TrashEntityModal } from '@neptune/shared/entity-ui';
import { BackLink } from '@neptune/shared/common-ui';
import { useEntityChange } from '@neptune/shared/entity-business-logic';
import { useGenericModal } from '@neptune/shared/common-business-logic';

type VersionBasicInfoProps = {
  expanded: boolean;
  modelVersion: Entity;
  trashBackLink?: React.ReactElement;
};

export const VersionBasicInfoContainer: React.FC<VersionBasicInfoProps> = ({
  expanded,
  modelVersion,
  trashBackLink,
}) => {
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const projectName = useSelector(getProjectNameFromRouteParams);
  const project = useSelector(getCurrentProject);
  const canModify = hasEntityModifyPermission(project);
  const entityType = modelVersion.type;
  const shortId = getEntityShortId(modelVersion);
  const stageString = getEntityStage(modelVersion);
  const modelShortId = getEntityModelId(modelVersion) as string;
  const stage = stageString !== undefined ? StageConverter.stringToStage(stageString) : undefined;
  const {
    cancelTrash,
    executeTrash,
    requestTrash,
    isOpen: isDeleteModalOpen,
  } = useTrashVersion({
    organizationName,
    projectName,
    modelShortId,
    modelVersion,
  });
  const isTrashed = modelVersion && getEntityTrashed(modelVersion);
  const handleVersionChange = useEntityChange({
    entityId: modelVersion.id,
    entityType: modelVersion.type,
  });

  const { openWithData } = useGenericModal<EntityDetailsModalData>(
    EntityTypeToDetailsModalName[modelVersion.type],
  );
  const openDetails = React.useCallback(() => {
    openWithData({ entityId: modelVersion.id });
  }, [openWithData, modelVersion.id]);

  const backLink = isTrashed ? (
    trashBackLink
  ) : (
    <BackLink
      routeName="project.model-registry"
      routeParams={{
        organizationName,
        projectName,
        type: 'model',
        shortId: modelShortId,
      }}
      text="Back to all versions"
    />
  );

  return (
    <>
      <VersionBasicInfo
        modelVersion={modelVersion}
        renderStage={(short?: boolean) => {
          return stage ? (
            <StageDropdownContainer
              modelVersion={modelVersion}
              disabled={!canModify}
              short={short}
              onEntityChange={handleVersionChange}
            />
          ) : null;
        }}
        backLink={backLink}
        onDetailsOpen={openDetails}
        onTrash={requestTrash}
        expanded={expanded}
      />
      {executeTrash && (
        <TrashEntityModal
          isOpen={isDeleteModalOpen}
          shortId={shortId}
          type={entityType}
          cancel={cancelTrash}
          execute={executeTrash}
        />
      )}
    </>
  );
};
