import actionTypes from 'state/actionTypes';

export function addNotification(type, text) {
  return {
    type: actionTypes.UI_NOTIFICATION_POPUP_ADD_NOTIFICATION,
    payload: { type, text },
  };
}

export function removeNotification(notificationId) {
  return {
    type: actionTypes.UI_NOTIFICATION_POPUP_REMOVE_NOTIFICATION,
    payload: { notificationId },
  };
}
