import React from 'react';
import { useEntityIdentificationEntries } from '@neptune/shared/entity-business-logic';
import { getIdFromAttributes, getNameFromAttributes } from 'domain/experiment/attribute';

type ExperimentNamesToRunId = Map<string, string>;

const EMPTY_SELECTION: string[] = [];

export function useExperimentIdentificationMap(
  projectIdentifier: string,
  experimentNames: string[],
  lastNotificationTimestamp?: number,
): {
  invalidateQuery: () => void;
  namesToShortIds: ExperimentNamesToRunId;
} {
  const [namesToShortIds, setNamesToShortIds] = React.useState<Map<string, string>>(new Map());

  const { result, invalidateQuery } = useEntityIdentificationEntries(
    projectIdentifier,
    experimentNames,
    EMPTY_SELECTION,
  );

  React.useEffect(() => {
    invalidateQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastNotificationTimestamp]);

  React.useEffect(() => {
    if (!result.data) {
      return;
    }

    const namesToRunIds = new Map<string, string>();

    for (const entry of result.data) {
      const experimentName = getNameFromAttributes(entry.attributes);
      const runId = getIdFromAttributes(entry.attributes);

      if (experimentName && runId) {
        namesToRunIds.set(experimentName, runId);
      }
    }

    setNamesToShortIds(namesToRunIds);
  }, [result.data]);

  return { namesToShortIds, invalidateQuery };
}
