import { FILTERING_QUERY_PARAMS } from 'router/config';
import { makeEnterpriseRoute } from 'router/helpers';
import { storage } from 'common/storage';

const metadataRoute = {
  name: 'project.metadata',
  path: '/metadata',
  onActivate: () => () => storage.local.setItem('lastProjectTab', 'metadata'),
};

const trashRoute = {
  name: 'project.trash',
  path: `/trash?${FILTERING_QUERY_PARAMS}`,
  onActivate: () => () => storage.local.setItem('lastProjectTab', 'trash'),
};

export const projectDashboardRoutes = [
  trashRoute,
  makeEnterpriseRoute(trashRoute),
  metadataRoute,
  makeEnterpriseRoute(metadataRoute),
];

export default projectDashboardRoutes;
