// App
import {
  MonitoringTimeAndPackageHistoryPeriodDTO,
  MonitoringTimeHistoryPeriodDTO,
} from '@neptune/shared/core-apis-backend-domain';

// Module
import { UsageHistoryPeriod } from './usage-history-period-model';

export abstract class UsageHistoryPeriodConverter {
  static periodFromApiToDomain({
    periodStart: date,
    usedSeconds,
  }:
    | MonitoringTimeHistoryPeriodDTO
    | MonitoringTimeAndPackageHistoryPeriodDTO): UsageHistoryPeriod {
    return {
      date,
      usedMinutes: usedSeconds / 60,
    };
  }
}
