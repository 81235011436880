// Libs
import React from 'react';
import { bemBlock, SimpleTooltip } from '@neptune/shared/venus-ui';

// App
import {
  OrganizationType,
  ProjectPrivacy,
  PrivacyAvailability,
} from '@neptune/shared/core-organizations-domain';

// Module
import './ProjectVisibilitySection.less';

const block = bemBlock('project-visibility-section');

type ProjectVisibilitySectionProps = {
  visibility?: ProjectPrivacy;
  organizationType: OrganizationType;
  availableProjectPrivacySettings: PrivacyAvailability[];
  onPrivacyChange(event: React.ChangeEvent): void;
};

const privacyNameCopy: Record<ProjectPrivacy, string> = {
  [ProjectPrivacy.private]: 'Private',
  [ProjectPrivacy.workspace]: 'Workspace',
  [ProjectPrivacy.public]: 'Public',

  [ProjectPrivacy.notSupported]: 'not implemented',
};

const customDescriptionCopy: Partial<
  Record<OrganizationType, Partial<Record<ProjectPrivacy, string>>>
> = {
  [OrganizationType.individual]: {
    [ProjectPrivacy.private]: 'Only you can see this project.',
  },
};

const defaultDescriptionCopy: Record<ProjectPrivacy, string> = {
  [ProjectPrivacy.private]:
    'Only people you give access to in project settings can see this project.',
  [ProjectPrivacy.workspace]: 'Anyone with access to this workspace can see this project.',
  [ProjectPrivacy.public]: 'Anyone on the Internet will be able to find and see this project.',

  [ProjectPrivacy.notSupported]: '',
};

function tooltipOverOption({
  privacy,
  available,
  availableInPlan,
  disabledByAdmin,
}: PrivacyAvailability): string | undefined {
  if (available) {
    return; // Show tooltips only over unavailable options.
  }

  if (privacy === ProjectPrivacy.private && !availableInPlan) {
    return 'Private projects are not available in your pricing plan. Upgrade your subscription to be able to restrict access to your projects.';
  }

  if (privacy === ProjectPrivacy.public && disabledByAdmin) {
    return 'Creating public projects in this workspace is disabled. This option can be changed in workspace settings.';
  }
}

export const ProjectVisibilitySection: React.FC<ProjectVisibilitySectionProps> = ({
  organizationType,
  availableProjectPrivacySettings,
  visibility,
  onPrivacyChange,
}) => {
  return (
    <React.Fragment>
      {availableProjectPrivacySettings.map((privacyAvailability) => {
        const { privacy, available } = privacyAvailability;
        return (
          <div key={privacy} className={block('input-row')}>
            <SimpleTooltip content={tooltipOverOption(privacyAvailability)}>
              <label>
                <input
                  type="radio"
                  name="visibility"
                  className={block('radio-input')}
                  checked={visibility === privacy}
                  disabled={!available}
                  value={privacy}
                  data-role="project-visibility-checkbox"
                  onChange={onPrivacyChange}
                />
                <span
                  className={block({
                    element: 'radio-caption',
                    modifiers: { disabled: !available },
                  })}
                >
                  <span className={block('radio-name')}>{privacyNameCopy[privacy]} </span>-{' '}
                  {customDescriptionCopy[organizationType]?.[privacy] ||
                    defaultDescriptionCopy[privacy]}
                </span>
              </label>
            </SimpleTooltip>
          </div>
        );
      })}
    </React.Fragment>
  );
};
