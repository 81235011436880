
import { makeEnterpriseRoute } from 'router/helpers';

const userProfileRoute = {
  name: 'global-views/user-profile',
  path: '/:userName?:edit',
};

export default [
  userProfileRoute,
  {
    ...makeEnterpriseRoute(userProfileRoute),
    path: '/o/:organizationName/:userName?:edit',
    onlyLoggedUser: true,
  },
];

