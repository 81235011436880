import { ProjectRoleDTO } from 'generated/backend-client';

export enum ProjectRole {
  manager = 'manager',
  member = 'member',
  viewer = 'viewer',

  notSupported = 'notSupported',
}

export const projectRoleLabels: Record<ProjectRole, string> = {
  [ProjectRole.manager]: 'Owner',
  [ProjectRole.member]: 'Contributor',
  [ProjectRole.viewer]: 'Viewer',

  [ProjectRole.notSupported]: 'Not implemented role',
};

export function projectRoleFromApiToDomain(source?: ProjectRoleDTO): ProjectRole {
  switch (source) {
    case ProjectRoleDTO.Manager:
      return ProjectRole.manager;

    case ProjectRoleDTO.Member:
      return ProjectRole.member;

    case ProjectRoleDTO.Viewer:
      return ProjectRole.viewer;

    default:
      return ProjectRole.notSupported;
  }
}

export function projectRoleFromDomainToApi(source?: ProjectRole): ProjectRoleDTO {
  switch (source) {
    case ProjectRole.manager:
      return ProjectRoleDTO.Manager;

    case ProjectRole.member:
      return ProjectRoleDTO.Member;

    case ProjectRole.viewer:
      return ProjectRoleDTO.Viewer;

    default:
      return ProjectRoleDTO.Viewer;
  }
}
