import { storage } from '@neptune/shared/legacy-domain';

const BULK_ACCEPT_INVITATION_MUTED_KEY = 'bulkAcceptInvitationMuted';

type MutedInvitations = {
  byId: Record<string, number | undefined>;
  projects: Record<string, number | undefined>;
  organizations: Record<string, number | undefined>;
};

export function getMutedInvitations(): MutedInvitations {
  return (
    storage.local.getItem(BULK_ACCEPT_INVITATION_MUTED_KEY) || {
      byId: {},
      projects: {},
      organizations: {},
    }
  );
}

export function setMutedInvitations(updated: MutedInvitations) {
  storage.local.setItem(BULK_ACCEPT_INVITATION_MUTED_KEY, updated);
}
