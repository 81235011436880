import React from 'react';
import { useDispatch } from 'react-redux';
import { revokeServiceAccountApiToken } from '@neptune/service-accounts-domain';
import { addNotification } from 'state/ui/global/notification-popup/actions';
import { useConfirmationModal } from '@neptune/shared/common-business-logic';

type UseServiceAccountsProps = {
  organizationName: string | undefined;
  serviceAccountId?: string;
  onRevoke?(serviceAccountId: string): void;
};

const MODAL_ID = 'SERVICE_ACCOUNT_REVOKE_TOKEN_MODAL';

export const useServiceAccountRevokeApiToken = ({
  organizationName,
  onRevoke,
}: UseServiceAccountsProps) => {
  const { isOpen, data: serviceAccountId, openWithData, close } = useConfirmationModal(MODAL_ID);
  const dispatch = useDispatch();

  const execute = React.useCallback(async () => {
    if (!organizationName || !serviceAccountId) {
      return undefined;
    }

    try {
      await revokeServiceAccountApiToken({
        organizationIdentifier: organizationName,
        serviceAccountId,
      });
    } catch (e) {
      dispatch(
        addNotification('error', 'We were not able to reset your token. Please try again later.'),
      );
    }

    onRevoke?.(serviceAccountId);
    close();
  }, [serviceAccountId, organizationName, dispatch, close, onRevoke]);

  return {
    isOpen,
    close,
    openWithData,
    execute,
  };
};
