import React from 'react';
import { uniq } from 'lodash';
import {
  isNonEmptyStringSetCriterion,
  isSearchQuery,
  SearchQuery,
} from '@neptune/search-query-domain';

import { useSearchQuery } from './use-search-query';

export const useSelectedStringSetValues = (attributeName: string) => {
  const { metaQuery } = useSearchQuery();
  const uniqueTags = React.useMemo(
    () => getUniqueValues(metaQuery, attributeName),
    [metaQuery, attributeName],
  );

  return uniqueTags;
};

function getUniqueValues(
  { criteria }: Pick<SearchQuery, 'criteria'>,
  attributeName: string,
): string[] {
  const tags = criteria.reduce((result: string[], criterion) => {
    if (isSearchQuery(criterion)) {
      return [...result, ...getUniqueValues(criterion, attributeName)];
    }

    if (criterion.attribute === attributeName && isNonEmptyStringSetCriterion(criterion)) {
      return [...result, ...criterion.value];
    }

    return result;
  }, []);

  return uniq(tags);
}
