// Libs
import React from 'react';

// App
import { Dialog } from '@neptune/shared/venus-ui';

export const CancelSubscriptionErrorModal: React.FC<{ isOpen: boolean; close: () => void }> = ({
  isOpen,
  close,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      title="Unable to delete account"
      primaryButtonLabel="OK"
      onPrimaryButtonClick={close}
      onClose={close}
    >
      <Dialog.Text>
        You are trying to delete your account, but you’re an Admin of at least one team workspace.
        You need to change your workspace role to Member or delete the workspace before deleting
        your account.
      </Dialog.Text>
    </Dialog>
  );
};
