import React from 'react';
import { AvatarSource } from '@neptune/shared/core-users-domain';
import { useSelector } from 'react-redux';
import { getContextOrganization } from 'state/selectors-global';
import { OrganizationType } from '@neptune/shared/core-organizations-domain';
import { calculateProjectPrivacyAvailability } from '@neptune/shared/core-project-business-logic';
import { ProjectBeingEdited } from 'state/ui/modals/project/actions';
import { ProjectForm } from './ProjectForm';

type ProjectFormContainerProps = {
  project: ProjectBeingEdited;
  initialProjectName?: string;
  mode: 'edit' | 'add';
  onProjectUpdate(project: ProjectBeingEdited): void;
  onValidationChange(isValid: boolean): void;
};

export const ProjectFormContainer: React.FC<ProjectFormContainerProps> = React.memo(
  function ProjectFormContainer({
    project,
    initialProjectName = '',
    mode,
    onProjectUpdate,
    onValidationChange,
  }) {
    const organization = useSelector(getContextOrganization);
    const [projectNameError, setProjectNameError] = React.useState<string>('');
    const [isProjectKeyValid, setIsProjectKeyValid] = React.useState<boolean>(true);
    const availableProjectPrivacySettings = React.useMemo(() => {
      const settings = calculateProjectPrivacyAvailability(organization ? organization.traits : {});

      if (organization?.organizationType === OrganizationType.individual) {
        // In individual organizations we won't show unavailable privacy settings,
        // except when disabled by user (disabledByAdmin).
        return settings.filter(({ availableInPlan }) => availableInPlan);
      }

      return settings;
    }, [organization]);

    const isValid = isFormValid(project, projectNameError, isProjectKeyValid);

    React.useEffect(() => {
      onValidationChange(isValid);
    }, [isValid, setIsProjectKeyValid, onValidationChange]);

    const handleProjectKeyValidationChange = React.useCallback(
      ({ isProjectKeyValid }: { isProjectKeyValid: boolean }) => {
        setIsProjectKeyValid(isProjectKeyValid);
      },
      [setIsProjectKeyValid],
    );

    const handleNameValidationChange = React.useCallback(
      ({ error }: { error: string }) => {
        setProjectNameError(error);
      },
      [setProjectNameError],
    );

    const handleProjectChange = React.useCallback(
      (name: string, value: any) => {
        const changedProject: ProjectBeingEdited = {
          ...project,
          [name]: value,
        };
        onProjectUpdate(changedProject);
      },
      [project, onProjectUpdate],
    );

    const handleColorChange = React.useCallback(
      (displayClass: string) => {
        const changedProject: ProjectBeingEdited = {
          ...project,
          displayClass,
        };
        onProjectUpdate(changedProject);
      },
      [project, onProjectUpdate],
    );

    const handleAvatarChange = React.useCallback(
      (emoji: string) => {
        const changedProject = {
          ...project,
          avatarUrl: emoji,
          avatarSource: AvatarSource.unicode,
        };
        onProjectUpdate(changedProject);
      },
      [project, onProjectUpdate],
    );

    const handleProjectKeyChange = React.useCallback(
      (projectKey: string) => {
        const changedProject: ProjectBeingEdited = {
          ...project,
          projectKey,
        };
        onProjectUpdate(changedProject);
      },
      [project, onProjectUpdate],
    );

    const organizationId =
      project && project.organizationId ? project.organizationId : organization?.id;

    return (
      <ProjectForm
        project={project}
        initialProjectName={initialProjectName}
        mode={mode}
        projectNameError={projectNameError}
        organizationId={organizationId}
        organizationName={organization?.name}
        organizationType={organization?.organizationType || OrganizationType.notSupported}
        availableProjectPrivacySettings={availableProjectPrivacySettings}
        onProjectKeyValidationChange={handleProjectKeyValidationChange}
        onNameValidationChange={handleNameValidationChange}
        onColorChange={handleColorChange}
        onAvatarChange={handleAvatarChange}
        onProjectChange={handleProjectChange}
        onProjectKeyChange={handleProjectKeyChange}
      />
    );

    function isFormValid(
      project: ProjectBeingEdited,
      projectNameError: string,
      isProjectKeyValid: boolean,
    ): boolean {
      return (
        project &&
        project.name !== '' &&
        project.name !== undefined &&
        project.projectKey !== '' &&
        project.projectKey !== undefined &&
        projectNameError === '' &&
        isProjectKeyValid
      );
    }
  },
);
