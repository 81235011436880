import React from 'react';
import { Toolbar } from '@neptune/shared/venus-ui';

export const ExportToolbar: React.FC = ({ children }) => {
  return (
    <Toolbar withPadding="md">
      <Toolbar.Item>{children}</Toolbar.Item>
    </Toolbar>
  );
};
