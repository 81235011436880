// Libs
import React from 'react';
import { Layout, View, bemBlock } from '@neptune/shared/venus-ui';

// App
import TextByOs from 'components/modals/api-token-modal/text-by-os/TextByOs';
import { MarkdownRenderer } from '@neptune/shared/common-ui';

// Module
import './SetupTutorial.less';

const block = bemBlock('setup-tutorial');

export const SetupTutorial: React.FC<{ apiToken?: string }> = ({
  children,
  apiToken = 'YOUR_API_TOKEN',
}) => {
  const windowsComponent = (
    <MarkdownRenderer className={block('markdown')} content={getWindowsMarkdown(apiToken)} />
  );
  const linuxComponent = (
    <MarkdownRenderer className={block('markdown')} content={getLinuxMarkdown(apiToken)} />
  );

  return (
    <View className={block()} spacedChildren="lg" overflow={['auto', 'hidden']} withCustomScrollbar>
      <View.Title>Copy and export your API token</View.Title>
      <Layout.Column spacedChildren="md">
        <TextByOs windows={windowsComponent} mac={linuxComponent} linux={linuxComponent} />
      </Layout.Column>
      {children}
    </View>
  );
};

function getCommonMarkdown(apiToken: string) {
  return `
Here is your API token:

\`\`\`
${apiToken}
\`\`\`

It is needed to authorize communication between your training scripts and Neptune. Remember to keep your API token secret! It is like a password to the application.
`;
}

function getWindowsMarkdown(apiToken: string) {
  return (
    getCommonMarkdown(apiToken) +
    `
You can export the \`NEPTUNE_API_TOKEN\` environment variable, by running the following command in your Command Prompt:

\`\`\`
set NEPTUNE_API_TOKEN="${apiToken}"
\`\`\`

Aside from setting the environment variable for the current session, you can set it permanently in your system. That way you don’t have to worry about it every time you start a run - Neptune will pick up the API token from the environment.

To set permanently, run:

\`\`\`
setx NEPTUNE_API_TOKEN "${apiToken}"
\`\`\`

This will take effect after restarting your Command Prompt.`
  );
}

function getLinuxMarkdown(apiToken: string) {
  return (
    getCommonMarkdown(apiToken) +
    `
You can also export the \`NEPTUNE_API_TOKEN\` environment variable, by running the following command in your terminal:

\`\`\`
export NEPTUNE_API_TOKEN="${apiToken}"
\`\`\`

The recommended method is to append this command to your \`~/.profile\` file. That way you don’t have to worry about it every time you start a run - Neptune will pick up the API token from the environment.`
  );
}
