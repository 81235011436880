import React from 'react';
import { Entity, EntityChanges, Stage } from '@neptune/shared/entity-domain';

export type StageChangeModalData = {
  entity: Entity;
  targetStage: Stage;
  onEntityChange: (changes: Partial<EntityChanges>) => Promise<void>;
};

type StageConfirmationContextValue = {
  data: StageChangeModalData | null;
  isOpen: boolean;
  openModal: (data: StageChangeModalData) => void;
  closeModal: () => void;
};

export const StageChangeConfirmationContext =
  React.createContext<StageConfirmationContextValue | null>(null);
