import { ExpandableLayoutActions, ExpandableLayoutActionTypes } from './actions';
import { getInitialState } from '../storage-utils';

const initialState = getInitialState();

export const expandableLayoutReducer = (state = initialState, action: ExpandableLayoutActions) => {
  switch (action.type) {
    case ExpandableLayoutActionTypes.Set: {
      const { id, projectIdentifier, layout } = action.payload;

      return {
        ...state,
        [projectIdentifier]: {
          ...state[projectIdentifier],
          [id]: layout,
        },
      };
    }
  }

  return state;
};
