import { AppState } from 'state/types';
import { GroupCompareState } from './group-compare-reducer';
import getUiState from 'state/ui/selectors';

export const getGroupCompareState = (state: AppState): GroupCompareState => {
  return getUiState(state).groupCompare;
};

const EMPTY_OBJECT = {};

export const getSelectedGroupCount = (
  state: AppState,
  id: string,
): Record<string, number | undefined> => {
  return getGroupCompareState(state)[id]?.selectedGroups || EMPTY_OBJECT;
};

export const getRunIdsInGroup = (state: AppState, id: string): Record<string, string[]> => {
  return getGroupCompareState(state)?.[id]?.runIdsInGroup || EMPTY_OBJECT;
};
