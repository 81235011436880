// App
import { PartialSearchCriterion, StringSeriesCriterion } from '../search-query-model';
import { criterionHasAttributeAndOperator } from '../search-query-model-core-utils';

// Module
import { CriterionHelpers } from './types';

export function isStringSeriesCriterion(
  criterion: PartialSearchCriterion,
): criterion is StringSeriesCriterion {
  return criterionHasAttributeAndOperator(criterion) && criterion.type === 'stringSeries';
}

export const stringSeriesCriterionHelpers: CriterionHelpers<StringSeriesCriterion> = {
  getReadableValue(criterion) {
    return `"${criterion.value}"`;
  },
  getShortReadableValue(criterion) {
    return criterion.value;
  },
  isComplete(criterion) {
    return criterion.value != null;
  },
};
