import React from 'react';

import { Dialog } from '@neptune/shared/venus-ui';
import { UpgradePlanLink } from './UpgradePlanLink';

export const AlreadyAnAdminModal: React.FC<{
  isOpen: boolean;
  close: () => void;
  workspaceName: string;
}> = ({ isOpen, close, workspaceName }) => {
  return (
    <Dialog
      isOpen={isOpen}
      title="Cannot change access rights"
      primaryButtonLabel="OK"
      onPrimaryButtonClick={close}
      onClose={close}
    >
      <Dialog.Text>
        User is already administrating another team workspace in the free trial subscription. To be
        able to grant Admin rights to this user,{' '}
        <span onClick={close}>
          <UpgradePlanLink workspaceName={workspaceName}>upgrade your plan</UpgradePlanLink>
        </span>
        .
      </Dialog.Text>
    </Dialog>
  );
};
