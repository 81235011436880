import { COMPARE_QUERY_PARAMS, FILTERING_QUERY_PARAMS } from 'router/config';
import { AppRoute } from '@neptune/shared/routing-domain';

const getTabFromSplit = (split?: 'cmp' | 'tbl' | 'bth') => {
  switch (split) {
    case 'cmp': {
      return 'compare';
    }

    case 'bth': {
      return 'compare';
    }

    case 'tbl': {
      return 'table';
    }

    default: {
      return 'table';
    }
  }
};

const getVersionsTab = (detailsTab: string) => {
  if (detailsTab === 'activity') {
    return 'activity-log';
  }

  return detailsTab;
};

const runDetailsNoTabRoute: AppRoute = {
  name: 'project-compatible-run-details-no-tab',
  path: '/:organizationName/:projectName/e/:shortId',
  canActivate:
    () =>
    ({ params }) => {
      return Promise.reject({
        redirect: {
          name: 'project.entity-with-shortid-only',
          params: {
            ...params,
            tab: 'details',
          },
        },
      });
    },
};

const enterpriseRunDetailsNoTabRoute: AppRoute = {
  name: 'enterprise-context/project-compatible-run-details-no-tab',
  path: '~/o/:organizationName/org/:projectName/e/:shortId',
  canActivate:
    () =>
    ({ params }) => {
      return Promise.reject({
        redirect: {
          name: 'enterprise-context/project.entity-with-shortid-only',
          params: {
            ...params,
            tab: 'details',
          },
        },
      });
    },
};

const runDetailsRoute: AppRoute = {
  name: 'project-run-compatible-details',
  path: '/:organizationName/:projectName/e/:shortId/:detailsTab',
  canActivate:
    () =>
    ({ params }) => {
      return Promise.reject({
        redirect: {
          name: 'project.entity-with-shortid-only',
          params: {
            ...params,
            tab: 'details',
            detailsTab: params.detailsTab === 'all' ? 'metadata' : params.detailsTab,
          },
        },
      });
    },
};

const enterpriseRunDetailsRoute: AppRoute = {
  name: 'enterprise-context/project-run-compatible-details',
  path: '~/o/:organizationName/org/:projectName/e/:shortId/:detailsTab',
  canActivate:
    () =>
    ({ params }) => {
      return Promise.reject({
        redirect: {
          name: 'enterprise-context/project.entity-with-shortid-only',
          params: {
            ...params,
            tab: 'details',
            detailsTab: params.detailsTab === 'all' ? 'metadata' : params.detailsTab,
          },
        },
      });
    },
};

const modelRouteDetails: AppRoute = {
  name: 'project-compatible-model-details',
  path: '/:organizationName/:projectName/m/:shortId/:detailsTab',
  canActivate:
    () =>
    ({ params }) => {
      return Promise.reject({
        redirect: {
          name: 'project.entity-with-shortid-only',
          params: {
            ...params,
          },
        },
      });
    },
};

const enterpriseModelDetailsRoute: AppRoute = {
  name: 'enterprise-context/project-compatible-model-details',
  path: '~/o/:organizationName/org/:projectName/m/:shortId/:detailsTab',
  canActivate:
    () =>
    ({ params }) => {
      return Promise.reject({
        redirect: {
          name: 'enterprise-context/project.entity-with-shortid-only',
          params: {
            ...params,
          },
        },
      });
    },
};

const modelRouteDetailsNoTab: AppRoute = {
  name: 'project-compatible-model-details-no-tab',
  path: '/:organizationName/:projectName/m/:shortId',
  canActivate:
    () =>
    ({ params }) => {
      return Promise.reject({
        redirect: {
          name: 'project.entity-with-shortid-only',
          params: {
            ...params,
          },
        },
      });
    },
};

const enterpriseModelDetailsNoTabRoute: AppRoute = {
  name: 'enterprise-context/project-compatible-model-details-no-tab',
  path: '~/o/:organizationName/org/:projectName/m/:shortId',
  canActivate:
    () =>
    ({ params }) => {
      return Promise.reject({
        redirect: {
          name: 'enterprise-context/project.entity-with-shortid-only',
          params: {
            ...params,
          },
        },
      });
    },
};

const modelVersionRouteDetailsNoTab: AppRoute = {
  name: 'project-compatible-model-version-details-no-tab',
  path: '/:organizationName/:projectName/m/:shortId/v/:versionId',
  canActivate:
    () =>
    ({ params }) => {
      const { versionId, ...restParams } = params;
      return Promise.reject({
        redirect: {
          name: 'project.entity-with-shortid-only',
          params: {
            ...restParams,
            shortId: versionId,
          },
        },
      });
    },
};

const enterpriseModelVersionDetailsNoTab: AppRoute = {
  name: 'enterprise-context/project-compatible-model-version-details-no-tab',
  path: '/o/:organizationName/org/:projectName/m/:shortId/v/:versionId',
  canActivate:
    () =>
    ({ params }) => {
      const { versionId, ...restParams } = params;
      return Promise.reject({
        redirect: {
          name: 'enterprise-context/project.entity-with-shortid-only',
          params: {
            ...restParams,
            shortId: versionId,
          },
        },
      });
    },
};

const modelVersionRouteDetails: AppRoute = {
  name: 'project-compatible-model-version-details',
  path: '/:organizationName/:projectName/m/:shortId/v/:versionId/:detailsTab',
  canActivate:
    () =>
    ({ params }) => {
      const { versionId, detailsTab, ...restParams } = params;
      return Promise.reject({
        redirect: {
          name: 'project.entity-with-shortid-only',
          params: {
            ...restParams,
            detailsTab: getVersionsTab(detailsTab),
            shortId: versionId,
          },
        },
      });
    },
};

const enterpriseModelVersionDetails: AppRoute = {
  name: 'enterprise-context/project-compatible-model-version-details',
  path: '/o/:organizationName/org/:projectName/m/:shortId/v/:versionId/:detailsTab',
  canActivate:
    () =>
    ({ params }) => {
      const { versionId, detailsTab, ...restParams } = params;
      return Promise.reject({
        redirect: {
          name: 'enterprise-context/project.entity-with-shortid-only',
          params: {
            ...restParams,
            detailsTab: getVersionsTab(detailsTab),
            shortId: versionId,
          },
        },
      });
    },
};

const dashboardRoute: AppRoute = {
  name: 'project-compatible-dashboard',
  path: '/:organizationName/:projectName/e/:shortId/dashboard/:dashboardId',
  canActivate:
    () =>
    ({ params }) => {
      return Promise.reject({
        redirect: {
          name: 'project.entity-with-shortid-only',
          params: {
            ...params,
            tab: 'details',
            detailsTab: 'dashboard',
          },
        },
      });
    },
};

const enterpriseDashboardRoute: AppRoute = {
  name: 'enterprise-context/project-compatible-dashboard',
  path: '~/o/:organizationName/org/:projectName/e/:shortId/dashboard/:dashboardId',
  canActivate:
    () =>
    ({ params }) => {
      return Promise.reject({
        redirect: {
          name: 'enterprise-context/project.entity-with-shortid-only',
          params: {
            ...params,
            tab: 'details',
            detailsTab: 'dashboard',
          },
        },
      });
    },
};

const experimentsRoute: AppRoute = {
  name: 'project-experiments',
  path: `/:organizationName/:projectName/experiments?${FILTERING_QUERY_PARAMS}&${COMPARE_QUERY_PARAMS}&:viewId`,
  canActivate:
    () =>
    ({ params }) => {
      const { split, ...restParams } = params;
      return Promise.reject({
        redirect: {
          name: 'project.runs-tab',
          params: {
            ...restParams,
            tab: getTabFromSplit(split),
          },
        },
      });
    },
};

const enterpriseExperimentsRoute: AppRoute = {
  name: 'enterprise-context/project-experiments',
  path: `~/o/:organizationName/org/:projectName/experiments?${FILTERING_QUERY_PARAMS}&${COMPARE_QUERY_PARAMS}&:viewId`,
  canActivate:
    () =>
    ({ params }) => {
      const { split, ...restParams } = params;
      return Promise.reject({
        redirect: {
          name: 'enterprise-context/project.runs-tab',
          params: {
            ...restParams,
            tab: getTabFromSplit(split),
          },
        },
      });
    },
};

export const compatibilityRoutes = [
  experimentsRoute,
  dashboardRoute,
  runDetailsRoute,
  runDetailsNoTabRoute,
  modelRouteDetailsNoTab,
  modelRouteDetails,
  modelVersionRouteDetailsNoTab,
  modelVersionRouteDetails,
  enterpriseExperimentsRoute,
  enterpriseDashboardRoute,
  enterpriseRunDetailsRoute,
  enterpriseRunDetailsNoTabRoute,
  enterpriseModelDetailsNoTabRoute,
  enterpriseModelDetailsRoute,
  enterpriseModelVersionDetailsNoTab,
  enterpriseModelVersionDetails,
];
