import { ProjectPrivacy } from '@neptune/shared/core-organizations-domain';
import { LegacyEmblem, GlyphName, Icon, Layout, Link, Text } from '@neptune/shared/venus-ui';
import { neptuneUrls } from '@neptune/shared/common-domain';
import React from 'react';

type ProjectVisibilityInfoProps = {
  projectName?: string;
  projectPrivacy?: ProjectPrivacy;
};

const privacyBasedCopy: Record<
  ProjectPrivacy.private | ProjectPrivacy.workspace,
  {
    privacyName: string;
    description: string;
    glyph: GlyphName;
  }
> = {
  [ProjectPrivacy.private]: {
    privacyName: 'Private',
    description: 'only people assigned to it can access it. ',
    glyph: 'lock',
  },
  [ProjectPrivacy.workspace]: {
    privacyName: 'Workspace',
    description: 'all workspace members can access it. ',
    glyph: 'lock-open',
  },
};

export const ProjectVisibilityInfo: React.FC<ProjectVisibilityInfoProps> = ({
  projectName,
  projectPrivacy,
}) => {
  if (!projectName || !projectPrivacy) {
    return null;
  }

  if (projectPrivacy === ProjectPrivacy.notSupported || projectPrivacy === ProjectPrivacy.public) {
    return null;
  }

  return (
    <Layout.Element data-role="project-visibility-info">
      <Text size="sm">
        The privacy of project <Text fontWeight="semibold">{projectName}</Text> is set to{' '}
        <LegacyEmblem>
          <Icon size="xs" glyph={privacyBasedCopy[projectPrivacy].glyph} />
          <Text size="xs">{privacyBasedCopy[projectPrivacy].privacyName}</Text>
        </LegacyEmblem>{' '}
        - {privacyBasedCopy[projectPrivacy].description}
        <Link href={neptuneUrls.workspacesAndProjectsDoc} external>
          Learn more about the project-level access control&nbsp;
          <Icon glyph="external-link" />
        </Link>
      </Text>
    </Layout.Element>
  );
};
