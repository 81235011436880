import { last } from 'lodash';
import { useSelector } from 'react-redux';

import { getRouteHistory } from './route-history-selectors';
import type { RouterState } from '@neptune/shared/routing-domain';

export function useLastRoute(predicate?: (state: RouterState) => boolean): RouterState | undefined {
  const routeHistory = useSelector(getRouteHistory);

  if (predicate == null) {
    return last(routeHistory);
  }

  const filtered = routeHistory.filter(predicate);
  return last(filtered);
}
