import type { Router } from 'router5';

const acceptRoute = {
  name: 'accept-invitation',
  path: '/-/invitations/:type/:invitationId',
  onlyLoggedUser: true,
};

const resendInvitationRoute = {
  name: 'accept-invitation.resend',
  path: '/:invitationTarget',
  onlyLoggedUser: true,
};

export const invitationRoutes = [acceptRoute, resendInvitationRoute];

export const isAcceptInvitationRoute = (router: Router) =>
  router.matchUrl(window.location.href)?.name === acceptRoute.name;
