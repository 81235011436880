import { Entity } from '@neptune/shared/entity-domain';
import { KnownAttributes } from 'domain/experiment/attribute';
import { fetchEntitiesChunks } from './fetch-entities-chunks';

const SYNC_TIME_MS = 20;

type FetchRequest = {
  fieldsToFetch: string[];
};

type QueueEntry = {
  entities: Entity[];
  requests: (FetchRequest | undefined)[]; // Allow cancellation by removing entries.
  promise: Promise<(Entity | undefined)[]>;
};

const requestQueue: Map<Entity[], QueueEntry> = new Map();

async function handleRequestEntry(entry: QueueEntry) {
  const { entities, requests } = entry;
  // Remove from queue.
  requestQueue.delete(entities);

  const requestedAttributes = new Set(
    requests.flatMap((request) => (request ? request.fieldsToFetch : [])),
  );

  if (entities.length === 0 || requestedAttributes.size === 0) {
    return entities;
  }

  requestedAttributes.add(KnownAttributes.Id);
  const fieldsToFetch = [...requestedAttributes.keys()];

  return await fetchEntitiesChunks({
    entities,
    fieldsToFetch,
  });
}

function getOrCreateEntry(entities: Entity[]) {
  const existingQueueEntry = requestQueue.get(entities);

  if (existingQueueEntry) {
    return existingQueueEntry;
  }

  const queueEntry: QueueEntry = {
    entities,
    requests: [],
    promise: new Promise<(Entity | undefined)[]>((resolve, reject) => {
      setTimeout(() => {
        return handleRequestEntry(queueEntry).then(resolve, reject);
      }, SYNC_TIME_MS);
    }),
  };
  requestQueue.set(entities, queueEntry);
  return queueEntry;
}

// Request clustering wrapper for fetchEntitiesChunks.
export function fetchEntitiesChunksInCluster({
  entities,
  fieldsToFetch,
}: {
  entities: Entity[];
  fieldsToFetch: string[];
}): Promise<(Entity | undefined)[]> {
  const queueEntry = getOrCreateEntry(entities);
  queueEntry.requests.push({ fieldsToFetch });
  return queueEntry.promise;
}
