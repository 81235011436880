// Libs
import React from 'react';
import { Layout, Text } from '@neptune/shared/venus-ui';

// App
import { formatMonitoringShort } from '@neptune/shared/common-util';

// Module
type ProjectMonitoringValueProps = {
  usedMinutes?: number;
  totalMinutes?: number;
};

export const ProjectMonitoringValue: React.FC<ProjectMonitoringValueProps> = ({
  usedMinutes = 0,
  totalMinutes = 0,
}) => {
  return (
    <Layout.Column span="auto" spacedChildren="xs">
      <Layout.Row span="auto" spacedChildren="xs">
        <Text fontWeight="semibold">{formatMonitoringShort(totalMinutes)}</Text>
        <Text>used in project (in total)</Text>
      </Layout.Row>
      <Layout.Row span="auto" spacedChildren="xs">
        <Text fontWeight="semibold" data-role="used-minutes">
          {formatMonitoringShort(usedMinutes)}
        </Text>
        <Text>used in the last 30 days</Text>
      </Layout.Row>
    </Layout.Column>
  );
};
