import { AttributeDefinition } from 'domain/experiment/attribute';
import { Widget, WidgetSource } from './core/widget';

export function inferWidgetName(widget: Widget): string {
  if (widget.name) {
    return widget.name;
  }

  if (widget.sources?.length === 1) {
    return widget.sources?.[0].metadata?.alias || widget.sources?.[0].value;
  }

  return 'Unnamed';
}

export function extractAttributeDefinitions(widget: Widget): AttributeDefinition[] {
  return widget.sources
    .filter((source) => source.type === 'field')
    .map(
      (source): Partial<AttributeDefinition> => ({
        name: source.value,
        type: source.metadata?.attributeType,
        ...(source.metadata?.subproperty && { subproperty: source.metadata.subproperty }),
      }),
    )
    .filter(
      (definition): definition is AttributeDefinition => !!definition.name && !!definition.type,
    );
}

export function attributeDefinitionToWidgetSource(
  attributeDefinition: AttributeDefinition,
): WidgetSource {
  return {
    type: 'field',
    value: attributeDefinition.name,
    metadata: {
      attributeType: attributeDefinition.type,
      ...(attributeDefinition.subproperty && { subproperty: attributeDefinition.subproperty }),
    },
  };
}
