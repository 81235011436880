import { chain, isUndefined, isNull } from 'lodash';
import type { AnyRouteParams, NavigationOptions } from '@neptune/shared/routing-domain';

import { navigateTo } from '../actions';
import { getCurrentRouteParams } from '../selectors';

import type { Deserializers, IDeserializers } from './IDeserializers';
import type { ISerializers, Serializers } from './ISerializers';

/**
 * ViewNavigation is strongly typed class that abstracts the way we interact with routing,
 * especially routing params as the consumer doesn't need to know real parameter names.
 * It allows using the same parameter names in many views even if route is the same (automatically adds prefix).
 */
export abstract class ViewNavigation<P extends AnyRouteParams>
  implements ISerializers<P>, IDeserializers<P>
{
  /**
   * Route name associated with this ViewNavigation instance.
   * Used for dispatching redux action.
   */
  protected abstract get routeName(): string;

  /**
   * Prefix that will be used for each parameter when serializing.
   * This is useful when there is a conflict of URL parameters across different views on a single route.
   */
  protected abstract get paramPrefix(): string;

  /**
   * Each field declared by <P> type needs to have explicitly defined deserializer.
   */
  abstract get deserializers(): Deserializers<P>;

  /**
   * Each field declared by <P> type needs to have explicitly defined serializer.
   */
  abstract get serializers(): Serializers<P>;

  protected serializeParamKey(key: keyof P): string {
    return `${this.paramPrefix}${key.toString()}`;
  }

  /**
   * An async (thunk) action creator for navigating using this ViewNavigation.
   * It takes care of parameters serialization.
   * Can be used whenever you want to programmatically navigate to this view.
   *
   * @example
   *  const dispatch = useDispatch()
   *  dispatch( myNavigationInstance.navigateTo({ paramThatCanBeUnderstoodByMyNavigation: 'foo' }) )
   *
   * @param params
   * @param options
   */
  navigateTo(params: P, options?: NavigationOptions) {
    return navigateTo(this.routeName, this.serializeParams(params), options);
  }

  /**
   * Takes raw parameters object (ie raw object from router) and deserializes them using provided deserializers.
   * Missing values from input parameters will still appear in returned object own properties but with undefined values.
   * It should happen only for optional parameters.
   * @param params
   */
  deserializeParams(params: AnyRouteParams): P {
    return Object.entries(this.deserializers).reduce(
      (deserializedParams: P, [key, deserializer]) => {
        deserializedParams[key as keyof P] = deserializer(params[this.serializeParamKey(key)]);
        return deserializedParams;
      },
      {} as P,
    );
  }

  /**
   * Returns serialized version of route parameters object based on prefix and delivered serializers.
   * This should be used whenever you'd like to put these parameters into URL.
   * Null and undefined values are removed to not clutter URL.
   * @param params
   */
  serializeParams(params: P): AnyRouteParams {
    const serialized = Object.entries(this.serializers).reduce<AnyRouteParams>(
      (serializedParams, [key, serializer]) => {
        serializedParams[this.serializeParamKey(key)] = serializer(params[key]);
        return serializedParams;
      },
      {},
    );

    return chain(serialized).omitBy(isNull).omitBy(isUndefined).value();
  }

  /**
   * Returns some of predefined params type from persistent storage.
   * Useful for implementing automatic redirections.
   */
  getPersistedParams(): Partial<P> {
    throw new Error('getPersistedParams: you need to implement me in derived class');
  }

  /**
   * Method used to persist some route parameters in storage, for later retrieving.
   * @param params
   */
  persistParams(params: Partial<P>) {
    params;
    throw new Error('persistParams: you need to implement me in derived class');
  }

  /**
   * Selects all params from App state that are understandable by this ViewNavigation.
   * @param state
   */
  paramsSelector = (state: any): P => {
    const anyParams = getCurrentRouteParams(state);
    return this.deserializeParams(anyParams);
  };

  /**
   * Helper useful for creating memoized selector that returns particular route param.
   * @param paramKey
   */
  createParamSelector<K extends keyof P>(paramKey: K): (state: any) => P[K] {
    return (state) => {
      const routeParams = getCurrentRouteParams(state);
      const deserializedParams = this.deserializeParams(routeParams);

      return deserializedParams[paramKey];
    };
  }
}
