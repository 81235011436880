import {
  DashboardVersionDTO,
  DashboardVersionDTOTypeEnum,
  leaderboardClient,
} from '@neptune/shared/core-apis-leaderboard-domain';
import { Dashboard, DashboardType } from 'domain/dashboard';

export type DashboardVersion = Pick<
  Dashboard,
  | 'id'
  | 'versionBranchId'
  | 'versionName'
  | 'versionDescription'
  | 'name'
  | 'createdTime'
  | 'lastUpdatedTime'
  | 'owner'
  | 'type'
>;

export type Version = {
  id: string;
  versionBranchId: string;
  versionName?: string;
  versionDescription?: string;
  timestamp?: Date;
  owner?: string;
};

type UpdateVersion = Pick<DashboardVersion, 'versionName' | 'versionDescription'>;

export const EDIT_VERSION_NAME_DESCRIPTION_MODAL = 'EDIT_VERSION_NAME_DESCRIPTION_MODAL';

export type DashboardVersionList = {
  entries: DashboardVersion[];
  limit: number;
  offset: number;
  total: number;
};

const dashboardVersionTypeMapping: Record<DashboardType, DashboardVersionDTOTypeEnum> = {
  compare: DashboardVersionDTOTypeEnum.Compare,
  experiment: DashboardVersionDTOTypeEnum.Experiment,
  report: DashboardVersionDTOTypeEnum.Report,
};

export async function listDashboardVersions({
  projectIdentifier,
  versionBranch,
  searchQuery,
  limit,
  offset,
}: {
  projectIdentifier: string;
  versionBranch: string;
  searchQuery?: string;
  limit?: number;
  offset?: number;
}): Promise<DashboardVersionList> {
  const response = await leaderboardClient.listDashboardBranchVersions({
    projectIdentifier,
    versionBranch,
    substringQuery: searchQuery || undefined,
    offset,
    limit,
  });

  return {
    ...response,
    entries:
      DashboardVersionModelConverter.listDashboardVersionsFromApiToDomain(response.entries) || [],
  };
}

export async function updateDashboardVersion({
  updateVersion,
  projectIdentifier,
  versionId,
}: {
  versionId: string;
  projectIdentifier: string;
  updateVersion: UpdateVersion;
}) {
  const response = await leaderboardClient.updateDashboardVersion({
    updateDashboardVersionDTO: updateVersion,
    projectIdentifier,
    dashboardId: versionId,
  });
  return DashboardVersionModelConverter.dashboardVersionFromApiToDomain(response);
}

export abstract class DashboardVersionModelConverter {
  static dashboardVersionTypeFromApiToDomain(type: DashboardVersionDTOTypeEnum): DashboardType {
    return dashboardVersionTypeMapping[type];
  }

  static dashboardVersionFromApiToDomain(version: DashboardVersionDTO): DashboardVersion {
    return {
      ...version,
      versionBranchId: version.versionBranch,
      type: DashboardVersionModelConverter.dashboardVersionTypeFromApiToDomain(version.type),
    };
  }

  static listDashboardVersionsFromApiToDomain(versions: DashboardVersionDTO[]): DashboardVersion[] {
    return versions.map((version) =>
      DashboardVersionModelConverter.dashboardVersionFromApiToDomain(version),
    );
  }
}
