// Libs
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

// App
import {
  requestAbort,
  requestRestoreLeaderboardEntries,
  requestStop,
} from 'state/project-leaderboard/bulk-operations/actions';
import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';
import { WithPermissions } from '@neptune/shared/project-domain';
import { Entity, getEntityTrashed } from '@neptune/shared/entity-domain';

type EntityControlActions = {
  onAbort?: () => void;
  onStop?: () => void;
  onRestore?: () => void;
};

export const useEntityControlActions = (
  entityWithPermissions: WithPermissions<Entity>,
): EntityControlActions => {
  const projectIdentifier = useSelector(getCurrentProjectIdentifier);
  const trashed = getEntityTrashed(entityWithPermissions);
  const dispatch = useDispatch();

  const handleAbort = React.useCallback(
    () =>
      dispatch(
        requestAbort({
          projectIdentifier,
          experimentIdentifiers: [entityWithPermissions.id],
        }),
      ),
    [dispatch, entityWithPermissions.id, projectIdentifier],
  );

  const handleRestore = React.useCallback(
    () =>
      dispatch(
        requestRestoreLeaderboardEntries({
          projectIdentifier,
          experimentIdentifiers: [entityWithPermissions.id],
        }),
      ),
    [dispatch, entityWithPermissions.id, projectIdentifier],
  );

  const handleStop = React.useCallback(
    () =>
      dispatch(
        requestStop({
          projectIdentifier,
          experimentIdentifiers: [entityWithPermissions.id],
        }),
      ),
    [dispatch, entityWithPermissions.id, projectIdentifier],
  );

  const canModify = entityWithPermissions.canModify && !trashed;
  const canRestore = entityWithPermissions.canModify && trashed;
  const canAbort = entityWithPermissions.canAbort && !trashed;

  return {
    onStop: canModify ? handleStop : undefined,
    onRestore: canRestore ? handleRestore : undefined,
    onAbort: canAbort ? handleAbort : undefined,
  };
};
