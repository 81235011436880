import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCurrentProject,
  getCurrentProjectIdentifier,
} from '@neptune/current-project-business-logic';
import { hasEntityDeletePermission } from '@neptune/shared/core-permissions-business-logic';
import { navigateTo } from 'router/actions';
import { Entity, getEntityTrashed, trashEntities } from '@neptune/shared/entity-domain';
import { useLocalModal } from '@neptune/shared/common-util';

type UseTrashVersionParams = {
  organizationName: string;
  projectName: string;
  modelVersion: Entity;
  modelShortId: string;
};

type UseTrashVersionValue = {
  isOpen: boolean;
  requestTrash?: () => void;
  executeTrash?: () => Promise<void>;
  cancelTrash: () => void;
};

export function useTrashVersion({
  organizationName,
  projectName,
  modelShortId,
  modelVersion,
}: UseTrashVersionParams): UseTrashVersionValue {
  const { close, isOpen, open } = useLocalModal();

  const dispatch = useDispatch();
  const projectIdentifier = useSelector(getCurrentProjectIdentifier);
  const project = useSelector(getCurrentProject);
  const canDelete = hasEntityDeletePermission(project);
  const versionId = modelVersion.id;
  const isTrashed = getEntityTrashed(modelVersion);

  const trashVersion = React.useCallback(async () => {
    await trashEntities({
      entityIds: [versionId],
      projectIdentifier,
    });
    close();
    dispatch(
      navigateTo('project.model-registry', {
        organizationName,
        projectName,
        type: 'model',
        shortId: modelShortId,
      }),
    );
  }, [versionId, projectIdentifier, close, dispatch, organizationName, projectName, modelShortId]);

  const requestTrash = canDelete && !isTrashed ? open : undefined;
  const executeTrash = canDelete && !isTrashed ? trashVersion : undefined;

  return {
    cancelTrash: close,
    executeTrash,
    requestTrash,
    isOpen,
  };
}
