import React from 'react';
import { bemBlock, Layout, Toolbar } from '@neptune/shared/venus-ui';

import './DashboardToolbarSearch.less';

const block = bemBlock('dashboard-toolbar-search');

type DashboardToolbarSearchProps = {
  searchInput?: React.ReactNode;
  limitAttributesInfo?: React.ReactNode;
};

export const DashboardToolbarSearch: React.FC<DashboardToolbarSearchProps> = ({
  searchInput,
  limitAttributesInfo,
}) => {
  return (
    <Layout.Row
      className={block()}
      data-role="dashboard-toolbar-search"
      alignItems="center"
      spacedChildren="sm"
    >
      {limitAttributesInfo && <Toolbar.Item>{limitAttributesInfo}</Toolbar.Item>}
      {searchInput}
    </Layout.Row>
  );
};
