import { Entity } from '@neptune/shared/entity-domain';

export enum EntityActionTypes {
  FullyUpdatedNotification = 'ENTITY_FULLY_UPDATED_NOTIFICATION',
}

export function entityFullyUpdatedNotification(entity: Entity) {
  return {
    type: EntityActionTypes.FullyUpdatedNotification,
    payload: { entity },
  };
}

export type EntityActions = ReturnType<typeof entityFullyUpdatedNotification>;
