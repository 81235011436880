import { LeaderboardViewDTO } from '@neptune/shared/core-apis-leaderboard-domain';

import { TableViewParamsConverter } from '@neptune/shared/leaderboard-domain';

import { LeaderboardView } from './leaderboard-view-model';

export class LeaderboardViewConverter {
  static fromApiToDomain(lbView: LeaderboardViewDTO): LeaderboardView {
    return {
      id: lbView.id,
      name: lbView.name,
      defaultView: lbView.defaultView,
      experimentsOnly: lbView.experimentsOnly,
      suggestionsEnabled: lbView.suggestionsEnabled,
      ...TableViewParamsConverter.fromApiToDomain(lbView),
    };
  }
  static fromDomainToApi(lbView: LeaderboardView): LeaderboardViewDTO {
    const viewParams = TableViewParamsConverter.fromDomainToApi(lbView);

    return {
      id: lbView.id,
      name: lbView.name,
      defaultView: lbView.defaultView,
      suggestionsEnabled: lbView.suggestionsEnabled,
      ...viewParams,
      runsLineage: !!viewParams.runsLineage,
      experimentsOnly: viewParams.experimentsOnly === undefined ? true : viewParams.experimentsOnly,
    };
  }
}
