import React from 'react';
import { Dialog } from '@neptune/shared/venus-ui';

export enum UnsavedChangesReason {
  ExportCsv = 'In order to export the view as .csv, you need to save the changes first.',
  Share = 'In order to share the view, you need to save the changes first.',
}

type UnsavedChangesModalProps = {
  reason?: UnsavedChangesReason;
  isOpen: boolean;
  onClose: () => void;
};

export const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({
  reason,
  isOpen,
  onClose,
}) => {
  return (
    <Dialog
      title="Info"
      role="unsaved-changes-modal"
      isOpen={isOpen}
      onClose={onClose}
      primaryButtonLabel="OK"
      primaryButtonRole="confirm-button"
      onPrimaryButtonClick={onClose}
    >
      <Dialog.Text>There are unsaved changes in the current view. {reason}</Dialog.Text>
    </Dialog>
  );
};
