// Libs
import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from '@neptune/shared/venus-ui';

// App
import { getIsLbViewModified } from 'state/project-leaderboard/lb-views/selectors';

type ShareMenuItemContainerProps = {
  onClick: () => void;
  openShareModal: () => void;
  openUnsavedChangesModal: () => void;
};

export const ShareMenuItemContainer: React.FC<ShareMenuItemContainerProps> = ({
  onClick,
  openShareModal,
  openUnsavedChangesModal,
}) => {
  const isLbViewModified = useSelector(getIsLbViewModified);

  const handleOpen = () => {
    if (isLbViewModified) {
      openUnsavedChangesModal();
      onClick();
      return;
    }

    openShareModal();
    onClick();
  };

  return (
    <Dropdown.Item
      data-item="share-project"
      label="Copy link to project"
      icon="link"
      onClick={handleOpen}
    />
  );
};
