// Libs
import * as Sentry from '@sentry/browser';

// App

// Module
import { InitParams, KnownSignals } from '../types';
import { CustomTracingBus } from '../CustomTracingBus';

const TRANSACTION_NAME = 'onboarding_project_list_loaded';

export function OnboardingProjectsLoadTransaction({ route, isMobile }: InitParams) {
  if (!route) {
    return;
  }

  const mainToken = CustomTracingBus.subscribe(KnownSignals.SURVEY_SUBMIT, (_, { withInvites }) => {
    const transaction = Sentry.startTransaction({ name: TRANSACTION_NAME });
    transaction.setTag('is_mobile', isMobile);
    transaction.setTag('with_invites', withInvites);

    const finishToken = CustomTracingBus.subscribe(
      KnownSignals.PROJECTS_RENDERED,
      (_, { items, renderedItems, isError }) => {
        transaction.setTag('fetched_elements', items);
        transaction.setTag('rendered_elements', renderedItems);
        transaction.setTag('is_error', isError);
        transaction.finish();
        removeListeners();
        CustomTracingBus.unsubscribe(mainToken);
      },
    );

    const invitationsToken = CustomTracingBus.subscribe(KnownSignals.INVITATIONS_RENDERED, () => {
      removeListeners();
    });

    const failToken = CustomTracingBus.subscribe(KnownSignals.SURVEY_SUBMIT_FAILED, () => {
      removeListeners();
    });

    function removeListeners() {
      CustomTracingBus.unsubscribe(finishToken);
      CustomTracingBus.unsubscribe(invitationsToken);
      CustomTracingBus.unsubscribe(failToken);
    }
  });
}
