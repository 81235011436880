// Libs
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// App
import { getGenericModalState, getGenericModalData } from 'state/selectors';
import { hideGenericModal, showGenericModal } from 'state/ui/modals/generic/actions';

// Module
import { ModalHookOutput } from './types';

export function useGenericModal<T = any>(modalName: string, modalData?: T): ModalHookOutput<T> {
  const isOpen = useSelector((state) => getGenericModalState(state, modalName));
  const data: T = useSelector((state) => getGenericModalData(state, modalName));
  const dispatch = useDispatch();

  const open = useCallback(
    () => dispatch(showGenericModal(modalName, modalData)),
    [dispatch, modalName, modalData],
  );
  const openWithData = useCallback(
    (data: T) => dispatch(showGenericModal(modalName, data)),
    [dispatch, modalName],
  );
  const close = useCallback(() => dispatch(hideGenericModal(modalName)), [dispatch, modalName]);

  return {
    isOpen: !!isOpen,
    data,
    open,
    openWithData,
    close,
  };
}
