import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { LeaderboardListPaginationOptions } from '@neptune/shared/leaderboard-domain';
import { getLeaderboardState } from './redux/leaderboards-selectors';
import { changePageSize, paginate } from './redux/pagination-actions';
import { MIN_PAGE_SIZE, PAGE_SIZES } from './redux/pagination-reducer';
import { getPaginationOptions } from './redux/pagination-selectors';

type HookResult = {
  pageSizes: number[];
  pagination: Required<LeaderboardListPaginationOptions>;
  handlePagination: (params: LeaderboardListPaginationOptions) => void;
  handleChangePageSize: (params: number) => void;
  shouldShowPagination: boolean;
};

export const useLeaderboardPagination = (id: string): HookResult => {
  const dispatch = useDispatch();

  const totalGroupCount = useSelector((state) => getLeaderboardState(state, id).totalGroupCount);
  const totalItems = useSelector((state) => getLeaderboardState(state, id).matchingItemCount);

  const limit = useSelector((state) => getPaginationOptions(state, id).limit);
  const offset = useSelector((state) => getPaginationOptions(state, id).offset);

  const total = isNil(totalGroupCount) ? totalItems : totalGroupCount;

  const handlePagination = React.useCallback(
    (params: LeaderboardListPaginationOptions) => dispatch(paginate(id, params)),
    [dispatch, id],
  );
  const handleChangePageSize = React.useCallback(
    (pageSize: number) => dispatch(changePageSize(id, pageSize)),
    [dispatch, id],
  );
  const shouldShowPagination = totalItems > MIN_PAGE_SIZE;

  const pagination = React.useMemo(
    (): Required<LeaderboardListPaginationOptions> => ({
      limit,
      offset,
      total,
    }),
    [limit, offset, total],
  );

  return {
    pageSizes: PAGE_SIZES,
    pagination,
    handlePagination,
    handleChangePageSize,
    shouldShowPagination,
  };
};
