import React from 'react';

import { addProjectServiceAccount } from '@neptune/service-accounts-domain';
import { ProjectRole } from '@neptune/shared/core-project-domain';
import { createErrorDescriptor, ErrorDescriptor } from 'common/error-handler';

export const useAddProjectServiceAccount = (projectIdentifier?: string) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<ErrorDescriptor>();

  const addAccount = React.useCallback(
    async ({ accountId, role }: { accountId: string; role: ProjectRole }) => {
      if (!projectIdentifier) {
        return;
      }

      setIsLoading(true);
      setError(undefined);

      try {
        await addProjectServiceAccount({
          projectIdentifier,
          serviceAccountId: accountId,
          role,
        });
      } catch (e) {
        const descriptor = createErrorDescriptor(e);
        setError(descriptor);
        throw descriptor;
      } finally {
        setIsLoading(false);
      }
    },
    [projectIdentifier],
  );

  return {
    addAccount,
    error,
    isLoading,
  };
};
