// Neptune Core UI core bootstrap
//
// Minimal and sufficient part of NCUI that need to be loaded
// when application based on NCUI is bootstrapped
// //////////////////////////////////////////////////////////////////////////////

import './less/core.less';

// {{{ FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  //   faAngleDoubleLeft,
  //   faAngleDoubleRight,
  //   faAngleDown,
  //   faAngleLeft,
  //   faAngleRight,
  //   faAngleUp,
  faArrowDown,
  faBell,
  faBold,
  faCheckCircle,
  faCaretDown,
  faCaretRight,
  faCaretSquareDown,
  faCaretSquareRight,
  faCaretUp,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCloudUploadAlt,
  faCode,
  faCog,
  faColumns,
  faCompress,
  faCrown,
  faDatabase,
  faEllipsisH,
//   faEllipsisV,
//   faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faFileAlt as faFileAltSolid,
  faFileImport,
  faFileExport,
//   faFolder,
//   faFolderOpen,
  faGlobeAmericas,
  faGripLines,
  faHeading,
  faItalic,
//   faInfo,
  faInfoCircle,
  faListOl,
  faListUl,
  faLevelUpAlt,
//   faLifeRing,
  faLink,
  faLock,
//   faMinus,
  faParagraph,
  faPen,
  faPlus,
  faRandom,
  faRocket,
  faRetweet,
  faSearch,
//   faSearchMinus,
//   faShapes,
  faSignOutAlt,
  faSlidersH,
//   faSortDown,
//   faSortUp,
  faSpinner,
  faSquare,
  faSquareRootAlt,
  faStrikethrough,
//   faStar as fasFaStar,
  faTag,
  faTerminal,
  faTimes,
  faTimesCircle,
//   faTh,
  faThumbtack,
  faToggleOn,
  faToggleOff,
  faUndoAlt,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendarAlt,
  faCaretSquareDown as farCaretSquareDown,
  faCaretSquareRight as farCaretSquareRight,
  faClipboard,
  faClone,
  faCommentAlt,
  faComment,
  faComments,
  faCreditCard,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileCode,
  faListAlt,
  faImage,
  faQuestionCircle,
//   faStar as farFaStar,
//   faStopCircle,
  faPlusSquare,
  faTrashAlt,
  faUser,
  faWindowMinimize,
} from '@fortawesome/free-regular-svg-icons';

import {
  faGithub,
  faKaggle,
  faLinkedin,
  faPython,
} from '@fortawesome/free-brands-svg-icons';

library.add(
//   faAngleDoubleLeft,
//   faAngleDoubleRight,
//   faAngleDown,
//   faAngleLeft,
//   faAngleRight,
//   faAngleUp,
  faArrowDown,
  faBold,
  faBell,
  faCalendarAlt,
  faCheckCircle,
  faCaretDown,
  faCaretRight,
  faCaretSquareDown,
  farCaretSquareDown,
  faCaretSquareRight,
  farCaretSquareRight,
  faCaretUp,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faClipboard,
  faClone,
  faCloudUploadAlt,
  faCode,
  faCog,
  faColumns,
  faCommentAlt,
  faComment,
  faComments,
  faCompress,
  faCreditCard,
  faCrown,
  faDatabase,
  faEllipsisH,
//   faEllipsisV,
//   faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileCode,
  faFileImport,
  faFileExport,
  faFileAltSolid,
//   faFolder,
//   faFolderOpen,
  faItalic,
  faGithub,
  faGlobeAmericas,
  faGripLines,
  faHeading,
  faImage,
//   faInfo,
  faInfoCircle,
  faKaggle,
  faLink,
  faLinkedin,
  faListAlt,
  faListOl,
  faListUl,
  faLevelUpAlt,
//   faLifeRing,
  faLock,
//   faMinus,
  faParagraph,
  faPen,
  faPlus,
  faPlusSquare,
  faPython,
  faQuestionCircle,
  faRandom,
  faRocket,
  faRetweet,
  faSearch,
//   faSearchMinus,
//   faShapes,
  faStrikethrough,
  faSignOutAlt,
  faSquare,
  faSquareRootAlt,
//   farFaStar,
//   fasFaStar,
  faSlidersH,
//   faSortDown,
//   faSortUp,
  faSpinner,
//   faStopCircle,
  faTag,
  faTerminal,
//   faTh,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faToggleOn,
  faToggleOff,
  faTrashAlt,
  faUndoAlt,
  faUser,
  faUsers,
  faWindowMinimize,
);
// FontAwesome }}}
