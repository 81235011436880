export function triggerDownload(url: string, fileName?: string) {
  const workerLink = document.createElement('a');
  workerLink.style.display = 'none';

  workerLink.setAttribute('href', url);

  if (fileName) {
    workerLink.setAttribute('download', fileName);
  }

  document.body.appendChild(workerLink);
  workerLink.click();
  document.body.removeChild(workerLink);
}

export function downloadTextFile(fileName: string, text: string, type = 'text/plain') {
  const url = window.URL.createObjectURL(new Blob([text], { type }));
  triggerDownload(url, fileName);
  window.URL.revokeObjectURL(url);
}
