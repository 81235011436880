import { NameMetaQuery } from '@neptune/object-table-search-domain';
import { isSearchCriterion, SearchQuery } from '@neptune/search-query-domain';
import { KnownAttributes } from 'domain/experiment/attribute';

export const isNameMetaQuery = (metaQuery: SearchQuery): metaQuery is NameMetaQuery => {
  const criteria = metaQuery.criteria?.[0];

  return (
    metaQuery.criteria?.length === 1 &&
    isSearchCriterion(criteria) &&
    criteria?.attribute === KnownAttributes.Name
  );
};
