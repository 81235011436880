import React from 'react';
import { Router, State } from 'router5';
import { useRouter } from 'react-router5';
import { uniqueId } from 'lodash';

type HandlerEntry = {
  onDeactivate(fromState: State, toState: State): Promise<boolean> | boolean;
  refCount: number;
};

const activeHandlers: Record<string, HandlerEntry> = {};

function masterHandlerFactory() {
  return async (fromState: State, toState: State) => {
    for (const { onDeactivate } of Object.values(activeHandlers)) {
      if ((await onDeactivate(fromState, toState)) !== true) {
        return false;
      }
    }

    return true;
  };
}

let routeWithMasterHandlerAttached: string | undefined = undefined;

function attachMasterHandlerToRoute(router: Router, routeName: string) {
  if (routeWithMasterHandlerAttached === routeName) {
    return;
  }

  if (routeWithMasterHandlerAttached !== undefined) {
    router.clearCanDeactivate(routeWithMasterHandlerAttached);
  }

  router.canDeactivate(routeName, masterHandlerFactory);

  routeWithMasterHandlerAttached = routeName;
}

type RouteDeactivateWatchProps = {
  onDeactivate: (fromState: State, toState: State) => Promise<boolean> | boolean;
  handlerId?: string;
};

export const RouteDeactivateWatch: React.FC<RouteDeactivateWatchProps> = ({
  onDeactivate,
  handlerId: userGivenHandlerId,
}) => {
  // We need to use the exact route name here, as it is passed to router.canDeactivate
  // down the line.
  // It would be tempting to use getCurrentRouteName, but it returns normalized route name,
  // without enterprise-context/ in case of enterprise routes.
  const router = useRouter();
  const routeName = router.getState().name;

  const uniqueSymbol = React.useRef(uniqueId()).current;
  const handlerId = userGivenHandlerId ?? uniqueSymbol;

  React.useEffect(() => {
    if (activeHandlers[handlerId]) {
      ++activeHandlers[handlerId].refCount;
    } else {
      activeHandlers[handlerId] = {
        onDeactivate,
        refCount: 1,
      };
    }

    return () => {
      if (--activeHandlers[handlerId].refCount <= 0) {
        delete activeHandlers[handlerId];
      }
    };
  }, [handlerId, onDeactivate]);

  React.useEffect(() => {
    attachMasterHandlerToRoute(router, routeName);

    return () => {
      if (
        routeWithMasterHandlerAttached !== undefined &&
        Object.values(activeHandlers).length === 0
      ) {
        router.clearCanDeactivate(routeWithMasterHandlerAttached);
        routeWithMasterHandlerAttached = undefined;
      }
    };
  }, [router, routeName]);

  return null;
};
