import React from 'react';
import { noop } from 'lodash';

const DEFAULT_VALUE = true;

export type ExperimentsOnlyOptionsContextValue = [boolean, (value: boolean) => void];

export const ExperimentsOnlyOptionsContext =
  React.createContext<ExperimentsOnlyOptionsContextValue>([DEFAULT_VALUE, noop]);

export const useExperimentsOnlyOptions = (): ExperimentsOnlyOptionsContextValue => {
  const [value, setValue] = React.useContext(ExperimentsOnlyOptionsContext);

  return [value, setValue];
};
