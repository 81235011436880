import { startsWithSegment } from 'router5-helpers';

const ENTERPRISE_PREFIX = 'enterprise-context/';

const prefixRegExp = new RegExp(`^${ENTERPRISE_PREFIX}`);

export function isEnterpriseRouteName(routeName = '') {
  return routeName.startsWith(ENTERPRISE_PREFIX);
}

export function toNormalizedRouteName(routeName = '') {
  return routeName.replace(prefixRegExp, '');
}

export function toEnterpriseRouteName(routeName = '') {
  if (isEnterpriseRouteName(routeName)) {
    return routeName;
  }

  return `${ENTERPRISE_PREFIX}${routeName}`;
}

export function convertRouteName(routeName: string, isEnterprise: boolean) {
  return isEnterprise ? toEnterpriseRouteName(routeName) : toNormalizedRouteName(routeName);
}

export function createNormalizedRouteTest(currentRouteName: string) {
  return startsWithSegment(toNormalizedRouteName(currentRouteName));
}
