import { Operation } from '@neptune/shared/core-apis-leaderboard-domain';
import { KnownAttributes } from 'domain/experiment/attribute';
import { StageConverter, Stage } from './entity-stage';

type StringSetChange = {
  valuesToAdd: string[];
  valuesToRemove: string[];
  path: string;
};

/**
 * @deprecated We plan to remove this and implement EntityChanges similar to StringSetChange that accepts value and path
 * */
export type LegacyEntityChanges = {
  name: string;
  description: string;
  stage: Stage;
};

export type EntityChanges = LegacyEntityChanges | StringSetChange;

export const convertEntityChangesToOperations = (changes: Partial<EntityChanges>): Operation[] => {
  if (isStringSetChange(changes)) {
    return convertStringSetChangeToOperation(changes, changes.path);
  }

  if (isLegacyEntityChanges(changes)) {
    return legacyEntityChangesToOperations(changes);
  }

  return [];
};

function legacyEntityChangesToOperations(changes: Partial<LegacyEntityChanges>): Operation[] {
  const operations: Operation[] = [];

  if (changes.name !== undefined) {
    operations.push({ path: KnownAttributes.Name, assignString: { value: changes.name } });
  }

  if (changes.description !== undefined) {
    operations.push({
      path: KnownAttributes.Description,
      assignString: { value: changes.description },
    });
  }

  if (changes.stage !== undefined) {
    operations.push({
      path: KnownAttributes.Stage,
      assignString: { value: StageConverter.fromDomainToApi(changes.stage) },
    });
  }

  return operations;
}

function convertStringSetChangeToOperation(
  changes: Partial<StringSetChange>,
  path: string,
): Operation[] {
  const operations: Operation[] = [];

  if (changes.valuesToRemove) {
    operations.push({
      path,
      removeStrings: { values: changes.valuesToRemove },
    });
  }

  if (changes.valuesToAdd) {
    operations.push({
      path,
      insertStrings: { values: changes.valuesToAdd },
    });
  }

  return operations;
}

function isStringSetChange(changes: Partial<EntityChanges>): changes is StringSetChange {
  return ('valuesToAdd' in changes || 'valuesToRemove' in changes) && 'path' in changes;
}

function isLegacyEntityChanges(changes: Partial<EntityChanges>): changes is LegacyEntityChanges {
  return 'name' in changes || 'description' in changes || 'stage' in changes;
}
