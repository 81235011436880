// Libs
import React from 'react';
import { bemBlock } from '@neptune/shared/venus-ui';

// Module
import './Switcher.less';

export type SwitcherProps = {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (newState: boolean) => void;
  'data-role'?: string;
};

const block = bemBlock('switcher');

export const Switcher: React.FC<SwitcherProps> = React.memo(function Switcher({
  checked,
  disabled,
  onChange,
  'data-role': dataRole,
}) {
  function toggle() {
    if (!disabled && onChange) {
      onChange(!checked);
    }
  }

  return (
    <div
      role="switch"
      data-role={dataRole}
      aria-checked={checked}
      data-checked={checked}
      className={block({
        modifiers: { checked, disabled },
      })}
      onClick={toggle}
    />
  );
});
