export type ColorsContext = {
  key: string;
  /** variable containing colors map in HEX */
  colors: Map<string, string>;
  /** returns color assigned to given key, called without argument returns DEFAULT_COLOR */
  getColor: (key?: string) => string;
  /** returns colors map converted to RGB colors scheme */
  getRGBColors: () => Map<string, string>;
  /** adds color to current namespace */
  setColor: (colorKey: string, color: string) => void;
  /** assigns colors to namespace, update will be ignored when key does not match recently initialized key */
  assignColors: (key: string, colorKeys: string[]) => void;
  /** initialize new colors namespace indexed by key */
  initializeColors: (key: string, colors: Map<string, string> | Record<string, string>) => void;
};

export const DEFAULT_COLOR = '#8D9195';
