import { SubscriptionStatusDTO } from '@neptune/shared/core-apis-backend-domain';

export enum SubscriptionStatus {
  inactive = 'inactive',
  pastDue = 'pastDue',
  ok = 'ok',

  notSupported = 'notSupported',
}

export function subscriptionStatusFromApiToStatus(
  source?: SubscriptionStatusDTO,
): SubscriptionStatus {
  switch (source) {
    case SubscriptionStatusDTO.Inactive:
      return SubscriptionStatus.inactive;

    case SubscriptionStatusDTO.Ok:
      return SubscriptionStatus.ok;

    case SubscriptionStatusDTO.PastDue:
      return SubscriptionStatus.pastDue;

    default:
      return SubscriptionStatus.notSupported;
  }
}
