import { fetchPendingInvitations, Invitation } from '@neptune/invitations-domain';
import { UserManagementLevel } from '@neptune/user-management-common-domain';

import { pick } from 'lodash';
import { createNormalizedRouteTest } from 'router/helpers';
import { State } from 'router5';

import { isInvitationMuted } from './mute-invitation';

export async function prepareInvitationList(
  routeInvitation: Invitation | null,
): Promise<Invitation[]> {
  const result = await fetchPendingInvitations();
  const now = Date.now();

  return result
    .filter(
      (invitation) =>
        !(
          routeInvitation?.level === invitation.level &&
          routeInvitation.invitee === invitation.invitee
        ),
    )
    .filter((invitation) => !isInvitationMuted(invitation, now));
}

export function extractInvitationRouteData(
  route: State | null,
): { type: string; invitationId: string } | undefined {
  const isInvitationTestRoute = createNormalizedRouteTest('accept-invitation');

  if (route && isInvitationTestRoute(route.name)) {
    return pick(route.params, ['type', 'invitationId']);
  }
}

export async function fetchPendingInvitationMatching(
  level: UserManagementLevel,
  invitationTarget: string,
  userEmail?: string,
) {
  const allInvitations = await fetchPendingInvitations();
  const invitation = allInvitations.find(
    (item) =>
      item.level === level &&
      item.invitee === userEmail &&
      (item.level === UserManagementLevel.organization
        ? item.organizationName === invitationTarget
        : item.projectName === invitationTarget),
  );

  if (!invitation) {
    throw 'not-found';
  }

  return invitation;
}
