import React from 'react';
import { capitalize } from 'lodash';
import config from 'config';

import { Dropdown, Layout, SimpleTooltip, Text } from '@neptune/shared/venus-ui';
import { EntityType } from '@neptune/shared/entity-domain';

export type DetailsDropdownMenuContentProps = {
  colorInput?: React.ReactNode;
  entityType: EntityType;
  onShowDetails: () => void;
  onStop?: () => void;
  onRestore?: () => void;
  onAbort?: () => void;
  onManageTags?: () => void;
  onRemove?: () => void;
  onCopy?: () => void;
  collapse: () => void;
};

const ENTITY_TYPE_TO_TEXT_MAP: Record<EntityType, string> = {
  run: 'run',
  project: 'project',
  model: 'model',
  modelVersion: 'model version',
  report: 'report',
};

export const DetailsDropdownMenuContent = ({
  colorInput,
  entityType,
  onShowDetails,
  onStop,
  onRestore,
  onAbort,
  onManageTags,
  onRemove,
  onCopy,
  collapse,
}: DetailsDropdownMenuContentProps) => {
  return (
    <>
      <Dropdown.Menu data-role="details-dropdown-menu">
        {onCopy && (
          <Dropdown.Item
            onClick={onCopy}
            component="label"
            icon="clone"
            data-item="copy-experiment-name"
          >
            <Text>Copy run name</Text>
            <Layout.Fill />
          </Dropdown.Item>
        )}
        <Dropdown.Item component="label" icon="color" data-item={`change-${entityType}-color`}>
          <Text>Change {ENTITY_TYPE_TO_TEXT_MAP[entityType]} color</Text>
          <Layout.Fill />
          {colorInput}
        </Dropdown.Item>
        <Dropdown.Item
          label={`${capitalize(ENTITY_TYPE_TO_TEXT_MAP[entityType])} information`}
          icon="info-circle"
          data-item={`show-${entityType}-details`}
          onClick={onShowDetails}
        />
        {onStop && (
          <SimpleTooltip
            content={
              config.disableRemoteRunActions
                ? "Remote stop isn't available in your installation yet"
                : undefined
            }
          >
            <Dropdown.Item
              label="Remote stop"
              icon="pause-circle"
              data-item="remote-stop"
              disabled={config.disableRemoteRunActions}
              onClick={() => {
                onStop();
                collapse();
              }}
            />
          </SimpleTooltip>
        )}
        {onRestore && (
          <Dropdown.Item
            label="Restore"
            icon="undo"
            data-item="restore"
            onClick={() => {
              onRestore();
              collapse();
            }}
          />
        )}
        {onAbort && (
          <SimpleTooltip
            content={
              config.disableRemoteRunActions
                ? "Remote abort isn't available in your installation yet"
                : undefined
            }
          >
            <Dropdown.Item
              label="Remote abort"
              icon="crossed-circle"
              data-item="remote-abort"
              disabled={config.disableRemoteRunActions}
              onClick={() => {
                onAbort();
                collapse();
              }}
            />
          </SimpleTooltip>
        )}
        {onManageTags && (
          <Dropdown.Item
            label="Manage tags"
            icon="tag"
            data-item="manage-tags"
            onClick={onManageTags}
          />
        )}
        {onRemove && (
          <SimpleTooltip
            content={
              config.withDisabledTrashing
                ? "Trashing isn't available in your installation yet"
                : undefined
            }
          >
            <Dropdown.Item
              label="Move to trash"
              icon="trash"
              data-item="trash"
              disabled={config.withDisabledTrashing}
              onClick={() => {
                onRemove();
                collapse();
              }}
            />
          </SimpleTooltip>
        )}
      </Dropdown.Menu>
    </>
  );
};
