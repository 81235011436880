import React from 'react';
import { neptuneUrls } from '@neptune/shared/common-domain';
import {
  Layout,
  StepperInput,
  Text,
  Modal,
  Link,
  Icon,
  SimpleTooltip,
} from '@neptune/shared/venus-ui';
import { Switcher } from 'components/switcher/Switcher';

interface ChangeProjectStorageQuotaModalProps {
  isOpen: boolean;
  onClose: () => void;
  storageLimit: number | undefined;
  onSave: (storageLimit: number, isEnforcedLimit: boolean) => Promise<void>;
  storageUnit: 'GB';
}

const INITIAL_STORAGE_LIMIT = 100;
const STORAGE_LIMIT_STEP = 10;
const MIN_STORAGE_LIMIT = 0;

export const ChangeProjectStorageQuotaModal: React.FC<ChangeProjectStorageQuotaModalProps> = ({
  onClose,
  isOpen,
  onSave,
  storageUnit: STORAGE_UNIT,
  storageLimit: initialStorageLimit,
}) => {
  const [storageLimit, setStorageLimit] = React.useState<number>(
    initialStorageLimit ?? INITIAL_STORAGE_LIMIT,
  );
  const [isStorageLimitEnforced, setIsStorageLimitEnforced] = React.useState<boolean>(
    initialStorageLimit !== undefined,
  );

  const isValidStorageLimit = storageLimit % STORAGE_LIMIT_STEP === 0;

  const isSaveBtnDisabled =
    (initialStorageLimit
      ? storageLimit === initialStorageLimit && isStorageLimitEnforced
      : storageLimit === INITIAL_STORAGE_LIMIT && !isStorageLimitEnforced) || !isValidStorageLimit;

  const handleReset = () => {
    setStorageLimit(initialStorageLimit ?? INITIAL_STORAGE_LIMIT);
    setIsStorageLimitEnforced(initialStorageLimit !== undefined);
  };

  const handleClose = () => {
    onClose();
    handleReset();
  };

  const handleSave = async () => {
    await onSave(storageLimit, isStorageLimitEnforced);
    onClose();
  };

  const handleChangeStorageLimit = (value?: number | undefined) => {
    if (value === undefined) {
      return;
    }

    setStorageLimit(value);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      data={{ role: 'change-project-storage-limit-modal' }}
    >
      <Modal.Header title="Configure storage limit" onClose={handleClose} />
      <Modal.Body>
        <Layout.Column spacedChildren="md">
          <Layout.Element>
            The limit controls the storage space available for the project. You can use it to make
            sure a project is isolated from the rest of the workspace and does not use up the whole
            workspace storage quota.
            <br /> <br />
            If the limit is reached, you can continue using Neptune in{' '}
            <Link href={neptuneUrls.workspaceOrProjectReadOnlyDoc} external>
              a limited read-only mode&nbsp;
              <Icon glyph="external-link" />
            </Link>
            . To continue logging new data, you need to increase the storage limit or delete some
            data from the project.
          </Layout.Element>
          <Layout.Separator />
          <Layout.Row justifyContent="space-between" alignItems="baseline">
            <Layout.Element>
              <Text fontWeight="semibold">Enforce limit</Text>
            </Layout.Element>
            <Layout.Element>
              <Switcher
                data-role="enforce-limit-switcher"
                checked={isStorageLimitEnforced}
                onChange={setIsStorageLimitEnforced}
              />
            </Layout.Element>
          </Layout.Row>
          <Layout.Separator />
          <Layout.Row justifyContent="space-between" alignItems="baseline">
            <Layout.Element>
              <Text fontWeight="semibold">Storage limit</Text>
            </Layout.Element>
            <Layout.Element span="6">
              <SimpleTooltip
                content={
                  isValidStorageLimit
                    ? undefined
                    : `Selected amount should be the multiple of ${STORAGE_LIMIT_STEP}`
                }
              >
                <StepperInput
                  error={!isValidStorageLimit}
                  data-role="project-storage-limit-stepper"
                  disabled={!isStorageLimitEnforced}
                  min={MIN_STORAGE_LIMIT}
                  value={storageLimit}
                  onChange={handleChangeStorageLimit}
                  step={STORAGE_LIMIT_STEP}
                  suffix={STORAGE_UNIT}
                />
              </SimpleTooltip>
            </Layout.Element>
          </Layout.Row>
        </Layout.Column>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Button data-role="save-button" disabled={isSaveBtnDisabled} onClick={handleSave}>
          Save
        </Modal.Button>
        <Modal.Button variant="basic" onClick={handleClose}>
          Cancel
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
