/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NewOrganizationMemberDTO,
    NewOrganizationMemberDTOFromJSON,
    NewOrganizationMemberDTOFromJSONTyped,
    NewOrganizationMemberDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewUsersDTO
 */
export interface NewUsersDTO {
    /**
     * 
     * @type {string}
     * @memberof NewUsersDTO
     */
    organizationName: string;
    /**
     * 
     * @type {Array<NewOrganizationMemberDTO>}
     * @memberof NewUsersDTO
     */
    members: Array<NewOrganizationMemberDTO>;
}

export function NewUsersDTOFromJSON(json: any): NewUsersDTO {
    return NewUsersDTOFromJSONTyped(json, false);
}

export function NewUsersDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewUsersDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationName': json['organizationName'],
        'members': ((json['members'] as Array<any>).map(NewOrganizationMemberDTOFromJSON)),
    };
}

export function NewUsersDTOToJSON(value?: NewUsersDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationName': value.organizationName,
        'members': ((value.members as Array<any>).map(NewOrganizationMemberDTOToJSON)),
    };
}


