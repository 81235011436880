export enum LastNotificationTimestampActionTypes {
  LeaderboardsChanged = 'LEADERBOARDS_CHANGED_NOTIFICATION',
}

export function notifyLeaderboardsChanged(leaderboardIds: string[]) {
  return {
    type: LastNotificationTimestampActionTypes.LeaderboardsChanged,
    payload: {
      leaderboardIds,
      timestamp: Date.now(),
    },
  };
}

export type LastNotificationTimestampActions = ReturnType<typeof notifyLeaderboardsChanged>;
