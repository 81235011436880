// Libs
import React from 'react';

export class WindowUnloadPrompt extends React.Component {
  constructor(...args) {
    super(...args);
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload(event) {
    // The way the string is returned varies between browsers.
    event.returnValue = 'Sure ?';
    return 'Really sure ?';
    /*
     * But browsers do not show the string anyway...
     * https://bugs.chromium.org/p/chromium/issues/detail?id=587940
     */
  }

  render() {
    return null;
  }
}

export default WindowUnloadPrompt;

