import { find, findIndex, uniqWith } from 'lodash';

import { OrderedColumnsAction, OrderedColumnsActionTypes } from '@neptune/shared/columns-domain';
import {
  columnIdentityComparator,
  createColumnByColumnIdentifier,
  LeaderboardColumn,
} from '@neptune/shared/leaderboard-domain';

import { getColumnsSet, getInitialState, setColumnsToColumnsSet } from '../utils';

const initialState = getInitialState();

export function orderedColumnsReducer(state = initialState, action: OrderedColumnsAction) {
  switch (action.type) {
    case OrderedColumnsActionTypes.Set: {
      const { columnsRootKey, columnsSetId, columns } = action.payload;

      return setColumnsToColumnsSet(state, columnsSetId, columnsRootKey, columns);
    }

    case OrderedColumnsActionTypes.Update: {
      const { columnsSetId, columnsRootKey, column, change } = action.payload;

      const newColumns = getColumnsSet(state, columnsSetId, columnsRootKey).map((stateColumn) => {
        if (columnIdentityComparator(stateColumn, column)) {
          return { ...stateColumn, ...change };
        }

        return stateColumn;
      });

      return setColumnsToColumnsSet(state, columnsSetId, columnsRootKey, newColumns);
    }

    case OrderedColumnsActionTypes.Move: {
      const { columnsSetId, columnsRootKey, source, target, order } = action.payload;

      const columns = getColumnsSet(state, columnsSetId, columnsRootKey);
      const targetColumnIndex = findIndex(columns, target);

      if (targetColumnIndex < 0) {
        return state;
      }

      const targetColumn = columns[targetColumnIndex];
      const sourceColumn = find(columns, source) as LeaderboardColumn;

      const leftSide = columns
        .slice(0, targetColumnIndex)
        .filter((column) => column !== sourceColumn);
      const rightSide = columns
        .slice(targetColumnIndex + 1)
        .filter((column) => column !== sourceColumn);
      const newSourceColumn = {
        ...sourceColumn,
        pinned: targetColumn.pinned,
      };
      const reorderedPart =
        order === 'after' ? [targetColumn, newSourceColumn] : [newSourceColumn, targetColumn];

      const newColumns = [...leftSide, ...reorderedPart, ...rightSide];

      return setColumnsToColumnsSet(state, columnsSetId, columnsRootKey, newColumns);
    }

    case OrderedColumnsActionTypes.Show: {
      const { columnsSetId, columnsRootKey, column } = action.payload;

      const columnObject = createColumnByColumnIdentifier(column);
      const oldColumns = getColumnsSet(state, columnsSetId, columnsRootKey);

      return setColumnsToColumnsSet(
        state,
        columnsSetId,
        columnsRootKey,
        uniqWith([...oldColumns, columnObject], columnIdentityComparator),
      );
    }

    case OrderedColumnsActionTypes.ShowPinned: {
      const { columnsSetId, columnsRootKey, column, pinned } = action.payload;

      const columnObject = createColumnByColumnIdentifier(column, pinned);
      const oldColumns = getColumnsSet(state, columnsSetId, columnsRootKey);

      if (oldColumns.some((oldColumn) => columnIdentityComparator(oldColumn, columnObject))) {
        const newColumns = oldColumns.map((oldColumn) => {
          if (columnIdentityComparator(oldColumn, columnObject)) {
            return { ...oldColumn, ...columnObject };
          }

          return oldColumn;
        });

        return setColumnsToColumnsSet(state, columnsSetId, columnsRootKey, newColumns);
      }

      return setColumnsToColumnsSet(
        state,
        columnsSetId,
        columnsRootKey,
        uniqWith([...oldColumns, columnObject], columnIdentityComparator),
      );
    }

    case OrderedColumnsActionTypes.Hide: {
      const { columnsSetId, columnsRootKey, column } = action.payload;

      const oldColumns = getColumnsSet(state, columnsSetId, columnsRootKey);
      const newColumns = oldColumns.filter(
        (oldColumn) => !columnIdentityComparator(oldColumn, column),
      );

      return setColumnsToColumnsSet(state, columnsSetId, columnsRootKey, newColumns);
    }

    case OrderedColumnsActionTypes.SetDefaultColumns: {
      const { columnsRootKey, defaultColumnsIds } = action.payload;

      return {
        ...state,
        [columnsRootKey]: {
          ...state[columnsRootKey],
          defaultColumnsIds,
        },
      };
    }
  }

  return state;
}
