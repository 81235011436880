// App
import { FloatCriterion, PartialSearchCriterion } from '../search-query-model';
import { criterionHasAttributeAndOperator } from '../search-query-model-core-utils';

// Module
import { CriterionHelpers } from './types';

export function isFloatCriterion(criterion: PartialSearchCriterion): criterion is FloatCriterion {
  return criterionHasAttributeAndOperator(criterion) && criterion.type === 'float';
}

export const floatCriterionHelpers: CriterionHelpers<FloatCriterion> = {
  getReadableValue(criterion) {
    return String(criterion.value);
  },
  getShortReadableValue(criterion) {
    return String(criterion.value);
  },
  isComplete(criterion): boolean {
    return criterion.value != null;
  },
};
