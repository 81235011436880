import { OrganizationRole } from '@neptune/shared/core-organizations-domain';
import { ProjectRole } from '@neptune/shared/core-project-domain';
import { UserManagementLevel } from '@neptune/user-management-common-domain';

export enum InvitationStatus {
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
  revoked = 'revoked',

  notSupported = 'notSupported',
}

export enum InvitationType {
  email = 'email',
  user = 'user',

  notSupported = 'notSupported',
}

export enum InvitationOrganizationRole {
  owner = 'owner',
  member = 'member',

  notSupported = 'notSupported',
}

export enum InvitationProjectRole {
  manager = 'manager',
  member = 'member',
  viewer = 'viewer',

  notSupported = 'notSupported',
}

export type BaseInvitation = {
  invitedBy: string;
  organizationName: string;
  id?: string;
  invitee: string;
  status: InvitationStatus;
  createdAt: Date;
  type: InvitationType;
};

export type ProjectInvitation = BaseInvitation & {
  level: UserManagementLevel.project;
  projectName: string;
  roleGrant: ProjectRole;
};
export type NewOrganizationInvitationProjectEntry = {
  id: string;
  role: ProjectRole;
};
export type OrganizationInvitationProjectEntry = {
  id: string;
  role: ProjectRole;
  name: string;
};
export type OrganizationInvitation = BaseInvitation & {
  level: UserManagementLevel.organization;
  roleGrant: OrganizationRole;
  lastSentAt: Date;
  projects?: NewOrganizationInvitationProjectEntry[];
};

export type Invitation = ProjectInvitation | OrganizationInvitation;
