import React from 'react';
import { Text } from '@neptune/shared/venus-ui';
import { differenceInMinutes, formatDistance } from 'date-fns';

type AutoSavedTimeProps = {
  autoSaving: boolean;
  lastUpdatedTime?: Date;
  prefix?: string;
};

export const SaveTime: React.FC<AutoSavedTimeProps> = ({
  autoSaving,
  lastUpdatedTime,
  prefix = 'Autosaved',
}) => {
  const [autoSavedTime, setAutoSavedTime] = React.useState<string | undefined>();

  React.useEffect(() => {
    function updateAutoSavedTime() {
      if (lastUpdatedTime) {
        const timeAgo =
          differenceInMinutes(lastUpdatedTime, new Date()) === 0
            ? 'just now...'
            : formatDistance(lastUpdatedTime, new Date(), { addSuffix: true });

        setAutoSavedTime(timeAgo);
      }
    }

    updateAutoSavedTime();

    const intervalId = setInterval(updateAutoSavedTime, 60000);

    return () => clearInterval(intervalId);
  }, [lastUpdatedTime]);

  if (autoSaving) {
    return (
      <Text size="xs" color="text-subdued" data-role="autosaving-label" italic>
        Autosaving...
      </Text>
    );
  }

  if (autoSavedTime) {
    return (
      <Text size="xs" color="text-subdued" data-role="autosaved-label" italic>
        {prefix} {autoSavedTime}
      </Text>
    );
  }

  return null;
};
