import { TextWrapping } from '@neptune/shared/common-domain';
import { AppState } from 'state/types';
import { TextWrappingState } from '../storage-utils';

export const getTextWrappingState = (state: any): TextWrappingState => {
  return state.ui.textWrapping;
};

export const getTextWrapping = (
  state: AppState,
  id: string,
  projectIdentifier: string,
): TextWrapping => {
  return getTextWrappingState(state)?.[projectIdentifier]?.[id] || TextWrapping.Clip;
};
