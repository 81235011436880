// Libs
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// App
import { fetchUpcomingInvoiceAction } from '../redux/upcoming-invoice-actions';
import { getUpcomingInvoiceState } from '../redux/upcoming-invoice-selectors';

// Module
export const useUpcomingInvoiceData = (organizationIdentifier?: string) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!organizationIdentifier) {
      return;
    }

    dispatch(fetchUpcomingInvoiceAction(organizationIdentifier));
  }, [dispatch, organizationIdentifier]);

  return useSelector(getUpcomingInvoiceState);
};
