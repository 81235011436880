import { WorkspaceStatus } from '@neptune/pricing-workspace-status-domain';

function monitoringTrigger(status: WorkspaceStatus, monitoringHoursLeft: number) {
  return (
    status.monitoringMinutesAvailable != null &&
    status.monitoringMinutesAvailable <= monitoringHoursLeft * 60
  );
}

export const monitoringAdminTrigger = (status: WorkspaceStatus): boolean =>
  monitoringTrigger(status, 50);
export const monitoringMemberTrigger = (status: WorkspaceStatus): boolean =>
  monitoringTrigger(status, 20);
export const monitoringReadOnlyTrigger = (status: WorkspaceStatus): boolean =>
  monitoringTrigger(status, 0);
