// Generated from NQLLexer.g4 by ANTLR 4.8
// jshint ignore: start
import antlr4 from 'antlr4/index';



var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0002\u0017\u009e\b\u0001\b\u0001\b\u0001\u0004\u0002\t\u0002\u0004",
    "\u0003\t\u0003\u0004\u0004\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t",
    "\u0006\u0004\u0007\t\u0007\u0004\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004",
    "\u000b\t\u000b\u0004\f\t\f\u0004\r\t\r\u0004\u000e\t\u000e\u0004\u000f",
    "\t\u000f\u0004\u0010\t\u0010\u0004\u0011\t\u0011\u0004\u0012\t\u0012",
    "\u0004\u0013\t\u0013\u0004\u0014\t\u0014\u0004\u0015\t\u0015\u0004\u0016",
    "\t\u0016\u0004\u0017\t\u0017\u0004\u0018\t\u0018\u0003\u0002\u0003\u0002",
    "\u0003\u0002\u0003\u0002\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0004",
    "\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0005\u0003\u0005\u0003\u0005",
    "\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005",
    "\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006",
    "\u0003\u0006\u0003\u0006\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\b\u0003\b\u0003\t\u0003\t",
    "\u0003\t\u0003\n\u0003\n\u0003\u000b\u0003\u000b\u0003\u000b\u0003\f",
    "\u0003\f\u0003\f\u0005\fd\n\f\u0003\r\u0003\r\u0003\r\u0003\u000e\u0003",
    "\u000e\u0003\u000f\u0003\u000f\u0003\u0010\u0003\u0010\u0003\u0011\u0003",
    "\u0011\u0003\u0011\u0003\u0011\u0003\u0011\u0003\u0012\u0003\u0012\u0003",
    "\u0012\u0003\u0012\u0003\u0012\u0003\u0013\u0006\u0013z\n\u0013\r\u0013",
    "\u000e\u0013{\u0003\u0014\u0006\u0014\u007f\n\u0014\r\u0014\u000e\u0014",
    "\u0080\u0003\u0014\u0003\u0014\u0003\u0015\u0003\u0015\u0003\u0015\u0003",
    "\u0015\u0003\u0016\u0003\u0016\u0003\u0016\u0003\u0016\u0003\u0016\u0005",
    "\u0016\u008e\n\u0016\u0003\u0016\u0003\u0016\u0003\u0017\u0003\u0017",
    "\u0003\u0017\u0003\u0017\u0003\u0018\u0003\u0018\u0003\u0018\u0003\u0018",
    "\u0003\u0018\u0005\u0018\u009b\n\u0018\u0003\u0018\u0003\u0018\u0002",
    "\u0002\u0019\u0005\u0003\u0007\u0004\t\u0005\u000b\u0006\r\u0007\u000f",
    "\b\u0011\t\u0013\n\u0015\u000b\u0017\f\u0019\r\u001b\u000e\u001d\u000f",
    "\u001f\u0010!\u0011#\u0016%\u0017\'\u0012)\u0013+\u0014-\u0002/\u0015",
    "1\u0002\u0005\u0002\u0003\u0004\u0013\u0004\u0002CCcc\u0004\u0002PP",
    "pp\u0004\u0002FFff\u0004\u0002QQqq\u0004\u0002TTtt\u0004\u0002VVvv\u0004",
    "\u0002EEee\u0004\u0002KKkk\u0004\u0002UUuu\u0004\u0002OOoo\u0004\u0002",
    "JJjj\u0004\u0002GGgg\u0004\u0002ZZzz\u0006\u0002/;C\\aac|\u0005\u0002",
    "\u000b\f\u000e\u000f\"\"\u0004\u0002$$^^\u0004\u0002^^bb\u0002\u00a2",
    "\u0002\u0005\u0003\u0002\u0002\u0002\u0002\u0007\u0003\u0002\u0002\u0002",
    "\u0002\t\u0003\u0002\u0002\u0002\u0002\u000b\u0003\u0002\u0002\u0002",
    "\u0002\r\u0003\u0002\u0002\u0002\u0002\u000f\u0003\u0002\u0002\u0002",
    "\u0002\u0011\u0003\u0002\u0002\u0002\u0002\u0013\u0003\u0002\u0002\u0002",
    "\u0002\u0015\u0003\u0002\u0002\u0002\u0002\u0017\u0003\u0002\u0002\u0002",
    "\u0002\u0019\u0003\u0002\u0002\u0002\u0002\u001b\u0003\u0002\u0002\u0002",
    "\u0002\u001d\u0003\u0002\u0002\u0002\u0002\u001f\u0003\u0002\u0002\u0002",
    "\u0002!\u0003\u0002\u0002\u0002\u0002#\u0003\u0002\u0002\u0002\u0002",
    "%\u0003\u0002\u0002\u0002\u0002\'\u0003\u0002\u0002\u0002\u0002)\u0003",
    "\u0002\u0002\u0002\u0003+\u0003\u0002\u0002\u0002\u0003-\u0003\u0002",
    "\u0002\u0002\u0004/\u0003\u0002\u0002\u0002\u00041\u0003\u0002\u0002",
    "\u0002\u00053\u0003\u0002\u0002\u0002\u00077\u0003\u0002\u0002\u0002",
    "\t:\u0003\u0002\u0002\u0002\u000b>\u0003\u0002\u0002\u0002\rG\u0003",
    "\u0002\u0002\u0002\u000fO\u0003\u0002\u0002\u0002\u0011V\u0003\u0002",
    "\u0002\u0002\u0013X\u0003\u0002\u0002\u0002\u0015[\u0003\u0002\u0002",
    "\u0002\u0017]\u0003\u0002\u0002\u0002\u0019c\u0003\u0002\u0002\u0002",
    "\u001be\u0003\u0002\u0002\u0002\u001dh\u0003\u0002\u0002\u0002\u001f",
    "j\u0003\u0002\u0002\u0002!l\u0003\u0002\u0002\u0002#n\u0003\u0002\u0002",
    "\u0002%s\u0003\u0002\u0002\u0002\'y\u0003\u0002\u0002\u0002)~\u0003",
    "\u0002\u0002\u0002+\u0084\u0003\u0002\u0002\u0002-\u008d\u0003\u0002",
    "\u0002\u0002/\u0091\u0003\u0002\u0002\u00021\u009a\u0003\u0002\u0002",
    "\u000234\t\u0002\u0002\u000245\t\u0003\u0002\u000256\t\u0004\u0002\u0002",
    "6\u0006\u0003\u0002\u0002\u000278\t\u0005\u0002\u000289\t\u0006\u0002",
    "\u00029\b\u0003\u0002\u0002\u0002:;\t\u0003\u0002\u0002;<\t\u0005\u0002",
    "\u0002<=\t\u0007\u0002\u0002=\n\u0003\u0002\u0002\u0002>?\t\b\u0002",
    "\u0002?@\t\u0005\u0002\u0002@A\t\u0003\u0002\u0002AB\t\u0007\u0002\u0002",
    "BC\t\u0002\u0002\u0002CD\t\t\u0002\u0002DE\t\u0003\u0002\u0002EF\t\n",
    "\u0002\u0002F\f\u0003\u0002\u0002\u0002GH\t\u000b\u0002\u0002HI\t\u0002",
    "\u0002\u0002IJ\t\u0007\u0002\u0002JK\t\b\u0002\u0002KL\t\f\u0002\u0002",
    "LM\t\r\u0002\u0002MN\t\n\u0002\u0002N\u000e\u0003\u0002\u0002\u0002",
    "OP\t\r\u0002\u0002PQ\t\u000e\u0002\u0002QR\t\t\u0002\u0002RS\t\n\u0002",
    "\u0002ST\t\u0007\u0002\u0002TU\t\n\u0002\u0002U\u0010\u0003\u0002\u0002",
    "\u0002VW\u0007@\u0002\u0002W\u0012\u0003\u0002\u0002\u0002XY\u0007@",
    "\u0002\u0002YZ\u0007?\u0002\u0002Z\u0014\u0003\u0002\u0002\u0002[\\",
    "\u0007>\u0002\u0002\\\u0016\u0003\u0002\u0002\u0002]^\u0007>\u0002\u0002",
    "^_\u0007?\u0002\u0002_\u0018\u0003\u0002\u0002\u0002`d\u0007?\u0002",
    "\u0002ab\u0007?\u0002\u0002bd\u0007?\u0002\u0002c`\u0003\u0002\u0002",
    "\u0002ca\u0003\u0002\u0002\u0002d\u001a\u0003\u0002\u0002\u0002ef\u0007",
    "#\u0002\u0002fg\u0007?\u0002\u0002g\u001c\u0003\u0002\u0002\u0002hi",
    "\u0007<\u0002\u0002i\u001e\u0003\u0002\u0002\u0002jk\u0007*\u0002\u0002",
    "k \u0003\u0002\u0002\u0002lm\u0007+\u0002\u0002m\"\u0003\u0002\u0002",
    "\u0002no\u0007$\u0002\u0002op\u0003\u0002\u0002\u0002pq\b\u0011\u0002",
    "\u0002qr\b\u0011\u0003\u0002r$\u0003\u0002\u0002\u0002st\u0007b\u0002",
    "\u0002tu\u0003\u0002\u0002\u0002uv\b\u0012\u0002\u0002vw\b\u0012\u0004",
    "\u0002w&\u0003\u0002\u0002\u0002xz\t\u000f\u0002\u0002yx\u0003\u0002",
    "\u0002\u0002z{\u0003\u0002\u0002\u0002{y\u0003\u0002\u0002\u0002{|\u0003",
    "\u0002\u0002\u0002|(\u0003\u0002\u0002\u0002}\u007f\t\u0010\u0002\u0002",
    "~}\u0003\u0002\u0002\u0002\u007f\u0080\u0003\u0002\u0002\u0002\u0080",
    "~\u0003\u0002\u0002\u0002\u0080\u0081\u0003\u0002\u0002\u0002\u0081",
    "\u0082\u0003\u0002\u0002\u0002\u0082\u0083\b\u0014\u0005\u0002\u0083",
    "*\u0003\u0002\u0002\u0002\u0084\u0085\u0007$\u0002\u0002\u0085\u0086",
    "\u0003\u0002\u0002\u0002\u0086\u0087\b\u0015\u0006\u0002\u0087,\u0003",
    "\u0002\u0002\u0002\u0088\u008e\n\u0011\u0002\u0002\u0089\u008a\u0007",
    "^\u0002\u0002\u008a\u008e\u0007^\u0002\u0002\u008b\u008c\u0007^\u0002",
    "\u0002\u008c\u008e\u0007$\u0002\u0002\u008d\u0088\u0003\u0002\u0002",
    "\u0002\u008d\u0089\u0003\u0002\u0002\u0002\u008d\u008b\u0003\u0002\u0002",
    "\u0002\u008e\u008f\u0003\u0002\u0002\u0002\u008f\u0090\b\u0016\u0002",
    "\u0002\u0090.\u0003\u0002\u0002\u0002\u0091\u0092\u0007b\u0002\u0002",
    "\u0092\u0093\u0003\u0002\u0002\u0002\u0093\u0094\b\u0017\u0006\u0002",
    "\u00940\u0003\u0002\u0002\u0002\u0095\u009b\n\u0012\u0002\u0002\u0096",
    "\u0097\u0007^\u0002\u0002\u0097\u009b\u0007^\u0002\u0002\u0098\u0099",
    "\u0007^\u0002\u0002\u0099\u009b\u0007b\u0002\u0002\u009a\u0095\u0003",
    "\u0002\u0002\u0002\u009a\u0096\u0003\u0002\u0002\u0002\u009a\u0098\u0003",
    "\u0002\u0002\u0002\u009b\u009c\u0003\u0002\u0002\u0002\u009c\u009d\b",
    "\u0018\u0002\u0002\u009d2\u0003\u0002\u0002\u0002\n\u0002\u0003\u0004",
    "c{\u0080\u008d\u009a\u0007\u0005\u0002\u0002\u0007\u0003\u0002\u0007",
    "\u0004\u0002\b\u0002\u0002\u0006\u0002\u0002"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

function NQLLexer(input) {
	antlr4.Lexer.call(this, input);
    this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    return this;
}

NQLLexer.prototype = Object.create(antlr4.Lexer.prototype);
NQLLexer.prototype.constructor = NQLLexer;

Object.defineProperty(NQLLexer.prototype, "atn", {
        get : function() {
                return atn;
        }
});

NQLLexer.EOF = antlr4.Token.EOF;
NQLLexer.AND = 1;
NQLLexer.OR = 2;
NQLLexer.NOT = 3;
NQLLexer.CONTAINS = 4;
NQLLexer.MATCHES = 5;
NQLLexer.EXISTS = 6;
NQLLexer.GT = 7;
NQLLexer.GE = 8;
NQLLexer.LT = 9;
NQLLexer.LE = 10;
NQLLexer.EQ = 11;
NQLLexer.NE = 12;
NQLLexer.COLON = 13;
NQLLexer.LPAREN = 14;
NQLLexer.RPAREN = 15;
NQLLexer.SIMPLE_STRING = 16;
NQLLexer.WS = 17;
NQLLexer.DOUBLE_QUOTED_STRING = 18;
NQLLexer.BACK_QUOTED_STRING = 19;
NQLLexer.BEGIN_DOUBLE_QUOTE = 20;
NQLLexer.BEGIN_BACK_QUOTE = 21;

NQLLexer.DOUBLE_QUOTED_STRING_MODE = 1;
NQLLexer.BACK_QUOTED_STRING_MODE = 2;

NQLLexer.prototype.channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];

NQLLexer.prototype.modeNames = [ "DEFAULT_MODE", "DOUBLE_QUOTED_STRING_MODE", 
                                 "BACK_QUOTED_STRING_MODE" ];

NQLLexer.prototype.literalNames = [ null, null, null, null, null, null, 
                                    null, "'>'", "'>='", "'<'", "'<='", 
                                    null, "'!='", "':'", "'('", "')'" ];

NQLLexer.prototype.symbolicNames = [ null, "AND", "OR", "NOT", "CONTAINS", 
                                     "MATCHES", "EXISTS", "GT", "GE", "LT", 
                                     "LE", "EQ", "NE", "COLON", "LPAREN", 
                                     "RPAREN", "SIMPLE_STRING", "WS", "DOUBLE_QUOTED_STRING", 
                                     "BACK_QUOTED_STRING", "BEGIN_DOUBLE_QUOTE", 
                                     "BEGIN_BACK_QUOTE" ];

NQLLexer.prototype.ruleNames = [ "AND", "OR", "NOT", "CONTAINS", "MATCHES", 
                                 "EXISTS", "GT", "GE", "LT", "LE", "EQ", 
                                 "NE", "COLON", "LPAREN", "RPAREN", "BEGIN_DOUBLE_QUOTE", 
                                 "BEGIN_BACK_QUOTE", "SIMPLE_STRING", "WS", 
                                 "DOUBLE_QUOTED_STRING", "DOUBLE_QUOTED_CHAR", 
                                 "BACK_QUOTED_STRING", "BACK_QUOTED_CHAR" ];

NQLLexer.prototype.grammarFileName = "NQLLexer.g4";


export { NQLLexer };

