import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { WithPaddingRenderer } from 'neptune-core-ui/components/table/renderers';
import { BaseMember } from '@neptune/user-management-domain';

export const FullNameRenderer: React.FC<{
  data: BaseMember;
}> = ({ data }) => {
  const { firstName, lastName } = data.registeredMemberInfo || {};

  return (
    <WithPaddingRenderer>
      <span>{`${firstName || ''} ${lastName || ''}`}</span>
    </WithPaddingRenderer>
  );
};
