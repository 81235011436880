/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LoginActionDTO {
    SETUSERNAME = 'SET_USERNAME',
    SURVEY = 'SURVEY'
}

export function LoginActionDTOFromJSON(json: any): LoginActionDTO {
    return LoginActionDTOFromJSONTyped(json, false);
}

export function LoginActionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginActionDTO {
    return json as LoginActionDTO;
}

export function LoginActionDTOToJSON(value?: LoginActionDTO | null): any {
    return value as any;
}

