import React from 'react';
import { DotsHButton } from '@neptune/shared/common-ui';
import { ButtonVariant, Dropdown } from '@neptune/shared/venus-ui';

type VersionActionsDropdownProps = {
  versionId?: string;
  className?: string;
  onShareLink?: (id: string) => void;
  onRestore?: (id: string) => void;
  onEditName?: (id: string) => void;
  onEditDescription?: (id: string) => void;
  variant?: ButtonVariant;
};

export const VersionActionsDropdown: React.FC<VersionActionsDropdownProps> = ({
  versionId,
  className,
  onShareLink,
  onRestore,
  onEditName,
  onEditDescription,
  variant,
}) => {
  if (!versionId) {
    return null;
  }

  return (
    <Dropdown
      toggle={
        <DotsHButton
          className={className}
          variant={variant}
          size="md"
          data-role="version-actions-dropdown-toggle"
        />
      }
    >
      {() => (
        <Dropdown.Menu>
          {onShareLink && (
            <Dropdown.Item
              onClick={() => onShareLink(versionId)}
              label="Share link"
              data-role="version-share-link"
            />
          )}
          {onRestore && (
            <Dropdown.Item
              onClick={() => onRestore(versionId)}
              label="Restore"
              data-role="version-restore"
            />
          )}
          {onEditName && (
            <Dropdown.Item
              onClick={() => onEditName(versionId)}
              label="Edit version name"
              data-role="version-edit-name"
            />
          )}
          {onEditDescription && (
            <Dropdown.Item
              onClick={() => onEditDescription(versionId)}
              label="Edit description"
              data-role="version-edit-description"
            />
          )}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};
