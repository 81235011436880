import { WorkspaceModeDTO } from '@neptune/shared/core-apis-backend-domain';

export enum WorkspaceMode {
  normal = 'normal',
  readOnly = 'read-only',

  notSupported = 'notSupported',
}

export function workspaceModeFromApiToDomain(source?: WorkspaceModeDTO): WorkspaceMode {
  switch (source) {
    case WorkspaceModeDTO.Normal:
      return WorkspaceMode.normal;

    case WorkspaceModeDTO.ReadOnly:
      return WorkspaceMode.readOnly;

    default:
      return WorkspaceMode.notSupported;
  }
}
