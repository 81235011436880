import React from 'react';
import { DropdownProps, Dropdown, DropdownChildrenRenderer } from '@neptune/shared/venus-ui';

type VersionsDropdownProps = {
  toggle: DropdownProps['toggle'];
  createContent: DropdownChildrenRenderer;
  onLoad?: () => void;
};

const DROPDOWN_ANIMATION: React.ComponentProps<typeof Dropdown>['menuAnimation'] = {
  direction: 'top-bottom',
};

const DROPDOWN_CONSTRAINTS: React.ComponentProps<typeof Dropdown>['constraints'] = [
  { to: 'window', attachment: 'together', pin: true },
];

export const VersionsDropdown: React.FC<VersionsDropdownProps> = ({
  onLoad,
  toggle,
  createContent,
}) => {
  return (
    <Dropdown
      attachment="top right"
      targetAttachment="bottom right"
      toggle={toggle}
      onExpand={onLoad}
      constraints={DROPDOWN_CONSTRAINTS}
      menuAnimation={DROPDOWN_ANIMATION}
    >
      {createContent}
    </Dropdown>
  );
};
