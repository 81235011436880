// Libs
import { Merge } from 'common/utility-types';
import { union } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Params } from 'router5/types/types/base';

// App
import {
  getContextOrganization,
  getCurrentRouteName,
  getCurrentRouteParams,
} from 'state/selectors-global';
import { NormalizedLinkContext } from '@neptune/shared/routing-domain';
import { NormalizedLink, NormalizedLinkProps } from '@neptune/shared/routing-ui';
import { AppState } from 'state/types';
import { getCurrentUsername } from 'state/users/current-user/selectors';

export type UserProfileLinkProps = Merge<
  Omit<NormalizedLinkProps, 'routeName' | 'routeParams'>,
  {
    organizationName?: string;
    userName?: string;
    editMode?: boolean;
    disabled?: boolean;
    title?: string;
  }
>;

const getContextOrganizationName = (state: AppState) => getContextOrganization(state)?.name;

export const UserProfileLink: React.FC<UserProfileLinkProps> = ({
  userName,
  editMode,
  disabled,
  ...props
}) => {
  const currentUsername = useSelector(getCurrentUsername);
  const currentRouteName = useSelector(getCurrentRouteName);
  const currentRouteParams = useSelector(getCurrentRouteParams);
  const organizationName =
    useSelector(getContextOrganizationName) || currentRouteParams.organizationName;
  const normalizedLinkContext = React.useContext(NormalizedLinkContext);

  if (!organizationName && normalizedLinkContext.inEnterpriseContext) {
    return <span>{props.children}</span>;
  }

  const name = 'global-views/user-profile';
  const params = {
    // using NormalizedLinkContext because isEnterpriseContext selector used here sometimes returns stalled value (we will be resolving this problem separately)
    ...(normalizedLinkContext.inEnterpriseContext ? { organizationName } : {}),
    userName: userName ?? currentUsername,
    edit: editMode ? true : undefined,
  };
  const forceDisabled = currentRouteName === name && areParamsEqual(params, currentRouteParams);
  return (
    <NormalizedLink
      {...props}
      routeName={name}
      routeParams={params}
      disabled={disabled || forceDisabled}
    />
  );
};

function areParamsEqual(params1: Params, params2: Params) {
  const compareValues = (v1: unknown, v2: unknown) => {
    return (v1 == null && v2 == null) || v1 === v2;
  };

  return union(Object.keys(params1), Object.keys(params2)).every((key) =>
    compareValues(params1[key], params2[key]),
  );
}
