import React from 'react';
import { Dialog, Text } from '@neptune/shared/venus-ui';
import type { EntityType } from '@neptune/shared/entity-domain';

type TrashEntityProps = {
  isOpen: boolean;
  cancel(): void;
  execute(): Promise<void>;
  shortId: string;
  type: EntityType;
};

const EntityTypeToText: Record<EntityType, string> = {
  modelVersion: 'version',
  model: 'model',
  run: 'run',
  project: 'project',
  report: 'report',
};

export const TrashEntityModal: React.FC<TrashEntityProps> = ({
  isOpen,
  cancel,
  execute,
  shortId,
  type,
  children,
}) => {
  const defaultText = (
    <>
      You are about to trash {`${EntityTypeToText[type]} `}
      <Text fontWeight="semibold" children={shortId} />.
    </>
  );
  return (
    <Dialog
      isOpen={isOpen}
      title={`Trash ${EntityTypeToText[type]}`}
      onClose={cancel}
      primaryButtonRole="entity-confirm-trash"
      primaryButtonLabel="Trash"
      onPrimaryButtonClick={execute}
      onSecondaryButtonClick={cancel}
    >
      {children ? children : defaultText}
    </Dialog>
  );
};
