import urlParse from 'url-parse';

const whitespaces = /\s/;
const protocolWhiteList = ['ftp:', 'git:', 'http:', 'https:', 's3:', 'sftp:', 'ssh:'];

export function isUrlLike(raw: string) {
  const input = raw.trim();

  if (input.length > 1000 || whitespaces.test(input)) {
    return false;
  }

  const url = urlParse(input, {});
  return protocolWhiteList.includes(url.protocol) && !!url.hostname;
}
