import { AttributeDefinition } from 'domain/experiment/attribute';

export type XAxisMode = 'step' | 'relativeTime' | 'absoluteTime' | 'timeSeries';
export type AxisScale = 'linear' | 'logarithmic';
export type AxisRange = [number | undefined, number | undefined];
export type JsonCompatibleAxisRange = [number | undefined | null, number | undefined | null];

export function flattenUndefinedRange(range: AxisRange | undefined) {
  if (range && range[0] == null && range[1] == null) {
    return undefined;
  }

  return range;
}

export function removeNullsFromAxisRange(
  range: JsonCompatibleAxisRange | undefined | null,
): AxisRange | undefined {
  return (range && [range[0] ?? undefined, range[1] ?? undefined]) ?? undefined;
}

export type AxisRangeHandlerParams = {
  min: number | undefined;
  max: number | undefined;
};

export const isAxisScale = (input: string): input is AxisScale =>
  input === 'linear' || input === 'logarithmic';

export type ChartConfigurationProps = ChartConfigurationSettings & {
  onSetYLogScale: (scale: AxisScale) => void;
  onSetXLogScale: (scale: AxisScale) => void;
  setXAxisMode?: (xAxisMode: XAxisMode) => void;
  setXAxisMetric?: (xAxisMetric: AttributeDefinition | undefined) => void;
  setYAxisRange?: (values: AxisRangeHandlerParams) => void;
  setMetricsStepsRange?: (values: AxisRangeHandlerParams) => void;
  selectedAttributes?: AttributeDefinition[];
  globalConfiguration?: boolean;
};

export type ChartConfigurationSettings = {
  averaging: boolean;
  xAxisScale: AxisScale;
  yAxisScale: AxisScale;
  xAxisMode: XAxisMode;
  xAxisMetric?: AttributeDefinition;
  yAxisRange?: AxisRange;
  metricsStepsRange?: AxisRange;
  customXExpression?: string;
};

export const DEFAULT_CHART_CONFIG: ChartConfigurationSettings = {
  averaging: false,
  xAxisMode: 'step',
  xAxisScale: 'linear',
  yAxisScale: 'linear',
  xAxisMetric: undefined,
  yAxisRange: undefined,
  metricsStepsRange: undefined,
};

export const prefillAttributes: AttributeDefinition[] = [
  { name: 'step', type: 'floatSeries', predefined: true },
  { name: 'relative time', type: 'datetime', predefined: true },
  { name: 'absolute time', type: 'datetime', predefined: true },
];

export const attributeNameToXAxisMode: Record<string, XAxisMode | undefined> = {
  step: 'step',
  'relative time': 'relativeTime',
  'absolute time': 'absoluteTime',
};

export const xAxisModeToAttributeName: Record<string, string | undefined> = {
  step: 'step',
  relativeTime: 'relative time',
  absoluteTime: 'absolute time',
};
