import { cannotUnarchiveProjectModalName } from '@neptune/project-domain';
import {
  archiveProject as domainArchiveProject,
  unarchiveProject as domainUnarchiveProject,
} from '@neptune/shared/core-project-domain';
import { handleError } from 'common/error-handler';
import { AsyncActionsReturnType, createAsyncActions } from 'state/async-actions';
import { updateProjectActions } from 'state/projects/actions';
import { showGenericModal } from 'state/ui/modals/generic/actions';
import { addNotification } from 'state/ui/global/notification-popup/actions';

export enum ProjectArchiveActionTypes {
  request = 'PROJECT_ARCHIVE',
  success = 'PROJECT_ARCHIVE_SUCCESS',
  fail = 'PROJECT_ARCHIVE_FAIL',
}

const archiveProjectActions = createAsyncActions({
  types: ProjectArchiveActionTypes,
  resolver: domainArchiveProject,
  onResolve: (project, dispatch) => {
    dispatch(updateProjectActions.success({ project }));
    dispatch(addNotification('success', `You've just archived the project ${project.name}`));
  },
});

export enum ProjectUnarchiveActionTypes {
  request = 'PROJECT_UNARCHIVE',
  success = 'PROJECT_UNARCHIVE_SUCCESS',
  fail = 'PROJECT_UNARCHIVE_FAIL',
}

const unarchiveProjectActions = createAsyncActions({
  types: ProjectUnarchiveActionTypes,
  resolver: domainUnarchiveProject,
  onResolve: (project, dispatch) => {
    dispatch(updateProjectActions.success({ project }));
    dispatch(addNotification('success', `You've just unarchived the project ${project.name}`));
  },
  onReject: (payload, dispatch) => {
    handleError(422, payload.error, (err) => {
      if (err.error?.errorType === 'LIMIT_OF_ACTIVE_PROJECTS_REACHED') {
        dispatch(showGenericModal(cannotUnarchiveProjectModalName));
      }
    });
  },
});

export const { execute: archiveProject } = archiveProjectActions;
export const { execute: unarchiveProject } = unarchiveProjectActions;

export type ProjectArchiveActions =
  | AsyncActionsReturnType<typeof archiveProjectActions>
  | AsyncActionsReturnType<typeof unarchiveProjectActions>;
