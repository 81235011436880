import { WidgetDefinition } from '../core/domain/widget-definition';
import { Widget } from '../core/widget';

export type SectionWidget = Widget & {
  type: 'section';
  options?: SectionWidgetOptions;
};

export type SectionWidgetOptions = {
  folded: boolean;
};

export function isSectionWidget(widget: Widget | undefined): widget is SectionWidget {
  return widget?.type === 'section';
}

export type SectionsFoldUpdate = Record<string, boolean>;

export function applySectionsFoldUpdate(widgets: Widget[], sectionsFoldUpdate: SectionsFoldUpdate) {
  return widgets.map((widget) => {
    if (
      !isSectionWidget(widget) ||
      sectionsFoldUpdate[widget.id] === undefined ||
      sectionsFoldUpdate[widget.id] === widget.options?.folded
    ) {
      return widget;
    }

    return {
      ...widget,
      options: {
        ...widget.options,
        folded: sectionsFoldUpdate[widget.id],
      },
    };
  });
}

export const sectionWidgetDefinition: WidgetDefinition = {
  type: 'section',
  name: 'Section',
  fromApiToDomain: (options?: Record<string, any>): SectionWidgetOptions => ({
    folded: !!(options && options.folded),
  }),
  fromDomainToApi: (options?: SectionWidgetOptions) => ({
    folded: !!options?.folded,
  }),
};
