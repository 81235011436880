import { useSelector } from 'react-redux';
import { useGenericModal } from '@neptune/shared/common-business-logic';
import { TOP_UP_MONITORING_HOURS_MODAL_NAME, TOP_UP_PLACEHOLDER_MODAL_NAME } from './modals';
import { getContextOrganization } from 'state/selectors-global';
import { AppState } from 'state/types';

export function useTopUpMonitoringHoursFlow() {
  const limitsEnforced = useSelector(
    (state: AppState) => getContextOrganization(state)?.limitsEnforced,
  );

  const { open: openTopUpModal } = useGenericModal(TOP_UP_MONITORING_HOURS_MODAL_NAME);
  const { open: openPlaceholderModal } = useGenericModal(TOP_UP_PLACEHOLDER_MODAL_NAME);

  const start = () => {
    if (limitsEnforced) {
      openTopUpModal();
    } else {
      openPlaceholderModal();
    }
  };

  return {
    start,
  };
}
