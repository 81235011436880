import {
  deserializeAsBoolean,
  deserializeAsOptString,
  deserializeAsString,
  deserializeAsStringArray,
  ISelectors,
  serializeToBoolean,
  serializeToString,
  serializeToStringArray,
  ViewNavigation,
} from '@neptune/shared/routing-business-logic';

import { CompareNavigation } from './CompareNavigation';
import { RunDetailsNavigation } from './RunDetailsNavigation';

type RunsTab = 'table' | 'compare' | 'details';
export type RunsRouteParams = {
  tab: RunsTab;
  // active lb view id
  viewId?: string;
  lbViewUnpacked?: boolean;
  // encoded ids of entities added to compare
  compare?: string;
  // query for filtering the table based on our DSL
  query?: string;
  // names of sorting fields
  sortBy?: string[];
  sortFieldType?: string[];
  sortFieldAggregationMode?: string[];
  sortDirection?: string[];
  groupBy?: string[];
  groupByFieldType?: string[];
  groupByFieldAggregationMode?: string[];
  suggestionsEnabled?: boolean;
};

export class RunsNavigation
  extends ViewNavigation<RunsRouteParams>
  implements ISelectors<RunsRouteParams>
{
  protected readonly routeName = 'project.runs';
  protected readonly paramPrefix = '';

  readonly serializers = {
    tab: serializeToString,
    viewId: serializeToString,
    lbViewUnpacked: serializeToBoolean,
    compare: serializeToString,
    query: serializeToString,
    sortBy: serializeToStringArray,
    sortFieldType: serializeToStringArray,
    sortFieldAggregationMode: serializeToStringArray,
    sortDirection: serializeToStringArray,
    groupBy: serializeToStringArray,
    groupByFieldType: serializeToStringArray,
    groupByFieldAggregationMode: serializeToStringArray,
    suggestionsEnabled: serializeToBoolean,
  };

  readonly deserializers = {
    tab: (rawValue?: string) => deserializeAsString(rawValue) as RunsTab,
    viewId: deserializeAsOptString,
    lbViewUnpacked: deserializeAsBoolean,
    compare: deserializeAsOptString,
    query: deserializeAsOptString,

    // todo in case of array we should decide if we should deserialize empty value to empty array or to undefined
    sortBy: deserializeAsStringArray,
    sortFieldType: deserializeAsStringArray,
    sortFieldAggregationMode: deserializeAsStringArray,
    sortDirection: deserializeAsStringArray,
    groupBy: deserializeAsStringArray,
    groupByFieldType: deserializeAsStringArray,
    groupByFieldAggregationMode: deserializeAsStringArray,
    suggestionsEnabled: deserializeAsBoolean,
  };

  selectors = {
    tab: this.createParamSelector('tab'),
    viewId: this.createParamSelector('viewId'),
    lbViewUnpacked: this.createParamSelector('lbViewUnpacked'),
    compare: this.createParamSelector('compare'),
    query: this.createParamSelector('query'),
    sortBy: this.createParamSelector('sortBy'),
    sortFieldType: this.createParamSelector('sortFieldType'),
    sortFieldAggregationMode: this.createParamSelector('sortFieldAggregationMode'),
    sortDirection: this.createParamSelector('sortDirection'),
    groupBy: this.createParamSelector('groupBy'),
    groupByFieldType: this.createParamSelector('groupByFieldType'),
    groupByFieldAggregationMode: this.createParamSelector('groupByFieldAggregationMode'),
    suggestionsEnabled: this.createParamSelector('suggestionsEnabled'),
  };

  constructor(
    public details: RunDetailsNavigation,
    public compare: CompareNavigation,
  ) {
    super();
  }
}
