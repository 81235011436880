import {
  deserializeAsOptString,
  ISelectors,
  serializeToString,
  ViewNavigation,
} from '@neptune/shared/routing-business-logic';

export type CompareRouteParams = {
  // Selected dashboard. Can be either predefined or user defined.
  dash?: string;
  // id used when comparing in 'side-by-side' dashboard
  referenceShortId?: string;
  // used in 'charts' dashboard for filtering charts
  chartFilter?: string;
  // used in 'images' dashboard for filtering images
  imageFilter?: string;
};

export class CompareNavigation
  extends ViewNavigation<CompareRouteParams>
  implements ISelectors<CompareRouteParams>
{
  selectors = {
    dash: this.createParamSelector('dash'),
    chartFilter: this.createParamSelector('chartFilter'),
    referenceShortId: this.createParamSelector('referenceShortId'),
    imageFilter: this.createParamSelector('imageFilter'),
  };

  readonly serializers = {
    dash: serializeToString,
    chartFilter: serializeToString,
    referenceShortId: serializeToString,
    imageFilter: serializeToString,
  };

  readonly deserializers = {
    dash: deserializeAsOptString,
    chartFilter: deserializeAsOptString,
    referenceShortId: deserializeAsOptString,
    imageFilter: deserializeAsOptString,
  };

  constructor(
    protected readonly routeName: string,
    protected readonly paramPrefix: string,
  ) {
    super();
  }
}
