// Libs
import React from 'react';
import { Link } from '@neptune/shared/venus-ui';

// App
import { NormalizedLink, NormalizedLinkProps } from '@neptune/shared/routing-ui';

// Module
export const CreateWorkspaceLink = ({ ...props }) => {
  return (
    <Link<NormalizedLinkProps>
      component={NormalizedLink}
      routeName="create-workspace"
      routeParams={{}}
      external
      {...props}
    />
  );
};
