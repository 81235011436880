// Libs
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rx';

// App
import { generateProjectKey } from '@neptune/shared/core-project-domain';

const withProjectKeyGenerated = Component => class extends React.Component {
  componentDidMount() {
    this.projectNameUpdatesStream = new Rx.Subject();

    this.projectNameUpdatesSubscription = (
      this
        .projectNameUpdatesStream
        .debounce(300)
        .filter(() => this.props.canGenerateProjectKey)
        .flatMapLatest((params) => generateProjectKey(params))
        .subscribe(generatedId => this.props.handleProjectKeyChange(generatedId.proposal))
    );
  }

  componentDidUpdate(prevProps) {
    const {
      organizationId,
      projectName,
    } = this.props;

    if (
      (projectName && projectName.length > 0)
      && ((projectName !== prevProps.projectName) || (organizationId !== prevProps.organizationId))
    ) {
      this.projectNameUpdatesStream.onNext({
        organizationId,
        projectName,
      });
    }
  }

  componentWillUnmount() {
    this.projectNameUpdatesSubscription.dispose();
    this.projectNameUpdatesStream = null;
  }

  render() {
    return (
      <Component
        {...this.props}
      />
    );
  }
};


export default withProjectKeyGenerated;
