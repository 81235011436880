import { WorkspaceStatus } from '@neptune/pricing-workspace-status-domain';
import { isAfter, sub } from 'date-fns';

function trialTrigger(status: WorkspaceStatus, now: Date, daysToTrialEnd: number) {
  return status.trialEndDate
    ? isAfter(now, sub(new Date(status.trialEndDate), { days: daysToTrialEnd }))
    : false;
}

export const trialReadOnlyTrigger = (status: WorkspaceStatus, now: Date): boolean =>
  trialTrigger(status, now, 0);
export const trialMemberTrigger = (status: WorkspaceStatus, now: Date): boolean =>
  trialTrigger(status, now, 5);
export const trialAdminTrigger = (status: WorkspaceStatus, now: Date): boolean =>
  trialTrigger(status, now, 5);
