import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentProject } from '@neptune/current-project-business-logic';
import { unarchiveProject } from '@neptune/project-business-logic';
import { ArchiveProjectEmptyView } from '@neptune/project-ui';
import {
  hasCapabilitiesToIncreaseQuota,
  hasCapabilitiesToUnarchiveProject,
} from '@neptune/shared/core-organizations-business-logic';
import { hasProjectUnarchivePermission } from '@neptune/shared/core-permissions-business-logic';
import {
  selectActiveProjectsUsage,
  selectFirstFetchStatus,
} from '@neptune/pricing-workspace-status-api';
import { getContextOrganization, getOrganizationNameFromRouteParams } from 'state/selectors-global';
import { neptuneUrls } from '@neptune/shared/common-domain';
import { AppState } from 'state/types';
import { isFetchStatusSuccess } from 'state/fetch-status';

export const ArchivedProjectContainer: React.FC = () => {
  const dispatch = useDispatch();
  const project = useSelector(getCurrentProject);
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const organization = useSelector(getContextOrganization);
  const activeProjectsFirstFetchStatus = useSelector((state: AppState) =>
    selectFirstFetchStatus(state, organizationName),
  );
  const activeProjectsUsage = useSelector((state: AppState) =>
    selectActiveProjectsUsage(state, organizationName),
  );

  const canUnarchive =
    isFetchStatusSuccess(activeProjectsFirstFetchStatus) &&
    hasProjectUnarchivePermission(project) &&
    hasCapabilitiesToUnarchiveProject(organization?.traits ?? {}, activeProjectsUsage);
  const shouldUpgradePlanHint = !hasCapabilitiesToIncreaseQuota(organization?.traits ?? {});
  const handleUnarchive = React.useCallback(() => {
    if (!project) {
      return;
    }

    dispatch(unarchiveProject(project));
  }, [dispatch, project]);

  if (!project || !organization) {
    return null;
  }

  return (
    <ArchiveProjectEmptyView
      organizationName={project.organizationName}
      canUnarchive={canUnarchive}
      onUnarchive={handleUnarchive}
      shouldUpgradePlanHint={shouldUpgradePlanHint}
      archiveProjectDocumentationLink={neptuneUrls.archivingProjectsDoc}
    />
  );
};
