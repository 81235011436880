import { AppState } from 'state/types';
import getParentState from 'state/project-leaderboard/selectors';
import { GroupPreviewState, groupPreviewInitialState } from './reducer';

export function getGroupPreviewState(
  state: AppState,
  leaderboardIdentifier: string,
): GroupPreviewState {
  return getParentState(state).groupsPreview[leaderboardIdentifier] || groupPreviewInitialState;
}

export function getOpenedGroups(state: AppState, leaderboardIdentifier: string) {
  return getGroupPreviewState(state, leaderboardIdentifier).openedGroups;
}

export function getFetchStatusMap(state: AppState, leaderboardIdentifier: string) {
  return getGroupPreviewState(state, leaderboardIdentifier).fetchStatusMap;
}
