import {
  PartialSearchCriterion,
  PluralStageCriterion,
  SingularStageCriterion,
  StageCriterion,
} from '../search-query-model';
import {
  criterionHasAttributeAndOperator,
  getReadableArrayValue,
} from '../search-query-model-core-utils';
import { CriterionHelpers } from './types';

export function isStageCriterion(criterion: PartialSearchCriterion): criterion is StageCriterion {
  return isPluralStageCriterion(criterion) || isSingularStageCriterion(criterion);
}

export function isPluralStageCriterion(
  criterion: PartialSearchCriterion,
): criterion is PluralStageCriterion {
  return (
    criterionHasAttributeAndOperator(criterion) &&
    criterion.type === 'stage' &&
    (criterion.operator === 'oneOf' || criterion.operator === '!oneOf')
  );
}

export function isSingularStageCriterion(
  criterion: PartialSearchCriterion,
): criterion is SingularStageCriterion {
  return (
    criterionHasAttributeAndOperator(criterion) &&
    criterion.type === 'stage' &&
    criterion.operator === '='
  );
}

export const stageCriterionHelpers: CriterionHelpers<StageCriterion> = {
  getReadableValue(criterion) {
    if (isPluralStageCriterion(criterion)) {
      return getReadableArrayValue(criterion.value);
    }

    return criterion.value;
  },
  getShortReadableValue(criterion) {
    if (isPluralStageCriterion(criterion)) {
      return criterion.value.join(', ');
    }

    return criterion.value;
  },
  isComplete(criterion) {
    if (isSingularStageCriterion(criterion)) {
      return criterion.value != null;
    }

    if (isPluralStageCriterion(criterion)) {
      return criterion.value?.length > 0;
    }

    return false;
  },
};
