export const featureFlags = [
  'withAdminPanel',

  'withRefactoredCharts',
  'withRefactoredCharts2',
  'withRefactoredCharts3',
  'withRunGroupsV4',

  'withSingleRunAutoFollow',
  'withSectionsInReports',
  'withSectionsInDashboards',
  'withXAxisRangeSetting',
  'withXAxisGlobalSettings',
  'withNameSearchRegExp',
  'withCompoundSearch',
  'withCompoundSearchWithEditableCriterionItems',
  'withReportsRunGroups',
  'withCustomXExpression',
] as const;
export type FeatureFlag = (typeof featureFlags)[number];

export type FeatureFlagConfig = `${FeatureFlag}Config`;
