import React from 'react';
import { uniq } from 'lodash';
import { storage } from '@neptune/shared/legacy-domain';

type Key =
  | 'mutedTrialNotifications'
  | 'mutedCardNotifications'
  | 'mutedPaymentNotifications'
  | 'mutedMonitoringNotifications'
  | 'mutedStorageNotifications';

export function useMuteState(key: Key, organizationName: string): [boolean, () => void] {
  const initialValue = storage.session.getItem(key)?.includes(organizationName);
  const [data, setData] = React.useState(initialValue === true);

  const handleData = React.useCallback(() => {
    const organizations = storage.session.getItem(key) || [];
    setData(true);
    storage.session.setItem(key, uniq([...organizations, organizationName]));
  }, [setData, organizationName, key]);

  return [data, handleData];
}
