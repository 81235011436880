import { UpdateProjectActionTypes } from 'state/projects/actions';
import { fetchStatus } from 'state/fetch-status';
import {
  CurrentProjectAction,
  FetchCurrentProjectActionTypes,
  SetIndicatedProjectEntitiesActionTypes,
} from './actions';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';
import { EntityType } from '@neptune/shared/entity-domain';

export type CurrentProjectState = {
  fetchStatus: fetchStatus;
  project?: ProjectWithRole;
  indicatedEntities?: { ids: string[]; type?: EntityType };
  error: unknown | null;
  requestedId: string;
  lastManuallyUpdated: number;
};

const initialState: CurrentProjectState = {
  fetchStatus: fetchStatus.NONE,
  project: undefined,
  indicatedEntities: undefined,
  error: null,
  requestedId: '',
  lastManuallyUpdated: 0,
};

type HandledActions =
  | CurrentProjectAction
  | {
      type: UpdateProjectActionTypes.success;
      payload: {
        project: ProjectWithRole;
      };
    };

export function currentProjectReducer(
  state: CurrentProjectState = initialState,
  action: HandledActions,
): CurrentProjectState {
  switch (action.type) {
    case FetchCurrentProjectActionTypes.request: {
      return {
        ...state,
        fetchStatus: fetchStatus.PENDING,
        requestedId: action.payload.projectIdentifier,
        error: null,
      };
    }

    case FetchCurrentProjectActionTypes.success: {
      return {
        ...state,
        fetchStatus: fetchStatus.SUCCESS,
        project: action.payload,
      };
    }

    case FetchCurrentProjectActionTypes.fail: {
      return {
        ...state,
        fetchStatus: fetchStatus.FAILED,
        error: action.error,
        project: undefined,
      };
    }

    case SetIndicatedProjectEntitiesActionTypes.set: {
      return { ...state, indicatedEntities: action.payload };
    }

    case SetIndicatedProjectEntitiesActionTypes.remove: {
      return { ...state, indicatedEntities: undefined };
    }

    case UpdateProjectActionTypes.success: {
      if (!state.project || state.project.id !== action.payload.project.id) {
        return state;
      }

      return {
        ...state,
        project: action.payload.project,
        lastManuallyUpdated: state.lastManuallyUpdated + 1,
      };
    }

    default:
      return state;
  }
}
