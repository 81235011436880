// Libs
import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'react-router5';
import { Card, Layout, Text } from '@neptune/shared/venus-ui';

// App
import { isAcceptInvitationRoute } from '@neptune/invitations-api';
import { isTeamTrialCookieForKeycloakRegistrationSet } from 'common/team-trial-cookies';
import { KeycloakTitle } from 'components/keycloak';
import { ProgressBar } from '@neptune/shared/common-ui';
import { backendClient, LoginActionDTO } from '@neptune/shared/core-apis-backend-domain';
import { completeLoginAction, setLoginActionFlag } from 'state/users/register/actions';
import { SurveyNavigation } from 'components/survey-navigation/SurveyNavigation';

// Module
import { NewOrganizationTypeForm } from './organization-type-form';
import { NewRoleForm } from './role-form';
import { ProblemForm } from './problem-form';
import {
  createRegistrationSurveyFlow,
  formatSurvey,
  RegistrationSurveyData,
  RegistrationSurveyStep,
} from './registration-survey-flow';
import { TeamTrialForm } from './team-trial-form';
import { CustomTracingBus, KnownSignals } from 'monitoring/custom-tracing';
import { getPlatformType } from '@neptune/shared/common-util';
import { SelfAttributionForm } from './self-attribution-form';
import { TeamTypeForm } from './team-type';

type ComponentsMapEntry = {
  component: React.ComponentType<{
    data: Partial<RegistrationSurveyData>;
    onChange: (data: Partial<RegistrationSurveyData>) => void;
  }>;
  progress: number;
};
const componentsMap: Record<RegistrationSurveyStep, ComponentsMapEntry> = {
  [RegistrationSurveyStep.selfAttribution]: { component: SelfAttributionForm, progress: 0.143 },
  [RegistrationSurveyStep.teamType]: { component: TeamTypeForm, progress: 0.285 },
  [RegistrationSurveyStep.role]: { component: NewRoleForm, progress: 0.429 },
  [RegistrationSurveyStep.problem]: { component: ProblemForm, progress: 0.577 },
  [RegistrationSurveyStep.organizationType]: { component: NewOrganizationTypeForm, progress: 0.72 },
  [RegistrationSurveyStep.teamTrial]: { component: TeamTrialForm, progress: 0.863 },
};

export const RegistrationSurvey: React.FC = ({}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [step, setStep] = React.useState<RegistrationSurveyStep>(
    isAcceptInvitationRoute(router)
      ? RegistrationSurveyStep.role
      : RegistrationSurveyStep.selfAttribution,
  );
  const [data, setData] = React.useState<RegistrationSurveyData>({
    teamTrialRegistration: isTeamTrialCookieForKeycloakRegistrationSet(),
    teamInvitationRegistration: isAcceptInvitationRoute(router),
    signupDevice: getPlatformType(),
  });

  const submitSurvey = React.useCallback(
    async (data: RegistrationSurveyData) => {
      const shouldGoToTeamTrialSurvey =
        data.teamTrial === 'yes' || (!data.teamTrial && data.teamTrialRegistration);

      if (!shouldGoToTeamTrialSurvey) {
        // this is the last step of the registration
        CustomTracingBus.publish(KnownSignals.SURVEY_SUBMIT, { withInvites: false });
      }

      const survey = formatSurvey(data);
      await backendClient.sendRegistrationSurvey({ survey: { survey } });

      if (shouldGoToTeamTrialSurvey) {
        dispatch(setLoginActionFlag('teamTrial'));
      }

      dispatch(completeLoginAction(LoginActionDTO.SURVEY));
    },
    [dispatch],
  );
  const updateData = React.useCallback(
    (updatedData: Partial<RegistrationSurveyData>) => setData({ ...data, ...updatedData }),
    [data],
  );

  // progress includes 50% taken from SetUsernameForm. We add current progress on top of it.
  const progress = 0.5 + componentsMap[step].progress / 2;

  const FormComponent = componentsMap[step].component;

  const flow = createRegistrationSurveyFlow(submitSurvey);

  const handleNext = async () => {
    const nextStep = await flow.goNext(step, data);

    setStep(nextStep);
  };

  const handlePrev = async () => {
    const prevStep = await flow.goPrev(step, data);

    setStep(prevStep);
  };

  const isPersonalizationStep = [
    RegistrationSurveyStep.organizationType,
    RegistrationSurveyStep.teamTrial,
  ].includes(step);

  return (
    <Layout.Column spacedChildren="lg">
      <Layout.Column alignItems="center" span="auto">
        <KeycloakTitle>Create a free account</KeycloakTitle>
      </Layout.Column>
      <Card>
        <Card.Section>
          <Card.Column spacedChildren="md">
            <Card.Column spacedChildren="xxs">
              <Card.HeaderText>
                {isPersonalizationStep
                  ? 'Personalize your experience'
                  : 'Help us learn more about you'}
              </Card.HeaderText>
              <Text size="xs" color="text-alt">
                {isPersonalizationStep
                  ? 'You can use Neptune for individual projects as well for team projects. '
                  : 'We want you to get started quickly and share with you only relevant resources.'}
              </Text>
            </Card.Column>
            <ProgressBar ratio={progress} size="xs" />
          </Card.Column>
        </Card.Section>
        <Card.Section>
          <FormComponent data={data} onChange={updateData} />
        </Card.Section>
        <Card.Footer>
          <SurveyNavigation
            showNext={flow.isNextAvailable(step, data)}
            disableNext={!flow.canGoNext(step, data)}
            handleNext={handleNext}
            showPrev={flow.isPrevAvailable(step, data)}
            disablePrev={!flow.canGoPrev(step, data)}
            handlePrev={handlePrev}
            data-step={step}
          />
        </Card.Footer>
      </Card>
    </Layout.Column>
  );
};
