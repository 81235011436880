import { backendClient } from '@neptune/shared/core-apis-backend-domain';
import { ProjectRole, projectRoleFromDomainToApi } from '@neptune/shared/core-project-domain';

import { ServiceAccountModelConverter } from './service-account-model-converter';

export async function fetchServiceAccounts(organizationIdentifier: string) {
  const accounts = await backendClient.listServiceAccounts({
    organizationIdentifier,
  });

  return accounts.map(ServiceAccountModelConverter.serviceAccountFromApiToDomain);
}

export async function fetchProjectServiceAccounts(projectIdentifier: string) {
  const accounts = await backendClient.listProjectServiceAccounts({
    projectIdentifier,
  });

  return accounts.map(ServiceAccountModelConverter.serviceAccountWithRoleFromApiToDomain);
}

type CreateServiceAccountParams = {
  organizationIdentifier: string;
  name: string;
  addToAllProjects: boolean;
};

export async function createServiceAccount({
  organizationIdentifier,
  name,
  addToAllProjects,
}: CreateServiceAccountParams) {
  return backendClient.createServiceAccount({
    organizationIdentifier,
    newServiceAccount: {
      name,
    },
    addToAllProjects,
  });
}

export async function deactivateServiceAccount(
  organizationIdentifier: string,
  serviceAccountId: string,
) {
  return backendClient.deactivateServiceAccount({
    organizationIdentifier,
    serviceAccountId,
  });
}

export async function reactivateServiceAccount(
  organizationIdentifier: string,
  serviceAccountId: string,
) {
  return backendClient.activateServiceAccount({
    organizationIdentifier,
    serviceAccountId,
  });
}

export async function fetchServiceAccountApiToken(serviceAccountId: string) {
  const { token } = await backendClient.getServiceAccountApiToken({
    serviceAccountId,
  });
  return token;
}

export async function revokeServiceAccountApiToken(params: {
  serviceAccountId: string;
  organizationIdentifier: string;
}) {
  return backendClient.revokeServiceAccountApiToken(params);
}

type AddProjectServiceAccountParams = {
  projectIdentifier: string;
  serviceAccountId: string;
  role: ProjectRole;
};

export async function addProjectServiceAccount({
  projectIdentifier,
  serviceAccountId,
  role,
}: AddProjectServiceAccountParams) {
  return backendClient.addProjectServiceAccount({
    projectIdentifier,
    account: {
      serviceAccountId,
      role: projectRoleFromDomainToApi(role),
    },
  });
}

type DeleteProjectServiceAccountParams = {
  projectIdentifier: string;
  serviceAccountId: string;
};

export async function deleteProjectServiceAccount(params: DeleteProjectServiceAccountParams) {
  return backendClient.deleteProjectServiceAccount(params);
}

type UpdateProjectServiceAccountParams = {
  projectIdentifier: string;
  serviceAccountId: string;
  role: ProjectRole;
};

export async function updateProjectServiceAccount({
  projectIdentifier,
  serviceAccountId,
  role,
}: UpdateProjectServiceAccountParams) {
  return backendClient.updateProjectServiceAccount({
    projectIdentifier,
    serviceAccountId,
    member: {
      role: projectRoleFromDomainToApi(role),
    },
  });
}
