import React from 'react';
import { getShortCriterionReadableValue, SearchQuery } from '@neptune/search-query-domain';
import { isNameMetaQuery } from './is-name-meta-query';

const getNameFromQuery = (query: SearchQuery) =>
  isNameMetaQuery(query) ? getShortCriterionReadableValue(query.criteria[0]) : '';

export const useNameQuery = (
  query: SearchQuery,
): { nameQuery: string; setNameQuery: React.Dispatch<React.SetStateAction<string>> } => {
  const [nameQuery, setNameQuery] = React.useState(() => getNameFromQuery(query));

  React.useEffect(() => {
    setNameQuery(getNameFromQuery(query));
  }, [query]);

  return { nameQuery, setNameQuery };
};
