import React from 'react';
import { useSelector } from 'react-redux';
import { startCase } from 'lodash';
import { Namespaces } from 'domain/common/namespaces';
import { isInSystemNamespace } from 'domain/common/utils';
import { AttributeDefinition, attributeDefinitionComparator } from 'domain/experiment/attribute';
import { LeaderboardColumn, serializeColumnIdentifier } from '@neptune/shared/leaderboard-domain';
import { getLeaderboardColumnsForCurrentProject } from 'state/ui/leaderboards/ordered-columns/selectors';

export const useColumnDisplayNameEnhancer = () => {
  const columns = useSelector(getLeaderboardColumnsForCurrentProject);
  const columnsHash = columns.map(serializeColumnIdentifier).join();
  return React.useCallback(
    (entry: AttributeDefinition) => expandAttributeWithColumnDisplayName(entry, columns),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsHash],
  );
};

function expandAttributeWithColumnDisplayName(
  attribute: AttributeDefinition,
  columns: LeaderboardColumn[],
): AttributeDefinition {
  return {
    ...attribute,
    displayName: getDisplayName(attribute, columns),
  };
}

function getDisplayName(
  attribute: AttributeDefinition,
  columns: LeaderboardColumn[],
): string | undefined {
  for (const column of columns) {
    const { id: name, columnType: type, aggregationMode: subproperty, displayName } = column;

    if (attributeDefinitionComparator(attribute, { name, type, subproperty }) && displayName) {
      return displayName;
    }
  }

  if (isInSystemNamespace(attribute.name)) {
    return startCase(attribute.name.replace(`${Namespaces.System}/`, ''));
  }
}
