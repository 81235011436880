import { Observable } from 'rxjs';

import config from 'config';
import { authClient } from '@neptune/shared/core-auth-domain';
import { createWebSocketStream } from '@neptune/shared/core-websockets-util';

const protocol = config.neptuneApiSecure ? 'wss' : 'ws';
const basePath = config.neptuneApiBasePath;
const port = config.neptuneApiWSPort;

const organizationDataStreams: Record<string /* organizationId */, Observable<any> | undefined> =
  {};

export type OrganizationNotification = {
  messageType: string;
  messageBody: any;
};

type GetOrganizationNotificationStreamParams = {
  organizationId: string;
  getAccessToken?: () => string | undefined;
};

export function getOrganizationNotificationStream<T extends OrganizationNotification>({
  organizationId,
  getAccessToken = () => authClient.token,
}: GetOrganizationNotificationStreamParams): Observable<T> {
  const url = buildUrl(protocol, basePath, port, getAccessToken(), organizationId);

  if (isOrganizationStreamCreated(organizationId)) {
    return getOrganizationStream(organizationId) as Observable<T>;
  }

  const organizationNotifications$ = createWebSocketStream<T>({
    url,
    identifier: `organization_${organizationId}`,
    retryBackoffConfig: {
      backoffDelay: undefined,
      initialInterval: 250,
      maxInterval: 300_000,
      maxRetries: 100,
      resetOnSuccess: true,
    },
  });

  addOrganizationStream(organizationId, organizationNotifications$);
  return organizationNotifications$;
}

// region internals
function isOrganizationStreamCreated(organizationId: string): boolean {
  return getOrganizationStream(organizationId) != null;
}

function getOrganizationStream(organizationId: string): Observable<any> | undefined {
  return organizationDataStreams[organizationId];
}

function addOrganizationStream(organizationId: string, stream$: Observable<any>) {
  organizationDataStreams[organizationId] = stream$;
}

function buildUrl(
  protocol: string,
  basePath: string,
  port: string,
  token: string | undefined,
  organizationId: string,
): string {
  const prefix = config.neptuneApiWSBasePath;
  const origin = `${protocol}://${basePath}:${port}`;
  const params = token ? `token=${token}` : '';

  return `${origin}${prefix}/organizations/${organizationId}/updates?${params}`;
}
// endregion
