import React from 'react';
import { useSelector } from 'react-redux';
import { Text, View } from '@neptune/shared/venus-ui';

import { ProjectUsageWidgetContainer } from 'components/usage-widget/project-usage-widget/ProjectUsageWidgetContainer';
import { ProjectUsageHistoryContainer } from 'components/usage-monitoring';
import { getContextOrganization } from 'state/selectors-global';
import { ProjectUsageSettingsContainer } from '@neptune/project-feature';
import { hasProjectUsageManagingPermission } from '@neptune/shared/core-permissions-business-logic';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';
import { makeProjectIdentifier } from '@neptune/shared/core-project-util';

type UsageProps = {
  project: ProjectWithRole;
};

export const Usage = ({ project }: UsageProps) => {
  const organization = useSelector(getContextOrganization);

  const showMonitoring = organization?.supportsMonitoringHoursLimits;
  const projectIdentifier = makeProjectIdentifier(project.organizationName, project.name);

  return (
    <View.Body data-role="project-usage-container">
      <View.Sidebar>
        <ProjectUsageWidgetContainer
          showMonitoring={showMonitoring}
          projectIdentifier={projectIdentifier}
        />
        {hasProjectUsageManagingPermission(project) && (
          <ProjectUsageSettingsContainer projectIdentifier={projectIdentifier} />
        )}
      </View.Sidebar>
      {showMonitoring && (
        <View.Content>
          <Text size="md" fontWeight="semibold" children="Usage history" />
          <ProjectUsageHistoryContainer projectIdentifier={projectIdentifier} />
        </View.Content>
      )}
    </View.Body>
  );
};
