import React from 'react';
import { ProjectMemberStorageErrorBanner } from '@neptune/project-ui';
import { useSelector } from 'react-redux';
import { getContextOrganization } from 'state/selectors-global';
import { useObtainOwnerEmails } from '@neptune/shared/organization-business-logic';

export const ProjectMemberStorageErrorBannerContainer: React.FC = () => {
  const organizationContext = useSelector(getContextOrganization);
  const adminEmails = useObtainOwnerEmails(organizationContext?.name);

  return <ProjectMemberStorageErrorBanner adminEmails={adminEmails} />;
};
