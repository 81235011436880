import { GroupByField } from './group-by-model';
import { GroupOptions } from './group-options/group-options-model';

export class GroupByModelConverter {
  static fromGroupOptionsToDomain(options: GroupOptions): GroupByField[] {
    const result: GroupByField[] = [];

    if (!options.groupBy) {
      return result;
    }

    return options.groupBy.reduce((acc, name, i) => {
      const type = options.groupByFieldType?.[i];
      const aggregationMode = options.groupByFieldAggregationMode?.[i];

      if (name && type) {
        acc.push({
          name,
          type,
          aggregationMode,
        });
      }

      return acc;
    }, result);
  }
}
