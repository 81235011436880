// App
import type { RouterConfig } from './types';
import {
  routerForOnlyLoggedUserMW,
  onRouteActivateMW,
  migrateTLDtoAiMW,
} from './router-middlewares';

export const desktopRouterConfig: RouterConfig = {
  defaultRoute: 'home',
  useHash: false,
  strictTrailingSlash: false,
  queryParamsMode: 'loose',
  middlewares: [routerForOnlyLoggedUserMW, onRouteActivateMW, migrateTLDtoAiMW],
};

export const FILTERING_QUERY_PARAMS =
  ':status&:searchTerm&:tags&:createDateRangeStart&:creationDateRangeEnd&:relativeTime&:owner';
export const COMPARE_QUERY_PARAMS = ':compare&:split&:dash';
