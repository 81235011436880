import {
  leaderboardClient,
  SearchLeaderboardTagsAttributePathEnum,
} from '@neptune/shared/core-apis-leaderboard-domain';

type FetchTagsParams = {
  projectIdentifier: string;
  query: string;
  search?: string;
  type?: string[];
  attributePath?: string;
};

export async function searchTags({
  projectIdentifier,
  query,
  search,
  type,
  attributePath,
}: FetchTagsParams): Promise<string[]> {
  const result = await leaderboardClient.searchLeaderboardTags({
    projectIdentifier,
    params: {
      query: { query },
    },
    search,
    type,
    attributePath: attributePath as SearchLeaderboardTagsAttributePathEnum,
  });

  return result.tags;
}
