import React from 'react';
import { Multiselect } from '@neptune/shared/venus-ui';

import { ServiceAccountEmblem } from '@neptune/shared/service-accounts-ui';
import { ServiceAccount } from '@neptune/service-accounts-domain';

import { ServiceAccountItem } from './ServiceAccountItem';
import './SelectServiceAccount.less';

export type SelectServiceAccountProps = {
  onChange(account?: ServiceAccount): void;
  onTextChange?(text?: string): void;
  selected?: ServiceAccount;
  serviceAccounts?: ServiceAccount[];
};

export const SelectServiceAccount: React.FC<SelectServiceAccountProps> = ({
  onChange,
  onTextChange,
  selected,
  serviceAccounts,
}) => {
  const [text, setText] = React.useState('');

  const handleRemove = React.useCallback(() => {
    setText('');
    onChange();
  }, [onChange]);

  const handleSelectionChange = React.useCallback(
    (item: ServiceAccount) => {
      if (item === selected) {
        handleRemove();
      } else {
        onChange(item);
        setText('');
      }
    },
    [onChange, handleRemove, selected],
  );

  const handleAdd = React.useCallback(
    (value: string) => {
      if (!value) {
        return;
      }

      const item = serviceAccounts?.find(({ name }) => name === value);

      if (!item) {
        return;
      }

      handleSelectionChange(item);
    },
    [handleSelectionChange, serviceAccounts],
  );

  const handleTextChange = React.useCallback(
    (value: string) => {
      setText(value);
      onTextChange?.(value);
    },
    [onTextChange],
  );

  return (
    <Multiselect<ServiceAccount>
      className="select-service-account"
      data-role="select-service-account"
      text={text}
      placeholder="Type service account name"
      selectedItems={selected ? [selected] : []}
      selectedLimit={1}
      items={serviceAccounts || []}
      renderSelectedItem={({ displayName }: ServiceAccount) => (
        <ServiceAccountEmblem name={displayName} key={displayName} onRemove={handleRemove} />
      )}
      renderMenuItem={({ item, props, key, style }) => (
        <ServiceAccountItem
          name={item.displayName}
          key={key}
          style={style}
          onClick={(event) => props.onSelectionChange?.(item, event)}
        />
      )}
      onTextChange={handleTextChange}
      onSelectionChange={handleSelectionChange}
      onItemAdd={handleAdd}
    />
  );
};
