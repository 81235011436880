import { WidgetDefinition } from '../core/domain/widget-definition';
import { Widget } from '../core/widget';

export type ArtifactDiffWidget = Widget & {
  type: 'artifactDiff';
};

export function isArtifactDiffWidget(widget: Widget): widget is ArtifactDiffWidget {
  return widget.type === 'artifactDiff';
}

export const artifactDiffWidgetName = 'Artifact Diff';

export const artifactDiffWidgetDefinition: WidgetDefinition = {
  type: 'artifactDiff',
  name: artifactDiffWidgetName,
};
