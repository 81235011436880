import React from 'react';
import { Icon, Layout, Modal, Text } from '@neptune/shared/venus-ui';
import ConfirmationModal from 'components/confirmation-modal/ConfirmationModal';

type CancelSubscriptionModalProps = {
  onCancelSubscription(): void;
};

export const CancelSubscriptionModal: React.FC<CancelSubscriptionModalProps> = ({
  onCancelSubscription,
}) => {
  return (
    <ConfirmationModal
      type="cancelSubscription"
      title="Cancel subscription"
      confirmButtonText="Yes, remove my account"
      cancelButtonText="No, I want to keep my account"
      onConfirm={onCancelSubscription}
    >
      <Layout.Row spacedChildren="md">
        <Modal.Text>
          <Icon size="lg" fixedWidth glyph="trash" color="error" />
        </Modal.Text>
        <Modal.Text>
          Cancelling your subscription will{' '}
          <Text color="error">
            completely remove your account including runs, projects and payment information.
          </Text>
        </Modal.Text>
      </Layout.Row>
    </ConfirmationModal>
  );
};
