import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { WithPaddingRenderer } from 'neptune-core-ui/components/table/renderers';
import { OrganizationInvitation } from '@neptune/invitations-domain';
import { BaseMember } from '@neptune/user-management-domain';

export type RoleDropdownItemProps<T extends string> = {
  value: T;
  disabled: boolean;
  userId?: string;
  onSelect?: (value: T) => void;
};

type RoleRendererProps<T extends BaseMember> = {
  data: T & { organizationInvitationInfo?: OrganizationInvitation };
  params: {
    renderRoleDropdown: React.FunctionComponent<RoleDropdownItemProps<T['role']>>;
  };
};

export const RoleRenderer = <T extends BaseMember>({ data, params }: RoleRendererProps<T>) => {
  const { renderRoleDropdown } = params;

  const { editableRole } = data;

  const { username: userId } = data.registeredMemberInfo || {};

  const isInvitation = data.invitationInfo != null || data.organizationInvitationInfo != null;
  const disabled = !editableRole || isInvitation;

  const dropdown = renderRoleDropdown({
    userId,
    value: data.role,
    disabled,
  });

  return <WithPaddingRenderer>{dropdown}</WithPaddingRenderer>;
};
