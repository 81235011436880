import React from 'react';
import { useSelector } from 'react-redux';

import config from 'config';
import { isOnPremiseDeployment } from 'common/deploymentModes';
import { getCurrentUserCreated, getCurrentUsername } from 'state/users/current-user/selectors';
import { useVisibilityChange } from '@neptune/shared/common-ui';
import { getProjectNameFromRouteParams } from 'state/selectors-global';
import { IntercomTour } from '@neptune/onboarding-business-logic';

const enrollmentDateOfPromptToExploreRunDetailsTour = new Date('2022-02-23');
const exampleProjectName = config.isProduction ? 'example-project-tensorflow-keras' : 'alpha2';

export const ExampleProjectUiTourTrigger: React.FC = () => {
  const onPrem = isOnPremiseDeployment();
  const username = useSelector(getCurrentUsername);
  const userCreated = useSelector(getCurrentUserCreated);
  const projectName = useSelector(getProjectNameFromRouteParams);
  const [started, setStarted] = React.useState(false);
  const [hidden, setHidden] = React.useState<boolean>();
  useVisibilityChange(setHidden);

  const userSignedUpAfterEnrollment = userCreated
    ? userCreated > enrollmentDateOfPromptToExploreRunDetailsTour
    : false;
  const isExampleProject = projectName === exampleProjectName;

  React.useEffect(() => {
    if (onPrem || started || hidden || !userSignedUpAfterEnrollment || !isExampleProject) {
      return;
    }

    IntercomTour.triggerTour(username, 'onboarding/day_1/example_project_ui_tour');
    setStarted(true);
  }, [onPrem, started, hidden, username, userSignedUpAfterEnrollment, isExampleProject]);

  return null;
};
