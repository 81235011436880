import { WidgetDefinition } from '../core/domain/widget-definition';
import { Widget } from '../core/widget';

export type TableWidget = Widget & {
  type: 'table';
  // Options are optional for backwards compatibility.
  options?: TableWidgetOptions;
};
export type TableWidgetOptions = {
  showNestedNamespaces: boolean;
};

export function isTableWidget(widget: Widget): widget is TableWidget {
  return widget.type === 'table';
}

export const tableWidgetName = 'Parameters table';

export function tableWidgetOptionsFromApiToDomain(options?: Record<string, any>) {
  return {
    // Default to true for backwards compatibility (this matches behavior before adding this setting).
    showNestedNamespaces: options?.showNestedNamespaces ?? true,
  };
}

export const tableWidgetDefinition: WidgetDefinition = {
  type: 'table',
  name: tableWidgetName,
  fromApiToDomain: tableWidgetOptionsFromApiToDomain,
};
