import React from 'react';
import { Dropdown, DropdownToggleInterface, Layout, bemBlock } from '@neptune/shared/venus-ui';
import { Stage } from '@neptune/shared/entity-domain';
import { StageEmblem } from '@neptune/shared/entity-ui';
import { StageTransition } from './StageTransition';
import './StageDropdown.less';

const block = bemBlock('stage-dropdown');

type StageDropdownProps = {
  stage: Stage;
  disabled?: boolean;
  short?: boolean;
  onChange?(newState: Stage): void;
};

export const StageDropdown: React.FC<StageDropdownProps> = ({
  stage,
  short,
  disabled = true,
  onChange,
}) => {
  const restStages = React.useMemo(
    () => Object.values(Stage).filter((currentItem: Stage) => currentItem !== stage),
    [stage],
  );

  return (
    <Dropdown
      autoWidthMenu="none"
      toggle={<StageDropdownToggle short={short} stage={stage} />}
      disabled={disabled}
    >
      {({ collapse }) => (
        <Dropdown.Menu className={block('menu')} spacedChildren="sm">
          <Layout.Column>
            {restStages.map((stateToChange) => (
              <Dropdown.Item
                key={stateToChange}
                className={block('menu-item')}
                alignItems="center"
                spacedChildren="xs"
                onClick={
                  onChange
                    ? () => {
                        onChange(stateToChange);
                        collapse();
                      }
                    : undefined
                }
                data-to={stateToChange}
              >
                <span>Change to</span>
                <StageTransition to={stateToChange} />
              </Dropdown.Item>
            ))}
          </Layout.Column>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

const StageDropdownToggle: React.FC<StageDropdownProps & DropdownToggleInterface> = ({
  stage,
  disabled = true,
  short,
  elementRef,
  pressed,
  onToggle,
}) => {
  return (
    <div
      className={block({
        element: 'toggle',
        modifiers: { disabled },
      })}
      ref={elementRef}
      data-role="stage-dropdown"
      onClick={!disabled ? onToggle : undefined}
    >
      <StageEmblem stage={stage} spacedChildren="xs">
        {!short ? (
          <>
            <span>{stage}</span>
            {!disabled && <Dropdown.Toggle.Icon pressed={pressed} />}
          </>
        ) : (
          <Layout.Element width={0} height={14} />
        )}
      </StageEmblem>
    </div>
  );
};
