import { createSelector } from 'reselect';
import { getExperimentDashboards } from 'state/project-dashboards/selectors';
import { RunDetailsTab } from '@neptune/project-runs-domain';

export const getDashboardTabs = createSelector(getExperimentDashboards, (dashboards) => {
  const detailsTabs: RunDetailsTab[] = dashboards.map((dashboard) => ({
    type: 'experiment',
    glyph: 'square',
    // we treat branchId as dashboardId to ensure backward compatibility
    dashboardId: dashboard.versionBranchId,
    routeParams: {
      detailsTab: 'dashboard',
      dashboardId: dashboard.versionBranchId,
    },
    label: dashboard.name,
  }));

  return detailsTabs;
});
