// Libs
import React, { ReactElement } from 'react';
import { bemBlock } from '@neptune/shared/venus-ui';

export interface FontProps {
  size?: 'xxs' | 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl' | 'xxl';
  weight?: 'regular' | 'semibold' | 'bold';
  style?: 'italic';
  wordBreak?: 'break-all' | 'break-word';
  textEllipsis?: boolean;
  color?: string;
  familyType?: 'sans' | 'code';
  className?: string;
  component?: string | ReactElement;
}

import './Font.less';

const block = bemBlock('font-component');

/** @deprecated use Text from @neptune/shared/venus-ui */
export const Font: React.FC<FontProps> = ({
  size,
  weight,
  style,
  wordBreak,
  textEllipsis,
  color,
  familyType,
  className,
  component = 'span',
  ...props
}) => {
  const cssClass = block({
    extra: className,
    modifiers: {
      size,
      weight,
      style,
      wordBreak,
      color,
      familyType,
      textEllipsis,
    },
  });

  if (typeof component === 'string') {
    return React.createElement(component, {
      className: cssClass,
      ...props,
    });
  }

  return React.cloneElement(component, {
    className: cssClass,
    ...props,
  });
};

export default Font;
