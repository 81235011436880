// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';

// Module
import ProjectKeyInput from './ProjectKeyInput';
import withProjectKeyGenerated from './withProjectKeyGenerated';
import withProjectKeyChecked from './withProjectKeyChecked';

const propTypes = {
  organizationId: PropTypes.string,
  projectName: PropTypes.string,
  projectKey: PropTypes.string,
  mode: PropTypes.oneOf(['add', 'edit']).isRequired,
  creatingMode: PropTypes.bool,
  handleProjectKeyChange: PropTypes.func,
  onValidationChange: PropTypes.func,
};
const defaultProps = {};

const EnhancedProjectKey = withProjectKeyGenerated(withProjectKeyChecked(ProjectKeyInput));

class ProjectKeyGenerator extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      changedByUser: false,
      error: '',
    };

    this.onChange = this.onChange.bind(this);
    this.onValidationChange = this.onValidationChange.bind(this);
  }

  getProjectKeyComponent() {
    if (this.props.mode === 'edit') {
      return ProjectKeyInput;
    }

    return EnhancedProjectKey;
  }

  onChange(event) {
    if (!this.state.changedByUser) {
      this.setState({ changedByUser: true });
    }

    const projectKeySetByUser = event.target.value || '';
    this.props.handleProjectKeyChange(projectKeySetByUser.toUpperCase());
  }

  onValidationChange({ error }) {
    if (this.state.error !== error) {
      this.setState({ error });
    }

    if (isFunction(this.props.onValidationChange)) {
      this.props.onValidationChange({ isProjectKeyValid: error === '' });
    }
  }

  render() {
    const { projectKey, projectName, organizationId, handleProjectKeyChange, inputClassName } =
      this.props;

    const { changedByUser, error } = this.state;
    const ProjectKeyComponent = this.getProjectKeyComponent();

    return (
      <ProjectKeyComponent
        disabled={this.props.mode === 'edit'}
        projectKey={projectKey}
        projectName={projectName}
        organizationId={organizationId}
        error={error}
        canValidateProjectKey={changedByUser}
        canGenerateProjectKey={!changedByUser}
        onChange={this.onChange}
        onValidationChange={this.onValidationChange}
        handleProjectKeyChange={handleProjectKeyChange}
        inputClassName={inputClassName}
      />
    );
  }
}

ProjectKeyGenerator.propTypes = propTypes;
ProjectKeyGenerator.defaultProps = defaultProps;

export default ProjectKeyGenerator;
