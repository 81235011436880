import React from 'react';
import { Checkbox, Layout, Link, bemBlock } from '@neptune/shared/venus-ui';
import { Tag } from './Tag';
import './TagRow.less';

const block = bemBlock('tag-row');

type TagRowProps = {
  className?: string;
  color?: string;
  tag: string;
  allCount: number;
  applyCount: number;
  initialCount: number;
  disabled?: boolean;
  actionText: string;
  onChange: (tag: string, checked: boolean) => void;
  onAction: (tag: string) => void;
};

export const TagRow: React.FC<TagRowProps> = ({
  className,
  color,
  tag,
  allCount,
  initialCount,
  applyCount,
  disabled = false,
  actionText,
  onAction,
  onChange,
}) => {
  let label;

  if (applyCount === allCount && initialCount !== allCount) {
    label = 'Tag will be assigned to all selected runs';
  }

  if (applyCount === 0 && initialCount !== 0) {
    label = 'Tag will be removed from all selected runs';
  }

  return (
    <Layout.Row
      className={block({ extra: className })}
      alignItems="center"
      span="auto"
      spacedChildren="md"
      data-role="tag-row"
      withPadding="xs"
    >
      <Checkbox
        disabled={disabled}
        checked={applyCount === allCount}
        indeterminate={applyCount !== 0 && applyCount !== allCount}
        title={tag}
        onChange={(ev, { checked }) => onChange(tag, checked)}
      />
      <Tag className={block('tag')} color={color} tag={tag} />
      <div className={block('label')}>{label}</div>
      {initialCount !== applyCount && onAction && (
        <Link className={block('link')} onClick={() => onAction(tag)}>
          {' '}
          {actionText}{' '}
        </Link>
      )}
    </Layout.Row>
  );
};
