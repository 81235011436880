import React from 'react';

import { Dialog } from '@neptune/shared/venus-ui';

export const OnlyAdminModal: React.FC<{ isOpen: boolean; close: () => void }> = ({
  isOpen,
  close,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      title="Cannot change access rights"
      primaryButtonLabel="OK"
      onPrimaryButtonClick={close}
      onClose={close}
    >
      <Dialog.Text>
        This workspace needs to have at least one Admin, and you are the only Admin at the moment.
        To be able to downgrade yourself to a Member, you need to grant the Admin rights to someone
        else first.
      </Dialog.Text>
    </Dialog>
  );
};
