/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceAccountsTraitDTO
 */
export interface ServiceAccountsTraitDTO {
    /**
     * 
     * @type {number}
     * @memberof ServiceAccountsTraitDTO
     */
    serviceAccountsLimit: number;
}

export function ServiceAccountsTraitDTOFromJSON(json: any): ServiceAccountsTraitDTO {
    return ServiceAccountsTraitDTOFromJSONTyped(json, false);
}

export function ServiceAccountsTraitDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceAccountsTraitDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceAccountsLimit': json['serviceAccountsLimit'],
    };
}

export function ServiceAccountsTraitDTOToJSON(value?: ServiceAccountsTraitDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceAccountsLimit': value.serviceAccountsLimit,
    };
}


