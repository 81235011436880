// Libs
import React from 'react';
import { Layout, Section, bemBlock } from '@neptune/shared/venus-ui';

// App

// Module
import { ServiceAccountHeader } from './ServiceAccountHeader';
import { ServiceAccountCreatedBy } from './ServiceAccountCreatedBy';
import { ServiceAccountActivatedContent } from './ServiceAccountActivatedContent';
import { ServiceAccountDeactivatedContent } from './ServiceAccountDeactivatedContent';
import './ServiceAccountTile.less';

const block = bemBlock('service-account-tile');

type ServiceAccountTileProps = {
  id: string;
  className?: string;
  name: string;
  createdBy: string;
  createdDate: Date;
  keyGeneratedBy?: string;
  keyGeneratedDate?: Date;
  onResetToken?: () => void;
  onDeactivateToken?: () => void;
  deactivatedBy?: string;
  deactivatedDate?: Date;
  onReactivateToken?: () => void;
  activated?: boolean;
  userComponent: React.FC<{ user: string }>;
  apiTokenSectionElement?: React.ReactElement;
};

export const ServiceAccountTile: React.FC<ServiceAccountTileProps> = ({
  id,
  className,
  name,
  createdBy,
  createdDate,
  keyGeneratedBy,
  keyGeneratedDate,
  onResetToken,
  onDeactivateToken,
  onReactivateToken,
  deactivatedBy,
  deactivatedDate,
  activated = true,
  userComponent: UserWithAvatar,
  apiTokenSectionElement,
}) => {
  const menu = React.useMemo(() => {
    const items = [];

    if (onDeactivateToken && activated) {
      items.push({
        caption: 'Deactivate',
        action: onDeactivateToken,
        dataRole: 'deactivate-account',
      });
    }

    return items.length > 0 ? items : undefined;
  }, [activated, onDeactivateToken]);

  return (
    <Section
      className={block({ modifiers: { activated }, extra: className })}
      spacedChildren="md"
      withBorder
      data-role="service-account"
      data-id={id}
      data-name={name}
      data-activated={activated}
    >
      <Layout.Column withPadding={['md', 'md', 'none']} spacedChildren="sm">
        <ServiceAccountHeader name={name} menu={menu} />
        <ServiceAccountCreatedBy
          createdBy={createdBy}
          createdDate={createdDate}
          userComponent={UserWithAvatar}
        />
      </Layout.Column>
      {activated ? (
        <ServiceAccountActivatedContent
          apiTokenSectionElement={apiTokenSectionElement}
          keyGeneratedBy={keyGeneratedBy}
          keyGeneratedDate={keyGeneratedDate}
          userComponent={UserWithAvatar}
          onResetToken={onResetToken}
        />
      ) : (
        <ServiceAccountDeactivatedContent
          deactivatedBy={deactivatedBy}
          deactivatedDate={deactivatedDate}
          userComponent={UserWithAvatar}
          onReactivateToken={onReactivateToken}
        />
      )}
    </Section>
  );
};
