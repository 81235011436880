import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ExperimentToolbarInfo } from '@neptune/shared/dashboard-ui';
import { usePermissionEnhancedEntry } from '@neptune/shared/project-business-logic';
import { getCurrentBasicEntity } from '@neptune/current-entity-business-logic';

import { DetailsDropdownMenuContainer } from './DetailsDropdownMenuContainer';
import {
  getEntityCustomId,
  getEntityFailed,
  getEntityState,
  getEntityShortId,
} from '@neptune/shared/entity-domain';
import { getEntityDisplayName } from '@neptune/shared/entity-util';
import { getNameFromAttributes } from 'domain/experiment/attribute';
import { addNotification } from 'state/ui/global/notification-popup/actions';

type EntityToolbarInfoProps = {
  trashBackLink?: React.ReactElement;
};

const DROPDOWN_CONSTRAINTS = [{ to: 'window', attachment: 'together' }];

export const EntityToolbarInfo = ({ trashBackLink }: EntityToolbarInfoProps) => {
  const dispatch = useDispatch();
  const entity = useSelector(getCurrentBasicEntity);
  const entityWithPermissions = usePermissionEnhancedEntry(entity);

  let heading = '';
  let shortId: string;
  let experimentName: string | undefined;

  if (entityWithPermissions) {
    shortId = getEntityShortId(entityWithPermissions);
    experimentName = getNameFromAttributes(entityWithPermissions.attributes);
    heading = getEntityDisplayName(shortId, experimentName);
  }

  const handleCopy = React.useCallback(async () => {
    if (!heading) {
      return;
    }

    await navigator.clipboard.writeText(heading);
    dispatch(addNotification('success', 'Copied to clipboard!'));
  }, [heading, dispatch]);

  if (!entity || !entityWithPermissions) {
    return null;
  }

  const state = getEntityState(entity);
  const failed = getEntityFailed(entity);
  const customRunId = getEntityCustomId(entity);

  return (
    <ExperimentToolbarInfo
      state={state}
      failed={failed}
      heading={heading}
      onCopy={handleCopy}
      caption={customRunId}
      trashBackLink={trashBackLink}
      menu={
        <DetailsDropdownMenuContainer
          constraints={DROPDOWN_CONSTRAINTS}
          entityWithPermissions={entityWithPermissions}
        />
      }
    />
  );
};
