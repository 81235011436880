export enum OwnerType {
  User = 'User',
  ServiceAccount = 'ServiceAccount',
}

export type OwnerEntry = {
  type: OwnerType;
  value: string;
  match?: string;
};
