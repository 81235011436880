// Libs
import React from 'react';
import { bemBlock, Icon } from '@neptune/shared/venus-ui';

// Module
import { Stage } from '@neptune/shared/entity-domain';
import { StageEmblem } from '@neptune/shared/entity-ui';
import './StageTransition.less';

type StageTransitionProps = {
  from?: Stage;
  to: Stage;
};

const block = bemBlock('stage-transition');

export const StageTransition: React.FC<StageTransitionProps> = ({ from, to }) => {
  return (
    <span className={block()}>
      {from && <StageEmblem stage={from}>{from}</StageEmblem>}
      &nbsp;
      <Icon className={block('icon')} color="text-alt" glyph="long-arrow-alt-right" />
      &nbsp;
      <StageEmblem stage={to}>{to}</StageEmblem>
    </span>
  );
};
