// Libs
import React from 'react';
import { ActiveListElementProvider, Dropdown } from '@neptune/shared/venus-ui';

// App
import { isFetchStatusPending, fetchStatus as FetchStatus } from 'state/fetch-status';

export type RoleDropdownProps<T extends string> = {
  roleLabels: Record<T, string>;
  availableRoles: T[];
  value: T;
  disabled?: boolean;
  fetchStatus?: FetchStatus;
  onSelect?: (role: T) => void;
  'data-role'?: string;
};

export const RoleDropdown = <T extends string>({
  roleLabels,
  availableRoles,
  value,
  fetchStatus = FetchStatus.NONE,
  disabled,
  onSelect,
  'data-role': dataRole = 'role-dropdown',
  ...props
}: RoleDropdownProps<T>) => {
  return (
    <Dropdown
      toggle={
        <Dropdown.Toggle
          span="greedy"
          label={roleLabels[value]}
          data-role={dataRole}
          variant="basic"
        />
      }
      disabled={disabled || isFetchStatusPending(fetchStatus)}
      {...props}
    >
      {({ collapse }) => (
        <Dropdown.Menu onClick={collapse} data-role={`${dataRole}-dropdown-menu`}>
          <ActiveListElementProvider
            entries={availableRoles}
            onActivate={(event, role) => {
              onSelect?.(role);
              collapse();
            }}
          >
            {({ entry: role, index, isSelected }) => (
              <Dropdown.Item
                active={isSelected}
                label={`${roleLabels[role]}`}
                data-role="dropdown-item"
                data-value={role}
                key={index}
                onClick={() => onSelect?.(role)}
              />
            )}
          </ActiveListElementProvider>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};
