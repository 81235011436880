import { AppState } from 'state/types';

import { RouterState } from '@neptune/shared/routing-domain';

import { RouteHistoryState } from './route-history-types';

export function getRouteHistoryState(state: any): RouteHistoryState {
  return state.routeHistory;
}

export function getRouteHistory(state: AppState): RouterState[] {
  return getRouteHistoryState(state);
}
