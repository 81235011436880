import React from 'react';

import { StageDropdown } from '@neptune/model-registry-common-ui';
import { useStageChange } from '@neptune/model-registry-common-business-logic';
import {
  Entity,
  StageConverter,
  getEntityStage,
  EntityChanges,
} from '@neptune/shared/entity-domain';

type StageDropdownContainerProps = Omit<React.ComponentProps<typeof StageDropdown>, 'stage'> & {
  modelVersion: Entity | undefined;
  onEntityChange: (entityChanges: Partial<EntityChanges>) => Promise<void>;
};

export const StageDropdownContainer: React.FC<StageDropdownContainerProps> = ({
  modelVersion,
  onEntityChange,
  ...restProps
}) => {
  const stageText = modelVersion ? getEntityStage(modelVersion) : undefined;
  const stage = stageText !== undefined ? StageConverter.stringToStage(stageText) : undefined;
  const { requestStageChange } = useStageChange({ modelVersion, onEntityChange });

  if (!stage) {
    return null;
  }

  return <StageDropdown stage={stage} onChange={requestStageChange} {...restProps} />;
};
