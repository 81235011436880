import { filter, Observable } from 'rxjs';

import {
  getProjectNotificationStream,
  ProjectNotification,
} from '@neptune/shared/core-project-business-logic';

export type MetadataChangedNotification = {
  messageType: 'MetadataChanged';
  messageBody: {
    id: string;
    type: string;
  };
};

export function getEntitiesNotificationStream(
  projectId: string,
  entityIds?: string[],
): Observable<MetadataChangedNotification> {
  const projectNotifications$ = getProjectNotificationStream({ projectId });

  return projectNotifications$.pipe(
    filter(isMetadataChangedNotification),
    filter((notification) => !entityIds || entityIds.includes(notification.messageBody.id)),
  );
}

function isMetadataChangedNotification(
  notification: ProjectNotification,
): notification is MetadataChangedNotification {
  return notification.messageType === 'MetadataChanged';
}
