// Libs
import React from 'react';
import { debounce } from 'lodash';
import { useFormik, FormikConfig, FormikValues } from 'formik';
import { useUpdateEffect } from 'react-use';

// Module
type UseDebouncedValidationFormikProps<Values extends FormikValues = FormikValues> = {
  debounceTime?: number;
} & FormikConfig<Values>;

export function useDebouncedValidationFormik<Values extends FormikValues>({
  debounceTime = 300,
  ...formikProps
}: UseDebouncedValidationFormikProps<Values>) {
  const formik = useFormik({
    ...formikProps,
    validateOnChange: false,
  });

  const debouncedValidate = React.useMemo(
    () => debounce(formik.validateForm, debounceTime),
    [formik.validateForm, debounceTime],
  );

  useUpdateEffect(() => {
    if (formik.isSubmitting) {
      debouncedValidate.cancel();
    }
  }, [formik.isSubmitting, debouncedValidate]);

  useUpdateEffect(() => {
    debouncedValidate(formik.values);
  }, [formik.values, debouncedValidate]);

  return formik;
}
