import React from 'react';

import { Dialog } from '@neptune/shared/venus-ui';

type OverrideViewModalProps = {
  isOpen: boolean;
  onClose(): void;
  onOverride(): void;
  onSaveAsNew(): void;
};

export const OverrideViewModal: React.FC<OverrideViewModalProps> = ({
  isOpen,
  onClose,
  onOverride,
  onSaveAsNew,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Save view"
      onPrimaryButtonClick={() => {
        onClose();
        onOverride();
      }}
      primaryButtonLabel="Override"
      primaryButtonRole="override-button"
      onSecondaryButtonClick={() => {
        onClose();
        onSaveAsNew();
      }}
      secondaryButtonLabel="Save as new view"
      secondaryButtonRole="save-as-new-button"
      role="override-view-modal"
    >
      <Dialog.Text>
        Someone has updated this view since you began editing it.
        <br />
        What do you want to do?
      </Dialog.Text>
    </Dialog>
  );
};
