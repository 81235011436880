// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { bemBlock } from '@neptune/shared/venus-ui';
// eslint-disable-next-line no-restricted-imports
import { Button, Icon } from 'neptune-core-ui/components';

// App
import { Input, Textarea } from '@neptune/shared/common-ui';

// Module
import './InputWithConfirmReset.less';

const propTypes = {
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  textarea: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

const defaultProps = {
  autoFocus: true,
  value: '',
};

const block = bemBlock('input-with-confirm-reset');

const InputWithConfirmReset = ({
  className,
  autoFocus,
  placeholder,
  maxLength,
  name,
  value,
  saveEnabled,
  textarea,
  onChange,
  onKeyDown,
  onSave,
  onReset,
}) => {
  const baseProps = {
    defaultValue: value,
    autoFocus,
    placeholder,
    maxLength,
    name,
    onFocus,
    onChange,
    onKeyDown,
  };
  const userInput = textarea ? renderTextArea(baseProps) : renderInput(baseProps);

  const formClasses = block({
    modifiers: {
      'buttons-at-bottom': textarea,
    },
    extra: [className],
  });

  const buttonClass = block('button');
  const iconClass = block('icon');

  return (
    <form className={formClasses} onSubmit={onSave}>
      {userInput}
      <div className={block('buttons-container')}>
        <Button
          type="submit"
          className={buttonClass}
          disabled={!saveEnabled}
          data-role="input-submit-button"
        >
          <Icon className={iconClass} glyph="ok" monospace />
        </Button>
        <Button className={buttonClass} data-role="input-reset-button" onClick={onReset}>
          <Icon className={iconClass} glyph="cross" monospace />
        </Button>
      </div>
    </form>
  );
};

function onFocus(event) {
  event.target.select();
}

function renderTextArea(props) {
  return (
    <Textarea
      className={block('textarea')}
      data-role="textarea-with-confirm-reset"
      rows="4"
      {...props}
    />
  );
}

function renderInput(baseProps) {
  return (
    <Input
      data-role="input-with-confirm-reset"
      className={block('text-input')}
      type="text"
      {...baseProps}
    />
  );
}

InputWithConfirmReset.propTypes = propTypes;
InputWithConfirmReset.defaultProps = defaultProps;

export default InputWithConfirmReset;
