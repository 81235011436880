import React from 'react';
import { EmptyView } from '@neptune/shared/common-ui';

export const ServiceAccountsPlaceholder: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <EmptyView
      variant="default"
      title="Service accounts are not available in your current pricing plan"
      subtitle="Upgrade your subscription to be able to create service accounts"
      icon="service-account"
      data-role="team-empty-state"
      action={
        <EmptyView.ActionButton
          icon="subscription"
          label="Upgrade your subscription"
          onClick={onClick}
          data-role="upgrade-button"
        />
      }
    />
  );
};
