import React from 'react';
import { CompareContext } from '@neptune/compare-domain';

export function useCompareContext() {
  const context = React.useContext(CompareContext);

  if (!context) {
    throw new Error('useCompareContext called outside CompareContextProvider');
  }

  return context;
}
