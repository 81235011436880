import React from 'react';
import { bemBlock, Button, Dropdown, DropdownToggleProps, Icon } from '@neptune/shared/venus-ui';

import './VersionsToggle.less';

const block = bemBlock('versions-toggle');

export const VersionsToggle: React.FC<
  DropdownToggleProps & { toggleVariant?: 'big' | 'small'; label?: string }
> = ({ toggleVariant = 'small', label, onCollapse, onExpand, onToggle, ...restProps }) => {
  if (toggleVariant === 'big') {
    return (
      <Dropdown.Toggle
        variant="basic"
        size="md"
        label={label}
        className={block()}
        onClick={onToggle}
        {...restProps}
      />
    );
  }

  return (
    <Button size="md" variant="basic" square onClick={onToggle} {...restProps}>
      <Icon glyph="clock" />
    </Button>
  );
};
