import React from 'react';
import { LeaderboardColumn } from '@neptune/shared/leaderboard-domain';
import { AppState } from 'state/types';
import { ColumnsRootKey } from './ColumnsRootKey';

export type ColumnsContextOptions = {
  columnsRootKey: ColumnsRootKey;
  columnsSetId: string;
  persistent?: boolean;
  selector: (state: AppState) => LeaderboardColumn[] | undefined;
};

export const ColumnsContext = React.createContext<ColumnsContextOptions>({
  columnsRootKey: ColumnsRootKey.NoKey,
  columnsSetId: 'undefined',
  selector: () => undefined,
});
