// Libs
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router5';
import { Layout } from '@neptune/shared/venus-ui';
import { handleError } from 'common/error-handler';
import { showGenericModal } from 'state/ui/modals/generic/actions';
import { useGenericModal } from '@neptune/shared/common-business-logic';

// App
import {
  CancelSubscriptionErrorModal,
  CancelSubscriptionModal,
  CancelSubscriptionButton,
} from '@neptune/user-management-ui';
import { getCurrentUser } from 'state/users/current-user/selectors';
import { openConfirmationModal } from 'state/ui/global/confirmation-modal/actions';
import { getOrganizationsList } from 'state/selectors';
import { CANCEL_SUBSCRIPTION_ERROR_MODAL } from '@neptune/user-management-domain';
import { cancelSubscriptionAndLogout } from '@neptune/user-management-business-logic';

// Module
import { createSelector } from 'reselect';
import { Organization } from '@neptune/shared/core-organizations-domain';

const getUserOrganizationId = createSelector(
  [getCurrentUser, getOrganizationsList],
  (currentUser, organizations: Organization[]) => {
    const userOrganization = organizations.find(
      (organization) => organization.name === currentUser?.username,
    );
    return userOrganization?.id;
  },
);

export const CancelSubscriptionButtonContainer: React.FC = withRouter(({ router }) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getUserOrganizationId);

  const { isOpen: isCancelSubscriptionErrorModalOpen, close: closeCancelSubscriptionErrorModal } =
    useGenericModal(CANCEL_SUBSCRIPTION_ERROR_MODAL);

  const onRequestCancelSubscription = React.useCallback(() => {
    dispatch(openConfirmationModal('cancelSubscription'));
  }, [dispatch]);

  const onExecuteCancelSubscription = React.useCallback(async () => {
    if (!organizationId) {
      return;
    }

    try {
      await cancelSubscriptionAndLogout(router, organizationId);
    } catch (e) {
      handleError(400, e, () => {
        dispatch(showGenericModal(CANCEL_SUBSCRIPTION_ERROR_MODAL));
      });
    }
  }, [dispatch, router, organizationId]);

  if (!organizationId) {
    return null;
  }

  return (
    <Layout.Element>
      <CancelSubscriptionButton onClick={onRequestCancelSubscription} />
      <CancelSubscriptionModal onCancelSubscription={onExecuteCancelSubscription} />
      <CancelSubscriptionErrorModal
        isOpen={isCancelSubscriptionErrorModalOpen}
        close={closeCancelSubscriptionErrorModal}
      />
    </Layout.Element>
  );
});
