// Libs
import React from 'react';
import { capitalize } from 'lodash';
import {
  Button,
  ButtonProps,
  DropdownToggleInterface,
  UITooltip,
  useTooltip,
  bemBlock,
} from '@neptune/shared/venus-ui';

// App
import { UnifiedExperimentStateIcon } from './experiment-state-icon/UnifiedExperimentStateIcon';
import { ExperimentState } from 'domain/experiment/experiment-state';
import { getReadableState } from 'common/state';
import { ErrorBoundaryContainer } from '@neptune/shared/common-feature';

// Module
import './ExperimentStatusToggle.less';

const block = bemBlock('experiment-status-toggle');

type ExperimentStatusToggleProps = {
  state?: ExperimentState;
  failed?: boolean;
} & DropdownToggleInterface &
  ButtonProps<{}>;

export const ExperimentStatusToggle: React.FC<ExperimentStatusToggleProps> = ({
  className,
  pressed,
  disabled,
  onToggle,
  onCollapse,
  onExpand,
  state,
  failed,
  ...restProps
}) => {
  const tooltipText = getHoverText(state, failed);
  const tooltipProps = useTooltip({
    disabled: disabled || pressed || !tooltipText,
    placement: 'bottom-center',
    triggerOffset: 0,
  });

  const cssClass = block({
    modifiers: {
      disabled,
      pressed,
    },
    extra: [className],
  });

  return (
    <span {...tooltipProps.triggerProps}>
      <Button
        className={cssClass}
        data-role="experiment-status-dropdown-toggle"
        variant="secondary"
        square
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          onToggle && onToggle(event);
        }}
        {...restProps}
      >
        <UnifiedExperimentStateIcon state={state} failed={failed} />
      </Button>
      <ErrorBoundaryContainer context="tooltip" silent>
        {!pressed &&
          tooltipProps.isOpen &&
          tooltipProps.renderLayer(
            <UITooltip {...tooltipProps.layerProps}>{tooltipText}</UITooltip>,
          )}
      </ErrorBoundaryContainer>
    </span>
  );
};

function getHoverText(state?: ExperimentState, failed?: boolean): React.ReactChild {
  const secondLine =
    state === 'running' ? 'Click to remotely stop or abort the run.' : 'Click to learn more.';
  const firstLine = `Status: ${state ? capitalize(getReadableState(state)) : ''}${
    failed === true ? ' (Failed)' : ''
  }`;

  return (
    <span>
      {firstLine} <br /> {secondLine}
    </span>
  );
}
