import { OrganizationTraits } from '@neptune/shared/core-organizations-domain';

export const isArchivingEnabled = (traits: OrganizationTraits): boolean => !!traits.activeProjects;

export const getActiveProjectQuota = (traits: OrganizationTraits): number | undefined =>
  traits.activeProjects?.currentQuota;

export const getEffectiveMaxQuota = (traits: OrganizationTraits): number =>
  traits.activeProjects?.maxQuota ?? Number.POSITIVE_INFINITY;

export const hasCapabilitiesToIncreaseQuota = (traits: OrganizationTraits): boolean =>
  !!traits.activeProjects?.quotaEditable;

const hasSpareQuota = (
  activeProjects: Required<OrganizationTraits>['activeProjects'],
  activeProjectsUsage: number | undefined,
) => (activeProjectsUsage ?? 0) < activeProjects.currentQuota;

export const hasCapabilitiesToCreateNewProject = (
  traits: OrganizationTraits,
  activeProjects?: number,
): boolean => (traits.activeProjects ? hasSpareQuota(traits.activeProjects, activeProjects) : true);

export const hasCapabilitiesToUnarchiveProject = (
  traits: OrganizationTraits,
  activeProjects?: number,
): boolean => (traits.activeProjects ? hasSpareQuota(traits.activeProjects, activeProjects) : true);
