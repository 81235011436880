import { Entity, getEntityAttribute } from '@neptune/shared/entity-domain';
import { GroupByContext } from '@neptune/shared/leaderboard-domain';
import { getAttributeValue } from 'domain/experiment/attribute';
import React from 'react';
import { useSelector } from 'react-redux';

const KEY_SEPARATOR = '\u0004';

type EntityData = { entityId?: Entity['id']; attributes: Entity['attributes'] };
type EntityOrEntityData = Pick<Entity, 'id' | 'attributes'> | EntityData;

function getEntityId(entity: EntityOrEntityData): string | undefined {
  if ('entityId' in entity) {
    return entity.entityId;
  }

  if ('id' in entity) {
    return entity.id;
  }
}

export function useGroupByEntities(
  entities: EntityOrEntityData[] | undefined,
): Record<string, string[]> | undefined {
  const groupByContext = React.useContext(GroupByContext);

  const groupOptions = useSelector(groupByContext.storageStrategy.selector);

  const groups = React.useMemo(() => {
    if (!groupOptions) {
      return undefined;
    }

    if (!groupOptions.groupBy) {
      return undefined;
    }

    if (!groupOptions.groupByFieldType) {
      return undefined;
    }

    if (!entities) {
      return undefined;
    }

    function groupByKey(entity: { attributes: Entity['attributes'] }) {
      return groupOptions?.groupBy
        ?.map((name, i) => {
          const entityType = groupOptions.groupByFieldType?.[i];
          const subProperty = groupOptions.groupByFieldAggregationMode?.[i];
          const value = entityType
            ? getAttributeValue(
                getEntityAttribute(entity, name, entityType),
                entityType,
                subProperty,
              )
            : undefined;
          return `${value}`;
        })
        .join(KEY_SEPARATOR);
    }

    const result: Record<string, string[]> = {};
    entities.forEach((entity) => {
      const key = groupByKey(entity);

      if (key == null) {
        return;
      }

      if (!result[key]) {
        result[key] = [];
      }

      result[key].push(getEntityId(entity) ?? '');
    });
    return result;
  }, [entities, groupOptions]);

  return groups;
}
