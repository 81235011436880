/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PricingPlanDTO,
    PricingPlanDTOFromJSON,
    PricingPlanDTOFromJSONTyped,
    PricingPlanDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PricingPlanDefinitionDTO
 */
export interface PricingPlanDefinitionDTO {
    /**
     * 
     * @type {PricingPlanDTO}
     * @memberof PricingPlanDefinitionDTO
     */
    plan: PricingPlanDTO;
    /**
     * 
     * @type {string}
     * @memberof PricingPlanDefinitionDTO
     */
    displayName: string;
}

export function PricingPlanDefinitionDTOFromJSON(json: any): PricingPlanDefinitionDTO {
    return PricingPlanDefinitionDTOFromJSONTyped(json, false);
}

export function PricingPlanDefinitionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricingPlanDefinitionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plan': PricingPlanDTOFromJSON(json['plan']),
        'displayName': json['displayName'],
    };
}

export function PricingPlanDefinitionDTOToJSON(value?: PricingPlanDefinitionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plan': PricingPlanDTOToJSON(value.plan),
        'displayName': value.displayName,
    };
}


