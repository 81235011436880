export enum Stage {
  PRODUCTION = 'production',
  STAGING = 'staging',
  ARCHIVED = 'archived',
  NONE = 'none',
}

export abstract class StageConverter {
  static stringToStage(text: string): Stage | undefined {
    switch (text) {
      case 'production':
        return Stage.PRODUCTION;

      case 'staging':
        return Stage.STAGING;

      case 'archived':
        return Stage.ARCHIVED;

      case 'none':
        return Stage.NONE;

      default:
        return undefined;
    }
  }

  static fromDomainToApi(stage: Stage): string {
    switch (stage) {
      case Stage.PRODUCTION:
        return 'production';

      case Stage.STAGING:
        return 'staging';

      case Stage.ARCHIVED:
        return 'archived';

      case Stage.NONE:
        return 'none';
    }
  }
}
