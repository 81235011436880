import { makeEnterpriseRoute } from '@neptune/shared/enterprise-context-business-logic';
import { AppRoute } from '@neptune/shared/routing-domain';

const reportsRoute: AppRoute = {
  name: 'project.reports',
  path: '/reports',
};

const reportRoute: AppRoute = {
  name: 'project.reports.view',
  path: '/:reportId',
};

const reportVersionRoute: AppRoute = {
  name: 'project.reports.version',
  path: '/:reportId/version/:versionId',
};

const reportDraftRoute: AppRoute = {
  name: 'project.reports.draft',
  path: '/:reportId/draft',
};

export const reportsRoutes = [
  reportsRoute,
  makeEnterpriseRoute(reportsRoute),
  reportRoute,
  makeEnterpriseRoute(reportRoute),
  reportVersionRoute,
  makeEnterpriseRoute(reportVersionRoute),
  reportDraftRoute,
  makeEnterpriseRoute(reportDraftRoute),
];
