// Libs
import React from 'react';
import PropTypes from 'prop-types';

// App
import { InputWithError } from '@neptune/shared/common-ui';

// Module
const propTypes = {
  projectKey: PropTypes.string,
  error: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  inputClassName: PropTypes.string,
};

const ProjectKeyInput = ({ projectKey, disabled, error, onChange, inputClassName }) => (
  <InputWithError
    inputClassName={inputClassName}
    error={error}
    type="text"
    value={projectKey}
    disabled={disabled}
    onChange={onChange}
  />
);

ProjectKeyInput.propTypes = propTypes;

export default ProjectKeyInput;
