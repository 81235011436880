import { actionTypes } from 'redux-router5';

import { getOrganizationsList } from 'state/selectors';
import { OrganizationType } from '@neptune/shared/core-organizations-domain';
import { navigateTo } from 'router/actions';
import { isEnterpriseContext } from 'router/selectors';

/**
 * Check if we have to change the solo/enterprise context on app start.
 */
const routeContextRedirectMiddleware = ({ dispatch, getState }) => next => action => {

  const result = next(action);

  if (action.type === actionTypes.TRANSITION_SUCCESS && action.payload.previousRoute == null) {
    const { route } = action.payload;
    const organization = getOrganizationsList(getState())
      .find(organization => organization.name === route.params.organizationName);

    if (organization == null) {
      return result;
    }

    const organizationType = organization ? organization.organizationType : OrganizationType.individual;

    const isEnterpriseOrganization = organizationType === OrganizationType.team;
    if (isEnterpriseOrganization !== isEnterpriseContext(getState())) {
      dispatch(navigateTo(route.name, route.params, {
        replace: true,
        forceContext: organizationType,
      }));
    }
  }

  return result;
};

export default routeContextRedirectMiddleware;
