import actionTypes from 'state/actionTypes';

export function collapseSideBar(sideBarId) {
  return {
    type: actionTypes.UI_SIDE_BAR_COLLAPSE,
    payload: {
      sideBarId,
    },
  };
}

export function expandSideBar(sideBarId) {
  return {
    type: actionTypes.UI_SIDE_BAR_EXPAND,
    payload: {
      sideBarId,
    },
  };
}
