import { loadObject, persistObject } from '@neptune/content-addressable-store-domain';
import {
  CompoundSearchCriterion,
  isArrayOfCompoundSearchCriterion,
} from './compound-search-filters';

export async function persistFilter(newFilter: CompoundSearchCriterion[]) {
  return persistObject(newFilter);
}

export async function loadFilter(compoundSearchFilterKey: string | undefined) {
  if (!compoundSearchFilterKey) {
    return undefined;
  }

  return loadObject(compoundSearchFilterKey, isArrayOfCompoundSearchCriterion);
}
