import { useSelector } from 'react-redux';

import {
  getOrganizationNameFromRouteParams,
  getProjectNameFromRouteParams,
  getShortIdFromRouteParams,
} from 'state/selectors-global';
import { makeEntityIdentifier } from '@neptune/shared/entity-util';

export function useModelVersionIdentifier(): string {
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const projectName = useSelector(getProjectNameFromRouteParams);
  const versionShortId = useSelector(getShortIdFromRouteParams);

  return makeEntityIdentifier(organizationName, projectName, versionShortId);
}
