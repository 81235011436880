import type { Integration } from './types';

export const otherFrameworks: Integration = {
  id: 'otherFrameworks',
  title: 'Other frameworks',
  installCommand: '',
  scriptName: '',
  runCommand: '',
  link: '',
  script: '',
};
