import { AnyAction } from 'redux';
import { DEFAULT_AUTO_COMPARE_LIMIT } from '@neptune/compare-domain';
import { NThunkAction } from 'state/types';
import { getCurrentRoute, navigateTo } from '@neptune/shared/routing-business-logic';

export const COMPARE_LEADERBOARD_CHANGED_NOTIFICATION = 'COMPARE_LEADERBOARD_CHANGED_NOTIFICATION';

export function compareLeaderboardChangedNotification() {
  return {
    type: COMPARE_LEADERBOARD_CHANGED_NOTIFICATION,
    payload: {},
  } as const;
}

export function setAutoModeOn(autoCompareLimit?: number): NThunkAction<void, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const route = getCurrentRoute(state);

    dispatch(
      navigateTo(
        route.name,
        {
          ...route.params,
          compare: `auto-${autoCompareLimit || DEFAULT_AUTO_COMPARE_LIMIT}`,
        },
        { replace: true },
      ),
    );
  };
}

export type CompareActions = ReturnType<typeof compareLeaderboardChangedNotification>;
