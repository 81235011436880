// App
import { FloatSeriesCriterion, PartialSearchCriterion } from '../search-query-model';
import { criterionHasAttributeAndOperator } from '../search-query-model-core-utils';

// Module
import { CriterionHelpers } from './types';

export function isFloatSeriesCriterion(
  criterion: PartialSearchCriterion,
): criterion is FloatSeriesCriterion {
  return criterionHasAttributeAndOperator(criterion) && criterion.type === 'floatSeries';
}

export const floatSeriesCriterionHelpers: CriterionHelpers<FloatSeriesCriterion> = {
  getReadableValue(criterion) {
    return String(criterion.value);
  },
  getShortReadableValue(criterion) {
    return String(criterion.value);
  },
  isComplete(criterion): boolean {
    return criterion.value != null;
  },
};
