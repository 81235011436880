// Libs
import jdenticon from 'jdenticon';

// Module
jdenticon.config = {
  replaceMode: 'observe', // required by React
  backColor: '#055fb3',
  lightness: {
    color: [0.8, 1.0],
    grayscale: [0.82, 1.0],
  },
  saturation: {
    color: 1.0,
    grayscale: 0.62,
  },
};

const AVATARS: { [key: string]: string | undefined } = {};

export function getGenerativeAvatarImageDataURI(seed: string): string {
  const cachedValue = AVATARS[seed];

  if (cachedValue) {
    return cachedValue;
  }

  const svg = jdenticon.toSvg(seed, 125);
  return (AVATARS[seed] = `data:image/svg+xml,${encodeURIComponent(svg)}`);
}

export function getEmojiBasedOnTime(dateTime?: Date) {
  if (!dateTime) {
    return;
  }

  // Animals emoji unicode range
  const min = 0x1f400;
  const max = 0x1f43f;

  const codePoint = (dateTime.getTime() % (max - min + 1)) + min;

  return codePoint.toString(16).toUpperCase();
}
