import { EntityType } from '@neptune/shared/entity-domain';
import React from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';

type UseAttributeDataParams = {
  attributeName: string;
  entityId: string;
  entityType: EntityType;
  path?: string;
};

export const useAttributeData = ({
  attributeName,
  entityId,
  entityType,
  path,
}: UseAttributeDataParams) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<any>();
  const [data, setData] = React.useState<Blob>();

  React.useEffect(() => {
    doEffect();

    async function doEffect() {
      setIsLoading(true);
      setError(undefined);

      try {
        const response = await leaderboardClient.downloadAttribute({
          attribute: attributeName,
          holderIdentifier: entityId,
          holderType: entityType,
          path,
        });
        setData(response);
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    }
  }, [attributeName, entityId, entityType, path]);

  return {
    data,
    error,
    isLoading,
  };
};
