// Libs
import * as Yup from 'yup';
import config from 'config';

// App
import { ServiceAccountsCreateErrors } from './service-accounts-create-errors';

export const schema = Yup.object({
  name: Yup.string()
    .min(3, ServiceAccountsCreateErrors.TOO_SHORT)
    .max(50, ServiceAccountsCreateErrors.TOO_LONG)
    .required(ServiceAccountsCreateErrors.REQUIRED)
    .matches(
      new RegExp(config.neptuneUsernameRegExp),
      ServiceAccountsCreateErrors.INVALID_CHARACTERS,
    ),
});
