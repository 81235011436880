import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  bemBlock,
} from '@neptune/shared/venus-ui';
// eslint-disable-next-line no-restricted-imports
import { Button } from 'neptune-core-ui/components';

import image from './server-error.svg';
import './ServerErrorModal.less';


const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

const block = bemBlock('server-error-modal');


const ServerErrorModal = ({
  isOpen,
  onClose,
}) => {
  return (
    <Modal
      className={block()}
      isOpen={isOpen}
      variant="simple"
      onClose={onClose}
    >
      <Modal.Header onClose={onClose} />
      <Modal.Body>
        <img
          className={block('image')}
          src={image}
        />
        <h2 className={block('header')}>
          Oops! The server didn’t respond.
        </h2>
        <p>
          Please try again in a few seconds.
        </p>
        <Button
          className={block('button')}
          size="large"
          onClick={onClose}
        >
          Ok
        </Button>
      </Modal.Body>
    </Modal>
  );
};

ServerErrorModal.propTypes = propTypes;


export default ServerErrorModal;
