import { WidgetDefinition } from '../core/domain/widget-definition';
import { Widget } from '../core/widget';

export const fileWidgetName = 'File preview';

export function isFileWidget(widget: Widget): boolean {
  return widget.type === 'file';
}

export const fileWidgetDefinition: WidgetDefinition = {
  type: 'file',
  name: fileWidgetName,
};
