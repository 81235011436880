// Libs
import React from 'react';
import { LegacyEmblem, LegacyEmblemProps, bemBlock } from '@neptune/shared/venus-ui';

// App

// Module
import { Stage } from '@neptune/shared/entity-domain';
import './StageEmblem.less';

const block = bemBlock('stage-emblem');

type StageEmblemProps = LegacyEmblemProps & {
  stage: Stage;
};

export const StageEmblem: React.FC<StageEmblemProps> = ({ stage, ...restProps }) => {
  return <LegacyEmblem className={block({ modifiers: { stage } })} {...restProps} />;
};
