import { AggregationMode, ColumnType } from '../column/column-model';

export type GroupOptions = {
  groupBy?: string[];
  groupByFieldType?: ColumnType[];
  groupByFieldAggregationMode?: AggregationMode[];
  openedGroups?: {
    openedGroup: string;
    beforeToken?: string;
    continuationToken?: string;
  }[];
};

export type GroupByParams = Required<
  Pick<GroupOptions, 'groupBy' | 'groupByFieldType' | 'groupByFieldAggregationMode'>
>;

export function isGroupByParams(groupBy: GroupOptions | undefined): groupBy is GroupByParams {
  return (
    groupBy !== undefined &&
    groupBy.groupBy !== undefined &&
    groupBy.groupByFieldType !== undefined &&
    groupBy.groupByFieldAggregationMode !== undefined &&
    groupBy.groupBy.length === groupBy.groupByFieldType.length &&
    groupBy.groupBy.length === groupBy.groupByFieldAggregationMode.length
  );
}
