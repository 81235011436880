// Libs
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// App
import RefetchProvider from 'components/providers/RefetchProvider';
import { fetchProjectDashboards } from 'state/project-dashboards/actions';
import {
  getOrganizationNameFromRouteParams,
  getProjectNameFromRouteParams,
} from 'state/selectors-global';
import { IntercomTour } from '@neptune/onboarding-business-logic';
import { fetchCurrentProject } from '@neptune/current-project-business-logic';
import { makeProjectIdentifier } from '@neptune/shared/core-project-util';

export const CurrentProjectProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const organizationName = useSelector(getOrganizationNameFromRouteParams);
  const projectName = useSelector(getProjectNameFromRouteParams);
  const projectIdentifier = makeProjectIdentifier(organizationName, projectName);

  const resolverFn = React.useCallback(() => {
    dispatch(fetchCurrentProject({ projectIdentifier }));

    IntercomTour.updateLastVisitedProject(projectIdentifier);
    dispatch(fetchProjectDashboards({ projectIdentifier }));
  }, [dispatch, projectIdentifier]);

  return (
    <RefetchProvider
      resolverFn={resolverFn}
      projectIdentifier={projectIdentifier}
      children={children}
    />
  );
};
