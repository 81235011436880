import { actionTypes } from 'redux-router5';

import { setDefaultOrganizationName } from 'state/organizations/actions';

/**
 * Check if context changed and update the default organization.
 */
const routeContextPersistMiddleware = ({ dispatch }) => next => action => {

  const result = next(action);

  if (action.type === actionTypes.TRANSITION_SUCCESS) {
    dispatch(setDefaultOrganizationName());
  }

  return result;
};

export default routeContextPersistMiddleware;
