/* tslint:disable */
/* eslint-disable */
/**
 * scissors
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExpandResponse,
    ExpandResponseFromJSON,
    ExpandResponseToJSON,
    SnipRequestDTO,
    SnipRequestDTOFromJSON,
    SnipRequestDTOToJSON,
    SnipResponseDTO,
    SnipResponseDTOFromJSON,
    SnipResponseDTOToJSON,
} from '../models';

export interface ExpandRequest {
    key: string;
}

export interface SnipRequest {
    request: SnipRequestDTO;
}

/**
 * no description
 */
export class ScissorsApi extends runtime.BaseAPI {

    /**
     */
    async expandRaw(requestParameters: ExpandRequest): Promise<runtime.ApiResponse<ExpandResponse>> {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling expand.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/scissors/v1/expand/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExpandResponseFromJSON(jsonValue));
    }

    /**
     */
    async expand(requestParameters: ExpandRequest): Promise<ExpandResponse> {
        const response = await this.expandRaw(requestParameters);
        return await response.value();
    }

    /**
     * Snip content
     */
    async snipRaw(requestParameters: SnipRequest): Promise<runtime.ApiResponse<SnipResponseDTO>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling snip.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/scissors/v1/snip`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SnipRequestDTOToJSON(requestParameters.request),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SnipResponseDTOFromJSON(jsonValue));
    }

    /**
     * Snip content
     */
    async snip(requestParameters: SnipRequest): Promise<SnipResponseDTO> {
        const response = await this.snipRaw(requestParameters);
        return await response.value();
    }

}
